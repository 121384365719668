import { useRef } from "react";
import {
  Text,
  Flex,
  Search,
  FieldOption,
  ButtonGroup,
  FiltersModal,
  FilterOption,
} from "~/components/ui-library";
import { ViewType, UseRegistryReturnProps } from "./useRegistry";

type FacetsProps = {
  filtersLoading?: boolean;
  filtersErrors?: string[] | undefined;
  filterOptions: FilterOption[];
  filters: UseRegistryReturnProps["filters"];
  viewOptions: UseRegistryReturnProps["viewOptions"];
  facets: UseRegistryReturnProps["facets"];
  state: UseRegistryReturnProps["state"];
  onFacetsChange: UseRegistryReturnProps["onFacetsChange"];
  listPaginationText: UseRegistryReturnProps["listPaginationText"];
};

const DISABLE_FILTERS = true;

const Facets = ({
  filtersLoading,
  filtersErrors,
  filterOptions,
  filters,
  viewOptions,
  facets,
  state,
  onFacetsChange,
  listPaginationText,
}: FacetsProps) => {
  const ref = useRef<null | HTMLElement>(null);

  return (
    <>
      {!DISABLE_FILTERS && (
        <>
          <Flex center data-testid="registry-facets" gap={3}>
            <Search
              size="small"
              name="Search"
              label=""
              placeholder="Search for Names, Technologies, Checks, ..."
              value={facets.query}
              onChangeHandler={({ name, value }: FieldOption) => {
                onFacetsChange({
                  query: value as string,
                });
              }}
              fullWidth
            />
            <FiltersModal
              filtersLoading={filtersLoading}
              filtersErrors={filtersErrors}
              filterOptions={filterOptions}
              filters={filters}
              onChangeHandler={(values) => {
                onFacetsChange({
                  ...values,
                });
              }}
              refEle={ref.current}
            />
          </Flex>
          <div
            data-testid="registry-selected-filters"
            ref={(ele) => (ref.current = ele)}
            style={{
              paddingTop: 16,
            }}
          />
        </>
      )}
      <Flex alignItems="center" justifyContent="space-between" my={5}>
        <Text secondary>
          {state.isListView ? listPaginationText : state.paginationText}
        </Text>
        {!state.isProperties && (
          <ButtonGroup
            options={viewOptions}
            selectedValue={facets.viewType}
            onChange={(value) => {
              onFacetsChange({
                viewType: value as ViewType,
              });
            }}
          />
        )}
      </Flex>
    </>
  );
};

export default Facets;
