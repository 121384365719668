import { useEffect } from "react";
import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import {
  ReportViewedPage,
  TestIamActionsQuery,
  useGetOrganizationOverviewQuery,
  useReportViewedMutation,
} from "~/operations";
import { Org } from "~/lib/types";
import {
  NavDrawerIntegrationsIcon,
  NavDrawerSpacesIcon,
  NavDrawerVulnerabilitiesIcon,
  NewReleases,
  WarningAmberIcon,
} from "~/components/icons";
import { AssetScoresOverTime } from "./asset-scores-over-time";
import { TopVulnerabilitiesFound } from "./top-vulnerabilities-found";
import { NewThisWeek } from "./new-this-week";
import { IntegrationsOverview } from "./integrations-overview";
import { AssetsToday } from "./assets-today";
import { TopVulnerabilities } from "./top-vulnerabilities";
import { SpacesGlance } from "./spaces-glance";
import { EndOfLife } from "./end-of-life";
import { DashboardCard } from "./dashboard-card";
import { ExplorerIcon } from "~/components/icons/mondoo/explorer";

export type OrganizationDashboardPageProps = {
  org: Org;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function OrganizationDashboardPage({
  org,
}: OrganizationDashboardPageProps) {
  const { data } = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn: org.mrn } },
  });

  const [reportViewed] = useReportViewedMutation();

  // Send report viewed mutation on mount
  useEffect(() => {
    reportViewed({
      variables: {
        input: {
          page: ReportViewedPage.OrgView,
          info: {
            orgView: {
              orgMrn: org.mrn,
            },
          },
        },
      },
    });

    return () => {};
  }, []);

  document.title = `${org.name} · Mondoo`;

  return (
    <Box>
      {/* Heading */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h5" fontWeight="bold" textTransform="uppercase">
            {org.name}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            <Typography variant="inherit" fontWeight="bold" component="span">
              ID:
            </Typography>{" "}
            {org.id}
          </Typography>
        </Box>
        {org.subscriptionInfo && (
          <Chip
            label={org.subscriptionInfo.basePlan.name}
            variant="outlined"
            size="small"
            sx={{
              borderRadius: 1,
              color: "#2FC089",
              borderColor: "#2FC089",
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          />
        )}
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box sx={{ mb: 4 }}>
        <Typography
          variant="h4"
          component="h2"
          fontWeight="bold"
          textTransform="uppercase"
        >
          Overview
        </Typography>
        <Typography variant="caption" color="text.secondary">
          <Typography variant="inherit" fontWeight="bold" component="span">
            Report Date:
          </Typography>{" "}
          {data?.organizationOverview?.reportDate}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item lg={8} md={6} sm={12} container spacing={3}>
          <Grid item xs={12}>
            <DashboardCard
              title="Asset Scores Over Time"
              icon={<ExplorerIcon />}
              sx={{ height: 336 }}
            >
              <AssetScoresOverTime organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardCard
              title="Top New Vulnerabilities Found"
              subtitle="Critical & High CVSS Vulnerabilities Over The Last 4 Weeks"
              icon={<NavDrawerVulnerabilitiesIcon />}
            >
              <TopVulnerabilitiesFound organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardCard
              title="New This Week"
              subtitle="Critical & High CVSS Vulnerabilities From This Week"
              icon={<NewReleases />}
              sx={{ height: 288 }}
            >
              <NewThisWeek organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardCard
              title="Integrations Overview"
              icon={<NavDrawerIntegrationsIcon />}
              sx={{ height: 136 }}
            >
              <IntegrationsOverview organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} sm={12} container spacing={3}>
          <Grid item xs={12}>
            <DashboardCard
              title="Assets Today"
              icon={<ExplorerIcon />}
              sx={{ height: 336 }}
            >
              <AssetsToday organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardCard
              title="Top Vulnerabilities"
              icon={<NavDrawerVulnerabilitiesIcon />}
              sx={{ height: 256 }}
            >
              <TopVulnerabilities organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardCard
              title="Spaces At A Glance"
              icon={<NavDrawerSpacesIcon />}
              sx={{ height: 248 }}
            >
              <SpacesGlance organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
          <Grid item xs={12}>
            <DashboardCard
              title="End Of Life"
              subtitle="# Of Assets That Are End-Of-Life"
              icon={<WarningAmberIcon />}
              sx={{
                height: 144,
                ".card-header": { mb: 1 },
                ".card-body": { overflow: "hidden" },
              }}
            >
              <EndOfLife organizationMrn={org.mrn} />
            </DashboardCard>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
