import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, List, ListItem, Paper, Typography } from "@mui/material";
import { useFrameworkContext } from "../framework";
import { FilterBar } from "../../filter-bar";
import { PolicyIndicator } from "~/components/ui-library";
import { GetContentQuery, TrustLevel, useGetContentQuery } from "~/operations";
import { pluralize } from "~/lib/pluralize";
import { usePolicyIndicator } from "./hooks/usePolicyIndicator";
import { Space } from "~/lib/types";
import { useSearch } from "~/components/search/useSearch";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";

export type Policy = NonNullable<
  GetContentQuery["content"]
>["edges"][0]["node"];

export function Policies({}) {
  const { space, framework } = useFrameworkContext();
  const { handleFilterQuery, searchFilters } = useSearch();

  const {
    data: { content } = { content: null },
    error,
    loading,
  } = useGetContentQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        contentMrns: framework.policiesMrns,
        query: searchFilters.join(","),
      },
    },
    skip: framework.policiesMrns.length === 0,
  });

  const { edges } = content ?? {};
  const policies = edges?.map((edge) => edge.node) || [];
  const activePolicies = policies.filter((policy) => policy.action);
  const recommendedPolicies = policies.filter((policy) => !policy.assigned);

  return (
    <Box>
      <FilterBar
        searchId="compliance-policies"
        placeholder="compliance_policies"
        searchFilters={searchFilters}
        handleFilterQuery={handleFilterQuery}
      />
      <PolicyList
        title="Recommended Policies"
        policies={recommendedPolicies}
        space={space}
      />
      <PolicyList
        title="Enabled Policies"
        policies={activePolicies}
        space={space}
      />
    </Box>
  );
}

type PolicyListProps = {
  title: string;
  policies: Policy[];
  space: Space;
};

const PolicyList = ({ title, policies, space }: PolicyListProps) => {
  if (!policies.length) return <Box />;

  return (
    <Box sx={{ mb: 5 }}>
      <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
        {title}
      </Typography>
      <List component={Paper} sx={{ py: 0, overflow: "hidden" }}>
        {policies.map((policy) => {
          const policyId = policy.mrn?.split("/").pop();
          return <PolicyItem key={policyId} policy={policy} space={space} />;
        })}
      </List>
    </Box>
  );
};

type PolicyItemProps = {
  policy: Policy;
  space: Space;
};

export const PolicyItem = ({ policy, space }: PolicyItemProps) => {
  const {
    policyIndicatorActions,
    policyIndicatorLoading,
    policyIndicatorStatus,
  } = usePolicyIndicator({ policy, space });
  const [showIndicator, setShowIndicator] = useState(false);

  const authors = policy.authors?.map((author) => author.name).join(", ");
  const caption = authors
    ? `${policy.trustLevel} - by ${authors}`
    : `${policy.trustLevel}`;
  const version = policy.version;
  const certifiedBy = policy.certifiedBy?.name;
  const checksCount = policy.statistics.checks;

  // this is fragile but should handle everything we have at the moment
  const policyId = policy.mrn?.split("/").pop();
  const policyOwner =
    policy.trustLevel === TrustLevel.Private ? space.id : "mondoohq";
  const registryLink = `/space/registry/namespace/${policyOwner}/policies/${policyId}?spaceId=${space.id}`;

  return (
    <ListItem
      onMouseEnter={() => setShowIndicator(true)}
      onMouseLeave={() => setShowIndicator(false)}
      sx={{
        p: 3,
        "&:not(:last-child)": {
          borderBottom: (theme) =>
            `1px solid ${theme.palette.background.lighter}`,
        },
        "&:hover": {
          backgroundColor: (theme) => theme.palette.background.light,
        },
      }}
      component={RouterLink}
      to={registryLink}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs="auto"
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "left",
          }}
        >
          {policyIcon(policy.name, "large")}
        </Grid>
        <Grid item xs={10} sm container>
          <Grid item xs={10} sm>
            <Typography variant="h6" sx={{ pl: 3 }}>
              {policy.name}
            </Typography>
          </Grid>
          <Grid item xs={12} container spacing={0}>
            <Grid item xs={12} sm="auto">
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ pl: 3, fontWeight: 700, textTransform: "lowercase" }}
              >
                {caption}
              </Typography>
            </Grid>
            {version && (
              <Grid item xs={12} sm="auto">
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontWeight: 700 }}
                >
                  v {policy.version}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ display: { xs: "flex", sm: "none" } }}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ pl: 3, fontWeight: 700 }}
              >
                {checksCount} {pluralize("Check", checksCount)}
              </Typography>
            </Grid>
            {certifiedBy && (
              <Grid item xs={12} sm="auto">
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ pl: 3, fontWeight: 700 }}
                >
                  Certified by {certifiedBy}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          sm={2}
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          {showIndicator ? (
            <PolicyIndicator
              indicator={policyIndicatorStatus()}
              loading={policyIndicatorLoading}
              actions={policyIndicatorActions}
            />
          ) : (
            <Typography color="text.secondary" sx={{ pr: 1, fontWeight: 400 }}>
              {checksCount} {pluralize("Check", checksCount)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};
