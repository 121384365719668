import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Impact } from "~/components/impact";
import { CvssScore, EpssScore, RiskFactorStats } from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { ScoreLayout } from "./ScoreLayout";
import { ScoreImpact } from "./ScoreImpact";
import { ScoreBlastRadius } from "./ScoreBlastRadius";
import { ErrorBoundary } from "~/components/error-boundary";
import { formatEPSS } from "~/utils/formatter";
import { AggregateScoresNode } from "~/components/FirewatchPage";

type ScoreBlockProps = {
  mainScore?: number | null | undefined;
  baseScore?: number | null | undefined;
  blastRadius?:
    | NonNullable<AggregateScoresNode>["blastRadius"]
    | null
    | undefined;
  epssScore?: EpssScore | null | undefined;
  cvssScore?: Pick<CvssScore, "value"> | null | undefined;
  riskFactors?: Array<RiskFactorStats | null> | null | undefined;
  hasError: boolean;
  hasScore: boolean;
};

export const ScoreBlock = ({
  mainScore,
  baseScore,
  epssScore,
  cvssScore,
  riskFactors,
  blastRadius,
  hasError,
  hasScore = true,
}: ScoreBlockProps) => {
  if (hasError) {
    return <LoadOrError type="error" />;
  }

  return (
    <ErrorBoundary key="score-block">
      <Grid
        id="score-box"
        item
        xs={12}
        container
        component={Paper}
        sx={{
          p: 5,
          pr: 5,
          width: {
            sm: 320,
          },
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pb: 3,
            textAlign: "center",
          }}
        >
          <Grid item xs>
            <Box mb={3}>
              <Impact
                impact={mainScore || 0}
                options={{ fontSize: 24, size: 16, border: 2 }}
                isRiskScore
                isActive={hasScore}
                showText={hasScore}
                hideTooltip={!hasScore}
              />
              {!hasScore && (
                <Typography sx={{ fontWeight: 700, fontSize: "24px" }}>
                  UNKNOWN
                </Typography>
              )}
            </Box>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs rowGap={2}>
          {typeof baseScore !== "undefined" && (
            <ScoreLayout
              key="impact-score"
              component={
                <ScoreImpact
                  isActive={hasScore}
                  value={baseScore || 0}
                  isRiskScore
                />
              }
              title="Base Score"
              amount={
                hasScore ? (
                  <Typography sx={{ fontWeight: 700 }}>
                    {baseScore}
                    <Typography component="span" sx={{ fontSize: 10 }}>
                      /100
                    </Typography>
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: 700 }}>N / A</Typography>
                )
              }
            />
          )}
          {cvssScore && (
            <ScoreLayout
              key="cvss-score"
              component={
                <ScoreImpact
                  isCVSS
                  isActive={hasScore}
                  value={cvssScore.value || 0}
                />
              }
              title="CVSS Score"
              amount={
                <Typography sx={{ fontWeight: 700 }}>
                  {(cvssScore.value || 0) / 10}
                </Typography>
              }
            />
          )}
          {epssScore && (
            <ScoreLayout
              key="epss-score"
              title="EPSS Score"
              amount={
                <Typography sx={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                  {formatEPSS(epssScore)}
                </Typography>
              }
            />
          )}
          {typeof riskFactors !== "undefined" && (
            <ScoreLayout
              key="risk-factors"
              title="Risk Factors"
              amount={
                hasScore ? (
                  <Typography sx={{ fontWeight: 700 }}>
                    {riskFactors?.length || 0}
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: 700 }}>N / A</Typography>
                )
              }
            />
          )}
          {typeof blastRadius !== "undefined" && (
            <ScoreLayout
              key="blast-radius"
              title="Blast Radius"
              component={<ScoreBlastRadius blastRadius={blastRadius} />}
              amount={
                hasScore ? (
                  blastRadius?.affected || 0
                ) : (
                  <Typography sx={{ fontWeight: 700 }}>N / A</Typography>
                )
              }
            />
          )}
        </Grid>
      </Grid>
    </ErrorBoundary>
  );
};

const LoadOrError = ({ type }: { type: "loading" | "error" }) => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pb: 3,
        mb: 3,
        textAlign: "center",
      }}
    >
      {type === "loading" && <Loading what="check scores" vertical />}

      {type === "error" && <LoadingFailed what="check scores" />}
    </Grid>
  );
};
