import { Box } from "@mui/material";
import { PrintPoliciesList } from "./print-policies-list";
import { PrintPolicyReport } from "./print-policy-report";
import { PrintPolicyVulnReport } from "./print-policy-vuln-report";
import { Asset, AssetReport } from "~/pages/inventory/asset/types";

type PrintPoliciesProps = {
  asset: Asset;
  assetReport: AssetReport;
};

export function PrintPolicies({ asset, assetReport }: PrintPoliciesProps) {
  const policyEdges = assetReport.policies?.edges || [];
  const assetMrn = asset.mrn;
  return (
    <Box p={4}>
      <PrintPoliciesList assetReport={assetReport} />
      {policyEdges.map((edge) => {
        if (
          edge.node.policy.mrn.startsWith(
            "//policy.api.mondoo.app/policies/platform-vulnerability",
          )
        ) {
          return (
            <PrintPolicyVulnReport
              edge={edge}
              assetMrn={assetMrn}
              key={edge.node.policy.mrn}
            />
          );
        } else {
          return <PrintPolicyReport edge={edge} key={edge.node.policy.mrn} />;
        }
      })}
    </Box>
  );
}
