export const sharedPalette = {
  primary: {
    main: "#9147FF",
    light: "#A07AFF",
    dark: "#5000C1",
    darker: "#3F137C", // used mainly for the top nav bar
    gradient: "linear-gradient(to bottom right, #9147FF 0%, #A07AFF 100%)",
  },
  secondary: {
    main: "#4C35E8",
    light: "#8797FF",
    dark: "#2A15B7",
  },
  error: {
    main: "#FF5252",
    light: "#FF7070",
    gradient: "linear-gradient(to left, #FF5252 0%, #FF7070 100%)",
  },
  warning: {
    main: "#FBD153",
    light: "#FFED9F",
    dark: "#755800",
    gradient: "linear-gradient(to left, #FBD153 0%, #FFED9F 100%)",
  },
  critical: {
    main: "#FC3779",
    light: "#FD6094",
    gradient: "linear-gradient(to left, #FD5CA4 0%, #FF7AB6 100%)",
  },
  high: {
    main: "#CF2584",
    light: "#DD519F",
    gradient: "linear-gradient(to left, #CF2584 0%, #DD519F 100%)",
  },
  medium: {
    main: "#039CD8",
    light: "#33C3FB",
    gradient: "linear-gradient(to left, #039CD8 0%, #33C3FB 100%)",
  },
  low: {
    main: "#2CBFC9",
    light: "#5FD4DB",
    gradient: "linear-gradient(to left, #2CBFC9 0%, #5FD4DB 100%)",
  },
  // none and good are the same for CVE purposes; none will be treated differently
  // when looking at general-purpose reports
  none: {
    main: "#2FC089",
    lighter: "#6CD9AF",
    gradient: "linear-gradient(to left, #2FC089 0%, #6CD9AF 100%)",
  },
  unknown: {
    main: "#CCCCCC",
    lighter: "#D9D9D9",
    gradient: "linear-gradient(to left, #CCCCCC 0%, #D9D9D9 100%)",
  },
  errorScan: {
    main: "#FF7849",
    lighter: "#FE936E",
    gradient: "linear-gradient(to left, #FF7849 0%, #FE936E 100%)",
  },
  // score rating
  fail: {
    main: "#FC3779",
    lighter: "#FD6094",
    gradient: "linear-gradient(to left, #FD5CA4 0%, #FF7AB6 100%)",
  },
  poor: {
    main: "#CF2584",
    lighter: "#DD519F",
    gradient: "linear-gradient(to left, #CF2584 0%, #DD519F 100%)",
  },
  fair: {
    main: "#039CD8",
    lighter: "#33C3FB",
    gradient: "linear-gradient(to left, #33C3FB 0%, #33C3FB 100%)",
  },
  good: {
    main: "#2CBFC9",
    lighter: "#5FD4DB",
    gradient: "linear-gradient(to left, #2CBFC9 0%, #5FD4DB 100%)",
  },
  excellent: {
    main: "#2FC089",
    lighter: "#6CD9AF",
    gradient: "linear-gradient(to left, #2FC089 0%, #6CD9AF 100%)",
  },
  unrated: {
    main: "#CCCCCC",
    lighter: "#D9D9D9",
    gradient: "linear-gradient(to left, #CCCCCC 0%, #D9D9D9 100%)",
  },
};
