import {
  CaseStatus,
  ClientIntegrationType,
  PageInfo,
  TestIamActionsQuery,
  useGetCasesQuery,
  useGetJiraIntegrationsQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { ListCaseEdges } from "~/components/cases/types";
import { TabOption } from "~/components/ui-library";
import React, { useCallback } from "react";
import { CASES_TABS } from "~/components/cases/constants";

type UseCasesPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type UseCasesPageReturn = {
  closedCases: ListCaseEdges;
  openCases: ListCaseEdges;
  hasJiraIntegration: boolean;
  hasCloseCasePermissions: boolean;
  hasCreateCasePermissions: boolean;
  hasIntegrationCreatePermission: boolean;
  closedCasesDataLoading: boolean;
  openCasesDataLoading: boolean;
  showCreateFirstCaseScreen: boolean;
  showCreateJiraIntegrationScreen: boolean;
  hasCases: boolean;
  tabList: TabOption[];
  currentTab: number;
  fetchMoreClosedCases: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  fetchMoreOpenCases: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  openCasesTotalCount: number;
  closedCasesTotalCount: number;
  openCasesPageInfo: PageInfo | undefined;
  closedCasesPageInfo: PageInfo | undefined;
  onTabChange: (_event: React.SyntheticEvent, newValue: number) => void;
};

export function useCasesPage({
  space,
  availablePermissions,
}: UseCasesPageProps): UseCasesPageReturn {
  const [currentTab, setCurrentTab] = React.useState(CASES_TABS.OPEN);

  const hasCloseCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_UPDATECASE,
  );

  const hasCreateCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE,
  );

  const hasIntegrationCreatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );

  const handleChangeTab = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setCurrentTab(newValue);
    },
    [],
  );

  const {
    data: closedCasesData,
    loading: closedCasesDataLoading,
    fetchMore: fetchMoreClosedCases,
  } = useGetCasesQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        statuses: [CaseStatus.Closed],
        first: 10,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: openCasesData,
    loading: openCasesDataLoading,
    fetchMore: fetchMoreOpenCases,
  } = useGetCasesQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        statuses: [CaseStatus.Open],
        first: 10,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: jiraIntegrationsData } = useGetJiraIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
        filterQuery: {
          types: [ClientIntegrationType.TicketSystemJira],
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const hasJiraIntegration =
    (jiraIntegrationsData?.listClientIntegrations?.integrations?.length || 0) >
    0;

  const closedCases = closedCasesData?.cases?.edges || [];
  const closedCasesPageInfo = closedCasesData?.cases?.pageInfo;
  const closedCasesTotalCount = closedCasesData?.cases?.totalCount || 0;
  const openCases = openCasesData?.cases?.edges || [];
  const openCasesPageInfo = openCasesData?.cases?.pageInfo;
  const openCasesTotalCount = openCasesData?.cases?.totalCount || 0;

  const showCreateFirstCaseScreen =
    closedCases.length === 0 &&
    openCases.length === 0 &&
    hasJiraIntegration &&
    !openCasesDataLoading &&
    !closedCasesDataLoading;

  const showCreateJiraIntegrationScreen =
    !hasJiraIntegration &&
    closedCases.length === 0 &&
    openCases.length === 0 &&
    !closedCasesDataLoading &&
    !openCasesDataLoading;

  const hasCases = closedCases.length > 0 || openCases.length > 0;

  const tabList: TabOption[] = [
    { label: `Open Cases (${openCasesTotalCount})`, value: "open" },
    { label: `Closed Cases (${closedCasesTotalCount})`, value: "closed" },
  ];

  return {
    closedCases,
    openCases,
    hasJiraIntegration,
    hasCloseCasePermissions,
    hasCreateCasePermissions,
    hasIntegrationCreatePermission,
    openCasesDataLoading,
    closedCasesDataLoading,
    showCreateFirstCaseScreen,
    showCreateJiraIntegrationScreen,
    hasCases,
    tabList,
    currentTab,
    onTabChange: handleChangeTab,
    fetchMoreClosedCases,
    closedCasesTotalCount,
    closedCasesPageInfo,
    fetchMoreOpenCases,
    openCasesPageInfo,
    openCasesTotalCount,
  };
}
