import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ResourcesIcon } from "~/components/icons/mondoo/resources";
import { GridData, GridSegment } from "../grid-segment";
import { useLoadPolicyDashboardStatsQuery } from "~/operations";
import { Space } from "~/lib/types";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

export function PolicyDistributionCard({ sizes, space }: Props) {
  const [gridData, setGridData] = useState<GridData[] | null>(null);
  const { loading, error, data } = useLoadPolicyDashboardStatsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
  });

  useEffect(() => {
    if (data?.policyDashboardStats?.distribution) {
      const { distribution } = data.policyDashboardStats;
      const nextGridData = distribution.map((x) => {
        return {
          title: x?.category || "",
          score: x?.count || 0,
          total: 0,
        };
      });

      setGridData(nextGridData);
    }
  }, [data]);

  return (
    <HubCard
      {...{
        loading,
        sizes,
        title: "Policy Distribution",
        icon: <ResourcesIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
    >
      <Grid container spacing={3} sx={{ pt: 2, justifyContent: "flex-end" }}>
        {gridData
          ?.filter((x): x is GridData => x !== null)
          .map((data) => (
            <GridSegment key={data.title} data={data} showTotal={false} />
          ))}
      </Grid>
    </HubCard>
  );
}
