import { Fragment, ReactNode } from "react";
import { BlockOutlined } from "@mui/icons-material";
import { alpha, Typography, Box, useTheme } from "@mui/material";
import {
  CheckIcon,
  CloseIcon,
  SnoozeIcon,
  WarningAmberIcon,
} from "~/components/icons";
import { getColor } from "~/lib/colors";
import { ScoreCard } from "./types";

type PolicyResultIconProps = {
  result: {
    result: keyof ScoreCard["scoreStats"] | "errorscan";
    value: number;
  };
};

type IconWrapperProps = {
  children: ReactNode;
};

export function PolicyResultDistribution({ result }: PolicyResultIconProps) {
  const theme = useTheme();
  const iconColor =
    result.value === 0
      ? theme.palette.text.disabled
      : getColor(theme, result.result);

  const IconWrapper = ({ children }: IconWrapperProps) => {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
        sx={(theme) => ({
          width: theme.spacing(3),
          height: theme.spacing(3),
          borderRadius: "50%",
          background: alpha(iconColor, 0.1),
        })}
      >
        {children}
      </Box>
    );
  };

  let icon = <div />;
  switch (result.result) {
    case "passed":
      icon = <CheckIcon sx={{ fontSize: "16px", color: iconColor }} />;
      break;

    case "failed":
      icon = <CloseIcon sx={{ fontSize: "16px", color: iconColor }} />;
      break;

    case "errorscan":
      icon = (
        <WarningAmberIcon
          sx={{
            fontSize: "14px",
            color: iconColor,
          }}
        />
      );
      break;

    case "snoozed":
      icon = <SnoozeIcon sx={{ fontSize: "16px", color: iconColor }} />;
      break;

    case "disabled":
      icon = <BlockOutlined sx={{ fontSize: "16px", color: iconColor }} />;
      break;
  }

  return (
    <Fragment>
      <IconWrapper>{icon}</IconWrapper>
      <Typography sx={{ color: iconColor }}>{result.value}</Typography>
    </Fragment>
  );
}
