import { Fragment } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ComplianceFramework } from "~/operations";
import { Space } from "~/lib/types";
import { useEmptyState } from "./useEmptyState";

type Props = {
  contentType: string;
  frameworkMrn?: ComplianceFramework["mrn"];
  space?: Space;
};

export function EmptyState({ contentType, frameworkMrn, space }: Props) {
  const { emptyStateContent } = useEmptyState({ space, frameworkMrn });

  const values = emptyStateContent(contentType);

  if (!values) {
    return <Box />;
  }

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
    >
      {values.icon}
      <Typography
        variant="h5"
        fontWeight={700}
        sx={{ mt: 2, mb: 1, textAlign: "center" }}
      >
        {values.headline}
      </Typography>
      {values?.textContent && <Fragment>{values.textContent}</Fragment>}
      {values?.actionButtons && (
        <Box textAlign="center" pt={3}>
          {values.actionButtons.map((actionButton) => (
            <Button
              variant="outlined"
              component={RouterLink}
              to={actionButton.to}
              sx={{
                mx: 1,
                borderColor: "secondary.light",
                color: "secondary.light",
              }}
            >
              {actionButton.text}
            </Button>
          ))}
        </Box>
      )}
    </Paper>
  );
}
