import { BoxProps } from "@mui/material";
import Flex from "../../layouts/Flex";
import TextCaption from "../TextCaption";
import { getAuthors } from "../../../utils";
import { ListName } from "../../../types";

type CaptionProps = BoxProps & {
  trustLevel: string;
  authors: ListName[];
  version: string;
  certifiedBy: ListName;
};

const Caption = ({
  trustLevel,
  authors,
  version,
  certifiedBy,
  ...restProps
}: CaptionProps) => {
  return (
    <Flex gap={2} {...restProps}>
      {trustLevel && (
        <TextCaption secondary bold>
          {trustLevel?.toLowerCase()}{" "}
          {authors && authors?.length > 0 ? `— by ${getAuthors(authors)}` : ""}
        </TextCaption>
      )}
      {version && (
        <TextCaption secondary bold>
          Version {version}
        </TextCaption>
      )}
      {certifiedBy?.name && (
        <TextCaption secondary bold>
          {`Certified By — ${certifiedBy?.name}`}
        </TextCaption>
      )}
    </Flex>
  );
};

export default Caption;
