import { Routes, Route, Navigate } from "react-router-dom";
import { Org } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { PageLayout } from "~/routes";
import {
  OrganizationDashboardPage,
  OrganizationSpacesPage,
  OrganizationSettingsPage,
} from "~/pages/organization";
import {
  ApiTokens,
  AuditLog,
  Authentication,
  Billing,
  GeneralSettings,
  LegalCompliance,
  Members,
  ServiceAccounts,
  GenerateServiceAccountsForm,
} from "~/pages/organization/settings";
import { IamActions } from "~/lib/iam";
import { GenerateApiTokenForm } from "~/pages/space-settings/api-tokens/generate-api-token-form";
import { FullTextSearchResults } from "~/pages/Search";

type Props = {
  org: Org;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function OrganizationRoutes({ org, availablePermissions }: Props) {
  const hasBillingPermissions = IamActions.billing().every((permission) =>
    availablePermissions?.includes(permission),
  );

  // Custom route to selectively restrict access to the billing tab
  function HasBillingAccess() {
    if (hasBillingPermissions) {
      return <Billing {...{ org }} />;
    } else {
      return <Navigate replace to="/general" />;
    }
  }

  return (
    <Routes>
      <Route element={<PageLayout />}>
        <>
          <Route
            index
            element={
              <Navigate to={`overview?organizationId=${org.id}`} replace />
            }
          />
          <Route
            path="overview"
            element={
              <OrganizationDashboardPage
                org={org}
                availablePermissions={availablePermissions}
              />
            }
          />
          <Route
            path="spaces"
            element={
              <OrganizationSpacesPage
                org={org}
                availablePermissions={availablePermissions}
              />
            }
          />
          <Route
            path="settings"
            element={
              <OrganizationSettingsPage {...{ org, availablePermissions }} />
            }
          >
            <Route index element={<GeneralSettings />} />
            <Route path="general" element={<GeneralSettings />} />
            <Route path="members/*" element={<Members />} />
            <Route path="auditlog" element={<AuditLog />} />
            <Route path="authentication" element={<Authentication />} />
            <Route path="billing" element={<HasBillingAccess />} />
            <Route path="legal" element={<LegalCompliance />} />
            <Route path="api-tokens" element={<ApiTokens />} />
            <Route
              path="api-tokens/add"
              element={<GenerateApiTokenForm {...{ org }} />}
            />
            <Route
              path="serviceaccounts"
              element={<ServiceAccounts {...{ org, availablePermissions }} />}
            />
            <Route
              path="serviceaccounts/add"
              element={<GenerateServiceAccountsForm {...{ org }} />}
            />
          </Route>
        </>
      </Route>

      <Route element={<PageLayout maxWidth={false} />}>
        <Route path="search" element={<FullTextSearchResults org={org} />} />
      </Route>
    </Routes>
  );
}
