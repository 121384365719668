import { ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

type DataTableProps = {
  title: string;
  children: ReactNode;
};

export function HalfStyleDataTable({ title, children }: DataTableProps) {
  return (
    <TableContainer>
      <Table
        sx={{
          "tr:hover": {
            background: "inherit",
          },
        }}
      >
        <TableHead sx={{ backgroundColor: "inherit" }}>
          <TableRow
            sx={{
              "&:hover": {
                pointerEvents: "none",
                background: "inherit",
              },
            }}
          >
            <TableCell
              colSpan={2}
              sx={{
                pt: 0,
                pl: 0,
                pb: 1,
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.background.lightest}`,
                textTransform: "uppercase",
              }}
            >
              {title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
