import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { GridSegment } from "../grid-segment";
import { RemediatedShieldIcon } from "~/components/icons/mondoo/dashboard";
import {
  RemediatedBySeverity,
  useLoadVulnDashboardStatsQuery,
} from "~/operations";
import { Space } from "~/lib/types";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

export function RemediatedCard({ space, sizes }: Props) {
  const { loading, error, data } = useLoadVulnDashboardStatsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
  });

  const d = data?.vulnDashboardStats?.remediatedBySeverity;

  const packageData = (data: RemediatedBySeverity) => {
    // turn the raw data into a form that the Grid segment can understand
    return {
      title: data.severity,
      score: data.fixed,
      total: data.total,
    };
  };

  return (
    <HubCard
      {...{
        loading,
        sizes,
        title: "Total Vulnerabilities Remediated",
        icon: <RemediatedShieldIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
    >
      <Grid container spacing={2} sx={{ pt: 2 }}>
        {d
          ?.filter((x): x is RemediatedBySeverity => x !== null)
          .map((x) => (
            <GridSegment
              data={packageData(x)}
              key={x.severity}
              color
              segments={5}
            />
          ))}
      </Grid>
    </HubCard>
  );
}
