import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { PrintPolicyReportSummary } from "./print-policy-report-summary";
import { PrintPolicyReportQueries } from "./print-policy-report-queries";
import { PolicyEdge } from "./print-asset";

type PrintPolicyReportProps = {
  edge: PolicyEdge;
};

export function PrintPolicyReport({ edge }: PrintPolicyReportProps) {
  return (
    <PrintPolicyBox id={edge.node.policy.mrn}>
      <PrintPolicyReportSummary edge={edge} />
      <PrintPolicyReportQueries edge={edge} />
    </PrintPolicyBox>
  );
}

// also used by print-policy-vuln-report
export const PrintPolicyBox = styled(Box)`
  margin: ${(p) => p.theme.spacing(6, 0)};
  padding-top: ${(p) => p.theme.spacing(6)};
  border-top: 1px solid ${(p) => p.theme.palette.divider};
  page-break-before: always;
  &:last-child {
    margin-bottom: 0;
  }
`;
