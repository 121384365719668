import { Fragment, useState } from "react";
import {
  alpha,
  Box,
  IconButton,
  IconButtonProps,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BarChartIcon,
  LightbulbIcon,
  MoonIcon,
  SpinnerIcon,
} from "../../../icons";
import { Maybe } from "../../../types";

enum Indicator {
  DISABLED = "disabled",
  PREVIEW = "preview",
  ENABLED = "enabled",
}

export type PolicyIndicatorType = "disabled" | "preview" | "enabled";
export type PolicyIndicatorAction = IconButtonProps["onClick"];
export type PolicyIndicatorProps = {
  loading: Maybe<PolicyIndicatorType>;
  indicator: PolicyIndicatorType;
  actions: Record<
    "disablePolicy" | "enablePolicy" | "togglePreview",
    PolicyIndicatorAction
  >;
};

export const PolicyIndicator = ({
  actions,
  indicator,
  loading,
}: PolicyIndicatorProps) => {
  const buttons = [
    {
      type: "moon",
      active: indicator === Indicator.DISABLED,
      action: actions.disablePolicy,
    },
    {
      type: "lightbulb",
      active: indicator === Indicator.PREVIEW,
      action: actions.togglePreview,
    },
    {
      type: "bar",
      active: indicator === Indicator.ENABLED,
      action: actions.enablePolicy,
    },
  ];

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {buttons.map((button, i) => (
        <Fragment key={button.type}>
          {i > 0 && (
            <Box
              sx={{
                height: 4,
                width: 24,
                backgroundColor: "background.lightest",
              }}
            />
          )}
          <IndicatorButton
            loading={loading}
            type={button.type}
            active={button.active}
            action={button.action}
          />
        </Fragment>
      ))}
    </Box>
  );
};

const IndicatorButton = ({
  loading,
  type,
  active,
  action,
}: {
  loading: Maybe<PolicyIndicatorType>;
  type: string;
  active: boolean;
  action: PolicyIndicatorAction;
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const theme = useTheme();
  const getIndicatorIcon = (type: string) => {
    switch (type?.toLowerCase()) {
      case "moon":
        return loading === Indicator.DISABLED ? (
          <MotionSpinnerIcon />
        ) : (
          <MoonIcon />
        );
      case "lightbulb":
        return loading === Indicator.PREVIEW ? (
          <MotionSpinnerIcon />
        ) : (
          <LightbulbIcon />
        );
      case "bar":
        return loading === Indicator.ENABLED ? (
          <MotionSpinnerIcon />
        ) : (
          <BarChartIcon />
        );
      default:
        return <BarChartIcon />;
    }
  };

  const getColor = (type: string): string => {
    switch (type?.toLowerCase()) {
      case "moon":
        return loading === Indicator.DISABLED
          ? theme.palette.primary.main
          : active
            ? alpha(theme.palette.error.main, 0.5)
            : theme.palette.background.lightest;
      case "lightbulb":
        return loading === Indicator.PREVIEW
          ? theme.palette.primary.main
          : active
            ? theme.palette.primary.main
            : theme.palette.background.lightest;
      case "bar":
        return loading === Indicator.ENABLED
          ? theme.palette.primary.main
          : active
            ? theme.palette.primary.main
            : theme.palette.background.lightest;
      default:
        return theme.palette.background.lightest;
    }
  };

  const getLabel = (type: string): string => {
    switch (type?.toLowerCase()) {
      case "moon":
        return "Disabled";
      case "lightbulb":
        return "Preview";
      case "bar":
        return "Enabled";
      default:
        return "";
    }
  };

  const getLabelColor = (type: string): string => {
    switch (type?.toLowerCase()) {
      case "moon":
        return active
          ? alpha(theme.palette.error.main, 0.5)
          : theme.palette.primary.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        onClick={action}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          mb: 0.75,
          backgroundColor: getColor(type),
          "&:hover": {
            backgroundColor: alpha(getColor(type), 0.75),
          },
        }}
      >
        {getIndicatorIcon(type)}
      </IconButton>
      <Typography
        variant="caption"
        sx={{
          display: hover ? "block" : "none",
          position: "absolute",
          width: 1,
          top: "100%",
          left: (theme) => theme.spacing(-0.5),
          textAlign: "center",
          textTransform: "uppercase",
          fontSize: 10,
          color: getLabelColor(type),
        }}
      >
        {getLabel(type)}
      </Typography>
    </Box>
  );
};

export default PolicyIndicator;

const spinKeyframe = {
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
};

const MotionSpinnerIcon = () => {
  return (
    <SpinnerIcon
      sx={{
        animation: "spin 3s linear infinite",
        ...spinKeyframe,
      }}
    />
  );
};
