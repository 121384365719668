import { Fragment, ReactNode, useState } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ComplianceBreadcrumbs } from "~/pages/compliance/breadcrumbs";
import { Space } from "~/lib/types";
import { Loading, LoadingFailed } from "~/components/ui-library";
import { Code } from "~/components/code";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { DataQueryListView } from "~/components/data-queries/data-query-list-view";
import { DataQueryGridView } from "~/components/data-queries/data-query-grid-view";
import { EmptyState } from "~/components/empty-state/empty-state";
import { ViewSwitcher, ViewType } from "~/components/view-switcher";
import { Header } from "~/pages/compliance/compliance-table";
import { useSearchParams } from "react-router-dom";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import {
  AssetOrderField,
  MqueryAssetScoresQuery,
  OrderDirection,
  useMqueryAssetScoresQuery,
} from "~/operations";
import { Markdown } from "~/components/markdown";
import { useSearch } from "~/components/search/useSearch";

export type MqueryAssetScoresConnection = NonNullable<
  MqueryAssetScoresQuery["mqueryAssetScores"]
>;
export type MQueryAssetScoresEdges = NonNullable<
  MqueryAssetScoresConnection["edges"]
>;
export type MQueryAssetScoresEdge = MQueryAssetScoresEdges[0];
export type MQueryAssetScoresNode = NonNullable<MQueryAssetScoresEdge["node"]>;
export type MQueryAssetScore = Extract<
  MQueryAssetScoresNode,
  { __typename: "QueryAssetScore" }
>;

export type DataQueryProps = {
  space: Space;
};

export function DataQuery({ space }: DataQueryProps) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [searchParams] = useSearchParams();
  const { handleFilterQuery, searchFilters } = useSearch();
  const queryMrn = searchParams.get("queryMrn") as string;
  const selectedView = searchParams.get("view") || "list";

  const {
    data: { mqueryAssetScores } = {},
    error,
    loading,
  } = useMqueryAssetScoresQuery({
    skip: !queryMrn,
    variables: {
      input: {
        // after: null,
        // limit: null,
        // orderBy: {
        //   direction: OrderDirection.Desc,
        //   field: AssetOrderField.LastUpdated,
        // },
        query: null,
        queryMrn: queryMrn,
        scopeMrn: space.mrn,
      },
      first: 50,
      after: null,
      orderBy: {
        direction:
          (searchParams.get("direction") as OrderDirection) ||
          OrderDirection.Desc,
        field: AssetOrderField.Name,
      },
    },
  });

  if (error) {
    return <LoadingFailed what="Assets" />;
  }

  if (loading && !mqueryAssetScores) {
    return <Loading what="Assets" />;
  }

  const { edges } = mqueryAssetScores || {};
  const assets =
    edges?.flatMap((edge) =>
      edge.node?.__typename === "QueryAssetScore" ? edge.node : [],
    ) || [];

  let copyAssets = [...assets];

  // Filter assets by Asset name only until we get more robust filtering
  // from the API
  if (searchFilters.length > 0) {
    copyAssets = copyAssets?.filter((asset) => {
      return asset.assetName
        ?.toLowerCase()
        .includes(searchFilters.join(" ").toLowerCase());
    });
  }

  const handleView = () => {
    if (copyAssets.length === 0) {
      return <NoAssetsFound />;
    }

    if (selectedView === "list") {
      return (
        <Grid item xs={12}>
          <DataQueryListView
            assets={copyAssets}
            pageItems={pageItems}
            setPageItems={setPageItems}
            tableHeaders={tableHeaders}
            space={space}
            queryMrn={queryMrn}
          />
        </Grid>
      );
    }

    if (selectedView === "keyboard") {
      return (
        <DataQueryGridView
          assets={copyAssets}
          space={space}
          queryMrn={queryMrn}
        />
      );
    }
  };

  return (
    <Grid container rowGap={5}>
      <Grid item xs={12}>
        <ComplianceBreadcrumbs space={space} mb={"0px"} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
          {mqueryAssetScores?.title}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {/* QueryPack or Policy Name goes here */}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Markdown
          sx={{ color: "text.secondary" }}
          source={mqueryAssetScores?.description || ""}
        />
      </Grid>

      <TitleDivider title="Queries">
        <Grid item xs={12}>
          <Code className="javascript" copyButton>
            {mqueryAssetScores?.mql}
          </Code>
        </Grid>
      </TitleDivider>

      <TitleDivider title="Assets">
        <Grid container rowGap={3}>
          {!assets || assets?.length === 0 ? (
            <Grid item xs={12}>
              <EmptyState contentType="data-query-assets" />
            </Grid>
          ) : (
            <Fragment>
              <Grid item xs={12}>
                <FilterBar
                  searchId="compliance-data-query-assets"
                  placeholder="compliance_data_query_assets"
                  searchFilters={searchFilters}
                  handleFilterQuery={handleFilterQuery}
                  mb={0}
                />
              </Grid>
              <Grid
                item
                xs="auto"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography color="text.secondary">
                  Showing {copyAssets.length} of {assets.length} Assets:
                </Typography>
              </Grid>
              <Grid item xs sx={{ textAlign: "right" }}>
                <ViewSwitcher
                  views={[ViewType.List, ViewType.Keyboard]}
                  defaultView={ViewType.List}
                />
              </Grid>
              {handleView()}
            </Fragment>
          )}
        </Grid>
      </TitleDivider>
    </Grid>
  );
}

type TitleDividerProps = {
  title: string;
  children: ReactNode;
};

const TitleDivider = ({ title, children }: TitleDividerProps) => {
  return (
    <Grid container item alignItems="center">
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              display: "inline-block",
              pr: 3,
              pb: 1,
              fontWeight: 700,
            }}
          >
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      {children}
    </Grid>
  );
};

const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  {
    id: "PLATFORM",
    label: "Platform",
  },
];

export const NoAssetsFound = () => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          py: 10,
          border: "1px solid",
          borderColor: "text.secondary",
          borderStyle: "dashed",
          borderRadius: "4px",
        }}
      >
        <Typography color="text.secondary" textAlign="center">
          No assets found, please try using a different filter.
        </Typography>
      </Grid>
    </Grid>
  );
};
