import { AdjustedAggregateScoreType } from "~/components/FirewatchPage";
import { SuccessCard } from "~/components/SuccessCard/SuccessCard";

import {
  ChecklistIcon,
  NavDrawerPolicyIcon,
  NavDrawerVulnerabilitiesIcon,
  RocketIcon,
  VulnerabilityPassIcon,
} from "~/components/icons";
import { Space } from "~/lib/types";
import { AggregateScoreType } from "~/operations";

type useFirewatchDashboardCardProps = {
  pageType: AdjustedAggregateScoreType;
  space: Space;
};

export function useFirewatchDashboardCard({
  pageType,
  space,
}: useFirewatchDashboardCardProps) {
  const getNoEntriesText = (
    pageType: useFirewatchDashboardCardProps["pageType"],
  ) => {
    switch (pageType) {
      case AggregateScoreType.Vulnerability:
        return "Set up integrations and enable policies to determine your top vulnerabilities";
      case AggregateScoreType.Check: //fallthrough
      case AggregateScoreType.Policy:
        return "Set up integrations and enable policies to determine your top misconfigurations";

      default:
        return "Set up integrations and enable policies to determine your top security findings";
    }
  };

  const getSuccessCard = (
    pageType: useFirewatchDashboardCardProps["pageType"],
  ) => {
    switch (pageType) {
      case AggregateScoreType.Vulnerability:
        return (
          <SuccessCard
            headline="No vulnerabilities found"
            icon={
              <VulnerabilityPassIcon
                sx={{ fontSize: 38, color: "none.main" }}
              />
            }
          />
        );
      case AggregateScoreType.Check:
        return (
          <SuccessCard
            headline="No misconfigurations found"
            icon={<ChecklistIcon sx={{ fontSize: 38, color: "none.main" }} />}
          />
        );
      case "NewestRelevantVulnerabilities":
        return (
          <SuccessCard
            headline="No new relevant vulnerabilities found"
            icon={<RocketIcon sx={{ fontSize: 38, color: "none.main" }} />}
          />
        );

      case AggregateScoreType.Policy:
        return (
          <SuccessCard
            headline="There are no policy findings to display"
            icon={
              <NavDrawerPolicyIcon sx={{ fontSize: 38, color: "none.main" }} />
            }
          />
        );
      default:
        return <div></div>;
    }
  };

  const getData = (pageType: useFirewatchDashboardCardProps["pageType"]) => {
    const noEntriesText = getNoEntriesText(pageType);
    const successCard = getSuccessCard(pageType);

    switch (pageType) {
      case AggregateScoreType.Vulnerability:
        return {
          title: "Top Vulnerabilities",
          icon: <NavDrawerVulnerabilitiesIcon />,
          noEntriesText,
          successCard,
          disableSorting: true,
          titleTo: `/space/vulnerabilities/cves?spaceId=${space.id}`,
        };
      case AggregateScoreType.Check:
        return {
          title: "Top Security Findings",
          icon: <ChecklistIcon fontSize="small" />,
          noEntriesText,
          successCard,
          disableSorting: true,
          titleTo: `/space/security/checks?spaceId=${space.id}`,
        };
      case AggregateScoreType.Policy:
        return {
          title: "Top Policy Findings",
          icon: <NavDrawerPolicyIcon fontSize="small" />,
          noEntriesText,
          successCard,
          disableSorting: true,
        };
      case "NewestRelevantVulnerabilities":
        return {
          title: "New Relevant Vulnerabilities",
          icon: <RocketIcon fontSize="small" />,
          noEntriesText,
          successCard,
        };

      default:
        return {
          title: "",
          icon: <ChecklistIcon fontSize="small" />,
          noEntriesText,
          successCard,
        };
    }
  };

  const { title, icon, noEntriesText, successCard, disableSorting, titleTo } =
    getData(pageType);

  return {
    title,
    icon,
    noEntriesText,
    successCard,
    disableSorting,
    titleTo,
  };
}
