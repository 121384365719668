const pluralize = (word: string, count: number): string => {
  // "cts" check is specifically looking for and not altering the word "projects"
  if (count === 1 || word.slice(word.length - 3) === "cts") return word;

  if (word.slice(word.length - 3)?.toLowerCase() === "key") {
    return "keys";
  }

  return word[word.length - 1] === "y" ? `${word.slice(0, -1)}ies` : `${word}s`;
};

export default pluralize;
