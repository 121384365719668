import { styled } from "@mui/material/styles";

export const GaugeRow = styled("div")`
  margin: 10px;
  display: flex;
  align-items: center;
`;

export const GaugeRowItem = styled("div")`
  margin: 6px;
`;
