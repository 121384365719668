import { FC } from "react";
import ReactMarkdown from "markdown-to-jsx";
import { styled, SxProps, Theme } from "@mui/material/styles";
import { Code } from "~/components/code";

const StyledMarkdown = styled(ReactMarkdown)`
  a {
    text-decoration: underline;
  }
`;

const overrides = {
  pre: {
    component: Code,
    props: {
      copyButton: true,
    },
  },
};
const options = { forceBlock: true, overrides };
const defaultProps = { ...ReactMarkdown.defaultProps, options };

export const Markdown: FC<{
  source: string;
  copyButton?: boolean;
  dataId?: string;
  className?: string;
  sx?: SxProps<Theme>;
}> = (props) => {
  const markdownProps = { ...defaultProps, ...props };
  return <StyledMarkdown {...markdownProps}>{props.source}</StyledMarkdown>;
};
