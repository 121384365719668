import { Box, Tooltip } from "@mui/material";
import { SpacePolicyReportQuery } from "~/operations";

type SpacePolicyReport = Extract<
  NonNullable<SpacePolicyReportQuery["spacePolicyReport"]>,
  { __typename: "SpacePolicyReport" }
>;
type AssetGrades = SpacePolicyReport["assetGrades"];

type Props = {
  assetGrades: AssetGrades;
};

type Colors = {
  letter: "A" | "B" | "C" | "D" | "F" | "U" | "X";
  width: string;
  color: string; //theme colors
  total: number;
};

export function EnabledPolicyStatsBar({ assetGrades }: Props) {
  // Calculate the width percentage of each letter grade
  const getStop = (grade: number): number => {
    return Math.round((grade / assetGrades.total) * 100) || 0;
  };

  const hasNoScores = ({ __typename, total, ...assetGrades }: AssetGrades) => {
    const values = Object.values(assetGrades).reduce(
      (agg: number, value: number) => agg + value,
      0,
    );
    return Boolean(values < 1);
  };

  // determine the width of each grade and return all as an Array
  const fillColors = (): Colors[] => {
    const errorStop = getStop(assetGrades.X);
    const uStop = getStop(assetGrades.U);
    const fStop = getStop(assetGrades.F);
    const dStop = getStop(assetGrades.D);
    const cStop = getStop(assetGrades.C);
    const bStop = getStop(assetGrades.B);
    const aStop = getStop(assetGrades.A);

    return [
      {
        letter: "X",
        width: `${errorStop}%`,
        color: "error.main",
        total: assetGrades.X,
      }, // Errors
      {
        letter: "U",
        width: `${uStop}%`,
        color: "unknown.main",
        total: assetGrades.U,
      }, // Unknown
      {
        letter: "F",
        width: `${fStop}%`,
        color: "fail.main",
        total: assetGrades.F,
      }, // F
      {
        letter: "D",
        width: `${dStop}%`,
        color: "poor.main",
        total: assetGrades.D,
      }, // D
      {
        letter: "C",
        width: `${cStop}%`,
        color: "fair.main",
        total: assetGrades.C,
      }, // C
      {
        letter: "B",
        width: `${bStop}%`,
        color: "good.main",
        total: assetGrades.B,
      }, // B
      {
        letter: "A",
        width: `${aStop}%`,
        color: "excellent.main",
        total: assetGrades.A,
      }, // A
    ];
  };

  const grades = fillColors();
  // isEmpty means that the bar is empty because the policy has no assets to score
  const isEmpty = hasNoScores(assetGrades);

  return (
    <Box
      width={1}
      height={12}
      display="flex"
      sx={{
        backgroundColor: "background.default",
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      {/* if the policy has no assets, we display a completely different 
          tooltip and mock the width so we get full hover response */}
      {isEmpty && (
        <Tooltip title="No Assets For This Policy" placement="top">
          <Box width="100%" />
        </Tooltip>
      )}

      {grades.map((grade) => (
        <Tooltip
          key={grade.letter}
          title={`${grade.total} Assets Grade ${grade.letter}`}
          placement="top"
        >
          <Box width={grade.width} sx={{ backgroundColor: grade.color }} />
        </Tooltip>
      ))}
    </Box>
  );
}
