import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Space } from "~/lib/types";
import {
  AssetUrlScoreDistribution,
  AssetUrlSegment,
  GetAggregateScoresRefreshInfoDocument,
  useGetAggregateScoresRefreshInfoQuery,
  useGetAssetUrlStatsQuery,
  useRefreshAggregateScoresMutation,
} from "~/operations";
import * as d3 from "d3";

export type AssetUrlStats = Omit<AssetUrlScoreDistribution, "__typename">;

export type GraphTarget = {
  height: number;
  depth: number;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
};

export type AssetUrlFilterItem = Omit<AssetUrlSegment, "__typename">;
export type AssetUrlFilter = AssetUrlFilterItem[];

export const isAssetUrlFilterItem = (
  value: any,
): value is AssetUrlFilterItem => {
  return ["key", "value"].every((p) => p in value);
};

export const isAssetUrlFilter = (value: any): value is AssetUrlFilter => {
  return Array.isArray(value) && value.every(isAssetUrlFilterItem);
};

export type Datum = {
  path: AssetUrlFilter;
  pathId: string;
  name: string;
  score: number;
  stats: AssetUrlStats;
  value: number;
  hover?: boolean;
  arcVisible?: boolean;
  current?: GraphTarget;
  target?: GraphTarget;
};
export type Data = Datum[];

export type UseAssetUrlStatsProps = {
  space: Space;
};

export type UseAssetUrlStats = {
  space: Space;
  queryResult: ReturnType<typeof useGetAssetUrlStatsQuery>;
  data: Data;
  lastRefreshed: string;
  isRefreshing: boolean;
  onRefreshClick: () => void;
  activePath: AssetUrlFilter;
  setActivePath: Dispatch<SetStateAction<AssetUrlFilter>>;
};

export function useAssetUrlStats({
  space,
}: UseAssetUrlStatsProps): UseAssetUrlStats {
  const queryResult = useGetAssetUrlStatsQuery({
    variables: { input: { spaceMrn: space.mrn } },
  });

  const {
    data: refreshInfoData,
    loading: isRefreshInfoLoading,
    startPolling,
    stopPolling,
  } = useGetAggregateScoresRefreshInfoQuery({
    variables: {
      groupMrn: space.mrn,
    },
  });

  const [refreshAggregateScores, { loading: isRefreshing }] =
    useRefreshAggregateScoresMutation({
      refetchQueries: [GetAggregateScoresRefreshInfoDocument],
    });

  // After the refresh button is clicked, we start polling to check if the refresh is done
  // Once the refresh is done, we stop polling
  useEffect(() => {
    if (refreshInfoData?.aggregateScoresRefreshInfo.refreshInProgress) {
      startPolling(10000);
    } else {
      stopPolling();
    }
  }, [refreshInfoData?.aggregateScoresRefreshInfo.refreshInProgress]);

  const rawStats = queryResult.data?.assetUrlStats?.stats || [];

  const data = rawStats.map(({ assetUrl, scoreDistribution }) => {
    const path = assetUrl.map(({ __typename, ...url }) => url);
    const pathId = pathToId(path);
    const name = assetUrl.slice(-1)[0].value;
    const { __typename, ...stats } = scoreDistribution;
    const score = computeScore(stats);
    const value = d3.sum(Object.values(stats));
    return {
      path,
      pathId,
      name,
      score,
      stats,
      value,
    };
  });

  const [activePath, setActivePath] = useState<AssetUrlFilter>([]);

  const onRefreshClick: UseAssetUrlStats["onRefreshClick"] = async () => {
    await refreshAggregateScores({ variables: { groupMrn: space.mrn } });
  };

  return {
    space,
    queryResult,
    data,
    lastRefreshed:
      refreshInfoData?.aggregateScoresRefreshInfo.lastUpdatedAt || "",
    isRefreshing:
      isRefreshing ||
      Boolean(refreshInfoData?.aggregateScoresRefreshInfo.refreshInProgress),
    onRefreshClick,
    activePath,
    setActivePath,
  };
}

export function computeScore(stats: AssetUrlStats): number {
  const total = d3.sum(Object.values(stats));
  if (stats.Unscored === total) return -1;
  return (
    (stats.Critical * 0 +
      stats.High * 0.2 +
      stats.Medium * 0.45 +
      stats.Low * 0.8 +
      stats.Ok * 1 +
      stats.Unscored * 1) /
    total
  );
}

export function pathItemToId(pathItem: { key: string; value: string }) {
  return Object.values(pathItem).join("=");
}

export function pathToId(path: { key: string; value: string }[]) {
  return path.reduce((acc, curr) => {
    acc = acc + "/" + pathItemToId(curr);
    return acc;
  }, "");
}
