import { useState } from "react";
import {
  Box,
  Paper,
  Table,
  SxProps,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableSortLabel,
  TablePagination,
  TableSortLabelProps,
  TablePaginationProps,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import SeverityChip from "../../buttons/SeverityChip";
import { Obj, PaginationOptions } from "../../../types";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  } else if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "impact",
    numeric: false,
    disablePadding: true,
    label: "Impact",
  },
  {
    id: "check",
    numeric: true,
    disablePadding: false,
    label: "Check",
  },
];

export type EnhancedTableHeadProps = {
  isQuerypack: boolean;
  onRequestSort: (
    event: Parameters<NonNullable<TableSortLabelProps["onClick"]>>[0],
    property: string,
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
};

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { isQuerypack, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: string): TableSortLabelProps["onClick"] =>
    (event) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.slice(isQuerypack ? 1 : 0).map((headCell, key) => (
          <TableCell
            sx={{
              py: 1,
              px: 2,
              ...(key === 0 ? { width: 200 } : {}),
            }}
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

type QueriesTableProps = {
  isQuerypack?: boolean;
  queries: Obj[];
  rowsPerPage?: number;
  onChange?: (value: PaginationOptions) => void;
  onNavigate: (value: any) => void;
  sx?: SxProps;
};

const QueriesTable = (props: QueriesTableProps) => {
  const { isQuerypack = false, queries, onNavigate, sx = {} } = props;
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    props.rowsPerPage || queries.length,
  );

  const handleRequestSort: EnhancedTableHeadProps["onRequestSort"] = (
    event,
    property,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage: TablePaginationProps["onRowsPerPageChange"] = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - queries.length) : 0;

  return (
    <Box sx={sx}>
      <Paper sx={{ width: "100%", boxShadow: "none" }}>
        <TableContainer>
          <Table
            size={"medium"}
            aria-labelledby="tableTitle"
            sx={{ minWidth: 750 }}
          >
            <EnhancedTableHead
              isQuerypack={isQuerypack}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={queries.length}
            />
            <TableBody>
              {stableSort(queries, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((query: Obj, index) => {
                  const labelId = `enhanced-table-${index}`;

                  return (
                    <TableRow
                      key={query.title}
                      tabIndex={-1}
                      hover
                      onClick={() => {
                        onNavigate(query);
                      }}
                    >
                      {!isQuerypack && (
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          sx={{
                            py: 1,
                            px: 2,
                          }}
                        >
                          <SeverityChip severity={query?.impact?.value || ""} />
                        </TableCell>
                      )}
                      <TableCell align="left">{query.title}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  sx={{
                    height: 53 * emptyRows,
                    cursor: "pointer",
                  }}
                >
                  <TableCell
                    sx={{
                      py: 1,
                      px: 2,
                    }}
                    colSpan={6}
                  />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {props.rowsPerPage && queries.length > props.rowsPerPage && (
          <TablePagination
            labelDisplayedRows={({ from, to, count, page }) => {
              props.onChange?.({ from, to, count, page });
              return `${from}-${to} of ${count}`;
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={queries.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
};

export default QueriesTable;
