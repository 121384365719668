import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  ChevronRightIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";

type SnowflakeFormInput = {
  integrationName: string;
  accountId: string;
  database: string;
  warehouse: string;
  schema: string;
  region: string;
  username: string;
  password: string;
  role: string | null;
};

const defaultValues: SnowflakeFormInput = {
  integrationName: "",
  accountId: "",
  database: "",
  warehouse: "",
  schema: "",
  region: "",
  username: "",
  password: "",
  role: "",
};

export function SnowflakeIntegrationForm({
  space,

  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: {
          input: { mrn: integrationMrn, type: ActionType.RunExport },
        },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "SnowflakeConfigurationOptions"
      ) {
        const { accountId, database, warehouse, schema, region, username } =
          updateFlow.integration.configurationOptions;
        reset({
          integrationName: updateFlow.integration.name,
          accountId,
          database,
          warehouse,
          schema,
          region,
          username,
          role: updateFlow.integration.configurationOptions.role
            ? updateFlow.integration.configurationOptions.role
            : "",
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<SnowflakeFormInput> = async (data) => {
    const snowflakeConfigurationOptions = {
      accountId: data.accountId.trim(),
      database: data.database.trim(),
      warehouse: data.warehouse.trim(),
      schema: data.schema.trim(),
      region: data.region.trim(),
      role: data.role ? data.role.trim() : "",
      username: data.username.trim(),
      password: data.password.trim(),
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Snowflake,
              configurationOptions: {
                snowflakeConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/snowflake/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Snowflake,
              longLivedToken: false,
              configurationOptions: {
                snowflakeConfigurationOptions,
              },
            },
          },
        });
        navigate(`/space/integrations/snowflake?spaceId=${space.id}`);
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "Snowflake · Integrations Setup · Mondoo";

  return (
    <Fragment>
      <IntegrationAddHeader {...{ type: IntegrationType.Snowflake }} />
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Step 1 */}
          <Box pb={4}>
            <Command
              number={1}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Choose an integration name
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Please choose a descriptive name that lets you easily identify
                your integration.
              </Typography>
              <Controller
                name="integrationName"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Your integration name..."
                    error={Boolean(errors.integrationName)}
                    helperText={
                      Boolean(errors.integrationName) && (
                        <ValidationMessage error={errors.integrationName} />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>

          {/* Step 2 */}
          <Box pb={4}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Define the export destination
            </Command>

            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mb: 2, mt: 2 }}
              >
                Copy and paste the information below from your Snowflake
                account. To learn more, read the{" "}
                <Link
                  href="https://mondoo.com/docs/platform/maintain/export/snowflake/"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo documentation
                </Link>
                .
              </Typography>
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Snowflake account ID
              </Typography>
              <Controller
                name="accountId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Snowflake account ID ..."
                    error={Boolean(errors.accountId)}
                    helperText={
                      getFieldState("accountId").isTouched &&
                      Boolean(errors.accountId) && (
                        <ValidationMessage
                          error={errors.accountId}
                          integrationTypeId="snowflake"
                        />
                      )
                    }
                  />
                )}
              />
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Database
              </Typography>
              <Controller
                name="database"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Database name ..."
                    error={Boolean(errors.database)}
                    helperText={
                      getFieldState("database").isTouched &&
                      Boolean(errors.database) && (
                        <ValidationMessage
                          error={errors.database}
                          integrationTypeId="snowflake"
                        />
                      )
                    }
                  />
                )}
              />
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Warehouse
              </Typography>
              <Controller
                name="warehouse"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="Warehouse name ..."
                    error={Boolean(errors.warehouse)}
                    helperText={
                      getFieldState("warehouse").isTouched &&
                      Boolean(errors.warehouse) && (
                        <ValidationMessage
                          error={errors.warehouse}
                          integrationTypeId="snowflake"
                        />
                      )
                    }
                  />
                )}
              />
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Schema
              </Typography>
              <Controller
                name="schema"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      mb: 3,
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="e.g. PUBLIC"
                    error={Boolean(errors.schema)}
                    helperText={
                      getFieldState("schema").isTouched &&
                      Boolean(errors.schema) && (
                        <ValidationMessage
                          error={errors.schema}
                          integrationTypeId="snowflake"
                        />
                      )
                    }
                  />
                )}
              />
              <Typography fontWeight={700} sx={{ mb: 1 }}>
                Region
              </Typography>
              <Controller
                name="region"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    sx={{
                      background: theme.palette.code.background,
                      borderRadius: 1,
                      color: "text.primary",
                      ...helperTextStyles,
                    }}
                    placeholder="e.g. uscentral-1.gcp"
                    error={Boolean(errors.region)}
                    helperText={
                      getFieldState("region").isTouched &&
                      Boolean(errors.region) && (
                        <ValidationMessage
                          error={errors.region}
                          integrationTypeId="snowflake"
                        />
                      )
                    }
                  />
                )}
              />
            </Box>
          </Box>
          {/* step 3 */}
          <Box pb={4}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Enter your credentials
            </Command>

            <Box>
              <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
                Account credentials
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                These are the credentials used to authenticate with Snowflake.
              </Typography>
            </Box>
            <Grid container columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      sx={{
                        mb: {
                          xs: 2,
                          md: 0,
                        },
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder="User name ..."
                      error={Boolean(errors.username)}
                      helperText={
                        getFieldState("username").isTouched &&
                        Boolean(errors.username) && (
                          <ValidationMessage
                            error={errors.username}
                            integrationTypeId="snowflake"
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword((prev) => !prev)}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOffIcon
                                  sx={{ color: "text.secondary" }}
                                />
                              ) : (
                                <VisibilityIcon
                                  sx={{ color: "text.secondary" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Password ..."
                      error={Boolean(errors.password)}
                      helperText={
                        getFieldState("password").isTouched &&
                        Boolean(errors.password) && (
                          <ValidationMessage
                            error={errors.password}
                            integrationTypeId="snowflake"
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Box>
              <Typography fontWeight={700} sx={{ mt: 3, mb: 1 }}>
                Role{" "}
                <Typography component="span" color="text.secondary">
                  (if applicable)
                </Typography>
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Specify the user’s role here as configured. This is optional for
                some permission levels. To learn more, read the{" "}
                <Link
                  href="https://docs.snowflake.com/en/user-guide/security-access-control-overview"
                  target="_blank"
                  rel="noopener"
                >
                  Snowflake documentation
                </Link>
                .
              </Typography>
            </Box>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={{
                    mb: 3,
                    background: theme.palette.code.background,
                    borderRadius: 1,
                    color: "text.primary",
                    ...helperTextStyles,
                  }}
                  placeholder="e.g. editor, User, devDept, team_3,..."
                  error={Boolean(errors.role)}
                  helperText={
                    getFieldState("role").isTouched &&
                    Boolean(errors.role) && (
                      <ValidationMessage
                        error={errors.role}
                        integrationTypeId="snowflake"
                      />
                    )
                  }
                />
              )}
            />
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              disabled={updateFlow ? !isValid : !isValid}
            >
              {updateFlow ? "update configuration" : "create export"}
            </Button>
          </Box>
        </form>
      </Box>
    </Fragment>
  );
}
