import routes from "~/pages/registry/routes";
import { Breadcrumbs } from "~/pages/registry/breadcrumbs";
import {
  Box,
  List,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  Flex,
  Text,
  Tabs,
  Pagination,
  RegistryItem,
  RegistriesByCategory,
} from "~/components/ui-library";
import { Upload } from "~/components/upload";
import Facets from "./Facets";
import useRegistries from "./useRegistries";
import { Space } from "~/lib/types";
import { CatalogType, TestIamActionsQuery } from "~/operations";
import { IamActions } from "~/lib/iam";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";

export type RegistriesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

const Registries = ({ space, availablePermissions }: RegistriesProps) => {
  const {
    loading,
    error,
    filtersLoading,
    filtersErrors,
    filterOptions,
    sortByOptions,
    registriesTabOptions,
    platforms,
    categoryOptions,
    registries,
    paginatedRegistries,
    facets,
    state,
    filters,
    onNavigate,
    onFacetsChange,
    onStateChange,
    onChangeBreadcrumb,
    onTabChange,
    onPaginationChange,
    onAddPolicyMutation,
    onAddQueryPackMutation,
    onChangeAssignHandler,
    onDeletePolicyMutation,
    getPolicyIndicatorProps,
  } = useRegistries({ space });

  const hasPolicyUploadPermission = availablePermissions.includes(
    IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  );
  const hasQueryPackUploadPermission = availablePermissions.includes(
    IamActions.ACTION_CNQUERY_EXPLORER_QUERYHUB_SETBUNDLE,
  );

  return (
    <Box>
      <Breadcrumbs
        routes={routes()}
        sx={{
          mb: 3,
        }}
        onChange={onChangeBreadcrumb}
        onNavigate={onNavigate}
      />
      <Grid container>
        <Grid item xs>
          <Typography
            variant="h4"
            fontWeight={700}
            sx={{ mb: 3, textTransform: "uppercase" }}
          >
            Registry
          </Typography>
        </Grid>
        <Grid item>
          {facets.catalogType === CatalogType.Policy &&
            hasPolicyUploadPermission && (
              <Upload
                title="Upload Policy"
                placeholder="Drag and drop a policy or click"
                successMessage="Successfully added policy bundle"
                errorMessage="Failed to upload policy bundle"
                spaceMrn={space.mrn}
                addFileMutation={onAddPolicyMutation}
              />
            )}
          {facets.catalogType === CatalogType.Querypack &&
            hasQueryPackUploadPermission && (
              <Upload
                title="Upload QueryPack"
                placeholder="Drag and drop a query pack or click"
                successMessage="Successfully added query pack"
                errorMessage="Failed to upload query pack"
                spaceMrn={space.mrn}
                addFileMutation={onAddQueryPackMutation}
              />
            )}
        </Grid>
      </Grid>
      {state.isReady ? (
        <>
          <Tabs
            options={registriesTabOptions}
            selectedTab={facets.catalogType}
            onTabChange={onTabChange}
            sx={{
              mb: 5,
            }}
          />
          {categoryOptions.length > 0 && (
            <RegistriesByCategory
              categoryOptions={categoryOptions}
              categories={facets.categories}
              onFacetsChange={onFacetsChange}
            />
          )}
          <Box mt={5}>
            <Facets
              filtersLoading={filtersLoading}
              filtersErrors={filtersErrors}
              filterOptions={filterOptions}
              filters={filters}
              sortByOptions={sortByOptions}
              facets={facets}
              state={state}
              onFacetsChange={onFacetsChange}
            />
          </Box>
          {loading && (
            <Flex center>
              <CircularProgress />
            </Flex>
          )}
          {error && (
            <Box>
              <Text>Errors</Text>
              <Text>{String(error)}</Text>
            </Box>
          )}
          {paginatedRegistries.length > 0 && (
            <List
              component={Paper}
              sx={(theme) => ({
                py: 0,
                overflow: "hidden",
                borderRadius: theme.spacing(1),
                boxShadow: (theme) => theme.shadows[1],
              })}
            >
              {paginatedRegistries.map((registryEntry) => (
                <RegistryItem
                  key={registryEntry.mrn}
                  icon={policyIcon(registryEntry.name, "large")}
                  isQuerypack={state.isQuerypack}
                  registryEntry={registryEntry}
                  policyIndicatorProps={getPolicyIndicatorProps(registryEntry)}
                  changeAssignMutation={() =>
                    onChangeAssignHandler(registryEntry)
                  }
                  onNavigate={() => {
                    onNavigate({
                      pathname: `/registry/namespace/[namespace]/${
                        state.isQuerypack ? "querypacks" : "policies"
                      }/[uid]`,
                      query: {
                        namespace: registryEntry.namespace,
                        uid: registryEntry.uid,
                      },
                    });
                  }}
                />
              ))}
            </List>
          )}
          <Pagination
            page={state.page}
            count={registries.length}
            rowsPerPage={state.rowsPerPage}
            onPaginationChange={onPaginationChange}
          />
        </>
      ) : null}
    </Box>
  );
};

export default Registries;
