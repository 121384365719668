import { Fragment } from "react";
import { Typography, Link, Box } from "@mui/material";
import { stackUrl, stacksUrl } from "~/pages/integration-setup";
import { IamActions } from "~/lib/iam";
import { TestIamActionsQuery } from "~/operations";
import { AwsIntegration } from "./integration-aws-account-serverless";
import { Code } from "~/components/code";

type Props = {
  awsIntegration: AwsIntegration;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function DetailedMessage({
  awsIntegration,
  availablePermissions,
}: Props) {
  let ebs = false;

  const hasIntegrationDeletePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_DELETE,
  );

  const hasIntegrationTriggerActionPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION,
  );

  if (awsIntegration?.configurationOptions?.scanConfiguration != undefined) {
    if (
      awsIntegration.configurationOptions.scanConfiguration.ec2ScanOptions !=
      undefined
    ) {
      ebs =
        awsIntegration.configurationOptions.scanConfiguration.ec2ScanOptions
          .ebsVolumeScan;
    }
  }

  const hasPolicyAssignPermission = [
    IamActions.POLICY_DISTRIBUTOR_ASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
  ].some((permission) => availablePermissions?.includes(permission));

  switch (awsIntegration.status) {
    case "WAITING_FOR_SETUP":
      return (
        <Box mt={2}>
          {hasPolicyAssignPermission ? (
            <Fragment>
              <Link
                color="secondary.main"
                href={stackUrl(
                  awsIntegration.configurationOptions
                    ?.cloudFormationTemplateUrl,
                  awsIntegration.configurationOptions?.originAWSAccountId,
                  awsIntegration.configurationOptions.region,
                  awsIntegration.mrn,
                  awsIntegration.token,
                  awsIntegration.configurationOptions.snsEndpoint,
                  awsIntegration.configurationOptions.isOrganization,
                )}
                target="_blank"
                sx={{
                  position: "absolute",
                  top: 15,
                  right: 45,
                  fontWeight: 500,
                  textTransform: "uppercase",
                  color: "#4fc3f7",
                }}
              >
                Launch AWS CloudFormation
              </Link>
              <Typography variant="caption" display="block">
                Please launch the CloudFormation stack on AWS and complete the
                installation.
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography variant="caption" display="block">
                Waiting for admin to launch the CloudFormation stack.
              </Typography>
            </Fragment>
          )}
          <Typography variant="caption" display="block">
            Once the stack is launched on AWS, it can take a few minutes for the
            status to update below.
          </Typography>
        </Box>
      );
    case "SETUP_IN_PROGRESS":
      return (
        <Box mt={2}>
          <Typography variant="caption" display="block">
            Waiting for AWS CloudFormation to complete. This step usually takes
            about three minutes.
          </Typography>
          {hasPolicyAssignPermission && (
            <Typography variant="caption" display="block">
              Consider activating some policies while you wait.
            </Typography>
          )}
        </Box>
      );

    case "ERROR":
      return (
        <Box mt={2}>
          <Typography variant="caption" display="block">
            Error detected. Contact support@mondoo.com for help.
          </Typography>
          {awsIntegration.messages?.map((msg) => (
            <Code key={msg.message} className="shell">
              {msg.message}
            </Code>
          ))}
        </Box>
      );
    case "DELETED":
      return (
        <Box mt={2}>
          <Typography variant="caption" display="block">
            AWS CloudFormation delete detected.
          </Typography>
          {hasIntegrationDeletePermission && (
            <Typography variant="caption" display="block">
              Please recreate{" "}
              <Link
                color="secondary.light"
                href={stackUrl(
                  awsIntegration.configurationOptions
                    ?.cloudFormationTemplateUrl,
                  awsIntegration.configurationOptions?.originAWSAccountId,
                  awsIntegration.configurationOptions.region,
                  awsIntegration.mrn,
                  awsIntegration.token,
                  awsIntegration.configurationOptions.snsEndpoint,
                  awsIntegration.configurationOptions.isOrganization,
                )}
                target="_blank"
              >
                the stack
              </Link>{" "}
              or delete the integration (see configuration tab).
            </Typography>
          )}
        </Box>
      );
    case "MISSING":
      return (
        <Box mt={2}>
          <Typography variant="caption" display="block">
            No ping detected in the last hour.
          </Typography>
          {hasIntegrationTriggerActionPermission && (
            <Fragment>
              <Typography variant="caption" display="block">
                Please check on{" "}
                <Link
                  color="secondary.light"
                  href={stacksUrl(awsIntegration.configurationOptions.region)}
                  target="_blank"
                >
                  the stack.
                </Link>{" "}
                If nothing appears wrong, try the "Retry Setup" button from the
                action menu to the right.
              </Typography>

              <Typography variant="caption" display="block">
                If the problem persists, please contact support@mondoo.com for
                help.
              </Typography>
            </Fragment>
          )}
        </Box>
      );
    case "WARNING": //fallthrough
    case "REGISTERING": //fallthrough
    case "CONFIGURING": //fallthrough
    case "ACTIVE": //fallthrough
    default:
      return <Fragment />;
  }
}
