import { useMemo, useState, useEffect, useCallback } from "react";
import {
  Impact,
  MProperty,
  Remediations,
  MqueryRemediation,
  useLoadMqueryLazyQuery,
  AuthenticationRequired,
} from "~/operations";
import { ApolloError } from "@apollo/client";
import { omit, pick } from "lodash";
import {
  Route,
  ListName,
  TabOption,
  VariantIcon,
} from "~/components/ui-library";
import { useParams } from "~/hooks";
import { getQueryMrn, updateQueryString } from "~/utils";

// Facets
export type Facets = {
  namespace: string;
  uid: string;
  mrn: string;
  registryUid: string;
  registryEntryName: string;
  variant: string;
};
const defaultFacets: Facets = {
  namespace: "",
  uid: "",
  mrn: "",
  registryUid: "",
  registryEntryName: "",
  variant: "",
};

// State
type State = {
  isReady: boolean;
};
const defaultState: State = {
  isReady: false,
};

export type QueryData = {
  title: string;
  desc: string;
  impact: Impact;
  properties: MProperty[];
  mql: string;
  audit: string;
  remediations: MqueryRemediation[];
  remediationMessage: string;
  hasRemediations: boolean;
  isAuthenticationRequired: boolean;
  showRemediation: boolean;
  trustLevel: string;
  authors: ListName[];
  version: string;
  certifiedBy: ListName;
  variants: TabOption[];
};

type UseQueriesReturnProps = {
  mQueryLoading: boolean;
  mQueryError: ApolloError | undefined;
  queryData: QueryData;
  facets: Facets;
  state: State;
  onNavigate: (href: Route["href"]) => void;
  onFacetsChange: (value: Partial<Facets>) => void;
  onStateChange: (value: Partial<State>) => void;
  onChangeBreadcrumb: () => void;
  onVariantsChange: (value: string) => void;
};

const defaultVariants: TabOption[] = [
  {
    label: "Overall",
    value: "",
    icon: "",
  },
];

const useQueries = (): UseQueriesReturnProps => {
  const { router, facetsFromQueryString } = useParams(defaultFacets);
  const [facets, setFacets] = useState<Facets>(defaultFacets);
  const [state, setState] = useState<State>(defaultState);

  const onNavigate = useCallback(
    (href: Route["href"]) => {
      router.navigateTo(href);
    },
    [router],
  );

  const onFacetsChange = useCallback((value: Partial<Facets>) => {
    setFacets((v) => ({ ...v, ...value }));
  }, []);

  const onStateChange = useCallback((value: Partial<State>) => {
    setState((v) => ({ ...v, ...value }));
  }, []);

  const onVariantsChange = useCallback((variant: string) => {
    onFacetsChange({
      variant,
    });
  }, []);

  const [
    loadMquery,
    { loading: mQueryLoading, error: mQueryError, data: mQueryData },
  ] = useLoadMqueryLazyQuery();

  useEffect(() => {
    if (!facetsFromQueryString) {
      return;
    }

    onFacetsChange(facetsFromQueryString);
    onStateChange({
      isReady: true,
    });
  }, [facetsFromQueryString]);

  useEffect(() => {
    if (!state.isReady) {
      return;
    }

    updateQueryString({
      router,
      defaultValues: defaultFacets,
      newValues: facets,
    });

    loadMquery({
      variables: {
        input: {
          mrn: getQueryMrn(facets),
        },
      },
    });
  }, [facets, state.isReady]);

  const queryData: QueryData = useMemo(() => {
    const mQuery = mQueryData?.mquery;
    const variants = (
      mQuery?.variants?.map((variant) => ({
        label: variant?.title || "",
        value: variant?.id || "",
        icon: <VariantIcon type={variant?.icon || ""} />,
      })) || []
    ).sort((a: any, b: any) => (a.label > b.label ? 1 : -1));

    const selectedVariant = mQuery?.variantQueries?.find(
      (variantQuery) => facets.variant === variantQuery?.filterID,
    );

    const docs = (selectedVariant || mQuery)?.docs;
    const impact = (selectedVariant || mQuery)?.impact;
    const mql = (selectedVariant || mQuery)?.mql;
    const properties = (selectedVariant || mQuery)?.properties;

    const audit = docs?.audit || "";
    const remediations = ((docs?.remediations as Remediations)?.entries ||
      []) as MqueryRemediation[];
    const remediationMessage = (docs?.remediations as AuthenticationRequired)
      ?.message;
    const hasRemediations =
      remediations.map((remediation) => remediation.desc).filter(Boolean)
        .length > 0;
    const isAuthenticationRequired = false;

    return {
      title: mQuery?.title || "",
      desc: docs?.desc || "",
      impact: {
        value: impact?.value || -1,
        rating: impact?.rating || "",
      } as Impact,
      properties: (properties || []) as MProperty[],
      mql: mql || "",
      audit,
      remediations,
      remediationMessage,
      hasRemediations,
      isAuthenticationRequired,
      showRemediation: hasRemediations || isAuthenticationRequired,
      // ToDo: Replace mQuery-data once data is available from API
      trustLevel: "",
      authors: [] as ListName[],
      version: "",
      certifiedBy: {} as ListName,
      variants: variants?.length ? [...defaultVariants, ...variants] : [],
    };
  }, [mQueryData?.mquery, facets.variant]);

  const onChangeBreadcrumb = useCallback(() => {
    onFacetsChange(pick(defaultFacets, ["variant"]));
    onStateChange(omit(defaultState, "isReady"));
  }, []);

  return {
    mQueryLoading,
    mQueryError,
    queryData,
    facets,
    state,
    onNavigate,
    onFacetsChange,
    onStateChange,
    onChangeBreadcrumb,
    onVariantsChange,
  };
};

export default useQueries;
