import {
  Card,
  CardContent,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { ScoreMajor } from "~/components/scores/styles";
import { AssetReport } from "~/pages/inventory/asset/types";

type PrintPoliciesListProps = {
  assetReport: AssetReport;
};

export function PrintPoliciesList({ assetReport }: PrintPoliciesListProps) {
  const tableRows = assetReport.listPolicies?.edges.map((edge) => {
    const policy = edge.node;
    const score = edge.node.score;
    return (
      <TableRow
        key={policy.name}
        sx={{ "&:last-child td": { borderBottom: "none" } }}
      >
        <TableCell>
          <ScoreMajor
            className={`score-major ${score.grade}`}
            sx={{
              display: "inline-block",
              m: 0,
              fontSize: "inherit",
              fontWeight: "bold",
              lineHeight: "normal",
              verticalAlign: "middle",
            }}
          >
            {score.grade}
          </ScoreMajor>
        </TableCell>
        <TableCell>
          <Link href={`#${policy.mrn}`}>{policy.name}</Link>
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Card variant="outlined" sx={{ my: 6, mx: 0 }}>
      <CardContent>
        <Typography variant="h4" component="h2" mb={2}>
          Policies
        </Typography>
        <TableContainer sx={{ border: "none" }}>
          <Table>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
