import { styled } from "@mui/material/styles";
import { Code } from "~/components/code";
import { scoreDotColor } from "./score-card";
import { PolicyQueryAssessment } from "~/pages/inventory/asset/types";

type PolicyQueryAssessmentResultsProps = {
  assessment: PolicyQueryAssessment;
  status: string;
};

export function PolicyQueryAssessmentResults({
  assessment,
  status,
}: PolicyQueryAssessmentResultsProps) {
  return (
    <ResultOutput className="ini" status={status}>
      {assessment.rendered}
    </ResultOutput>
  );
}

const ResultOutput = styled(Code)<{ status: string }>`
  .hljs-section {
    color: ${scoreDotColor};
  }
`;
