import { useTheme } from "@mui/material";
import { usePlatformVulnerabilityConfiguration } from "~/hooks";
import { getColor } from "~/lib/colors";
import { pluralize } from "~/lib/pluralize";
import { Space } from "~/lib/types";
import { AssetReport } from "~/operations";

type UseAssetInsightsProps = {
  space: Space;
  insights: AssetReport["insights"];
  exceptionsCount: number;
  isEol: boolean;
};

type AssetInsightsItem = {
  title: string;
  grade: string;
  policyCount: string;
  color: string;
  disabled: boolean;
  to?: string;
};

const HYPHEN = "–";

export function useAssetInsights({
  insights,
  space,
  exceptionsCount,
  isEol,
}: UseAssetInsightsProps) {
  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });
  const theme = useTheme();

  const insightHref = (key: string) => {
    const paths: Record<string, string | null> = {
      security: "policies",
      vulnerability: "vulnerabilities",
      exceptions: "exceptions",
    };
    const path = paths[key];
    if (path) {
      return `${path}?spaceId=${space.id}`;
    }
  };

  // extract typename from insights
  const { __typename, ...insightsData } = insights || {};
  const assetInsights: AssetInsightsItem[] = Object.entries(insightsData).map(
    ([key, value]) => {
      // check if the insight is disabled
      // if it's a vulnerability insight, check if the PVC is enabled
      // if it's a security insight, check if there are any policies
      const getDisabledStatus = () => {
        if (key === "vulnerability" && !value) {
          return true;
        }
        if (value?.__typename === "AssetInsightsVulnerability") {
          return !pvcIsEnabled;
        }
        if (value?.__typename === "AssetInsightsSecurity") {
          return value.policyCount === 0;
        }
        return false;
      };

      const disabledInsight = getDisabledStatus();

      // create the policy count label
      // if there is no insight data, set the label to "Not Applicable" <-- this needs to be confirmed
      // if the insight is disabled, set the label to "Disabled"
      // if it's a security insight, set the label to the policy count
      const policyCountLabel = !value
        ? "Not Applicable"
        : value?.__typename === "AssetInsightsVulnerability" && disabledInsight
          ? "Disabled"
          : value?.__typename === "AssetInsightsVulnerability" && isEol
            ? "End-Of-Life"
            : value?.__typename === "AssetInsightsSecurity"
              ? `${value.policyCount} ${pluralize("Policy", value.policyCount)}`
              : "";

      // get the color for the insight
      // if the insight is disabled, set the color to "disabled"
      const color = disabledInsight
        ? getColor(theme, "disabled")
        : getColor(theme, value?.score?.grade || "unknown");

      const getGrade = () => {
        if (!value) return "N";

        if (
          value.__typename === "AssetInsightsVulnerability" &&
          disabledInsight
        ) {
          return HYPHEN;
        }

        return value.score.grade;
      };

      const grade = getGrade();

      return {
        title: pluralize(key, 2),
        grade,
        policyCount: policyCountLabel,
        color,
        disabled: disabledInsight,
        to: insightHref(key),
      };
    },
  );

  // Manually add the exceptions insight until this is calculated in the backend
  assetInsights.push({
    title: "exceptions",
    grade: HYPHEN,
    policyCount: `${exceptionsCount} ${pluralize(
      "Exception",
      exceptionsCount,
    )}`,
    color: getColor(theme, "U"),
    disabled: false,
    to: insightHref("exceptions"),
  });

  return {
    insightsData: assetInsights,
  };
}
