import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Typography, alpha } from "@mui/material";
import { isEmpty } from "lodash";
import { HubCard } from "~/components/hub-card";
import { AssetReport } from "~/pages/inventory/asset/types";
import { useAssetInsights } from "~/pages/inventory/hooks";
import { Space } from "~/lib/types";

type AssetInsightsProps = {
  space: Space;
  insights: AssetReport["insights"];
  exceptionsCount: number;
  isEol: boolean;
};

export function AssetInsights({
  insights,
  space,
  exceptionsCount,
  isEol,
}: AssetInsightsProps) {
  const { insightsData } = useAssetInsights({
    insights,
    space,
    exceptionsCount,
    isEol,
  });

  return (
    <HubCard title="ASSET INSIGHTS" loading={false}>
      <Grid container spacing={3} mt={0}>
        {isEmpty(insightsData) ? (
          <NoInsights />
        ) : (
          insightsData.map((insight) => {
            return (
              <Grid
                container
                key={insight.title}
                className="asset-insight"
                item
                columnGap={2}
                sm
                md={4}
                sx={{ flexWrap: "nowrap" }}
              >
                <Grid item xs="auto">
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: theme.spacing(6),
                      height: theme.spacing(6),
                      p: 0.5,
                      borderRadius: 1,
                      background: alpha(insight.color, 0.1),
                    })}
                  >
                    <Typography
                      className="asset-insight-grade"
                      sx={(theme) => ({
                        color: insight.color,
                        fontSize: theme.spacing(4),
                        fontWeight: 700,
                      })}
                    >
                      {insight.grade}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container item xs justifyContent="space-between">
                  <Grid item>
                    <Typography
                      component="h6"
                      {...(insight.to && {
                        component: RouterLink,
                        to: insight.to,
                      })}
                      sx={{
                        textTransform: "capitalize",
                        fontWeight: 700,
                        whiteSpace: "nowrap",
                      }}
                    >
                      {insight.title}
                    </Typography>

                    <Typography
                      color="text.secondary"
                      fontWeight={700}
                      className="asset-insight-label"
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {insight.policyCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            );
          })
        )}
      </Grid>
    </HubCard>
  );
}

const NoInsights = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        placeContent: "center",
        p: 2.5,
      }}
    >
      <Typography color="text.secondary" variant="subtitle1">
        No insights available
      </Typography>
    </Grid>
  );
};
