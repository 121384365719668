import React from "react";
import { Paper, Typography } from "@mui/material";
import { pluralize } from "~/lib/pluralize";
import { ArrowUpwardIcon } from "~/components/icons";
import { Flex } from "~/components/ui-library";
import { Link } from "react-router-dom";
import { Space } from "~/lib/types";
import { JiraIcon } from "~/components/icons/mondoo/integrations/jira-icon";

type CasesSummaryProps = {
  count: number;
  space: Space;
};

export const CasesSummary = ({ count, space }: CasesSummaryProps) => {
  return (
    <Paper sx={{ px: 3, py: 1, mb: 3 }}>
      <Flex justifyContent="space-between">
        <Flex gap={2}>
          <JiraIcon />
          <Typography fontWeight={700}>
            {count} open related {pluralize("case", count)}
          </Typography>
        </Flex>
        <Link
          to={`/space/cases?spaceId=${space.id}`}
          style={{ display: "inline-flex" }}
        >
          <ArrowUpwardIcon sx={{ rotate: "90deg" }} color="primary" />
        </Link>
      </Flex>
    </Paper>
  );
};
