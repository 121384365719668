import { Fragment, useState } from "react";
import { ComplianceBreadcrumbs } from "../breadcrumbs";
import { Space } from "~/lib/types";
import {
  alpha,
  Typography,
  TableCell,
  TableRow,
  Checkbox,
  CircularProgress,
  Box,
  Grid,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { ComplianceTable, Header } from "../compliance-table";
import { FormatDate } from "~/lib/date";
import { UseReportExports, useReports } from "./use-reports";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import { SelectionToolbar } from "~/components/data-table";
import { NoReports } from "./no-reports";
import { DeleteIcon, RefreshIcon, SaveAltIcon } from "~/components/icons";
import {
  ContentFlag,
  FormatRelativeDate,
  LoadingFailed,
  LoadingPage,
} from "~/components/ui-library";
import { DocumentStatus, DocumentType } from "~/operations";

type ReportsProps = {
  space: Space;
};

export function Reports({ space }: ReportsProps) {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const {
    selectedReports,
    handle,
    documentList: { loading, error, reports = [], data },
  } = useReports({
    space,
  });

  if (loading && !data) {
    return <LoadingPage what="reports" />;
  }

  if (error) {
    return <LoadingFailed what="reports" />;
  }

  const tableHeaders: Header[] = [
    {
      id: "SELECT",
      label: (
        <Checkbox
          onChange={handle.selectAll}
          checked={
            selectedReports.length > 0 &&
            selectedReports.length === reports.length
          }
          indeterminate={
            selectedReports.length > 0 &&
            selectedReports.length !== reports.length
          }
        />
      ),
      sortable: false,
    },
    { id: "NAME", label: "Document Name" },
    { id: "TYPE", label: "Report Type" },
    { id: "CREATED_BY", label: "Created By" },
    { id: "CREATED_AT", label: "Date Created" },
    { id: "ACTIONS", label: "", sortable: false },
  ];

  return (
    <Grid container rowGap={5}>
      <Grid item xs={12}>
        <ComplianceBreadcrumbs space={space} mb={"0px"} reports />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h4"
          fontWeight={700}
          sx={{ textTransform: "uppercase" }}
        >
          Reports
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {data && !loading && !reports.length ? (
          <NoReports {...{ space }} />
        ) : (
          <Fragment>
            <ComplianceTable
              tableHeaders={tableHeaders}
              // permission need to be added here?
              selectable={true}
              selection={selectedReports}
              paginationProps={{
                fetchMore: handle.fetchMore,
                pageInfo: data?.pageInfo,
                setPageItems,
                totalCount: data?.totalCount || 0,
              }}
            >
              {reports.slice(pageItems.from, pageItems.to).map((report) => {
                //Determine if the report is Queued or Running
                const jobInProcess = [
                  DocumentStatus.Queued,
                  DocumentStatus.Running,
                ].includes(report.status);
                const jobFailure = report.status === DocumentStatus.Failed;
                const requestedAt = `${FormatRelativeDate(
                  report.requestedAt,
                )} - ${FormatDate(report.requestedAt)}`;

                return (
                  <TableRow
                    key={report.mrn}
                    sx={{
                      // only display the icon buttons on hover
                      "& .action-button": {
                        opacity: 0,
                      },
                      "&:hover": {
                        "& .action-button": {
                          opacity: 1,
                        },
                      },
                      ...(jobInProcess && {
                        // if the job is in process, make the row grey and the text lighter
                        "& .MuiTableCell-root": {
                          color: "text.secondary",
                          opacity: 0.5, // TODO: Fix CSS specificity issue
                          cursor: "default",
                        },
                        // hide the checkbox when the job is in process
                        "& .select-checkbox": {
                          display: "none",
                        },
                        // if the job is in process, hide the delete and download buttons
                        "& .action-button": {
                          display: "none",
                        },
                      }),
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        className="select-checkbox"
                        onChange={(_e, checked) =>
                          handle.selectionChange(checked, report.mrn)
                        }
                        checked={selectedReports.includes(report.mrn)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {report.name}
                        {jobInProcess && (
                          <CircularProgress
                            size={16}
                            sx={{ ml: 1, color: "text.primary" }}
                          />
                        )}
                        {jobFailure && <JobFailureFlag />}
                      </Box>
                    </TableCell>
                    <TableCell>
                      {report.type === DocumentType.FrameworkReport
                        ? "Framework"
                        : "Control"}
                    </TableCell>
                    <TableCell>{report.createdBy?.name}</TableCell>
                    <TableCell
                      sx={{ fontSize: (theme) => theme.spacing(1.25) }}
                    >
                      {requestedAt}
                    </TableCell>
                    <TableCell align="right">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          gap: (theme) => theme.spacing(1),
                        }}
                      >
                        {/* If the download fails, we'll show a retry icon in place of the download icon */}
                        <Tooltip
                          title={jobFailure ? "Retry" : "Download"}
                          placement="top"
                          arrow
                        >
                          <span>
                            <IconButton
                              className="action-button"
                              onClick={() => handle.downloadSingle(report)}
                            >
                              {jobFailure ? (
                                <RefreshIcon fontSize="small" />
                              ) : (
                                <SaveAltIcon fontSize="small" />
                              )}
                            </IconButton>
                          </span>
                        </Tooltip>

                        <Tooltip title="Delete" placement="top" arrow>
                          <span>
                            <IconButton
                              className="action-button"
                              onClick={() => handle.deleteSingle(report.mrn)}
                            >
                              <DeleteIcon fontSize="small" color="error" />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </ComplianceTable>
            {selectedReports.length > 0 && (
              <ReportSelectionToolbar
                {...{ reports, selectedReports, handle }}
              />
            )}
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
}

type ReportSelectionToolbarProps = {
  reports: UseReportExports["documentList"]["reports"];
  selectedReports: UseReportExports["selectedReports"];
  handle: UseReportExports["handle"];
};

const ReportSelectionToolbar = ({
  reports,
  selectedReports,
  handle,
}: ReportSelectionToolbarProps) => {
  return (
    <SelectionToolbar>
      <Typography>
        Selected {selectedReports.length} of {reports.length} reports
      </Typography>
      {/* We are not currently supporting multi-download yet */}
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handle.downloadZip}
        startIcon={<SaveAltIcon />}
      >
        Download
      </Button> */}
      <Button
        variant="contained"
        onClick={handle.delete}
        startIcon={<DeleteIcon />}
        sx={{
          background: (theme) => alpha(theme.palette.error.main, 0.5),
          "&:hover": {
            background: (theme) => alpha(theme.palette.error.main, 0.7),
          },
        }}
      >
        Delete
      </Button>
      <Button onClick={handle.cancel}>Cancel</Button>
    </SelectionToolbar>
  );
};

const JobFailureFlag = () => {
  return (
    <Tooltip
      title="Mondoo couldn't generate this report. Please try again."
      placement="top"
      arrow
      PopperProps={{
        sx: {
          ".MuiTooltip-tooltip": {
            maxWidth: 220,
            textAlign: "center",
          },
        },
      }}
    >
      <span>
        <ContentFlag flag="error" color="error" layout={{ ml: 1 }} />
      </span>
    </Tooltip>
  );
};
