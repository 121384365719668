import { forwardRef, ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

type FlexProps = BoxProps & {
  center?: boolean;
  children?: ReactNode;
};
const Flex = forwardRef(({ center, ...restProps }: FlexProps, ref) => {
  return (
    <Box
      ref={ref}
      display="flex"
      {...(center ? { alignItems: "center", justifyContent: "center" } : {})}
      {...restProps}
    />
  );
});

export default Flex;
