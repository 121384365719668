import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { Button, Typography, useTheme, Box, alpha } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { TimerOutlinedIcon } from "~/components/icons";
import { getColor } from "~/lib/colors";
import {
  RemediatedBySeverity,
  useLoadVulnDashboardStatsQuery,
} from "~/operations";
import { Space } from "~/lib/types";

type MttrObject = {
  [category: string]: {
    days: number;
    hours: number;
    minutes: number;
  };
};

type Props = {
  sizes?: CardSizes;
  space: Space;
};

type Category = "low" | "medium" | "high" | "critical";
const categoryButtons: Category[] = ["low", "medium", "high", "critical"];

export function MttrPerScoreCard({ sizes, space }: Props) {
  const [mttrMap, setMttrMap] = useState<MttrObject>(defaultData);
  const [active, setActive] = useState<Category>("critical");

  const { loading, error, data } = useLoadVulnDashboardStatsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
  });

  useEffect(() => {
    if (data) {
      let nextData = { ...defaultData };
      data.vulnDashboardStats?.remediatedBySeverity
        ?.filter((x): x is RemediatedBySeverity => x !== null)
        .forEach((data) => {
          // Calculate the number of days
          const days = Math.floor(data.mttrInMinutes / 1440);
          // Calculate the number of hours
          const hours = Math.floor((data.mttrInMinutes % 1440) / 60);
          // Calculate the number of minutes
          const minutes = data.mttrInMinutes % 60;

          nextData[data.severity] = {
            days,
            hours,
            minutes,
          };
        });
      setMttrMap(nextData);
    }
  }, [data]);

  return (
    <HubCard
      {...{
        loading,
        sizes,
        title: "MTTR Per Score",
        tagline: "MEAN TIME TO REMEDIATE PER CVSS CATEGORY",
        icon: <TimerOutlinedIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
    >
      <Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          {categoryButtons.map((category) => (
            <SelectorButton
              {...{ category, active, setActive }}
              key={category}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            mt: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mx: 1,
            }}
          >
            <Typography
              sx={{ fontSize: (theme) => theme.spacing(8), lineHeight: "80px" }}
              fontWeight={800}
            >
              {mttrMap[active].days}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", color: "text.secondary" }}
            >
              Days
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mx: 1,
            }}
          >
            <Typography
              fontWeight={800}
              sx={{
                fontSize: (theme) => theme.spacing(5),
                color: "text.secondary",
              }}
            >
              {mttrMap[active].hours}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", color: "text.secondary" }}
            >
              Hours
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mx: 1,
            }}
          >
            <Typography
              fontWeight={800}
              sx={{
                fontSize: (theme) => theme.spacing(5),
                color: "text.secondary",
              }}
            >
              {mttrMap[active].minutes}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", color: "text.secondary" }}
            >
              Minutes
            </Typography>
          </Box>
        </Box>
      </Fragment>
    </HubCard>
  );
}

const SelectorButton = ({
  category,
  active,
  setActive,
}: {
  category: Category;
  active: Category;
  setActive: Dispatch<SetStateAction<Category>>;
}) => {
  const theme = useTheme();
  const color = getColor(theme, category);

  return (
    <Button
      variant="contained"
      onClick={() => setActive(category)}
      sx={{
        py: 0.5,
        px: 1,
        mx: { xs: 0.5, sm: 1, md: 0.5 },
        color: active === category ? color : alpha(color, 0.5),
        backgroundColor: alpha(color, 0.1),
        ...(active === category && {
          boxShadow: `inset 0 0 0 1px ${color}`,
        }),
        "&:hover": {
          backgroundColor: alpha(color, 0.2),
          ...(active === category && {
            boxShadow: `inset 0 0 0 1px ${color}`,
          }),
        },
      }}
    >
      {category}
    </Button>
  );
};

let defaultData: MttrObject = {
  low: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  medium: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  high: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
  critical: {
    days: 0,
    hours: 0,
    minutes: 0,
  },
};
