import { Fragment, ReactNode, useLayoutEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { SortDirection, Space } from "~/lib/types";
import {
  FormatType,
  GetAssetReportDataQueriesQuery,
  MqueryType,
  useGetAssetReportDataQueriesQuery,
} from "~/operations";
import { DataTable, DetailRow } from "~/components/data-table";
import { useAssetOutlet } from "../asset";
import { Code } from "~/components/code";
import { Flex, Loading, LoadingFailed } from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { FilterBar } from "../../compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { useSearchParams } from "react-router-dom";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { stateFromScore } from "~/lib/score";
import { PolicyQueryAssessmentResults } from "~/components/policy-query-assessment-results";
import { EmptySection } from "~/components/vulnerabilities";

export type AssetReport = Extract<
  NonNullable<GetAssetReportDataQueriesQuery["assetReport"]>,
  { __typename: "AssetReport" }
>;
type DataQueries = NonNullable<AssetReport["dataQueries"]>;
type DataQueryEdges = NonNullable<DataQueries["edges"]>;
type DataQueriesEdge = DataQueryEdges[0];

type AssetDataQueriesProps = {
  space: Space;
};

export function AssetDataQueries({ space }: AssetDataQueriesProps) {
  const [searchParams] = useSearchParams();
  const { assetMrn } = useAssetOutlet();
  const [sortDirection, setSortDirection] = useState<SortDirection>("DESC");
  const { handleFilterQuery, searchFilters } = useSearch();
  const queryMrn = searchParams.get("queryMrn");

  const { data, error, loading, fetchMore } = useGetAssetReportDataQueriesQuery(
    {
      skip: !assetMrn,
      variables: {
        input: {
          assetMrn,
          formatType: FormatType.Json,
          policyFilter: null,
        },
        first: null,
        after: null,
        packsFilter: null,
      },
    },
  );

  const dataQueriesAssetReport =
    data?.assetReport?.__typename === "AssetReport"
      ? data.assetReport
      : undefined;
  const { dataQueries } = dataQueriesAssetReport || {};

  let copyEdges = [...(dataQueries?.edges || [])];
  if (searchFilters.length > 0) {
    copyEdges = copyEdges?.filter((edge) => {
      return edge.node.mquery.title
        ?.toLowerCase()
        .includes(searchFilters.join(" ").toLowerCase());
    });
  }

  // Focus on query if searchParams contains codeId
  const focusedQueryEdge = dataQueries?.edges.find((edge) => {
    return edge.node.mquery.mrn === queryMrn;
  });

  useLayoutEffect(() => {
    if (focusedQueryEdge) {
      const { mrn } = focusedQueryEdge.node.mquery;
      document.getElementById(mrn)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [focusedQueryEdge]);

  if (loading) {
    return (
      <Flex center sx={{ py: 10 }}>
        <Loading what="Data queries" />
      </Flex>
    );
  }

  if (error || !assetMrn || !dataQueriesAssetReport) {
    return (
      <Flex center sx={{ py: 10 }}>
        <LoadingFailed what="Data queries" />
      </Flex>
    );
  }

  const handleSort = () => {
    setSortDirection((prev) => (prev === "DESC" ? "ASC" : "DESC"));
  };

  const sortEdges = (edges: DataQueryEdges) => {
    const sortedEdges = [...edges].sort((a, b) => {
      if (a.node.mquery.title < b.node.mquery.title) {
        return -1;
      }
      if (a.node.mquery.title > b.node.mquery.title) {
        return 1;
      }
      return 0;
    });
    // reverse if DESC
    if (sortDirection === "ASC") {
      return sortedEdges.reverse();
    } else {
      return sortedEdges;
    }
  };

  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <FilterBar
          searchId="asset-data-queries-search-bar"
          placeholder="asset_data_queries"
          searchFilters={searchFilters}
          handleFilterQuery={handleFilterQuery}
        />
      </Grid>
      <DataTable>
        <TableHead>
          <TableRow>
            <TableCell
              sortDirection={sortDirection === "DESC" ? "desc" : "asc"}
            >
              <TableSortLabel
                onClick={handleSort}
                direction={sortDirection === "DESC" ? "desc" : "asc"}
                active
              >
                Query
              </TableSortLabel>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortEdges(copyEdges).map((queryEdge) => {
            return (
              <DataQueryRow
                space={space}
                queryEdge={queryEdge}
                key={queryEdge.node.mquery.mrn}
                shouldOpen={
                  queryEdge.node.mquery.mrn ===
                  focusedQueryEdge?.node.mquery.mrn
                }
              />
            );
          })}
        </TableBody>
      </DataTable>
    </Grid>
  );
}

function DataQueryRow({
  space,
  queryEdge,
  shouldOpen,
}: {
  space: Space;
  queryEdge: DataQueriesEdge;
  shouldOpen: boolean;
}) {
  const [open, setOpen] = useState<boolean>(shouldOpen);
  const { node } = queryEdge;
  const results = getQueryResults(node);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <TableRow onClick={handleClick} id={queryEdge.node.mquery.mrn}>
        <TableCell>
          <Typography>{node.mquery.title}</Typography>
          <List sx={{ p: 0 }}>
            <Typography variant="caption" color="text.secondary">
              {node.policyName}
            </Typography>
          </List>
          <Typography variant="caption" color="text.secondary"></Typography>
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <DetailRow colSpan={2} open={open}>
        <Box sx={{ mt: 1 }}>
          {node.mquery.docs?.desc && (
            <DetailSection
              heading="Description"
              child={<Markdown source={node.mquery.docs?.desc} />}
            />
          )}
          <DetailSection heading="Query" code={node.mquery.mql} />
          <DetailSection heading="Result" child={results} />
        </Box>
      </DetailRow>
    </Fragment>
  );
}

type DetailSectionProps = {
  heading: string;
  code?: string;
  content?: string;
  child?: ReactNode;
};

const DetailSection = ({
  heading,
  code,
  content,
  child,
}: DetailSectionProps) => {
  return (
    <Grid container alignItems="center">
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography
            sx={{
              display: "inline-block",
              pr: 3,
              pb: 1,
              fontWeight: 700,
            }}
          >
            {heading}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item xs={12} sx={{ mb: 2 }}>
        {content && <Typography>{content}</Typography>}
        {code && (
          <Code className="javascript" copyButton>
            {code}
          </Code>
        )}
        {child && child}
      </Grid>
    </Grid>
  );
};

export const getQueryStatus = (node: DataQueriesEdge["node"]) => {
  const { mqueryType, score } = node;
  if (mqueryType === MqueryType.Data) return "data";
  switch (stateFromScore(score)) {
    case 4:
      return "error";
    case 3:
      return "fail";
    case 2:
      return "skip";
    case 1:
      return "pass";
    case 0:
    default:
      return "unknown";
  }
};

export const getQueryResults = (node: DataQueriesEdge["node"]) => {
  let results = undefined;
  const { assessment, data } = node;
  let status = getQueryStatus(node);
  if (assessment && status === "fail") {
    results = (
      <PolicyQueryAssessmentResults assessment={assessment} status={status} />
    );
  } else if (data) {
    try {
      results = (
        <Code className="plaintext">{JSON.stringify(node.data, null, 2)}</Code>
      );
    } catch {
      results = (
        <Code className="plaintext">
          An error occurred while processing results
        </Code>
      );
    }
  } else {
    results = (
      <EmptySection
        id={`data-query-${node.mquery.mrn}-results-empty`}
        text="When last run, this query returned no results."
      />
    );
  }
  return results;
};
