import { Route, Status } from "~/components/ui-library";

type EntryRoute = {
  isQuerypack?: boolean;
  name?: string | string[] | undefined;
  namespace?: string | string[] | undefined;
  uid?: string | string[] | undefined;
};

type RoutesProps = {
  registry?: EntryRoute;
  queries?: EntryRoute;
};

const routes = (props?: RoutesProps): Route[] => {
  const { registry, queries } = props || {};
  const routesList: Route[] = [
    // Home Route
    { name: "", href: { pathname: "/" } },
    // Registries Route
    {
      name: "Registry",
      href: { pathname: "/registry" },
    },
  ];

  // Registry Route
  if (registry) {
    const name = String(registry.name) || Status.Pending;

    routesList.push(
      !registry.uid
        ? {
            name,
          }
        : {
            name,
            href: {
              pathname: `/registry/namespace/[namespace]/${
                registry.isQuerypack ? "querypacks" : "policies"
              }/[uid]`,
              query: { namespace: registry.namespace, uid: registry.uid },
            },
          },
    );
  }

  // Queries Route
  if (queries) {
    routesList.push({
      name: String(queries.name) || "Queries",
    });
  }

  return routesList;
};

export default routes;
