import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  OrderDirection,
  ScoreStateFilter,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreFilter,
  VulnerabilityScoreOrder,
  VulnerabilityScoreOrderField,
  VulnerabilityScoreType,
} from "~/operations";
import { Space } from "~/lib/types";
import { AssetContextualLinkType } from "~/pages/space/vulnerabilities/components/AffectedAssets";

type UseVulnerabilityAffectedAssetsParams = {
  vulnerabilityId: string;
  vulnerabilityScoreType: "CVE" | "Advisory" | "Policy";
  space: Space;
};

const getVulnerabilityScoreType = (scope: string): VulnerabilityScoreType => {
  switch (scope) {
    case "CVE":
      return VulnerabilityScoreType.Cve;
    case "Advisory":
      return VulnerabilityScoreType.Advisory;
    default:
      return VulnerabilityScoreType.Cve;
  }
};

export function useVulnerabilityAffectedAssets({
  vulnerabilityId,
  vulnerabilityScoreType,
  space,
}: UseVulnerabilityAffectedAssetsParams) {
  const { handleSortClick, orderBy } = useSort<VulnerabilityScoreOrder>({
    defaultSort: {
      field: VulnerabilityScoreOrderField.CvssScore,
      direction: OrderDirection.Desc,
    },
    validFields: ["CVSS_SCORE", "MRN", "LAST_UPDATED"],
  });

  const filter: VulnerabilityScoreFilter = {
    queryTerms: [vulnerabilityId],
    scoreType: getVulnerabilityScoreType(vulnerabilityScoreType),
    state: ScoreStateFilter.Open,
  };

  const { data, error, loading, fetchMore } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: space.mrn,
      first: 5,
      orderBy,
      filter,
    },
    skip: Boolean(!vulnerabilityId),
  });

  const vulnerabilityScoresUnion = data?.vulnerabilityScores;
  const vulnerabilityScores =
    vulnerabilityScoresUnion?.__typename === "VulnerabilityScoresConnection"
      ? vulnerabilityScoresUnion
      : undefined;

  const assets = vulnerabilityScores?.edges?.flatMap(({ node }) =>
    node?.asset
      ? {
          id: node.asset.id,
          mrn: node.asset.mrn,
          score: node.riskScore || 0,
          lastUpdated: node.updatedAt,
          riskFactors: node.riskFactors,
          title: node.asset.name,
          tags: node.asset.tags,
          iconId: node.asset.icon,
        }
      : [],
  );

  function getUrlContextType(): (typeof AssetContextualLinkType)[keyof typeof AssetContextualLinkType] {
    const vulnType = getVulnerabilityScoreType(vulnerabilityScoreType);

    switch (vulnType) {
      case VulnerabilityScoreType.Cve:
        return AssetContextualLinkType.Cve;
      case VulnerabilityScoreType.Advisory:
        return AssetContextualLinkType.Advisory;
    }
  }

  return {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount: vulnerabilityScores?.filteredTotalCount || 0,
    fetchMore,
    pageInfo: vulnerabilityScores?.pageInfo,
    error,
    loading,
    urlContextType: getUrlContextType(),
  };
}
