import { Typography, Grid } from "@mui/material";
import { CardViewLabels } from "~/components/label";
import { FormatDate } from "~/lib/date";
import { MondooLogoHorizontal } from "~/images/icons";
import { Asset } from "~/pages/inventory/asset/types";
import { AssetScoreBox } from "../asset-score-box";

type PrintAssetSummaryProps = {
  asset: Asset;
  showScore?: boolean;
};

export function PrintAssetSummary({
  asset,
  showScore = true,
}: PrintAssetSummaryProps) {
  return (
    <Grid
      container
      sx={{
        p: 4,
        borderBottom: "1px solid #DBDADD",
      }}
    >
      <Grid item xs>
        <MondooLogoHorizontal color="#222222" />
        <Typography variant="h4" component="h1" mt={1} mb={2}>
          Asset: {asset.name}
        </Typography>
        <Typography variant="h5" component="h4" mb={2}>
          ID: {asset.id}
        </Typography>
        <Typography mb={2}>Updated: {FormatDate(asset.updatedAt)}</Typography>
        <CardViewLabels labels={asset.labels} />
      </Grid>
      <Grid item xs="auto">
        {showScore && <AssetScoreBox score={asset.score} />}
      </Grid>
    </Grid>
  );
}
