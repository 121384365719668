import { Link as RouterLink } from "react-router-dom";
import { Typography, Paper, Box, useTheme, alpha } from "@mui/material";
import { Org, Space } from "~/lib/types";
import { LoadOrganizationStatsQuery } from "~/operations";
import { ChevronRightIcon } from "~/components/icons";
import { ChartData, DonutChart } from "./charts/donut-chart";
import { getColor } from "~/lib/colors";
import { useColorMode } from "~/providers/color-mode";

type RiskDistribution = NonNullable<Space["stats"]>["riskdistribution"];
type OrgSpace = NonNullable<
  LoadOrganizationStatsQuery["organization"]
>["spacesList"]["edges"][0]["node"];

type SpaceCardEntry = {
  id: string;
  href: string;
  org?: Org;
  space: Space | OrgSpace;
  shared?: boolean;
};

export type SpaceItemCardProps = {
  entry: SpaceCardEntry;
};

export function SpaceItemCard({ entry }: SpaceItemCardProps) {
  const theme = useTheme();
  const data = entry.space.stats?.riskdistribution;
  const { mode } = useColorMode();

  const packageData = ({
    __typename,
    total,
    ...dataCopy
  }: RiskDistribution): ChartData[] => {
    const readyData = Object.entries(dataCopy).map(([key, value]) => {
      return {
        label: key === "U" ? "Unknown" : key,
        value: value as number,
        color: getColor(theme, key),
      };
    });

    return readyData.sort(customSort);
  };

  // This sorts first by Grade letter, then by length so
  // we end up with A, B, C, D, F, Unrated, Error
  const customSort = (a: ChartData, b: ChartData) => {
    if (a.label.length > 2 && b.label.length > 2) {
      return b.label.length - a.label.length;
    }
    return a.label < b.label ? -1 : 1;
  };

  // get the color from the already packaged data
  const getBackgroundColor = (readyData: ChartData[]) => {
    let x = 0;
    let color = theme.palette.text.primary;
    readyData.forEach((item) => {
      if (item.value > x) {
        x = item.value;
        color = item.color;
      }
    });
    return color;
  };

  const readyData = data ? packageData(data) : [];
  const backgroundShade = getBackgroundColor(readyData);
  const isUnknownBackgroundShade = backgroundShade.toLowerCase() === "#cccccc";

  let title = entry.space.name || entry.space.id;
  if (entry.shared && entry.org) {
    title = `${entry.org.name} / ${title}`;
  }

  return (
    <Paper
      component={RouterLink}
      to={entry.href}
      sx={[
        {
          display: "block",
          position: "relative",
          cursor: "pointer",
          outline: 0,
        },
        {
          "&:after": {
            content: "' '",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            borderRadius: 0.5,
            zIndex: -1,
            opacity: "0",
            transition: "opacity 300ms ease-in-out",
            boxShadow: `0px 0px 0px 2px ${backgroundShade}`,
          },
          "&:hover:after": {
            opacity: "80%",
            transition: "opacity 300ms ease-in-out",
          },
          "&:focus:after": {
            opacity: "80%",
            transition: "opacity 300ms ease-in-out",
          },
        },
      ]}
    >
      <Box
        sx={{
          pl: 2,
          pr: 0.5,
          pt: 1.75,
          pb: 1.5,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          // We want to only make 50% opacity if the color is '#cccccc'
          backgroundColor: alpha(
            backgroundShade,
            isUnknownBackgroundShade && mode === "light" ? 0.5 : 0.1,
          ),
        }}
      >
        <Typography fontWeight={700}>{title}</Typography>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              lineHeight: "24px",
              textTransform: "uppercase",
            }}
          >
            Open
          </Typography>
          <ChevronRightIcon />
        </Box>
      </Box>

      <DonutChart data={readyData} dataType="assets" />
    </Paper>
  );
}
