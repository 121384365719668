import { get } from "lodash";
import {
  Box,
  Grid,
  Switch,
  Divider,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import {
  Flex,
  Text,
  Tabs,
  Caption,
  Pagination,
  ShareButton,
  TabsVariant,
  QueriesTable,
  PolicyIndicator,
  RegistryGroupItem,
} from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { QueryRef } from "~/operations";
import { Space } from "~/lib/types";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { PolicyDownloadButton } from "~/components/policy-download-button";
import routes from "~/pages/registry/routes";
import { Breadcrumbs } from "~/pages/registry/breadcrumbs";
import { PropsTable } from "./props-table";
import useRegistry from "./useRegistry";
import Facets from "./Facets";
import githubLogo from "~/images/githubLogo.svg";
import { DeleteIcon } from "~/components/icons";

type RegistryProps = {
  isQuerypack: boolean;
  space: Space;
};

const Registry = ({ isQuerypack, space }: RegistryProps) => {
  const {
    loading,
    error,
    filtersLoading,
    filtersErrors,
    filterOptions,
    registryTabOptions,
    viewOptions,
    registryByPlatform,
    registryEntry,
    paginatedGroups,
    facets,
    state,
    filters,
    onNavigate,
    onFacetsChange,
    onStateChange,
    onChangeBreadcrumb,
    onTabChange,
    onVariantsChange,
    onPaginationChange,
    onNavigateToQueries,
    listPaginationText,
    onListPaginationChange,
    adjustedProps,
    onChangeAssignHandler,
    handleDeletePolicy,
    deletePolicyLoading,
    getPolicyIndicatorProps,
  } = useRegistry({ isQuerypack, space });

  return (
    <Box>
      <Breadcrumbs
        routes={routes({
          registry: {
            isQuerypack,
            name: registryEntry.name,
            namespace: registryEntry.namespace,
            uid: registryEntry.uid,
          },
        })}
        sx={{
          mb: 3,
        }}
        onChange={onChangeBreadcrumb}
        onNavigate={onNavigate}
      />
      {state.isReady ? (
        <>
          <Grid container sx={{ mb: 5 }}>
            <Grid item xs={12} md minWidth={0}>
              <Typography
                variant="h4"
                fontWeight={700}
                sx={{
                  fontSize: {
                    xs: 24,
                    md: 32,
                  },
                }}
              >
                {registryEntry.name}
              </Typography>
              <Caption
                trustLevel={registryEntry.trustLevel}
                authors={registryEntry.authors}
                version={registryEntry.version}
                certifiedBy={registryEntry.certifiedBy}
                gap={2}
              />
            </Grid>
            <Grid item xs={12} md sx={{ ml: "auto", mt: { xs: 2 } }}>
              <Flex
                alignItems="center"
                justifyContent="flex-end"
                flexShrink={0}
                gap={3}
              >
                <ShareButton emailLink={registryEntry.emailLink} />
                {registryEntry.githubUrl && (
                  <a href={registryEntry.githubUrl} target="_blank">
                    <IconButton>
                      <img src={githubLogo} height="30px" />
                    </IconButton>
                  </a>
                )}
                <PolicyDownloadButton
                  data-name={`policy-download-button-${registryEntry.mrn}`}
                  policy={registryEntry}
                  space={space}
                  color="primary"
                  variant="contained"
                  sx={{ minWidth: 135 }}
                />
                {registryEntry.trustLevel === "PRIVATE" && (
                  <IconButton
                    onClick={() =>
                      onStateChange({
                        deleteModalOpen: true,
                      })
                    }
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                )}
                <Divider orientation="vertical" flexItem />
                <Text secondary>{state.activeStatsText}</Text>
                {isQuerypack ? (
                  <Switch
                    size="small"
                    checked={registryEntry.assigned || false}
                    onChange={() => onChangeAssignHandler()}
                  />
                ) : (
                  <PolicyIndicator {...getPolicyIndicatorProps()} />
                )}
              </Flex>
            </Grid>
          </Grid>
          {registryEntry.variants.length > 0 && (
            <TabsVariant
              options={registryEntry.variants}
              selectedTab={facets.variant || ""}
              onTabChange={onVariantsChange}
              mb={3}
            />
          )}
          {registryEntry.docs && (
            <Markdown
              sx={{ color: "text.secondary" }}
              source={registryEntry.docs}
            />
          )}
          {registryTabOptions.length > 0 && (
            <Tabs
              options={registryTabOptions}
              selectedTab={facets.registryType}
              onTabChange={onTabChange}
              sx={{
                pb: 2,
              }}
            />
          )}
          <Box mt={5}>
            <Facets
              filtersLoading={filtersLoading}
              filtersErrors={filtersErrors}
              filterOptions={filterOptions}
              filters={filters}
              viewOptions={viewOptions}
              facets={facets}
              state={state}
              onFacetsChange={onFacetsChange}
              listPaginationText={listPaginationText}
            />
          </Box>
          {loading && (
            <Flex center>
              <CircularProgress />
            </Flex>
          )}
          {error && (
            <Box>
              <Text>Errors</Text>
              <Text>{String(error)}</Text>
            </Box>
          )}
          {state.isProperties ? (
            adjustedProps && (
              <PropsTable
                {...{
                  space,
                  data: adjustedProps,
                }}
              />
            )
          ) : (
            <>
              {state.isListView ? (
                <QueriesTable
                  isQuerypack={isQuerypack}
                  queries={registryEntry.queries}
                  rowsPerPage={10}
                  onChange={onListPaginationChange}
                  onNavigate={onNavigateToQueries}
                  sx={{
                    overflow: "hidden",
                    borderRadius: 2,
                    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.5)",
                  }}
                />
              ) : (
                <>
                  {paginatedGroups.length > 0 && (
                    <Flex flexDirection="column" gap={3}>
                      {paginatedGroups.map((group, key) => {
                        const queryRefs =
                          get(group, isQuerypack ? "queries" : "checks") || [];
                        const groupQueries = (
                          queryRefs
                            ?.map((queryRef: QueryRef) =>
                              registryEntry.queries.find(
                                (query) => query?.mrn === queryRef?.mrn,
                              ),
                            )
                            .filter(Boolean) || []
                        ).map((query) => ({
                          ...query,
                          namespace: get(query, "namespace") || "mondoohq",
                          uid: query?.uid || query?.mrn.split("/").pop(),
                        }));
                        if (groupQueries.length > 0) {
                          return (
                            <RegistryGroupItem
                              // title can be undefined
                              key={group.title || key}
                              isQuerypack={isQuerypack}
                              group={group}
                              groupQueries={groupQueries}
                              onNavigate={onNavigateToQueries}
                            />
                          );
                        }
                      })}
                    </Flex>
                  )}
                </>
              )}
            </>
          )}
          {!state.isProperties && !state.isListView && (
            <Pagination
              page={state.page}
              count={registryEntry.groups.length}
              rowsPerPage={state.rowsPerPage}
              onPaginationChange={onPaginationChange}
            />
          )}
        </>
      ) : null}
      <DeleteConfirmDialog
        isDeleting={deletePolicyLoading}
        content="Are you sure you want to delete this policy?  This action cannot be undone."
        open={state.deleteModalOpen}
        onConfirm={() => handleDeletePolicy(registryEntry.mrn)}
        onClose={() =>
          onStateChange({
            deleteModalOpen: false,
          })
        }
      />
    </Box>
  );
};

export default Registry;
