import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  LoadCicdProjectJobsQuery,
  useLoadCicdProjectJobsQuery,
} from "~/operations";

type CicdProjectJobs = NonNullable<LoadCicdProjectJobsQuery["cicdProjectJobs"]>;

type JobsProviderProps = {
  children: ReactNode;
};

type ContextTypes =
  | {
      cicdProjectJobs: CicdProjectJobs | null;
      loading: boolean;
      refetch: ReturnType<typeof useLoadCicdProjectJobsQuery>["refetch"];
      fetchMore: ReturnType<typeof useLoadCicdProjectJobsQuery>["fetchMore"];
    }
  | undefined;

const JobsContext = createContext<ContextTypes>(undefined);

function JobsProvider({ children }: JobsProviderProps) {
  const [cicdProjectJobs, setCicdProjectJobs] =
    useState<CicdProjectJobs | null>(null);

  const [searchParams] = useSearchParams();

  const spaceId = searchParams.get("spaceId");
  const projectId = searchParams.get("projectId");

  if (!spaceId || !projectId) {
    throw new Error(
      "useJobs must be used within a route that has `spaceId` and `projectId` searchParams",
    );
  }

  const { data, loading, error, refetch, fetchMore } =
    useLoadCicdProjectJobsQuery({
      variables: {
        input: {
          spaceMrn: `//captain.api.mondoo.app/spaces/${spaceId}`,
          projectId,
        },
      },
    });

  useEffect(() => {
    if (data?.cicdProjectJobs) {
      setCicdProjectJobs(data.cicdProjectJobs);
    }
  }, [data]);

  return (
    <JobsContext.Provider
      value={{ cicdProjectJobs, loading, refetch, fetchMore }}
    >
      {children}
    </JobsContext.Provider>
  );
}

function useJobs() {
  const context = useContext(JobsContext);
  if (context === undefined) {
    throw new Error("useJobs must be used within a JobsProvider");
  }
  return context;
}

export { JobsProvider, useJobs };
