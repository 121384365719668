import { PolicyReportSummariesEdge, SortDirection } from "../types";

export const sortByDate = (
  a: PolicyReportSummariesEdge,
  b: PolicyReportSummariesEdge,
  sortDirection: SortDirection,
) => {
  const aValue = a.node?.policy.updatedAt;
  const bValue = b.node?.policy.updatedAt;
  if (!aValue || !bValue) return 0;
  switch (true) {
    case aValue > bValue:
      return -1 * sortDirection;
    case aValue < bValue:
      return 1 * sortDirection;
    default:
      return 0;
  }
};
