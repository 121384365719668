import { Box, Button, Grid, Typography } from "@mui/material";
import { upload } from "@testing-library/user-event/dist/cjs/utility/upload.js";
import { Fragment, useCallback } from "react";
import { AddButton } from "~/components/add-button";
import { SaveAltIcon } from "~/components/icons";
import { Upload } from "~/components/upload";
import { Space } from "~/lib/types";
import {
  useUploadFrameworkMutation,
  GetComplianceFrameworksDocument,
  UploadFrameworkInput,
} from "~/operations";

type ComplianceHeaderProps = {
  hasFrameworks: boolean;
  hasAddFrameworkPermission: boolean;
  isAdding: boolean;
  setIsAdding: (isAdding: boolean) => void;
  space: Space;
};

export function ComplianceHeader({
  hasFrameworks,
  hasAddFrameworkPermission,
  isAdding,
  setIsAdding,
  space,
}: ComplianceHeaderProps) {
  const [uploadFramework] = useUploadFrameworkMutation({
    refetchQueries: [GetComplianceFrameworksDocument],
  });

  const handleUploadFramework = useCallback((input: UploadFrameworkInput) => {
    return uploadFramework({ variables: { input } });
  }, []);

  const getComplianceTitle = () => {
    if (isAdding) {
      return "AVAILABLE FRAMEWORKS";
    }
    return "COMPLIANCE";
  };

  return (
    <Grid
      container
      item
      columnGap={2}
      mt={2}
      mb={4}
      sx={{
        alignItems: {
          xs: "start",
          sm: "center",
        },
      }}
    >
      <Grid item xs>
        <Typography
          component="h1"
          fontWeight="bold"
          sx={{
            fontSize: {
              xs: 22,
              sm: 32,
            },
          }}
        >
          {getComplianceTitle()}
        </Typography>
      </Grid>
      <Grid item sx={{ minWidth: 150 }}>
        <Box
          display="flex"
          justifyContent="end"
          sx={{
            mt: {
              xs: 0.75,
              sm: 0,
            },
            gap: 2,
          }}
        >
          {hasAddFrameworkPermission ? (
            <Fragment>
              {isAdding && (
                <Upload
                  title="Upload Custom Framework"
                  placeholder={`Drag and drop a framework or click to upload a framework`}
                  errorMessage={`Failed to upload framework`}
                  successMessage={`Successfully uploaded framework`}
                  spaceMrn={space.mrn}
                  addFileMutation={handleUploadFramework}
                  button={
                    <Button
                      variant="outlined"
                      color="primary"
                      data-name="upload-custom-framework"
                      startIcon={<SaveAltIcon />}
                    >
                      Add custom framework
                    </Button>
                  }
                  force={false}
                />
              )}
              {hasFrameworks && (
                <AddButton
                  id="framework"
                  aria-label="Add Integration"
                  close={isAdding}
                  onClick={() => setIsAdding(!isAdding)}
                />
              )}
            </Fragment>
          ) : undefined}
        </Box>
      </Grid>
    </Grid>
  );
}
