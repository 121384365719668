import { useState } from "react";
import { useSnackbar } from "notistack";
import { ButtonProps } from "@mui/material";
import { LoadingButton } from "./loading-button";
import { Space } from "~/lib/types";
import { SaveAltIcon } from "../components/icons";
import { PolicyBundleYaml, useDownloadBundleLazyQuery } from "~/operations";

type PolicyDownloadButtonProps = {
  space: Space;
  policy: { name: string; mrn: string };
} & ButtonProps;

export function PolicyDownloadButton({
  space,
  policy,
  ...rest
}: PolicyDownloadButtonProps) {
  const [getYamlData, { called, error, loading, data }] =
    useDownloadBundleLazyQuery({
      variables: { input: { mrn: policy.mrn } },
      fetchPolicy: "network-only",
    });

  // this is to make sure we don't get stuck in a download loop since our download occurs after
  // the network request to the server is made
  const [canExport, setCanExport] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  if (error && canExport) {
    enqueueSnackbar("Policy download failed", { variant: "error" });
    setCanExport(false);
  }

  if (canExport && data != undefined && data.downloadBundle != undefined) {
    const yamlData = data.downloadBundle as PolicyBundleYaml;
    // only allow one download per click
    setCanExport(false);
    // a bunch of weird magic to make the download work
    // https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
    const file = new Blob([yamlData.yaml], {
      type: "text/yaml",
    });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);

    // generates a filename that should sort properly on the file system
    function makeFileName(): string {
      const now = new Date();
      const fileDate = new Date(
        now.getTime() - now.getTimezoneOffset() * 60 * 1000,
      )
        .toISOString()
        .split("T")[0];
      return `mondoo-policy-${policy.name.replaceAll(
        " ",
        "-",
      )}_${fileDate}_${parseInt((now.getTime() / 1000).toString())}.mql.yaml`;
    }

    element.download = makeFileName();
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();

    // this timeout is here to avoid a render warning, so this library does not render during render
    window.setTimeout(function () {
      enqueueSnackbar(
        "Success! If you do not see your policy download, make sure your browser allows downloads from this site.",
        { variant: "success" },
      );
    }, 0);
  }

  const initiateExport = () => {
    setCanExport(true);
    getYamlData();
  };

  return (
    <LoadingButton
      disabled={called && loading}
      endIcon={<SaveAltIcon />}
      loading={called && loading}
      buttonText="Download"
      onClick={initiateExport}
      data-name={`policy-download-button-${policy.name.replaceAll(" ", "-")}`}
      {...rest}
    />
  );
}
