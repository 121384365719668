import { useParams } from "react-router-dom";
import { PolicyViewer } from "../components/policy-gql/policy-gql";
import { PolicyIdToMrn } from "../lib/mrn";
import { TestIamActionsQuery, useLoadPolicyQuery } from "~/operations";
import { Space } from "~/lib/types";
import { LoadingFailedPage, LoadingPage } from "~/components/ui-library";

export type PolicyPageProps = {
  space: Space;
  query: string;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function PolicyPage({ space, availablePermissions }: PolicyPageProps) {
  const { policyId } = useParams();

  if (policyId == null) {
    throw "missing ID for policy page";
  }

  const policymrn = PolicyIdToMrn(policyId);

  const { loading, data, error } = useLoadPolicyQuery({
    variables: { input: { spaceMrn: space.mrn, mrn: policymrn } },
  });

  if (loading) {
    return <LoadingPage what="policy" />;
  }

  const policy = data?.policy;

  if (error || !policy) {
    return <LoadingFailedPage what="policy" />;
  }

  document.title = `${policy.name} · Security · Mondoo`;

  return (
    <PolicyViewer
      policy={policy}
      space={space}
      availablePermissions={availablePermissions}
    />
  );
}
