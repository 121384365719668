import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { AttachIcon, CloseIcon } from "~/components/icons";
import { Flex } from "~/components/ui-library";
import { JiraIcon } from "~/components/icons/mondoo/integrations/jira-icon";
import { Link as RouterLink } from "react-router-dom";
import { Space } from "~/lib/types";

type CreateCasesIntegrationModalProps = {
  open: boolean;
  onClose: () => void;
  space: Space;
  redirectTo: string;
  hasIntegrationCreatePermission: boolean;
};

export const CreateCasesIntegrationModal = ({
  open,
  onClose,
  space,
  redirectTo,
  hasIntegrationCreatePermission,
}: CreateCasesIntegrationModalProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 5,
        }}
      >
        <Typography sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <AttachIcon />
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            GET STARTED USING CASES
          </Typography>
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 4, px: 5 }}>
        <Flex flexDirection="column" alignItems="center">
          <JiraIcon sx={{ width: "128px", height: "128px" }} />
          <Typography textAlign="center" mt={5}>
            Set up cases so you can create and track issues from within Mondoo.
            Cases integrate Mondoo with your existing ticketing system workflow
            and automatically add asset data and remediation advice to issues.
          </Typography>
          <Typography
            mt={2}
            variant="caption"
            color="text.secondary"
            textAlign="center"
          >
            To learn about setting up compatible integrations, read the{" "}
            <Link
              sx={{ color: "inherit", textDecoration: "underline" }}
              href="https://mondoo.com/docs/platform/cases/overview/"
              target="_blank"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
        </Flex>
      </DialogContent>
      <DialogActions sx={{ px: 5, py: 5, justifyContent: "center" }}>
        <Flex flexDirection="column" gap={1}>
          <Button
            disabled={!hasIntegrationCreatePermission}
            component={RouterLink}
            to={`/space/integrations/add/mondoo/ticket_system_jira?spaceId=${
              space.id
            }&redirectTo=${encodeURIComponent(redirectTo)}`}
            variant="contained"
            color="primary"
          >
            Add Integration
          </Button>
          {!hasIntegrationCreatePermission && (
            <Typography component="span" sx={{ fontSize: 10 }} color="error">
              You don't have permission to create an integration.
            </Typography>
          )}
        </Flex>
      </DialogActions>
    </Dialog>
  );
};
