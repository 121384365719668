import { Routes, Route } from "react-router-dom";
import { InventoryPage } from "~/pages/inventory/inventory";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { AssetRoutes } from "./assetRoutes/assetRoutes";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function InventoryRoutes({ space, availablePermissions }: Props) {
  return (
    <Routes>
      <Route
        index
        element={
          <InventoryPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      />
      <Route
        path="/*"
        element={<AssetRoutes {...{ space, availablePermissions }} />}
      />
    </Routes>
  );
}
