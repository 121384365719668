import { Dispatch, SetStateAction, SyntheticEvent } from "react";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import {
  PolicyAction,
  SPACE_REPORT,
  SpaceReportDocument,
  TestIamActionsQuery,
  useAssignPolicyMutation,
  useUnassignPolicyMutation,
} from "~/operations";
import {
  sortByAssetCounts,
  sortByDate,
  sortByName,
  sortByScore,
} from "../utils";
import { useNavigate } from "react-router-dom";
import {
  PolicyReportSummariesEdge,
  SelectedPolicies,
  SelectedPolicy,
  Sort,
  SortDirection,
} from "../types";
import { enqueueSnackbar } from "notistack";
import { pluralize } from "~/lib/pluralize";

type UsePoliciesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  selectedItems: SelectedPolicies;
  setSelectedItems: Dispatch<SetStateAction<SelectedPolicies>>;
};

export function usePolicies({
  availablePermissions,
  selectedItems,
  setSelectedItems,
  space,
}: UsePoliciesProps) {
  const url = new URL(window.location.toString());
  const navigate = useNavigate();

  // Whether the user has permission to disable policies from this view
  const hasEditPolicyPermission =
    availablePermissions != null &&
    [
      IamActions.POLICY_MARKETPLACE_SETPOLICYBUNDLE,
      IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
    ].some((permission) => availablePermissions?.includes(permission));

  const [disablePolicies] = useUnassignPolicyMutation({
    variables: {
      input: {
        policyMrns: selectedItems.map((policy) => policy.policyMrn),
        assetMrn: space.mrn,
      },
    },
    refetchQueries: [
      {
        query: SpaceReportDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [assignPolicy] = useAssignPolicyMutation();

  // Handle when a user checks a single policy
  const handleCheck = (_e: SyntheticEvent, policy: SelectedPolicy) => {
    let selectedItemsCopy = [...selectedItems];
    const alreadySelected = selectedItemsCopy.find(
      (item) => item.policyMrn === policy.policyMrn,
    );
    if (alreadySelected) {
      selectedItemsCopy = selectedItemsCopy.filter(
        (i) => i.policyMrn !== policy.policyMrn,
      );
    } else {
      selectedItemsCopy.push(policy);
    }

    setSelectedItems(selectedItemsCopy);
  };

  // Uncheck all the selected policies
  const unCheckAll = () => {
    setSelectedItems([]);
  };

  // Handle action to disable any selected policies.
  const handleDisabling = () => {
    const totalPolicies = selectedItems.length;
    //handle an edge case if someone is able to attempt to
    //somehow disable policies with none selected
    if (totalPolicies < 1) {
      return enqueueSnackbar(
        "There are no policies selected to disable. Please make a selection and try again",
        {
          variant: "error",
        },
      );
    }

    try {
      disablePolicies();
      unCheckAll();
      enqueueSnackbar(
        `Successfully disabled ${totalPolicies} ${pluralize(
          "policy",
          totalPolicies,
        )}`,
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(
        `Failed to disable ${pluralize("policy", totalPolicies)}`,
        {
          variant: "error",
        },
      );
    }
  };

  // handle placing policies in preview mode
  const handlePlaceInPreview = () => {
    handleAssignPolicy(PolicyAction.Ignore);
  };

  const handleEnable = () => {
    handleAssignPolicy(PolicyAction.Active);
  };

  const hasSelectedEnabledPolicy = selectedItems.some(
    (i) => i.action === PolicyAction.Active,
  );

  const hasSelectedPreviewedPolicy = selectedItems.some(
    (i) => i.action === PolicyAction.Ignore,
  );

  const hasSelectedBothEnabledAndPreviewedPolicies =
    hasSelectedEnabledPolicy && hasSelectedPreviewedPolicy;

  // handle enabling / setting policies in preview
  const handleAssignPolicy = async (action: PolicyAction) => {
    const totalPolicies = selectedItems.length;
    try {
      await assignPolicy({
        variables: {
          input: {
            action,
            policyMrns: selectedItems.map((i) => i.policyMrn),
            assetMrn: space.mrn,
          },
        },
        refetchQueries: [
          {
            query: SpaceReportDocument,
            variables: { input: { spaceMrn: space.mrn } },
          },
        ],
      });
      unCheckAll();
      switch (action) {
        case PolicyAction.Ignore:
          enqueueSnackbar(
            `Successfully placed ${totalPolicies} ${pluralize(
              "policy",
              totalPolicies,
            )} in preview`,
            { variant: "success" },
          );
          break;
        case PolicyAction.Active:
          enqueueSnackbar(
            `Successfully enabled ${totalPolicies} ${pluralize(
              "policy",
              totalPolicies,
            )}`,
            { variant: "success" },
          );
          break;
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to place ${pluralize("policy", totalPolicies)} in preview`,
        {
          variant: "error",
        },
      );
    }
  };

  // Check if the user is sorting by reading search Parameters
  // By default, we'll sort the rows by Policy Name, A => Z
  const sort: Sort = {
    field: url.searchParams.get("field") || "SCORE",
    direction:
      (url.searchParams.get("direction") as Sort["direction"]) || "asc",
  };
  const sortDirection: SortDirection = sort.direction === "asc" ? -1 : 1;

  const handleSort = (
    a: PolicyReportSummariesEdge,
    b: PolicyReportSummariesEdge,
  ) => {
    switch (sort.field) {
      case "POLICY":
        return sortByName(a, b, sortDirection);
      case "ASSETS":
        return sortByAssetCounts(a, b, sortDirection);
      case "LAST_UPDATE":
        return sortByDate(a, b, sortDirection);
      case "SCORE":
        return sortByScore(a, b, sortDirection);
      default:
        return sortByName(a, b, sortDirection);
    }
  };

  const handleFilter = (query: string) => {
    if (query.length > 0) {
      url.searchParams.set("query", query);
    } else {
      url.searchParams.delete("query");
    }
    navigate(url.pathname + url.search, { replace: true });
  };

  // Handle action when a user clicks on the "up/down" arrow
  // at the top of each column
  const handleSortClick = (field: string) => {
    if (sort.field === field && sort.direction === "asc") {
      url.searchParams.set("direction", "desc");
    } else {
      url.searchParams.set("direction", "asc");
    }

    url.searchParams.set("field", field);
    navigate(url.pathname + url.search, { replace: true });
  };

  const handleNavigation = (href: string) => {
    navigate(href);
  };

  return {
    handle: {
      sort: handleSort,
      filter: handleFilter,
      sortClick: handleSortClick,
      disablePolicies: handleDisabling,
      unCheckAll,
      check: handleCheck,
      navigate: handleNavigation,
      previewPolicies: handlePlaceInPreview,
      enablePolicies: handleEnable,
    },
    state: {
      hasSelectedBothEnabledAndPreviewedPolicies,
      hasSelectedEnabledPolicy,
      hasSelectedPreviewedPolicy,
    },
    permissions: {
      edit: hasEditPolicyPermission,
    },
    sort: {
      sort,
      sortDirection,
    },
  };
}
