import { Fragment, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Grid,
  Paper,
  Button,
  ButtonProps,
  InputLabel,
  FormControl,
  TextField,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  Link,
  InputAdornment,
  IconButton,
  OutlinedInput,
  OutlinedInputProps,
  FormHelperText,
  Box,
} from "@mui/material";
import { LoadingButton } from "../components/loading-button";

import { RefreshIcon } from "~/components/icons";
import { Validations } from "../lib/validations";
import { useViewer } from "~/providers/viewer";

import { useNavigate } from "react-router-dom";
import {
  useCreateOrganizationMutation,
  useOrganizationIdSuggestionLazyQuery,
} from "~/operations";

interface CreateOrganizationProps {}

interface CreateOrganizationState {
  organization: string;
  organizationError: string;
  tandc: boolean;
  organizationID: string;
  organizationIDError: string;
  organizationIDEdit: boolean;
}

const initialOrgState: CreateOrganizationState = {
  organization: "",
  organizationError: "",
  tandc: false,
  organizationID: "",
  organizationIDError: "",
  organizationIDEdit: false,
};

export function CreateOrganizationPage({}: CreateOrganizationProps) {
  const navigate = useNavigate();
  const [state, setState] = useState<CreateOrganizationState>(initialOrgState);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { viewer, refetch } = useViewer();

  const [organizationIdSuggestion] = useOrganizationIdSuggestionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const getIdSuggestion = async (id = "") => {
    // ID needs to be hyphenated to be valid
    const idAvailable = id.trim().split(" ").join("-").toLowerCase();
    const result = await organizationIdSuggestion({
      variables: { input: { idAvailable } },
    });
    return result.data?.organizationIDSuggestion;
  };

  const generateRandomId = async () => {
    const suggestion = await getIdSuggestion();
    if (suggestion && suggestion.available) {
      setState((prevState) => ({
        ...prevState,
        organizationID: suggestion.id,
        organizationIDError: "",
      }));
    }
  };

  useEffect(() => {
    generateRandomId();
  }, []);

  const [createOrganization] = useCreateOrganizationMutation();

  const handleCreateClick = async () => {
    setIsCreating(true);
    if (viewer == null) throw "cannot add org space without a viewer";

    const { organization, organizationID } = state;
    if (organization == null || organization === "") return;

    try {
      // ID needs to be hyphenated to be valid
      const id = organizationID.trim().split(" ").join("-").toLowerCase();
      const name = organization;
      const createOrgResult = await createOrganization({
        variables: { input: { name, id } },
      });
      const createdOrg = createOrgResult.data?.createOrganization;
      if (!createdOrg) throw "Failed to create organization";

      enqueueSnackbar(`Successfully created organization: ${organization}`, {
        variant: "success",
      });
      // we have to wait for viewer and settings to load again
      // before we can navigate to the new organization
      await refetch();
      navigate(`/organization/spaces?organizationId=${createdOrg.id}`);
    } catch {
      setIsCreating(false);
      enqueueSnackbar(`Failed to create organization`, { variant: "error" });
    }
  };

  const handleGenerateIdClick = () => {
    generateRandomId();
  };

  const enableEditOrganizationID: ButtonProps["onClick"] = (_evt) => {
    setState((prevState) => ({ ...prevState, organizationIDEdit: true }));
  };

  const validateOrganizationName = (organization: string) => {
    let organizationError = "";
    if (
      !organization ||
      !organization.match(Validations.nameInvalidLengthRegex)
    ) {
      organizationError = Validations.nameInvalidLengthErrorMsg;
    } else if (
      !organization ||
      !organization.match(Validations.nameInvalidCharactersRegex)
    ) {
      organizationError = Validations.nameInvalidCharactersErrorMsg;
    }

    setState((prevState) => ({
      ...prevState,
      organizationError,
      organization,
    }));
  };

  const validateOrganizationId = async (organizationID: string) => {
    let organizationIDError = "";
    if (
      !organizationID ||
      !organizationID.match(Validations.idInvalidLengthRegex)
    ) {
      organizationIDError = Validations.idInvalidLengthErrorMsg;
    } else if (
      !organizationID ||
      !organizationID.match(Validations.idInvalidCharactersRegex)
    ) {
      organizationIDError = Validations.idInvalidCharactersErrorMsg;
    }

    setState((prevState) => ({
      ...prevState,
      organizationIDError,
      organizationID,
    }));

    // check if the id is still available
    try {
      const suggestion = await getIdSuggestion(organizationID);
      if (suggestion?.available !== true) {
        throw "This organization id is not available";
      }
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        organizationIDError: String(err),
      }));
    }
  };

  const handleOrganizationNameChange: OutlinedInputProps["onChange"] = (
    evt,
  ) => {
    validateOrganizationName(evt.target.value);
  };

  const handleOrganizationIdChange: OutlinedInputProps["onChange"] = (evt) => {
    validateOrganizationId(evt.target.value);
  };

  const handleChangeTermsAndCondition: CheckboxProps["onChange"] = (evt) => {
    setState((prevState) => ({ ...prevState, tandc: evt.target.checked }));
  };

  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          style={{
            maxWidth: 1200,
            margin: "auto",
          }}
        >
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">Create a new organization</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  An organization allows you to group many spaces together. Each
                  organization is billed separately.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  What’s your organization’s name?
                </Typography>
                <TextField
                  id="org-name-input"
                  style={{ width: "100%" }}
                  label="Organization Name"
                  variant="outlined"
                  onChange={handleOrganizationNameChange}
                  error={state.organizationError.length === 0 ? false : true}
                  helperText={state.organizationError}
                />
              </Grid>
              <Grid item xs={12}>
                {!state.organizationIDEdit && (
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box sx={{ mr: 2 }}>
                      <Typography>
                        OrganizationID:{" "}
                        <Typography
                          id="orgid"
                          component="span"
                          fontWeight={700}
                        >
                          {state.organizationID}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="caption"
                        fontWeight={700}
                        sx={{ color: (theme) => theme.palette.warning.main }}
                      >
                        Organization ID can not be changed later.
                      </Typography>
                    </Box>
                    <Button color="primary" onClick={enableEditOrganizationID}>
                      Edit
                    </Button>
                  </Box>
                )}
                {state.organizationIDEdit && (
                  <Fragment>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="orgid-input">
                        Organization ID
                      </InputLabel>
                      <OutlinedInput
                        id="orgid-input"
                        label="Organization ID"
                        onChange={handleOrganizationIdChange}
                        error={
                          state.organizationIDError.length === 0 ? false : true
                        }
                        value={state.organizationID}
                        aria-describedby="orgid-helper-text"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="generate new id"
                              onClick={handleGenerateIdClick}
                              size="large"
                            >
                              <RefreshIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="orgid-helper-text">
                        {state.organizationIDError || Validations.idInformMsg}{" "}
                      </FormHelperText>
                    </FormControl>
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="policy-accept-input"
                      checked={state.tandc}
                      onChange={handleChangeTermsAndCondition}
                    />
                  }
                  label={
                    <p>
                      I agree to the{" "}
                      <Link
                        target="_blank"
                        href="https://mondoo.com/terms-of-service"
                      >
                        Terms of Service
                      </Link>{" "}
                      and the{" "}
                      <Link
                        target="_blank"
                        href="https://mondoo.com/privacy-policy"
                      >
                        Privacy Policy
                      </Link>
                    </p>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <LoadingButton
                  buttonText={"Create Organization"}
                  disabled={
                    !state.tandc ||
                    state.organizationError.length > 0 ||
                    state.organization == ""
                  }
                  variant="contained"
                  color="primary"
                  onClick={handleCreateClick}
                  loading={isCreating}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
}
