import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Typography, Box, Divider, Chip, Button } from "@mui/material";
import {
  useGetSharedSpacesCountQuery,
  useTestIamActionsQuery,
} from "~/operations";
import { useViewer } from "~/providers/viewer";
import { AddButton } from "~/components/add-button";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { AddIcon, NavDrawerOrganizationsIcon } from "~/components/icons";
import { IoOnTheMoon } from "~/components/galaxy/io-on-the-moon";
import { Config } from "~/configuration_provider";
import { PLATFORM_POLICY_MRN } from "~/lib/mrn";
import { IamActions } from "~/lib/iam";

export function RegionDashboard() {
  const { viewer, selectedRegion } = useViewer();
  const { setTree } = useAppNavDrawer();

  useEffect(() => {
    setTree([
      {
        text: "Organizations",
        icon: <NavDrawerOrganizationsIcon />,
        link: {
          to: `/dashboard`,
        },
      },
    ]);
    return () => setTree([]);
  }, []);

  const isPrivateInstance = Config.VITE_PRIVATE_INSTANCE === "true";

  const { data: sharedSpacesCountData } = useGetSharedSpacesCountQuery();
  const [sharedSpacesCount, setSharedSpacesCount] = useState<number>(0);
  useEffect(() => {
    if (sharedSpacesCountData) {
      setSharedSpacesCount(
        sharedSpacesCountData?.sharedSpaces?.totalCount || 0,
      );
    }
  }, [sharedSpacesCountData]);

  const hasOrgsOrSpaces = Boolean(
    sharedSpacesCount > 0 || viewer?.organizations?.length,
  );

  const { data } = useTestIamActionsQuery({
    variables: {
      resourceMrn: PLATFORM_POLICY_MRN,
      actions: [IamActions.CAPTAIN_CREATEORGANIZATION],
    },
    skip: !isPrivateInstance,
  });
  const testIamActions = data?.testIamActions || [];
  const hasCreateOrgAction =
    !isPrivateInstance ||
    testIamActions.includes(IamActions.CAPTAIN_CREATEORGANIZATION);

  const content = (
    <Grid container spacing={3}>
      {viewer?.organizations?.map((org) => (
        <Grid item xs={12} md={6} key={org.id} id="orgs-list">
          <Box
            className="dashboard-card orgs-list-item"
            sx={{
              display: "block",
              position: "relative",
              "&::after": {
                content: "' '",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                borderRadius: 0.5,
                zIndex: -1,
                opacity: "0",
                transition: "opacity 300ms ease-in-out",
                boxShadow: `0px 0px 0px 2px #CCCCCC}`,
              },
              "&:hover::after, &:focus::after": {
                opacity: "80%",
                transition: "opacity 300ms ease-in-out",
              },
            }}
            component={RouterLink}
            to={`/organization?organizationId=${org.id}`}
          >
            <Box
              className="dashboard-card-header"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "background.light",
                p: 2,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
              }}
            >
              <Box>
                <Typography variant="body1" fontWeight="bold" lineHeight="1">
                  {org.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {org.id}
                </Typography>
              </Box>
              {org.subscriptionInfo && (
                <Chip
                  label={org.subscriptionInfo?.basePlan.name}
                  variant="outlined"
                  size="small"
                  sx={{
                    borderRadius: 1,
                    color: "#2FC089",
                    borderColor: "#2FC089",
                    textTransform: "uppercase",
                    fontWeight: 600,
                  }}
                />
              )}
            </Box>
            <Box
              className="dashboard-card-body"
              sx={{
                backgroundColor: "background.paper",
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body1" fontWeight="bold" lineHeight="1">
                    Spaces
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {org.spacesCount}
                  </Typography>
                </Grid>
                {org.members && (
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      lineHeight="1"
                    >
                      Members
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {org.members.totalCount}
                    </Typography>
                  </Grid>
                )}
                {org.owners && (
                  <Grid item xs={4}>
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      lineHeight="1"
                    >
                      Owners
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {org.owners.edges?.at(0)?.node?.user.name}{" "}
                      {org.owners.totalCount > 1 &&
                        `+ ${org.owners.totalCount - 1} more`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
      ))}

      {/* Shared Spaces */}
      {sharedSpacesCount > 0 && (
        <Grid item xs={12} md={6}>
          <Box
            className="dashboard-card"
            sx={{
              display: "block",
              position: "relative",
              "&::after": {
                content: "' '",
                position: "absolute",
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                borderRadius: 0.5,
                zIndex: -1,
                opacity: "0",
                transition: "opacity 300ms ease-in-out",
                boxShadow: `0px 0px 0px 2px #CCCCCC}`,
              },
              "&:hover::after, &:focus::after": {
                opacity: "80%",
                transition: "opacity 300ms ease-in-out",
              },
            }}
            component={RouterLink}
            to={`/spaces`}
          >
            <Box
              className="dashboard-card-header"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "background.light",
                p: 2,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                minHeight: "72px",
              }}
            >
              <Box>
                <Typography variant="body1" fontWeight="bold" lineHeight="1">
                  Shared Spaces
                </Typography>
              </Box>
            </Box>
            <Box
              className="dashboard-card-body"
              sx={{
                backgroundColor: "background.paper",
                display: "flex",
                justifyContent: "space-between",
                p: 2,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
            >
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="body1" fontWeight="bold" lineHeight="1">
                    Spaces
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {sharedSpacesCount}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      )}

      {/* Add Org */}
      {hasCreateOrgAction && (
        <Grid item xs={12} md={6}>
          <Box
            className="dashboard-add-card"
            sx={{
              borderRadius: 1,
              border: "2px dashed",
              borderColor: "background.lighter",
              color: "#7B7B7B",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              height: "100%",
              minHeight: "144px",
              transition: (theme) =>
                theme.transitions.create(["color", "border-color"]),

              "&:hover": {
                borderColor: "background.lightest",
                color: "text.primary",
              },
            }}
            component={RouterLink}
            to="/new/organization"
          >
            <AddIcon fontSize="large" />
            <Typography variant="body2" textTransform="uppercase">
              Add Organization
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );

  const emptyContent = (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Box id="welcome" sx={{ textAlign: "center", m: 2, p: 2 }}>
          <Box
            sx={{
              display: "flex",
              alighItems: "center",
              justifyContent: "center",
              py: 2,
              width: 1,
            }}
          >
            <Box sx={{ width: { xs: 100, sm: 150, md: 200 } }}>
              <IoOnTheMoon />
            </Box>
          </Box>
          {!isPrivateInstance && <Typography variant="h2">Welcome</Typography>}
          {!isPrivateInstance && (
            <Typography variant="body1">
              You don't have any organizations or spaces yet. Start by creating
              one.
            </Typography>
          )}
          {isPrivateInstance && (
            <Typography
              variant="h5"
              sx={{ textTransform: "uppercase", fontWeight: 700, mb: 3 }}
            >
              Welcome to your Mondoo dedicated account
            </Typography>
          )}
          {isPrivateInstance && (
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              Please wait for an administrator to grant you access to your
              company's organizations.
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ textAlign: "center", m: 2, p: 2 }}>
          {hasCreateOrgAction && (
            <Button
              variant="outlined"
              component={RouterLink}
              to={"/new/organization"}
            >
              New Organization
            </Button>
          )}
        </Box>
      </Grid>
    </Grid>
  );

  document.title = "Dashboard · Mondoo";

  return (
    <Box>
      {/* Heading */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Typography variant="h5" fontWeight="bold" textTransform="uppercase">
            {!isPrivateInstance
              ? `${selectedRegion.name} Region`
              : "Organizations"}
          </Typography>
        </Box>
        {hasCreateOrgAction && <AddButton href="/new/organization" />}
      </Box>
      <Divider sx={{ my: 3 }} />
      {hasOrgsOrSpaces ? content : emptyContent}
    </Box>
  );
}
