import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import {
  AggregateScoreOrderField,
  AggregateScoreType,
  NotFoundError,
  OrderDirection,
  RequestError,
  useGetSpaceSoftwareQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { HomeIcon, PackageIcon } from "~/components/icons";
import { MessageSeverity, WarningMessage } from "~/components/user-warning";
import { Header } from "~/components/DetailsPage/components/Header";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { SoftwareDescription } from "~/pages/space/software/Software/components/SoftwareDescription";
import { SoftwareRiskFactors } from "~/pages/space/software/Software/components/SoftwareRiskFactors";
import { SoftwareVersions } from "~/pages/space/software/Software/components/SoftwareVersions";
import { SoftwareAssets } from "~/pages/space/software/Software/components/SoftwareAssets";
import { LoadingPage } from "~/components/loading";
import { VersionsIcon } from "~/components/icons/mondoo/versions";
import { pluralize } from "~/lib/pluralize";
import React, { useEffect } from "react";
import { trimSoftwareMrnVersion } from "~/pages/space/software/Software/utils";
import { Flex } from "~/components/ui-library";
import { SoftwareAdvisories } from "./components/SoftwareAdvisories";
import { SoftwareVulnerabilities } from "./components/SoftwareVulnerabilities";

type SoftwareProps = { space: Space };

export const Software = ({ space }: SoftwareProps) => {
  const [searchParams] = useSearchParams();
  const genericFindingMrn = searchParams.get("genericFindingMrn") || "";
  const versionedFindingMrn = searchParams.get("versionedFindingMrn") || "";
  const isVersionedSoftware = versionedFindingMrn.length > 0;

  if (!genericFindingMrn && !versionedFindingMrn) {
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg="No Software MRN provided!"
        />
      </Grid>
    );
  }

  const {
    data: versionedSoftwareDetailsData,
    loading: versionedSoftwareDetailsLoading,
    error: versionedSoftwareAggDataError,
  } = useGetSpaceSoftwareQuery({
    variables: {
      entityMrn: space.mrn,
      orderBy: {
        field: AggregateScoreOrderField.Rank,
        direction: OrderDirection.Asc,
      },
      filter: {
        softwareFindingMrn: versionedFindingMrn || genericFindingMrn,
        scoreType: AggregateScoreType.VersionedSoftware,
      },
    },
  });

  const {
    data: genericSoftwareDetailsData,
    loading: genericSoftwareDetailsLoading,
    error: genericSoftwareAggDataError,
  } = useGetSpaceSoftwareQuery({
    variables: {
      entityMrn: space.mrn,
      orderBy: {
        field: AggregateScoreOrderField.Rank,
        direction: OrderDirection.Asc,
      },
      filter: {
        findingMrn: genericFindingMrn,
        scoreType: AggregateScoreType.Software,
      },
    },
    skip: Boolean(versionedFindingMrn),
  });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: versionedFindingMrn || genericFindingMrn,
    scoreType: isVersionedSoftware
      ? AggregateScoreType.VersionedSoftware
      : AggregateScoreType.Software,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [genericFindingMrn, versionedFindingMrn]);

  const genericSoftwareNotFoundError =
    genericSoftwareDetailsData?.aggregateScores?.__typename === "NotFoundError"
      ? genericSoftwareDetailsData?.aggregateScores
      : undefined;

  const genericSoftwareRequestError =
    genericSoftwareDetailsData?.aggregateScores?.__typename === "RequestError"
      ? genericSoftwareDetailsData?.aggregateScores
      : undefined;

  const versionedSoftwareNotFoundError =
    versionedSoftwareDetailsData?.aggregateScores?.__typename ===
    "NotFoundError"
      ? versionedSoftwareDetailsData?.aggregateScores
      : undefined;

  const versionedSoftwareRequestError =
    versionedSoftwareDetailsData?.aggregateScores?.__typename === "RequestError"
      ? versionedSoftwareDetailsData?.aggregateScores
      : undefined;

  if (
    genericSoftwareDetailsLoading ||
    versionedSoftwareDetailsLoading ||
    riskFactorsLoading
  ) {
    return <LoadingPage what="Software" />;
  }

  if (
    genericSoftwareRequestError ||
    versionedSoftwareRequestError ||
    versionedSoftwareAggDataError ||
    genericSoftwareAggDataError
  ) {
    const error = genericSoftwareRequestError || versionedSoftwareRequestError;
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg={(error as RequestError).message}
        />
      </Grid>
    );
  }

  if (versionedSoftwareNotFoundError || genericSoftwareNotFoundError) {
    const error =
      versionedSoftwareNotFoundError || genericSoftwareNotFoundError;
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg={(error as NotFoundError).message}
        />
      </Grid>
    );
  }

  const versionedSoftwareScores =
    versionedSoftwareDetailsData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? versionedSoftwareDetailsData.aggregateScores
      : undefined;

  const genericSoftwareScores =
    genericSoftwareDetailsData?.aggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? genericSoftwareDetailsData.aggregateScores
      : undefined;

  const softwareEdges = isVersionedSoftware
    ? versionedSoftwareScores?.edges
    : genericSoftwareScores?.edges;
  const software = softwareEdges?.[0]?.node;

  const stats: StatsProps["stats"] =
    [
      {
        label: "Scanned",
        value: software?.versionDistribution?.assets?.toString() || "---",
        icon: <Radar fontSize="inherit" />,
      },
      {
        label: "Installs",
        value:
          software?.versionDistribution?.installations?.toString() || "---",
        icon: <FlagOutlined fontSize="inherit" />,
        onClick: () => {
          document
            .querySelector(`#software-assets`)
            ?.scrollIntoView({ behavior: "smooth" });
        },
      },
      !isVersionedSoftware
        ? {
            label: pluralize(
              "Version",
              genericSoftwareScores?.edges?.[0]?.node?.versionDistribution
                ?.distribution?.length || 0,
            ),
            value:
              genericSoftwareScores?.edges?.[0]?.node?.versionDistribution?.distribution?.length?.toString() ||
              "---",
            icon: <VersionsIcon fontSize="inherit" />,
            onClick: () => {
              document
                .querySelector(`#software-versions`)
                ?.scrollIntoView({ behavior: "smooth" });
            },
          }
        : null,
    ].flatMap((e) => e ?? []) || [];

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/software"
      component={RouterLink}
      to={`/space/vulnerabilities/software?spaceId=${space.id}`}
      display="flex"
    >
      Software
    </Link>,
    isVersionedSoftware && (
      <Flex gap={1}>
        <DynamicIcon iconId={software?.iconId} defaultIcon={PackageIcon} />
        <Link
          key="/space/software"
          component={RouterLink}
          to={`/space/vulnerabilities/software/${software?.title}?spaceId=${
            space.id
          }&genericFindingMrn=${trimSoftwareMrnVersion(versionedFindingMrn)}`}
          display="flex"
        >
          {trimSoftwareMrnVersion(versionedFindingMrn).split("/").pop()}
        </Link>
      </Flex>
    ),
    <Typography key="/space/vulnerabilities">{software?.title}</Typography>,
  ];

  if (!software) {
    return (
      <Grid item xs={12}>
        <WarningMessage
          severity={MessageSeverity.Error}
          msg={"Software Not Found!"}
        />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Header
          id="software-header"
          title={String(software?.title)}
          created={String(software?.firstDetectedAt)}
          lastModified={String(software?.lastScannedAt)}
          riskFactors={riskFactors}
          icon={
            <DynamicIcon
              iconId={software?.iconId}
              defaultIcon={PackageIcon}
              size="40px"
            />
          }
        />
      </Grid>

      <SoftwareDescription
        stats={stats}
        description={String(software?.description)}
        space={space}
        findingMrn={genericFindingMrn}
        edges={versionedSoftwareScores?.edges}
        title={String(software?.title)}
        riskFactors={riskFactors}
        versionDistribution={
          isVersionedSoftware
            ? versionedSoftwareScores?.edges?.[0]?.node?.versionDistribution
            : genericSoftwareScores?.edges?.[0]?.node?.versionDistribution
        }
        showVersionDistribution={!isVersionedSoftware}
        softwareNode={software}
      />

      {riskFactorsWithDocs.length > 0 && (
        <SoftwareRiskFactors riskFactorsWithDocs={riskFactorsWithDocs} />
      )}

      {!isVersionedSoftware && (
        <SoftwareVersions
          space={space}
          loading={versionedSoftwareDetailsLoading}
          edges={versionedSoftwareScores?.edges}
          riskFactors={riskFactors}
        />
      )}

      <SoftwareAssets
        space={space}
        findingMrn={
          isVersionedSoftware ? versionedFindingMrn : genericFindingMrn
        }
        version={
          isVersionedSoftware ? versionedFindingMrn.split("/").pop() : null
        }
        riskFactors={riskFactors}
        softwareTitle={software?.title}
      />

      {isVersionedSoftware && (
        <SoftwareVulnerabilities
          space={space}
          findingMrn={versionedFindingMrn || genericFindingMrn}
        />
      )}

      {isVersionedSoftware && (
        <SoftwareAdvisories
          space={space}
          findingMrn={versionedFindingMrn || genericFindingMrn}
        />
      )}
    </Grid>
  );
};
