import { PolicyReportSummariesEdge, SortDirection } from "../types";

export const sortByAssetCounts = (
  a: PolicyReportSummariesEdge,
  b: PolicyReportSummariesEdge,
  sortDirection: SortDirection,
) => {
  const aValue = a.node?.assetGrades.total || 0;
  const bValue = b.node?.assetGrades.total || 0;
  switch (true) {
    case aValue > bValue:
      return -1 * sortDirection;
    case aValue < bValue:
      return 1 * sortDirection;
    default:
      return 0;
  }
};
