import React from "react";
import { BarChartIcon, Flex } from "~/components/ui-library";
import { Typography } from "@mui/material";
import { CISIcon } from "~/pages/compliance/icons";

type CheckPolicyInfoTagsProps = {
  version: string | undefined;
  certifiedBy: string | undefined;
  groups: string | undefined;
};

export const CheckPolicyInfoTags = ({
  version,
  certifiedBy,
  groups,
}: CheckPolicyInfoTagsProps) => {
  return (
    <Flex gap={3} mt={1} data-testid="policy-info-tags">
      {/*{groups && (
        <Flex alignItems="center">
          <Typography variant="caption" color="text.secondary" fontWeight={700}>
            {groups}
          </Typography>
          <BarChartIcon fontSize="inherit" />
        </Flex>
      )}*/}
      {version && (
        <Flex alignItems="center" data-testid="version-tag">
          <Typography variant="caption" color="text.secondary" fontWeight={700}>
            Version {version}
          </Typography>
        </Flex>
      )}
      <Flex gap={1} alignItems="center" data-testid="active-tag">
        <BarChartIcon fontSize="inherit" />
        <Typography variant="caption" color="text.secondary" fontWeight={700}>
          Active
        </Typography>
      </Flex>
      {certifiedBy && (
        <Flex gap={1} alignItems="center" data-testid="certified-tag">
          {certifiedBy.toLowerCase() === "cis" ? (
            <Flex gap={1} alignItems="center">
              <Typography
                variant="caption"
                color="text.secondary"
                fontWeight={700}
              >
                Certified By
              </Typography>
              <CISIcon fontSize="inherit" />
            </Flex>
          ) : (
            <Typography
              variant="caption"
              color="text.secondary"
              fontWeight={700}
            >
              Certified By {certifiedBy}
            </Typography>
          )}
        </Flex>
      )}
    </Flex>
  );
};
