import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { Loading, LoadingFailed } from "~/components/ui-library";
import {
  FormatType,
  SpacePolicyReportQuery,
  useSpacePolicyReportQuery,
} from "~/operations";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";
import { usePolicyOutletContext } from "./policy-gql";
import { DatasetSearchBar } from "../search";
import { ClickableTableRow } from "../report";
import { DistributionBarGraph } from "../distribution-bar-graph";
import { EmptyState } from "~/components/empty-state/empty-state";

type SpacePolicyReport = Extract<
  NonNullable<SpacePolicyReportQuery["spacePolicyReport"]>,
  { __typename: "SpacePolicyReport" }
>;
type AssetSummaries = NonNullable<SpacePolicyReport["assetSummaries"]>;
type AssetSummariesEdge = NonNullable<AssetSummaries["edges"]>[0];

type Props = {};

type Sort = {
  field: string;
  direction: "asc" | "desc";
};

type SortDirection = 1 | -1;

export function Assets({}: Props) {
  const { policy, space } = usePolicyOutletContext();
  const policyMrn = policy.mrn;
  const url = new URL(window.location.toString());
  const navigate = useNavigate();
  const { data, loading, error } = useSpacePolicyReportQuery({
    variables: {
      input: { spaceMrn: space.mrn, policyMrn, formatType: FormatType.Csv },
    },
    fetchPolicy: "no-cache",
  });

  // Display a loading spinner in the queries tab until we've successfully
  // returned and loaded the data
  if (loading)
    return (
      <Box pt={4}>
        <Loading what="assets" />
      </Box>
    );

  // Display an error if the SpacePolicyReport call fails
  if (error || data?.spacePolicyReport?.__typename !== "SpacePolicyReport")
    return (
      <Box pt={4}>
        <LoadingFailed what="assets" />
      </Box>
    );

  let filteredAssetSummaries =
    data.spacePolicyReport.assetSummaries?.edges?.slice() || [];

  // Check if the user is sorting by reading search Parameters
  // By default, we'll sort the rows by Policy Name, A => Z
  const sort: Sort = {
    field: url.searchParams.get("field") || "SCORE",
    direction:
      (url.searchParams.get("direction") as Sort["direction"]) || "asc",
  };
  const sortDirection: SortDirection = sort.direction === "asc" ? -1 : 1;

  // If user is filtering, we read the parameters for query
  // and deliver the results.
  const query = url.searchParams.get("query");
  if (query) {
    filteredAssetSummaries = filteredAssetSummaries.filter(
      (edge: AssetSummariesEdge) => {
        if (!edge.node?.asset) return false;
        //   This filter is adjusted to allow the user to search by asset state, name, and ID
        const { id, name, state } = edge.node.asset;
        const comparator = `${id} ${name} ${state}`;
        return comparator.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
    );
  }

  const handleSort = (a: AssetSummariesEdge, b: AssetSummariesEdge) => {
    switch (sort.field) {
      case "POLICY_SCORE":
        return sortByScore(a, b, sortDirection);
      default:
        return sortByName(a, b, sortDirection);
    }
  };

  // handle action when a user filters by a custom string
  // This will set the query as a parameter and then navigate to the new url.
  const handleFilter = (query: string) => {
    if (query.length > 0) {
      url.searchParams.set("query", query);
    } else {
      url.searchParams.delete("query");
    }
    navigate(url.pathname + url.search, { replace: true });
  };

  // Handle action when a user clicks on the "up/down" arrow
  // at the top of each column
  const handleSortClick = (field: string) => {
    if (sort.field === field && sort.direction === "asc") {
      url.searchParams.set("direction", "desc");
    } else {
      url.searchParams.set("direction", "asc");
    }

    url.searchParams.set("field", field);
    navigate(url.pathname + url.search, { replace: true });
  };

  // Navigate to the desired asset
  const handleNavigate = (assetId: string, policyMrn: string) => {
    // This function decodes the URL and can determine the appropriate ID
    // to use for navigation. Policies have a different ID when they are custom
    // vs when they are created by Mondoo
    const policyLink = PolicyMrnToURIEncodedId(policyMrn);

    // There are two possible URLs that this can compile down to
    // All policies live at `/policy` except for mondoo platform vulnerability policy
    const group =
      policyMrn.split("/").pop() === "platform-vulnerability"
        ? "vulnerabilities"
        : "checks";
    const nextUrl = `/space/inventory/${assetId}/${group}?spaceId=${space.id}&encodedPolicyMrn=${policyLink}`;
    navigate(nextUrl);
  };

  if (!filteredAssetSummaries.length) {
    return (
      <Box pt={3}>
        <EmptyState contentType="policy-assets" space={space} />
      </Box>
    );
  }

  return (
    <Fragment>
      <Grid container pb={3} justifyContent="flex-end">
        <Grid item xs={12} sm={6}>
          <DatasetSearchBar
            onChange={debounce((query) => handleFilter(query), 300)}
            onQuery={() => {}}
            placeholder="Filter assets..."
            value={url.searchParams.get("query")}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table id="policy-assets-list">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell
                  key={header.id}
                  sortDirection={
                    sort.field === header.id ? sort.direction : false
                  }
                  colSpan={header.colSpan}
                  sx={{ ...header.options }}
                >
                  <TableSortLabel
                    onClick={() => handleSortClick(header.id)}
                    direction={sort.direction}
                    active={sort.field === header.id}
                  >
                    {header.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAssetSummaries
              .sort(handleSort)
              .map((edge: AssetSummariesEdge) => {
                if (!edge.node) return undefined;
                const {
                  asset,
                  score,
                  grade,
                  mqueryResultsDistribution,
                  policyMrn,
                } = edge.node;

                return (
                  <ClickableTableRow
                    key={asset.id}
                    onClick={() => handleNavigate(asset.id, policyMrn)}
                  >
                    <TableCell width={50}>
                      <Typography
                        fontSize={24}
                        fontWeight={500}
                        display="inline"
                        sx={{ mr: 0.5 }}
                      >
                        {grade}
                      </Typography>
                      <Typography fontSize={10} display="inline">
                        {score.value}
                      </Typography>
                    </TableCell>
                    <TableCell width={150}>
                      <DistributionBarGraph
                        distribution={mqueryResultsDistribution}
                      />
                    </TableCell>
                    <TableCell>
                      <Typography fontSize={14} className="policy-asset-name">
                        {asset.name}
                      </Typography>
                      <Typography fontSize={10} color="text.secondary">
                        {asset.platform?.name} {asset.platform?.release}
                      </Typography>
                    </TableCell>
                  </ClickableTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
}

type Header = {
  id: string;
  label: string;
  colSpan?: number;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

const tableHeaders: Header[] = [
  {
    id: "POLICY_SCORE",
    label: "Policy Score",
    colSpan: 2,
    options: { textAlign: "center" },
  },
  { id: "ASSET", label: "Asset" },
];

///////// SORTING HELPERS

const sortByName = (
  a: AssetSummariesEdge,
  b: AssetSummariesEdge,
  sortDirection: SortDirection,
) => {
  const aName = a.node?.asset?.name?.toUpperCase();
  const bName = b.node?.asset?.name?.toUpperCase();
  if (!aName || !bName) return 0;
  switch (true) {
    case aName > bName:
      return -1 * sortDirection;
    case aName < bName:
      return 1 * sortDirection;
    default:
      return 0;
  }
};

const sortByScore = (
  a: AssetSummariesEdge,
  b: AssetSummariesEdge,
  sortDirection: SortDirection,
) => {
  const assetA = a.node?.asset;
  const assetB = b.node?.asset;
  if (!assetA || !assetB) return 0;
  switch (true) {
    case assetA.score.value > assetB.score.value:
      return 1 * sortDirection;
    case assetA.score.value < assetB.score.value:
      return -1 * sortDirection;
    default:
      return assetA.mrn.localeCompare(assetB.mrn) * sortDirection;
  }
};
