import { Box, useTheme } from "@mui/material";
import { DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ExplorerIcon } from "~/components/icons/mondoo/explorer";
import { getColor } from "~/lib/colors";
import { useLoadPolicyDashboardStatsQuery } from "~/operations";
import { Space } from "~/lib/types";

type Props = {
  space: Space;
  sizes?: CardSizes;
};

export function AssetOverviewCard({ space, sizes }: Props) {
  const theme = useTheme();
  const { loading, data } = useLoadPolicyDashboardStatsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
  });

  const d = data?.policyDashboardStats?.assetStats;
  const graphData = [
    { label: "A", value: d?.A || 0, color: getColor(theme, "A") },
    { label: "B", value: d?.B || 0, color: getColor(theme, "B") },
    { label: "C", value: d?.C || 0, color: getColor(theme, "C") },
    { label: "D", value: d?.D || 0, color: getColor(theme, "D") },
    { label: "F", value: d?.F || 0, color: getColor(theme, "F") },
    { label: "Unrated", value: d?.U || 0, color: getColor(theme, "U") },
    { label: "Error", value: d?.Error || 0, color: getColor(theme, "X") },
  ];

  return (
    <HubCard
      {...{
        loading,
        sizes,
        title: "Asset Overview",
        icon: <ExplorerIcon fontSize="small" />,
      }}
      PaperProps={{ height: 264 }}
    >
      <Box sx={{ mt: 2 }}>
        <DonutChart data={graphData} dataType="assets" />
      </Box>
    </HubCard>
  );
}
