import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  CheckScoreFilter,
  CheckScoreOrder,
  CheckScoreOrderField,
  OrderDirection,
  ScoreStateFilter,
  useGetCheckScoresQuery,
} from "~/operations";
import { Space } from "~/lib/types";

type UseCheckAffectedAssetsParams = {
  checkMrn: string;
  space: Space;
};

export function useCheckAffectedAssets({
  checkMrn,
  space,
}: UseCheckAffectedAssetsParams) {
  const { handleSortClick, orderBy } = useSort<CheckScoreOrder>({
    defaultSort: {
      field: CheckScoreOrderField.Score,
      direction: OrderDirection.Desc,
    },
    validFields: ["ASSET_NAME", "LAST_UPDATED", "SCORE"],
  });

  const filter: CheckScoreFilter = {
    checkMrn,
    queryTerms: [],
    state: ScoreStateFilter.Open,
  };

  const { data, error, loading, fetchMore } = useGetCheckScoresQuery({
    variables: {
      entityMrn: space.mrn,
      first: 5,
      orderBy,
      filter,
    },
    skip: Boolean(!checkMrn),
  });

  const checkScoresUnion = data?.checkScores;
  const checkScores =
    checkScoresUnion?.__typename === "CheckScoresConnection"
      ? checkScoresUnion
      : undefined;

  const assets = checkScores?.edges?.flatMap(({ node }) =>
    node?.asset
      ? {
          id: node.asset.id,
          mrn: node.asset.mrn,
          score: node.riskScore || 0,
          lastUpdated: node.lastUpdated,
          riskFactors: node.riskFactors,
          title: node.asset.name,
          iconId: node.asset.icon,
        }
      : [],
  );

  return {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount: checkScores?.filteredTotalCount || 0,
    fetchMore,
    pageInfo: checkScores?.pageInfo,
    error,
    loading,
  };
}
