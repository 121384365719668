import { Fragment } from "react";
import {
  Grid,
  Typography,
  Link,
  Box,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Collapse,
} from "@mui/material";
import { ControlResultIcon } from "~/components/ui-library";
import { Link as RouterLink } from "react-router-dom";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PrintAssetSummary } from "~/components/print/print-asset-summary";
import {
  Field,
  LoadAssetReportQuery,
  useLoadAssetReportQuery,
} from "~/operations";
import { Markdown } from "~/components/markdown";
import { SeverityChip } from "~/components/severity-chip";
import { Header } from "~/pages/compliance/compliance-table";
import { Code } from "~/components/code";

export type AssetReport = Extract<
  NonNullable<LoadAssetReportQuery["assetReport"]>,
  { __typename: "AssetReport" }
>;
export type PolicyEdge = NonNullable<
  NonNullable<AssetReport["listPolicies"]>["edges"]
>[0];

type PrintAssetProps = {
  assetMrn: string;
  checkMrn: string; // the mqueryMrn of the desired check
};

export function PrintCheck({ assetMrn, checkMrn }: PrintAssetProps) {
  const spaceId = assetMrn.match(/spaces\/([\w-]+)/)?.[1] || "";
  const assetId = assetMrn.split("/").pop() || "";
  const { data, loading, error } = useLoadAssetReportQuery({
    variables: {
      mrn: assetMrn,
      assetLinkInput: { spaceID: spaceId, assetId },
      assetReportInput: { assetMrn },
    },
  });

  const asset = data?.asset;
  const assetReport = data?.assetReport;

  if (loading) {
    return <LoadingPage what="asset" />;
  }

  if (error || !asset || assetReport?.__typename !== "AssetReport") {
    return <LoadingFailedPage what="asset" />;
  }

  const { queries: checks } = assetReport;

  const check = checks?.edges.find((check) => {
    return check.node.mquery.mrn === checkMrn;
  });

  const policyLinks = check?.node.policies?.map((policy) => {
    const name = policy.split("/").pop();
    return {
      name,
      href: `/space/registry/namespace/mondoohq/policies/${name}?spaceId=${spaceId}`,
    };
  });

  const remediation = check?.node.mquery.docs?.remediations;
  const hasRemediation =
    remediation?.__typename === "Remediations" &&
    remediation.entries.length > 0;

  return (
    <>
      <PrintAssetSummary asset={asset} showScore={false} />
      <Box p={4}>
        <Grid container gap={1.5}>
          <Grid item xs="auto" sx={{ display: "flex", placeItems: "center" }}>
            {check?.node.assessment?.state && (
              <ControlResultIcon status={check?.node.assessment.state} />
            )}
          </Grid>
          <Grid item xs sx={{ display: "flex", placeItems: "center" }}>
            <Typography variant="h5" fontWeight={700}>
              {check?.node?.mquery.title}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption" color="text.secondary">
              Policies:{" "}
              {policyLinks?.map((policy, i) => (
                <Typography sx={{ display: "inline" }}>
                  <Link
                    variant="caption"
                    component={RouterLink}
                    to={policy.href}
                    key={policy.name}
                    sx={{
                      color: "text.secondary",
                      textDecoration: "underline",
                    }}
                  >
                    {policy.name}
                  </Link>
                  {i < policyLinks.length - 1 ? ", " : ""}
                </Typography>
              ))}
            </Typography>
          </Grid>
          <Grid item xs>
            {check?.node.mquery.docs?.desc && (
              <Markdown
                source={check.node.mquery.docs.desc}
                sx={{ fontSize: 14, p: 0, m: 0 }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm="auto">
            <SeverityChip severity={check?.node?.mquery.impact?.value} />
          </Grid>
        </Grid>

        {check?.node.assessment && (
          <Grid container>
            <Grid container item alignItems="center" sx={{ mt: 5 }}>
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                <Box id="some-id">
                  <Typography
                    sx={{
                      display: "inline-block",
                      pr: 3,
                      fontWeight: 700,
                    }}
                  >
                    Affected resources
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={4}>
                {check.node.assessment?.extendedResults?.map(
                  (extendedResult, index) => {
                    const result = extendedResult.actual?.at(0);
                    if (!result) return <Fragment key={index} />;
                    return (
                      <TableContainer
                        component={Paper}
                        sx={{ mb: 3 }}
                        key={index}
                      >
                        <Accordion expanded={true}>
                          <AccordionSummary
                            sx={{ backgroundColor: "background.lighter" }}
                          >
                            {result.name}{" "}
                            <Typography
                              component="span"
                              color="text.secondary"
                              sx={{ pl: 1 }}
                            >
                              {result.fields?.length}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ padding: 0 }}>
                            <Table>
                              <TableHead>
                                <TableRow
                                  sx={{
                                    backgroundColor: "background.lighter",
                                    "& > *": {
                                      borderBottom: "unset",
                                      cursor: "pointer",
                                    },
                                  }}
                                >
                                  {tableHeaders.map((header) => {
                                    return (
                                      <TableCell
                                        key={header.id}
                                        align={header.options?.textAlign}
                                        width={header.options?.width}
                                      >
                                        <TableSortLabel
                                          // onClick={handleSortClick(header.id)}
                                          direction="desc"
                                          active={header.id === "CREATED_AT"}
                                        >
                                          {header.label}
                                        </TableSortLabel>
                                      </TableCell>
                                    );
                                  })}
                                  <TableCell width="50px">
                                    {/* intentionally blank */}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {result?.fields
                                  ?.filter((x): x is Field => !!x)
                                  .map((field) => {
                                    return (
                                      <Row key={field.name} field={field} />
                                    );
                                  })}
                              </TableBody>
                            </Table>
                          </AccordionDetails>
                        </Accordion>
                      </TableContainer>
                    );
                  },
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {hasRemediation && (
          <Grid container alignItems="center" sx={{ mt: 5 }}>
            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Box id="some-id">
                <Typography
                  sx={{
                    display: "inline-block",
                    pr: 3,
                    fontWeight: 700,
                  }}
                >
                  Remediation
                </Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {remediation.entries.map((entry) => {
                return (
                  <Markdown
                    source={entry.desc}
                    sx={{ fontSize: 14, p: 0, m: 0 }}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}

const tableHeaders: Header[] = [
  { id: "RESOURCE", label: "Resource" },
  { id: "PATH", label: "path" },
  { id: "FIELD", label: "field" },
];

function Row({ field }: { field: Field }) {
  return (
    <Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset", cursor: "pointer" },
        }}
      >
        <TableCell component="th" scope="row">
          {field.name}
        </TableCell>
        <TableCell>{field.path}</TableCell>
        <TableCell>{field.type}</TableCell>
        <TableCell></TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Code className="diff">{field.value}</Code>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
