import React from "react";
import { Grid } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { SoftwareVersionsChart } from "./SoftwareVersionsChart";
import { SoftwareVersionsTable } from "./SoftwareVersionsTable";
import { Space } from "~/lib/types";
import { AggregateScoresEdges } from "~/components/FirewatchPage";
import { RiskFactorStats } from "~/operations";

type SoftwareVersionsProps = {
  space: Space;
  loading: boolean;
  edges: AggregateScoresEdges;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
};

export const SoftwareVersions = ({
  space,
  loading,
  edges,
  riskFactors,
}: SoftwareVersionsProps) => {
  return (
    <Grid id="software-versions" item xs={12}>
      <SectionHeading heading="Versions" />
      <SoftwareVersionsChart
        edges={edges}
        riskFactors={riskFactors}
        space={space}
      />
      <SoftwareVersionsTable space={space} loading={loading} edges={edges} />
    </Grid>
  );
};
