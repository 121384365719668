import { Box } from "@mui/material";
import { TestIamActionsQuery } from "~/operations";
import Registry from "~/pages/dashboard/Registry";
import { Space } from "~/lib/types";

type PoliciesPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isQuerypack: boolean;
};

export const PoliciesPage = ({
  space,
  availablePermissions,
  isQuerypack,
}: PoliciesPageProps) => {
  document.title = "Registry · Mondoo";

  return (
    <Box>
      <Registry {...{ space, isQuerypack }} />
    </Box>
  );
};
