import { SyntheticEvent, useCallback, useState } from "react";
import { alpha, useTheme } from "@mui/material";
import { getColorByCompletion } from "~/lib/colors";
import { ComplianceFramework } from "../compliance-page";
import {
  ComplianceFrameworkMutationAction,
  ComplianceFrameworkState,
  GetActiveComplianceFrameworksDocument,
  GetAvailableComplianceFrameworksDocument,
  GetComplianceFrameworkDocument,
  GetComplianceFrameworksDocument,
  UploadFrameworkInput,
  useApplyFrameworkMutation,
  useDeleteFrameworkMutation,
  useDownloadFrameworkLazyQuery,
  useUploadFrameworkMutation,
} from "~/operations";
import { useFrameworkIcon } from "../framework/framework-icon";
import { IconButtonType } from "../components/DynamicButtonIcon";
import { CircularIconButton } from "../components/CircularIconButton";
import { useSnackbar } from "notistack";
import { Space } from "~/lib/types";

type useFrameworkCardProps = {
  framework: ComplianceFramework;
  officialFramework?: boolean;
  space: Space;
};

export function useFrameworkCard({
  framework,
  officialFramework,
  space,
}: useFrameworkCardProps) {
  const theme = useTheme();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [showEditTools, setShowEditTools] = useState(false);
  const { iconKey } = useFrameworkIcon({ framework });

  const { __typename, checks, ...frameworkStats } = framework.stats;
  const authors = framework.authors?.map((author) => author.name).join(", ");
  const completion = framework.stats.controls.averageCompletion || 0;
  const [
    downloadFramework,
    { loading: downloadFrameworkLoading, error: downloadFrameworkError },
  ] = useDownloadFrameworkLazyQuery({
    variables: {
      input: { mrn: framework.mrn, scopeMrn: framework.scopeMrn },
    },
  });

  const [removeFramework] = useApplyFrameworkMutation({
    refetchQueries: [
      // ComplianceFrameworkDocument and GetComplianceFrameworksDocument should
      // both be removed after isFeatureEnabled("Framework States") is removed and
      // the new framework flow is deployed
      {
        query: GetComplianceFrameworkDocument,
        variables: {
          input: {
            frameworkMrn: framework.mrn,
            scopeMrn: space.mrn,
          },
        },
      },
      {
        query: GetComplianceFrameworksDocument,
        variables: {
          input: {
            scopeMrn: space.mrn,
          },
        },
      },
      {
        query: GetActiveComplianceFrameworksDocument,
        variables: {
          input: {
            scopeMrn: space.mrn,
            states: [
              ComplianceFrameworkState.Active,
              ComplianceFrameworkState.Preview,
            ],
          },
        },
      },
      {
        query: GetAvailableComplianceFrameworksDocument,
        variables: {
          input: {
            scopeMrn: space.mrn,
          },
        },
      },
    ],
  });

  const [uploadFramework] = useUploadFrameworkMutation({
    refetchQueries: [GetComplianceFrameworksDocument],
  });

  const [deleteFramework] = useDeleteFrameworkMutation({
    variables: { input: { mrn: framework.mrn } },
    refetchQueries: [
      GetComplianceFrameworksDocument,
      GetActiveComplianceFrameworksDocument,
    ],
  });

  const [canExport, setCanExport] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  if (downloadFrameworkError && canExport) {
    enqueueSnackbar("Framework download failed", { variant: "error" });
    setCanExport(false);
  }

  const handleCancelDelete = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(false);
  };

  const handleDownloadFramework = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setCanExport(true);

    // TODO:: create reusable function and reuse it here and in policy-download-button.tsx component
    downloadFramework().then(({ data }) => {
      if (
        canExport &&
        data != undefined &&
        data.downloadFramework != undefined
      ) {
        const yamlData = data.downloadFramework;
        // only allow one download per click
        setCanExport(false);
        // a bunch of weird magic to make the download work
        // https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
        const file = new Blob([yamlData.yaml], {
          type: "text/yaml",
        });
        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);

        // generates a filename that should sort properly on the file system
        function makeFileName(): string {
          const now = new Date();
          const fileDate = parseInt((now.getTime() / 1000).toString());
          const fileName = `${framework.name}_${fileDate}.mql.yaml`;

          return fileName;
        }

        element.download = makeFileName();
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();

        // this timeout is here to avoid a render warning, so this library does not render during render
        window.setTimeout(function () {
          enqueueSnackbar(
            "Success! If you do not see your framework download, make sure your browser allows downloads from this site.",
            { variant: "success" },
          );
        }, 0);
      }
    });
  };

  const handleReplaceFramework = useCallback((input: UploadFrameworkInput) => {
    return uploadFramework({ variables: { input } });
  }, []);

  const handleDeleteFrameworkBegin = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleRemoveFrameworkBegin = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteDialogOpen(true);
  };

  const handleDeleteFrameworkConfirm = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await deleteFramework();
      setDeleteDialogOpen(false);
    } catch {
      console.error("Error deleting framework");
    }
  };

  const handleRemoveFrameworkConfirm = async (
    e: SyntheticEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      await removeFramework({
        variables: {
          input: {
            action: ComplianceFrameworkMutationAction.Disable,
            frameworkMrn: framework.mrn,
            scopeMrn: space.mrn,
          },
        },
      });
      enqueueSnackbar("Framework removed", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to remove framework", { variant: "error" });
    }
  };

  const toggleShowEditTools = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (showEditTools) {
      setShowEditTools(false);
    } else {
      setShowEditTools(true);
    }
  };

  const getBackgroundColor = () => {
    return framework.state === ComplianceFrameworkState.Preview
      ? alpha(theme.palette.unknown.main, 0.1)
      : () => {
          const color = getColorByCompletion(theme, framework.completion || 0);
          return alpha(color, 0.1);
        };
  };

  const getCompletionTextColor = () => {
    return framework.state === ComplianceFrameworkState.Preview
      ? theme.palette.unknown.light
      : () => getColorByCompletion(theme, framework.completion || 0);
  };

  const downloadFrameworkButton = () => {
    return (
      <CircularIconButton
        title="Download Framework"
        iconType={IconButtonType.Download}
        onClick={handleDownloadFramework}
      />
    );
  };

  const displayInteractions = () => {
    if (officialFramework) {
      return (
        <CircularIconButton
          title="Download Framework"
          iconType={IconButtonType.Download}
          onClick={handleDownloadFramework}
        />
      );
    }
    if (showEditTools) {
      return (
        <CircularIconButton
          title="Close Edit Tools"
          iconType={IconButtonType.Close}
          onClick={toggleShowEditTools}
        />
      );
    } else {
      return (
        <CircularIconButton
          title="Open Edit Tools"
          iconType={IconButtonType.Edit}
          onClick={toggleShowEditTools}
        />
      );
    }
  };

  return {
    loading: {
      deleteProcess: false, // placeholder for when deletion mutation is in progress
      downloadFramework: downloadFrameworkLoading,
    },
    data: {
      authors,
      completion,
      iconKey,
      frameworkStats,
      frameworkMrn: framework.mrn,
      name: framework.name,
      tooltipTitle: framework.name.length > 25 ? framework.name : "",
      displayInteractions: displayInteractions(),
      uploadedAt: framework.modifiedAt || framework.createdAt,
      state: framework.state,
    },
    downloadFrameworkButton,
    showEditTools,
    setShowEditTools,
    get: {
      backgroundColor: getBackgroundColor,
      completionTextColor: getCompletionTextColor,
    },
    handle: {
      deleteDialogOpen,
      cancelDelete: handleCancelDelete,
      downloadFramework: handleDownloadFramework,
      replaceFramework: handleReplaceFramework,
      removeFrameworkBegin: handleRemoveFrameworkBegin,
      removeFramework: handleRemoveFrameworkConfirm,
      deleteFrameworkBegin: handleDeleteFrameworkBegin,
      deleteFrameworkConfirm: handleDeleteFrameworkConfirm,
      toggleShowEditTools: toggleShowEditTools,
    },
  };
}
