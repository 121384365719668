type Header = {
  id: string;
  label: string;
  colSpan?: number;
  sortable?: boolean;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

export const mapintegrationRouteToTableHeaders = (
  integrationTypeId: string | undefined,
): Header[] => {
  if (!integrationTypeId) return defaultTableHeaders;

  switch (integrationTypeId) {
    case "azure":
    case "gitlab":
    case "google_workspace":
    case "hosted_slack":
    case "host":
    case "ms365":
    case "okta":
      return defaultTableHeaders;

    case "amazons3":
    case "bigquery":
    case "azure_blob":
    case "s3":
    case "amazon-s3-compatible":
    case "snowflake":
      return exportTableHeaders;

    case "oci":
    case "postgres":
    case "gcs_bucket":
    case "gcp":
      return ociTableHeaders;

    case "aws":
      return awsTableHeaders;

    case "kubernetes":
      return kubernetesTableHeaders;

    case "ticket_system_jira":
      return jiraTableHeaders;

    case "github":
      return githubTableHeaders;

    default:
      return defaultTableHeaders;
  }
};

// hosted slack
// gitlab
// googleworkspace
// okta
// ms365
// azure
const defaultTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  { id: "STATUS", label: "" },

  {
    id: "CREATED_AT",
    label: "Created",
    options: { textAlign: "right", width: 250 },
  },
  {
    id: "LAST_SCAN",
    label: "Last Scanned",
    options: { textAlign: "right", width: 250 },
  },
];

// s3
// s3compatible
// azure blob
// snowflake
// bigquery
const exportTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  { id: "STATUS", label: "" },
  {
    id: "CREATED_AT",
    label: "Created",
    options: { textAlign: "right", width: 250 },
  },
  {
    id: "LAST_EXPORT",
    label: "Last Export",
    options: { textAlign: "right", width: 250 },
  },
];

// oci
// postgresql
// gcs-bucket
// gcp
const ociTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  { id: "STATUS", label: "" },

  {
    id: "CREATED_AT",
    label: "Created",
    options: { textAlign: "right", width: 250 },
  },
];

// jira
const jiraTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  { id: "STATUS", label: "", options: { textAlign: "right" } },
];

// aws
const awsTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
    options: { textAlign: "left" },
  },
  {
    id: "TYPE",
    label: "Type",
    options: { textAlign: "left" },
  },
  {
    id: "STATUS",
    label: "",
  },
  { id: "CREATED_AT", label: "Created", options: { textAlign: "left" } },
  {
    id: "LAST_UPDATED",
    label: "Last Updated",
    options: { textAlign: "right" },
  },
];

const kubernetesTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  { id: "STATUS", label: "" },
  { id: "K8S_VERSION", label: "K8s Version" },
  {
    id: "CREATED_AT",
    label: "Created",
  },
  { id: "SERVICE_ACCOUNT", label: "", options: { textAlign: "right" } },
];

// github
const githubTableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Name",
  },
  { id: "STATUS", label: "" },
  { id: "GITHUB_TYPE", label: "Type" },
  {
    id: "CREATED_AT",
    label: "Created",
    options: { textAlign: "right", width: 250 },
  },
  {
    id: "LAST_SCAN",
    label: "Last Scanned",
    options: { textAlign: "right", width: 250 },
  },
];
