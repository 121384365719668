import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Flex } from "~/components/ui-library";
import { isFeatureEnabled } from "~/login/features";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useNavigate } from "react-router-dom";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { getError } from "~/lib/handle-error";
import {
  GCP_ID_PATTERN,
  helperTextStyles,
  ValidationMessage,
} from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";

type GcpFormInput = {
  integrationName: string;
  gcpIntegrationType: "organization" | "project";
  gcpIntegrationId: string;
  clientId: string;
  jsonValue: string | null;
  discoverAll: boolean;
};

const defaultValues: GcpFormInput = {
  integrationName: "",
  gcpIntegrationType: "organization",
  gcpIntegrationId: "",
  clientId: "",
  jsonValue: "",
  discoverAll: false,
};

export function GcpIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    getFieldState,
    formState: { errors, isValid, isDirty, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [gcpIntegrationType, jsonValue] = watch([
    "gcpIntegrationType",
    "jsonValue",
  ]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "GcpConfigurationOptions"
      ) {
        reset({
          integrationName: updateFlow.integration.name,
          gcpIntegrationType: updateFlow.integration.configurationOptions
            ?.projectId
            ? "project"
            : "organization",
          gcpIntegrationId: updateFlow.integration.configurationOptions
            .projectId
            ? updateFlow.integration.configurationOptions.projectId
            : updateFlow.integration.configurationOptions.organizationId
              ? updateFlow.integration.configurationOptions.organizationId
              : "",
          discoverAll:
            updateFlow.integration.configurationOptions?.discoverAll ?? false,
          jsonValue: "",
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<GcpFormInput> = async (data) => {
    if (!updateFlow && !data.jsonValue) {
      throw new Error("You must supply a valid .json file");
    }

    const gcpConfigurationOptions = {
      projectId:
        data.gcpIntegrationType === "project" ? data.gcpIntegrationId : null,
      organizationId:
        data.gcpIntegrationType === "organization"
          ? data.gcpIntegrationId
          : null,
      serviceAccount: data.jsonValue,
      discoverAll: data.discoverAll,
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              name: data.integrationName.trim(),
              mrn,
              type: ClientIntegrationType.Gcp,
              configurationOptions: {
                gcpConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/gcp/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Gcp,
              longLivedToken: false,
              configurationOptions: {
                gcpConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("jsonValue", await files[0].text());
  };

  document.title = "GCP · Integrations Setup · Mondoo";

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Gcp }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Select your integration type
                </Command>
                <Box sx={{ pl: 1, pt: 2 }}>
                  <Controller
                    name="gcpIntegrationType"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Box>
                          <FormControlLabel
                            value="organization"
                            control={<Radio />}
                            label="Organization"
                            disabled={Boolean(updateFlow)}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            color="text.secondary"
                            sx={{ pl: 4, mt: -1 }}
                          >
                            Integrate an entire GCP organization with Mondoo and
                            (optionally) scan a defined subset of projects in
                            the organization.
                          </Typography>
                        </Box>
                        <Box>
                          <FormControlLabel
                            value="project"
                            control={<Radio />}
                            label="Project"
                            disabled={Boolean(updateFlow)}
                          />
                          <Typography
                            variant="caption"
                            display="block"
                            color="text.secondary"
                            sx={{ pl: 4, mt: -1 }}
                          >
                            Integrate a single GCP project with Mondoo, with no
                            way to access the organization.
                          </Typography>
                        </Box>
                      </RadioGroup>
                    )}
                  />
                </Box>
              </Box>
              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  {gcpIntegrationType === "organization"
                    ? "Enter the organization resource ID"
                    : "Enter the project ID"}
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Find IDs of your organization or project in your{" "}
                    <Link
                      href="https://console.cloud.google.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      Google Cloud console
                    </Link>
                    . To learn more, read the{" "}
                    <Link
                      href="https://cloud.google.com/resource-manager/docs/creating-managing-organization#retrieving_your_organization_id"
                      target="_blank"
                      rel="noopener"
                    >
                      Google documentation
                    </Link>{" "}
                    or the{" "}
                    <Link
                      href="https://cloud.google.com/resource-manager/docs/creating-managing-projects#identifying_projects"
                      target="_blank"
                      rel="noopener"
                    >
                      GCP project documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="gcpIntegrationId"
                    control={control}
                    rules={{ required: true, pattern: GCP_ID_PATTERN }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder={`Your ${gcpIntegrationType} identifier...`}
                        error={Boolean(errors.gcpIntegrationId)}
                        helperText={
                          getFieldState("gcpIntegrationId").isTouched &&
                          Boolean(errors.gcpIntegrationId) && (
                            <ValidationMessage
                              error={errors.gcpIntegrationId}
                              integrationTypeId="gcp"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
              {/* step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Create your Google service account
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3, mt: 2 }}
                  >
                    Before you can integrate your Google Cloud Platform
                    organization or project with any third party service, you
                    must create a Google service account. You can do this in the
                    Google Cloud console. To learn how, read the{" "}
                    <Link
                      href="https://cloud.google.com/iam/docs/service-accounts-create"
                      target="_blank"
                      rel="noopener"
                    >
                      Google documentation
                    </Link>
                    .
                  </Typography>
                  <Button
                    href="https://console.cloud.google.com/"
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                  >
                    Go to Google Cloud console
                  </Button>
                </Box>
              </Box>

              {/* step 5 */}
              <Box pb={4}>
                <Command
                  number={5}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  {updateFlow ? (
                    <Fragment>
                      Update your Google service account config{" "}
                      <Typography
                        component="span"
                        sx={{ color: "text.secondary", fontStyle: "italic" }}
                      >
                        (Optional)
                      </Typography>
                    </Fragment>
                  ) : (
                    "Provide your Google Service Account config"
                  )}
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Upload the .json file generated in the previous step.
                  </Typography>
                  <AnimatePresence mode="wait">
                    {!jsonValue ? (
                      <Box key="dropzone" component={motion.div} {...fadeInOut}>
                        <Dropzone
                          className="mondoo-dropzone-area"
                          accept={{ "application/json": [] }}
                          multiple={false}
                          showAlerts={["error"]}
                          onDropAccepted={onDropAccepted}
                        >
                          <DropzoneText>
                            Drag and drop your .json file here.
                          </DropzoneText>
                        </Dropzone>
                      </Box>
                    ) : (
                      <Box
                        key="success"
                        component={motion.div}
                        {...fadeInOut}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          py: 5,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                          }}
                        >
                          <Typography variant="h6">
                            Successfully loaded JSON file
                          </Typography>
                          <GreenCheckCircleIcon
                            fontSize="large"
                            sx={{ ml: 2 }}
                          />
                        </Box>

                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() =>
                            setValue("jsonValue", "", { shouldDirty: true })
                          }
                        >
                          Remove JSON file
                        </Button>
                      </Box>
                    )}
                  </AnimatePresence>
                </Box>
              </Box>

              {isFeatureEnabled("GCPAssets") && (
                <Box pb={4}>
                  <Command
                    number={6}
                    options={{
                      fontSize: { xs: 16 },
                      dotColor: theme.palette.background.lightest,
                    }}
                  >
                    Discover All
                  </Command>

                  <Flex alignItems="center" justifyContent="space-between">
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, mt: 2 }}
                    >
                      Discover all resources as assets.
                    </Typography>
                    <Controller
                      name="discoverAll"
                      control={control}
                      render={({ field }) => (
                        <Switch {...field} checked={field.value} size="small" />
                      )}
                    />
                  </Flex>
                </Box>
              )}
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  // setValue doesn't trigger the required validation so we're doing it manually here
                  disabled={
                    updateFlow ? !isValid || !isDirty : !isValid || !jsonValue
                  }
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={["gcp", "gke"]} />
      )}
    </Fragment>
  );
}
