import { PolicyReportSummariesEdge, SortDirection } from "../types";

export const sortByName = (
  a: PolicyReportSummariesEdge,
  b: PolicyReportSummariesEdge,
  sortDirection: SortDirection,
) => {
  const aName = a.node?.policy.name.toUpperCase();
  const bName = b.node?.policy.name.toUpperCase();
  if (!aName || !bName) return 0;
  switch (true) {
    case aName > bName:
      return -1 * sortDirection;
    case aName < bName:
      return 1 * sortDirection;
    default:
      return 0;
  }
};
