import { PolicyReportSummariesEdge, SortDirection } from "../types";

export const sortByScore = (
  a: PolicyReportSummariesEdge,
  b: PolicyReportSummariesEdge,
  sortDirection: SortDirection,
) => {
  const statsA = a.node?.assetGrades;
  const statsB = b.node?.assetGrades;
  const policyA = a.node?.policy;
  const policyB = b.node?.policy;
  if (!statsA || !statsB || !policyA || !policyB) return 0;
  switch (true) {
    case statsA.F > statsB.F:
      return 1 * sortDirection;
    case statsA.F < statsB.F:
      return -1 * sortDirection;

    case statsA.D > statsB.D:
      return 1 * sortDirection;
    case statsA.D < statsB.D:
      return -1 * sortDirection;

    case statsA.C > statsB.C:
      return 1 * sortDirection;
    case statsA.C < statsB.C:
      return -1 * sortDirection;

    case statsA.B > statsB.B:
      return 1 * sortDirection;
    case statsA.B < statsB.B:
      return -1 * sortDirection;

    case statsA.A > statsB.A:
      return 1 * sortDirection;
    case statsA.A < statsB.A:
      return -1 * sortDirection;

    case statsA.U > statsB.U:
      return 1 * sortDirection;
    case statsA.U < statsB.U:
      return -1 * sortDirection;

    case statsA.X > statsB.X:
      return 1 * sortDirection;
    case statsA.X < statsB.X:
      return -1 * sortDirection;

    default:
      return policyA.mrn.localeCompare(policyB.mrn);
  }
};
