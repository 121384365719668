import { styled } from "@mui/material/styles";
import { CardContent, Card, Typography } from "@mui/material";
import { ReportsGaugeRow } from "~/components/summary-row";
import { ScoreMajor } from "~/components/scores/styles";
import { PolicyEdge } from "./print-asset";
import { stateFromScore } from "~/lib/score";

type PrintPolicyReportSummaryProps = {
  edge: PolicyEdge;
};

export function PrintPolicyReportSummary({
  edge,
}: PrintPolicyReportSummaryProps) {
  const policy = edge.node.policy;
  const score = edge.node.score;
  const queries = edge.node.queries || [];
  const gaugeSummary = queries.reduce(
    (acc, cur) => {
      // ERROR = 4, FAIL = 3, SKIP = 2,  PASS = 1, UNKNOWN = 0
      const state = stateFromScore(cur.score);
      if (state === 1 || state === 2) {
        acc.good = acc.good + 1;
      } else if (state === 3 || state === 4) {
        acc.bad = acc.bad + 1;
      }
      acc.total = acc.total + 1;
      return acc;
    },
    {
      total: 0,
      bad: 0,
      good: 0,
    },
  );

  return (
    <PrintPolicySummaryCard variant="outlined">
      <CardContent>
        <Typography className="summary-title" variant="h4" component="h3">
          {policy.name}
        </Typography>
        <div className="summary-items">
          <div className="summary-item">
            <StyledScoreMajor className={`score-major ${score.grade}`}>
              {score.grade}
            </StyledScoreMajor>
          </div>
          <div className="summary-item">
            <ReportsGaugeRow {...gaugeSummary} />
          </div>
          <div className="summary-item percent-chart-item">
            <div className="percent-chart">
              <div className="percent-chart-label">Score</div>
              <div className="percent-chart-value">{score.value}</div>
              <div className="percent-chart-bar">
                <div
                  className="percent-chart-bar-value"
                  style={{ width: `${score.value}%` }}
                ></div>
              </div>
            </div>
          </div>
          {/* Policy completion is not returned from API at the moment */}
          {/* <div className="summary-item percent-chart-item">
							<div className="percent-chart">
								<div className="percent-chart-label">Completion</div>
								<div className="percent-chart-value">{policy.completion}%</div>
								<div className="percent-chart-bar">
									<div className="percent-chart-bar-value" style={{width: `${policy.completion}%`}}></div>
								</div>
							</div>
						</div> */}
        </div>
      </CardContent>
    </PrintPolicySummaryCard>
  );
}

const PrintPolicySummaryCard = styled(Card)`
  margin-bottom: ${(p) => p.theme.spacing(6)};

  .summary-title {
    margin-bottom: ${(p) => p.theme.spacing(2)};
  }

  .summary-items {
    display: flex;
    margin: ${(p) => p.theme.spacing(0, -4)};
  }

  .summary-item {
    border-right: 1px solid ${(p) => p.theme.palette.divider};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(p) => p.theme.spacing(0, 4)};
  }

  .summary-item:last-child {
    border-right: none;
  }

  .score-major {
    margin: 0;
    font-size: 5em;
    line-height: normal;
    vertical-align: middle;
  }

  .percent-chart-item {
    flex-grow: 1;
    flex-basis: 0;
  }

  .percent-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
  }

  .percent-chart-label {
    font-size: 12px;
  }

  .percent-chart-value {
    font-size: 3em;
    line-height: 1.2em;
  }

  .percent-chart-bar {
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: ${(p) => p.theme.palette.grey[200]};
  }

  .percent-chart-bar-value {
    height: 6px;
    border-radius: 3px;
    background: ${(p) => p.theme.palette.grey[400]};
  }
`;

const StyledScoreMajor = styled(ScoreMajor)`
  font-size: 7em;
  margin-right: 10px;
  display: inline-block;
  line-height: 140px;
  vertical-align: top;
`;
