import { Box } from "@mui/material";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { Queries } from "~/pages/dashboard/Queries";

type QueriesPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isQuerypack?: boolean;
};

export const QueriesPage = ({
  space,
  availablePermissions,
  isQuerypack,
}: QueriesPageProps) => {
  document.title = "Registry · Mondoo";

  return (
    <Box>
      <Queries {...{ space, isQuerypack }} />
    </Box>
  );
};
