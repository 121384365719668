import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, TableCell, Tooltip, Typography } from "@mui/material";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { Tag } from "~/operations";
import { Flex } from "~/components/ui-library";
import React from "react";
import { JiraIcon } from "~/components/icons/mondoo/integrations/jira-icon";
import { Link } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import { Format } from "~/lib/date";

type CaseTitleCellProps = {
  title: string;
  createdAt: string | undefined;
  assets: number | undefined;
  isOpen?: boolean;
  ticketId: string;
  "data-testid"?: string;
  url: string;
  tags: Array<Tag>;
};

export const CaseTitleCell = ({
  createdAt,
  title,
  ticketId,
  assets,
  url,
  tags,
  ...props
}: CaseTitleCellProps) => {
  const tagsWithCreated: Tag[] = [
    {
      __typename: "Tag",
      key: "Created",
      value: `${Format(createdAt)}`,
    },
    ...tags,
  ];

  return (
    <TableCell data-testid={props["data-testid"]}>
      <Flex alignItems="center" flex="1 0 auto">
        <Box mr={2} display="inline-flex">
          <Tooltip
            title={
              <Box>
                <Flex alignItems="center" gap={1}>
                  <Typography
                    component={Link}
                    sx={{ fontSize: 10, fontWeight: 500 }}
                    to={url}
                    target="_blank"
                  >
                    Open in Jira
                  </Typography>
                  <Launch sx={{ fontSize: 16 }} />
                </Flex>
              </Box>
            }
            placement="top"
            arrow
          >
            <JiraIcon />
          </Tooltip>
        </Box>
        <Flex flexDirection="column" flex="1 0 auto">
          <OverflowHelper>
            <Flex gap={1}>
              <Typography>
                <Typography component="span" fontWeight={700}>
                  {ticketId}
                </Typography>
                : {title}
              </Typography>
            </Flex>
          </OverflowHelper>
          <Box display="flex" gap={1} mt={1}>
            {tagsWithCreated?.map((tag) => (
              <FirewatchChip key={tag.key} tag={tag} />
            ))}
          </Box>
        </Flex>
      </Flex>
    </TableCell>
  );
};
