import { alpha, Button, ButtonGroup, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CardIcon, ChartIcon, ListIcon } from "~/components/icons";
import { ViewComfyFilled } from "./icons/mondoo/view-comfy-filled";

const icons = {
  cards: <CardIcon />,
  list: <ListIcon />,
  graph: <ChartIcon />,
  keyboard: <ViewComfyFilled />,
};

export enum ViewType {
  Cards = "cards",
  List = "list",
  Graph = "graph",
  Keyboard = "keyboard",
}

// export type ViewType = "cards" | "list" | "graph" | "keyboard";

type ViewSwitcherProps = {
  views: ViewType[];
  defaultView: ViewType;
};

export function ViewSwitcher({ views, defaultView }: ViewSwitcherProps) {
  let navigate = useNavigate();
  const url = new URL(window.location.toString());
  const params = new URLSearchParams(url.search);
  const selectedView = params.get("view") || defaultView;

  const onChangeView = (view: ViewType) => {
    params.set("view", view);
    navigate(`${window.location.pathname}?${params}`, { replace: true });
  };

  return (
    <ButtonGroup
      variant="text"
      id="view-switcher"
      sx={{ border: "1px solid", borderColor: alpha("#FFFFFF", 0.12) }}
    >
      {views.map((view) => (
        <StyledButton
          className={`${view}-button`}
          key={view}
          isActive={selectedView === view}
          onClick={() => onChangeView(view)}
          sx={{ p: 1.5 }}
        >
          {icons[view]}
        </StyledButton>
      ))}
    </ButtonGroup>
  );
}

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<{ isActive: boolean }>`
  background-color: ${(props) =>
    props.isActive
      ? alpha("#FFFFFF", 0.16)
      : props.theme.palette.background.default};
  color: ${(props) => props.theme.palette.text.primary};

  &:hover {
    background-color: ${(props) => props.theme.palette.background.light};
  }

  &.MuiButtonGroup-grouped:not(:last-of-type) {
    border-color: transparent;
  }
`;
