import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { RecommendedPolicies } from "../../components/recommended-policies";
import {
  DOMAIN_PATTERN,
  HelperText,
  helperTextStyles,
  isIpOrDomain,
  ValidationMessage,
} from "../../validations/helpers";
import { UpdateFlowData } from "../../types";

type DomainIntegrationInput = {
  host: string;
  http: boolean;
  https: boolean;
};

const defaultValues: DomainIntegrationInput = {
  host: "",
  http: false,
  https: true,
};

export function DomainIntegrationForm({
  space,

  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const [portsErrors, setPortsErrors] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    watch,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
    },
  });

  const [http, https] = watch(["http", "https"]);

  useEffect(() => {
    const portsTouched =
      getFieldState("https").isTouched || getFieldState("http").isTouched;

    const portsErrors = Boolean(https === false && http === false);
    const addPortError = portsTouched && Boolean(portsErrors);

    setPortsErrors(addPortError);
  }, [https, http]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename !==
        "HostConfigurationOptions"
      )
        return;

      reset({
        host: updateFlow.integration.name,
        http: updateFlow.integration.configurationOptions?.http ?? undefined,
        https: updateFlow.integration.configurationOptions?.https ?? undefined,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<DomainIntegrationInput> = async (data) => {
    // strip http:// or https:// from host if it exists
    // strip trailing slash if it exists
    let hostName = data.host.trim();
    hostName = hostName.replace(/(http:\/\/|https:\/\/)|\/?$/g, "");

    const hostConfigurationOptions = {
      host: hostName,
      http: data.http,
      https: data.https,
    };

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              name: hostName,
              mrn: `//integration.api.mondoo.app/spaces/${
                space.id
              }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
              type: ClientIntegrationType.Host,
              configurationOptions: {
                hostConfigurationOptions,
              },
            },
          },
        });
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/host/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: hostName,
              type: ClientIntegrationType.Host,
              longLivedToken: false,
              configurationOptions: {
                hostConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "Domain/IP Address · Integrations Setup · Mondoo";

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Host }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Enter the domain or IP address to scan
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    To scan a domain, enter the full domain: subdomain, domain
                    name, and top level domain (e.g. www.mysite.com). To scan an
                    IP address, enter the full IP address (e.g. 123.45.76.72).
                  </Typography>
                  <Controller
                    name="host"
                    control={control}
                    rules={{
                      required: true,
                      validate: {
                        matchIpOrDomain: isIpOrDomain,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your domain or IP address... "
                        error={Boolean(errors.host)}
                        helperText={
                          Boolean(errors.host) && (
                            <ValidationMessage
                              error={errors.host}
                              integrationTypeId="host"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Select ports to scan
                </Command>

                <Box sx={{ my: 2 }}>
                  <FormGroup
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        alignSelf: "flex-start",
                      },
                    }}
                  >
                    {portsErrors && (
                      <FormHelperText>
                        <HelperText>
                          You must select at least one port to scan.
                        </HelperText>
                      </FormHelperText>
                    )}
                    <Controller
                      name="https"
                      control={control}
                      rules={{
                        required: Boolean(https === false && http === false),
                      }}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox checked={field.value} />}
                          label="HTTPS (port: 443)"
                        />
                      )}
                    />
                    <Controller
                      name="http"
                      control={control}
                      rules={{
                        required: Boolean(https === false && http === false),
                      }}
                      render={({ field }) => (
                        <FormControlLabel
                          {...field}
                          control={<Checkbox checked={field.value} />}
                          label="HTTP (port: 80)"
                        />
                      )}
                    />
                  </FormGroup>
                </Box>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  disabled={!isValid || portsErrors}
                >
                  {updateFlow ? "update configuration" : "create"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={["host"]} />
      )}
    </Fragment>
  );
}
