import { format } from "date-fns";
import { Grid, Link, TableCell, TableRow, Typography } from "@mui/material";
import { Flex, Obj } from "~/components/ui-library";
import { HalfStyleDataTable } from "./half-data-table";
import { HostedClientIntegration } from "../types";
import { isFeatureEnabled } from "~/login/features";
import { GithubIntegrationType } from "~/operations";
import { OpenInNewIcon } from "~/components/icons";
import { stackUrl } from "~/pages/integration-setup";

type Props = {
  integration: HostedClientIntegration;
  token?: string;
};

export function ConfigurationsAndMessages({ integration, token }: Props) {
  const isEmptyObj = (obj: Obj): boolean => {
    return Object.keys(obj).length === 0;
  };

  const formatConfigName = (config: string) => {
    switch (config.toLowerCase()) {
      case "accountid":
        return "Account ID";
      case "tenantid":
        return "Tenant ID";
      case "clientid":
        return "Client ID";
      case "scanVms":
        return "Scan VMs";
      case "discoverall":
        if (isFeatureEnabled("GCPAssets")) {
          return "Discover All";
        } else {
          return "";
        }
      case "discovergroups":
        return "Discover Groups";
      case "discoverprojects":
        return "Discover Projects";
      case "discoverterraform":
        return "Discover Terraform";
      case "discoverk8smanifests":
        return "Discover Kubernetes Manifests";
      case "baseurl":
        return "Base URL";
      case "enterpriseurl":
        return "Enterprise URL";
      case "group":
        return "Group";
      case "type":
        return "Type";
      case "datasetid":
        return "Dataset ID";
      case "projectid":
        return "Project ID";
      case "organizationid":
        return "Organization ID";
      case "organization":
        return "Organization";
      case "impersonateduseremail":
        return "Impersonated User Email";
      case "customerid":
        return "Customer ID";
      case "host":
        return "Host";
      case "githuborg":
        return "GitHub Organization";
      case "githubtype":
        return "GitHub Integration Type";
      case "reposallowlist":
        return "Allow List";
      case "reposdenylist":
        return "Deny List";
      default:
        return config;
    }
  };

  // TODO: Format everything instead of just these
  const formatGroup = [
    "http",
    "https",
    "type",
    "scanVms",
    "discoverAll",
    "discoverGroups",
    "discoverProjects",
    "discoverTerraform",
  ];

  const formatValue = (key: string, value: any) => {
    if (!value && key === "discoverAll" && isFeatureEnabled("GCPAssets"))
      return "false";
    if (!value && key === "scanVms") return "false";

    if (value === true) {
      return "true";
    }
    if (value === false) {
      return "false";
    }

    if (!value) return;

    if (value !== typeof "string") return value;
    switch (value.toLowerCase()) {
      case "repo":
        return "Repository";
      case "org":
        return "Organization";
      default:
        return value;
    }
  };

  // Unless we're looking at an Azure integration which has unique displayedConfigs
  // then we'll show the default ones
  let displayedConfigs: Record<string, unknown> = {};

  const configOptions = integration.configurationOptions;
  const configTypename = configOptions?.__typename;

  if (
    configTypename === "AWSConfigurationOptions" ||
    configTypename === "HostedAwsConfigurationOptions"
  ) {
    const isServerless = configTypename === "AWSConfigurationOptions";
    const isOrganization = isServerless && configOptions.isOrganization;
    const installMethod = isServerless ? "Serverless" : "Hosted";
    const scanScope = isOrganization ? "organization" : "single account";
    const integrationType = `${installMethod}, ${scanScope}`;
    displayedConfigs = {
      "Integration Type": integrationType,
      ...(isServerless
        ? {
            [isOrganization ? "Account IDs" : "Account ID"]:
              configOptions.accountIds?.join(", "),
            ...(token
              ? {
                  "AWS CloudFormation": (
                    <Link
                      href={stackUrl(
                        configOptions.cloudFormationTemplateUrl,
                        configOptions.originAWSAccountId,
                        configOptions.region,
                        integration.mrn,
                        token,
                        configOptions.snsEndpoint,
                        configOptions.isOrganization,
                      )}
                      display="inline-flex"
                      gap={1}
                      alignItems="center"
                    >
                      View CloudFormation Configuration{" "}
                      <OpenInNewIcon fontSize="inherit" />
                    </Link>
                  ),
                }
              : {}),
            "Full Scan Interval": `${configOptions.scanConfiguration.cronScaninHours} hours`,
            "Discover EC2 Instances": configOptions.scanConfiguration.ec2Scan
              ? "On"
              : "Off",
            "SSM scanning enabled": configOptions.scanConfiguration
              .ec2ScanOptions.ssm
              ? "On"
              : "Off",
            "EC2 Instance Connect scanning enabled": configOptions
              .scanConfiguration.ec2ScanOptions.instanceConnect
              ? "On"
              : "Off",
            "EBS scanning enabled": configOptions.scanConfiguration
              .ec2ScanOptions.ebsVolumeScan
              ? "On"
              : "Off",
            "Discover ECS containers": configOptions.scanConfiguration.ecsScan
              ? "On"
              : "Off",
            "Discover ECR images": configOptions.scanConfiguration.ecrScan
              ? "On"
              : "Off",
          }
        : {}),
      ...(!isServerless
        ? {
            "Authentication method": configOptions.accessKeyId
              ? "Key based"
              : "Role based",
          }
        : {}),
    };
  }

  if (configTypename === "AzureConfigurationOptions") {
    const scanAllSubscriptions = Boolean(
      !configOptions.subscriptionsBlacklist &&
        !configOptions.subscriptionsWhitelist,
    );

    displayedConfigs = {
      ...Object.fromEntries(
        Object.entries(configOptions).filter(
          ([key]) => !key.includes("list") && key !== "__typename",
        ),
      ),
      "Scan all subscriptions": scanAllSubscriptions ? "enabled" : "disabled",
      ...(configOptions.subscriptionsWhitelist
        ? {
            Allowlist: configOptions.subscriptionsWhitelist.join(", "),
          }
        : {}),
      ...(configOptions.subscriptionsBlacklist
        ? {
            Denylist: configOptions.subscriptionsBlacklist.join(", "),
          }
        : {}),
    };
  }

  if (configTypename === "GithubConfigurationOptions") {
    displayedConfigs = {
      "GitHub Integration Type":
        configOptions.githubType === GithubIntegrationType.Org
          ? "Organization"
          : "Repository",
      ...(configOptions.githubType === GithubIntegrationType.Repo
        ? {
            Owner: configOptions.owner,
            Repository: configOptions.repository,
          }
        : {
            Organization: configOptions.githubOrg,
            "Scan all repositories":
              (configOptions.reposAllowList?.length || 0) === 0 &&
              (configOptions.reposDenyList?.length || 0) === 0
                ? "enabled"
                : "disabled",
            ...(configOptions.reposAllowList?.length !== 0 ||
            configOptions.reposDenyList?.length !== 0
              ? {
                  "Allow List":
                    (configOptions.reposAllowList || []).join(", ") || " - ",
                  "Deny List":
                    (configOptions.reposDenyList || []).join(", ") || " - ",
                }
              : {}),
          }),
      discoverTerraform: configOptions.githubDiscoverTerraform
        ? "enabled"
        : "disabled",
      discoverK8sManifests: configOptions.githubDiscoverK8sManifests
        ? "enabled"
        : "disabled",
      enterpriseUrl: configOptions.enterpriseUrl,
    };
  }

  if (configTypename === "JiraConfigurationOptions") {
    displayedConfigs = {
      Host: configOptions.host,
      Email: configOptions.email,
      "Automatically close tickets": configOptions.autoCloseTickets
        ? "enabled"
        : "disabled",
      /* TODO:: Uncomment when isFeatureEnabled("Cases") is removed
      "Automatically create cases on drift": configOptions.autoCreateCases
        ? "enabled"
        : "disabled",
      "Default Jira project": configOptions.defaultProject || "-",
       */
    };

    // TODO:: remove when isFeatureEnabled("Cases") is removed
    if (isFeatureEnabled("Cases")) {
      displayedConfigs["Automatically create cases on drift"] =
        configOptions.autoCreateCases ? "enabled" : "disabled";
      displayedConfigs["Default destination"] =
        configOptions.defaultProject || "-";
    }
  }

  if (isEmptyObj(displayedConfigs) && configOptions) {
    displayedConfigs = {
      ...Object.fromEntries(
        Object.entries(configOptions).filter(
          ([key, value]) => key !== "__typename" && value !== null,
        ),
      ),
    };
  }

  return (
    <Grid
      container
      columnSpacing={{ xs: 0, sm: 3 }}
      rowSpacing={{ xs: 5, sm: 0 }}
    >
      <Grid item xs={12} sm={6}>
        <HalfStyleDataTable title="Integration Configuration">
          {displayedConfigs &&
            Object.entries(displayedConfigs).map(([key, value]) => (
              <TableRow key={key}>
                <TableCell
                  sx={{
                    py: 1,
                    pl: 0,
                    color: "text.secondary",
                  }}
                  width="50%"
                >
                  {formatConfigName(key)}
                </TableCell>
                <TableCell
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    py: 1,
                    pl: 0,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "inline-block",
                      mr: 1,
                    }}
                  >
                    {(formatGroup.includes(key)
                      ? formatValue(key, value)
                      : value) || <>&nbsp;</>}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
        </HalfStyleDataTable>
      </Grid>

      <Grid item xs={12} sm={6}>
        <HalfStyleDataTable title="Most Recent Activity">
          {integration.messages && integration.messages.length > 0 ? (
            integration.messages.map((message, i) => (
              <TableRow key={i}>
                <TableCell
                  sx={{
                    py: 1,
                    pl: 0,
                    color: "text.secondary",
                    verticalAlign: "top",
                  }}
                  width="50%"
                >
                  {message?.timestamp &&
                    format(new Date(message.timestamp), "M/dd/yy, h:mmaaa")}
                </TableCell>
                <TableCell sx={{ py: 1, pl: 0 }}>{message.message}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell align="center">
                <Flex
                  sx={{
                    height: (theme) => theme.spacing(10),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography color="text.secondary">
                    You have no recent activity.
                  </Typography>
                </Flex>
              </TableCell>
            </TableRow>
          )}
        </HalfStyleDataTable>
      </Grid>
    </Grid>
  );
}
