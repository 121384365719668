import React, { useEffect, useState } from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import { JiraFormInput } from "~/pages/integrations/hosted-integrations/forms/jira/types";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  JiraProject,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { JiraIntegration } from "~/components/cases/components/CaseSettings/types";
import { mapIntegrationType } from "~/lib/integrations";
import { ArrowForward } from "@mui/icons-material";
import { Flex } from "~/components/ui-library";
import { ConfigurationListSubheader } from "~/components/configuration-items";
import { Link } from "react-router-dom";

type CaseSettingsIntegrationProps = {
  integration: JiraIntegration;
  isProjectsEmpty: boolean;
  projects: JiraProject[] | null | undefined;
  space: Space;
};

export const CaseSettingsIntegration = ({
  isProjectsEmpty,
  projects,
  integration,
  space,
}: CaseSettingsIntegrationProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isValid },
  } = useForm<JiraFormInput>({
    defaultValues: {
      defaultProject: "",
      autoCloseTickets: false,
      autoCreateCases: false,
    },
    mode: "onChange",
  });

  const href = `/space/integrations/${encodeURIComponent(
    mapIntegrationType(integration?.type || ""),
  )}/${integration?.mrn.split("/").pop()}?spaceId=${space.id}`;

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: JiraFormInput) => {
    const jiraConfigurationOptions = {
      host: data.host.trim(),
      email: data.email.trim(),
      apiToken: "",
      autoCloseTickets: data.autoCloseTickets,
      autoCreateCases: data.autoCreateCases,
      defaultProject: data.defaultProject,
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemJira,
          configurationOptions: {
            jiraConfigurationOptions,
          },
        },
      },
    });
  };

  useEffect(() => {
    // TypeScript users
    const subscription = watch(() => {
      isInitialized && handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, isInitialized]);

  const [watchAutoCreate] = watch(["autoCreateCases"]);

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "JiraConfigurationOptions") return;
    reset({
      host: configOptions.host,
      email: configOptions.email,
      autoCloseTickets: configOptions.autoCloseTickets,
      autoCreateCases: configOptions.autoCreateCases,
      defaultProject: configOptions.defaultProject,
    });
    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <ConfigurationListSubheader component="div">
        <Flex
          justifyContent="space-between"
          flex="1 0 auto"
          alignItems="center"
        >
          <Typography component="span" variant="h6">
            {integration?.name}
          </Typography>
          <IconButton component={Link} to={href} aria-label="generate new id">
            <ArrowForward color="primary" />
          </IconButton>
        </Flex>
      </ConfigurationListSubheader>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          disabled={!watchAutoCreate}
          isProjectsEmpty={isProjectsEmpty}
          projects={projects}
          showDefaultProjectDropdown
        />
      </Paper>
    </Box>
  );
};
