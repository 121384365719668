import { Policies } from "../components/Policies/types";
import { ScoreCard } from "../components/PolicyScoreCard";
import { PolicyAction } from "~/operations";

type UsePolicyGridProps = {
  policies: Policies["edges"];
};

export function usePolicyGrid({ policies }: UsePolicyGridProps) {
  let scorecards: ScoreCard[] = [];

  policies.forEach((policyEdge) => {
    const { mrn, name, score, scoreStats, action } = policyEdge.node;
    const isPlatVulnPolicy =
      mrn === "//policy.api.mondoo.app/policies/platform-vulnerability";
    // const isPlatEOLPolicy =
    //   mrn === "//policy.api.mondoo.app/policies/platform-eol";
    if (isPlatVulnPolicy) return;
    const unscored = score.type !== 2;
    const tags = unscored ? ["scores not available"] : ["scored"];
    scorecards.push({
      mrn: mrn,
      title: name,
      score,
      unscored,
      tags,
      date: new Date(),
      trend: null,
      assigned: true, // need assignment data
      action: action || PolicyAction.Active,
      scoreStats: scoreStats,
    });
  });

  return {
    scorecards,
  };
}
