import React, { useEffect, useRef, useState } from "react";
import { CaseTarget, CreateCasesFormInput } from "~/components/cases/types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { AttachIcon, CloseIcon } from "~/components/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LoadingButton } from "~/components/loading-button";
import { useSnackbar } from "notistack";
import { useColorMode } from "~/providers/color-mode";
import { SelectedEntity } from "~/components/exceptions/types";
import {
  CreateCaseInput,
  TicketRefType,
  useGetCaseContentQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { useJiraTicketContext } from "~/hooks/useJiraTicketContext";
import {
  Autocomplete,
  DropdownAutocomplete,
} from "~/components/Form/components";

type CreateCaseModalProps = {
  open: boolean;
  onClose: () => void;
  onSave: (params: CreateCaseInput) => Promise<void>;
  loading: boolean;
  target: CaseTarget;
  selectedAssets: SelectedEntity[];
  space: Space;
  hasCreateCasePermissions: boolean;
};

export const CreateCaseModal = ({
  onClose,
  onSave,
  open,
  loading,
  selectedAssets,
  space,
  hasCreateCasePermissions,
}: CreateCaseModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { mode } = useColorMode();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [projectQuery, setProjectQuery] = useState("");

  const handleToggle = () => {
    setIsAutocompleteOpen((prevOpen) => !prevOpen);
  };

  const {
    saveSelectedProject,
    loadSelectedProject,
    projects,
    isProjectsEmpty,
    fetchProjects,
  } = useJiraTicketContext({ space, skip: !open });

  const { data: caseContentData } = useGetCaseContentQuery({
    variables: {
      input: {
        type: TicketRefType.Jira,
        references: selectedAssets.map((r) => ({
          findingMrn: r.mrn,
          scopeMrn: r.scopeMrn as string,
        })),
      },
    },
    skip: !open,
  });

  const { register, handleSubmit, formState, reset, control } =
    useForm<CreateCasesFormInput>({
      mode: "onChange",
      defaultValues: {
        title: "",
        description: "",
      },
    });

  useEffect(() => {
    const savedSelectedProject = loadSelectedProject();

    reset({
      title: caseContentData?.caseContent?.title,
      description: caseContentData?.caseContent?.description,
      projectId: savedSelectedProject ? savedSelectedProject.key : "",
    });
  }, [caseContentData, projects]);

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleFormSubmit: SubmitHandler<CreateCasesFormInput> = async (
    formData,
  ) => {
    try {
      return await onSave({
        references: selectedAssets.map((r) => ({
          findingMrn: r.mrn,
          scopeMrn: r.scopeMrn as string,
        })),
        ticketConfig: {
          jira: {
            projectKey: formData.projectId,
            issueType: "Task",
          },
        },
        title: formData.title,
        description: formData.description,
      });
    } catch (error) {
      enqueueSnackbar(`Failed to create case`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit(handleFormSubmit),
      }}
      onClose={handleClose}
      data-test-id="create-case-dialog"
    >
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 5,
        }}
      >
        <Typography sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <AttachIcon />
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            Create New Case
          </Typography>
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 5 }}>
        {/*<Typography color="text.secondary" mb={3}>
          Your issue will be created with attached labels, so you can easily
          find, track, and assign them.
          For more information on labels, check
          out the{" "}
          <Link
            sx={{ color: "inherit", textDecoration: "underline" }}
            href="https://mondoo.com/docs/platform/maintain/jira/"
            target="_blank"
          >
            Mondoo Docs
          </Link>
          .
        </Typography>*/}
        <FormControl fullWidth>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Project
          </Typography>

          <Controller
            key="projectId"
            name="projectId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              const getDisplayValue = () => {
                const selectedProject = projects?.find(
                  (project) => project.key === field.value,
                );
                const selectedProjectTitle = `(${selectedProject?.key}) ${selectedProject?.name}`;

                if (projectQuery.length > 0 && isProjectsEmpty) {
                  return <>No projects are matching the search criteria</>;
                }

                if (isProjectsEmpty) {
                  return <>No projects available – create one in Jira first</>;
                }

                if (!field.value || !selectedProjectTitle) {
                  return <>Select Project</>;
                }

                return <>{selectedProjectTitle}</>;
              };

              return (
                <>
                  <DropdownAutocomplete
                    open={isAutocompleteOpen}
                    anchorRef={anchorRef}
                    selected={getDisplayValue()}
                    onToggle={handleToggle}
                    data-testid="project-dropdown"
                  />
                  <Autocomplete
                    options={(projects || []).map(({ key, name }) => ({
                      value: key,
                      label: name,
                    }))}
                    scope="projects"
                    selected={field.value}
                    onSelect={(option) => {
                      saveSelectedProject(option);
                      field.onChange(option);
                      setIsAutocompleteOpen(false);
                    }}
                    open={isAutocompleteOpen}
                    onClose={() => setIsAutocompleteOpen(false)}
                    anchorRef={anchorRef}
                    onSearchInputChange={(e) => {
                      setProjectQuery(e.target.value);
                      fetchProjects(e.target.value);
                    }}
                  />
                </>
              );
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Issue name
          </Typography>
          <TextField
            placeholder="Name your issue..."
            {...register("title", { required: true })}
            error={Boolean(formState.errors.title)}
            helperText={formState.errors.title?.message}
            disabled={!hasCreateCasePermissions}
            InputProps={{
              sx: {
                backgroundColor:
                  mode === "light" ? "common.white" : "common.black",
                borderRadius: 1,
              },
            }}
            data-test-id="case-title-input"
          />
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Issue description
          </Typography>
          <TextField
            placeholder="Describe the issue..."
            multiline
            rows={6}
            {...register("description", { required: true })}
            error={Boolean(formState.errors.description)}
            helperText={formState.errors.description?.message}
            disabled
            InputProps={{
              sx: {
                backgroundColor:
                  mode === "light" ? "common.white" : "common.black",
                borderRadius: 1,
              },
            }}
            data-test-id="case-description-input"
          />
        </FormControl>
        {/*<FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Labels{" "}
            <Typography component="span" color="text.secondary">
              (Optional)
            </Typography>
          </Typography>
          <TextField
            placeholder="Add labels separated by commas..."
            {...register("labels")}
            error={Boolean(formState.errors.labels)}
            helperText={formState.errors.labels?.message}
            InputProps={{
              sx: {
                backgroundColor:
                  mode === "light" ? "common.white" : "common.black",
                borderRadius: 1,
              },
            }}
            data-test-id="justification-input"
          />
        </FormControl>*/}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          type="submit"
          disabled={!formState.isValid || !hasCreateCasePermissions}
          loading={loading}
          buttonText="Create Case"
          data-testid="modal-create-case-button"
        />
      </DialogActions>
    </Dialog>
  );
};
