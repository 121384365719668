import { Tabs as TabsMUI, TabsProps as TabsMUIProps, Tab } from "@mui/material";
import { TabOption } from "../../../types";

type TabsProps = TabsMUIProps & {
  options: TabOption[];
  selectedTab: string;
  onTabChange: (value: string) => void;
};

const Tabs = ({
  options,
  selectedTab,
  onTabChange,
  sx,
  ...restProps
}: TabsProps) => {
  return (
    <TabsMUI
      value={selectedTab}
      onChange={(e, value) => {
        onTabChange(value);
      }}
      sx={[
        {
          ".MuiButtonBase-root": {
            textTransform: "capitalize",
          },
          ".MuiTabs-indicator": {
            height: "3px",
          },
          ".MuiTabs-flexContainer": {
            borderBottom: "3px solid",
            borderColor: "divider",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...restProps}
    >
      {options.map(({ label, value }) => (
        <Tab key={value} label={label} value={value} />
      ))}
    </TabsMUI>
  );
};

export default Tabs;
