import routes from "~/pages/registry/routes";
import { Breadcrumbs } from "~/pages/registry/breadcrumbs";
import { Box, Divider, CircularProgress } from "@mui/material";
import {
  Flex,
  Text,
  Caption,
  ListName,
  TabsVariant,
  TextHeading,
  SeverityChip,
  PropertyItem,
  TextTitle,
} from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { Code } from "~/components/code";
import useQueries from "./useQueries";

type QueriesProps = {
  isQuerypack?: boolean;
};

export function Queries({ isQuerypack = false }: QueriesProps) {
  const {
    mQueryLoading,
    mQueryError,
    queryData,
    facets,
    state,
    onNavigate,
    onFacetsChange,
    onStateChange,
    onChangeBreadcrumb,
    onVariantsChange,
  } = useQueries();

  if (mQueryLoading || mQueryError) {
    return (
      <>
        {mQueryLoading && (
          <Flex center>
            <CircularProgress />
          </Flex>
        )}
        {mQueryError && (
          <Box>
            <Text>Errors</Text>
            <Text key={String(mQueryError)}>{String(mQueryError)}</Text>
          </Box>
        )}
      </>
    );
  }

  return (
    <Box mb={5}>
      <Breadcrumbs
        routes={routes({
          registry: {
            isQuerypack,
            name:
              facets.registryEntryName || (isQuerypack ? "Queries" : "Checks"),
            namespace: facets.namespace,
            uid: facets.registryUid,
          },
          queries: {
            name: queryData.title,
          },
        })}
        onChange={onChangeBreadcrumb}
        onNavigate={onNavigate}
        sx={{
          mb: 3,
        }}
      />
      <Box mb={4}>
        <Flex justifyContent="space-between">
          <TextHeading bold>{queryData.title}</TextHeading>
        </Flex>
        <Caption
          trustLevel={queryData.trustLevel}
          authors={(queryData.authors || []) as ListName[]}
          version={queryData.version}
          certifiedBy={(queryData.certifiedBy || {}) as ListName}
          gap={2}
        />
      </Box>
      {queryData.variants.length > 0 && (
        <TabsVariant
          options={queryData.variants}
          selectedTab={facets.variant || ""}
          onTabChange={onVariantsChange}
          mb={3}
        />
      )}
      {queryData.desc && (
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          <Markdown source={queryData.desc} />
          <SeverityChip
            severity={queryData.impact.value}
            alignSelf="start"
            mt={2}
          />
        </Flex>
      )}
      <Box>
        {(queryData.properties.length > 0 || queryData.mql) && (
          <Flex center gap={3} my={4}>
            <TextTitle bold>Queries</TextTitle>
            <Box width={1}>
              <Divider />
            </Box>
          </Flex>
        )}
        {queryData.properties.length > 0 && (
          <Box>
            <Flex alignItems="center" justifyContent="space-between" my={4}>
              <Text bold>PROPERTIES</Text>
            </Flex>
            {queryData.properties.map(({ uid, mql }, key) => (
              <PropertyItem key={key} index={key} uid={uid} mql={mql} />
            ))}
          </Box>
        )}
        {queryData.mql && (
          <Box>
            <Flex alignItems="center" justifyContent="space-between" my={3}>
              <Text bold>MQL</Text>
            </Flex>
            <Box>
              <Code copyButton className="coffeescript">
                {queryData.mql}
              </Code>
            </Box>
          </Box>
        )}
        {queryData.audit && (
          <Box>
            <Flex center gap={3} my={4}>
              <TextTitle bold>Audit</TextTitle>
              <Box width={1}>
                <Divider />
              </Box>
            </Flex>
            <Markdown source={queryData.audit} />
          </Box>
        )}
        {queryData.showRemediation && queryData.remediations.length > 0 && (
          <Box>
            <Flex center gap={3} my={4}>
              <TextTitle bold>Remediation</TextTitle>
              <Box width={1}>
                <Divider />
              </Box>
            </Flex>
            <Box>
              {queryData.remediations.map((remediation) => (
                <Markdown key={remediation.id} source={remediation.desc} />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
