import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Header } from "~/types/table";

import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import React, { useState } from "react";
import { CaseTitleCell } from "~/components/cases/components/CasesTable/CaseTitleCell";
import { PageInfo, useGetCasesQuery } from "~/operations";
import { ListCaseEdges } from "~/components/cases/types";
import { CloseCaseButtonCell } from "~/components/cases/components/CasesTable/CloseCaseButtonCell";
import { Link } from "react-router-dom";
import { Flex } from "~/components/ui-library";

type VulnerabilitiesTableProps = {
  fetchMore: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  cases: ListCaseEdges | null | undefined;
  pageInfo: PageInfo | undefined;
  totalCount: number;
  isLoading: boolean;
  onClose?: (mrn: string) => void;
  isOpen?: boolean;
};

export const CasesTable = ({
  fetchMore,
  cases,
  pageInfo,
  totalCount,
  isLoading,
  onClose,
  isOpen = false,
}: VulnerabilitiesTableProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const paginatedCases =
    cases?.slice(pageItems.from, pageItems.to)?.flatMap((e) => e.node ?? []) ||
    [];

  const tableHeaders: Header[] = [
    {
      id: "CASE",
      label: " ",
      options: {
        width: "16px",
      },
      sortable: false,
    },
    {
      id: "CASE",
      label: "Case",
      sortable: false,
    },
    {
      id: "CLOSE_CASE",
      label: " ",
      sortable: false,
    },
  ];

  return (
    <Paper sx={{ my: 3 }}>
      <TableContainer>
        <Table>
          <TableHead tableHeaders={tableHeaders} />
          <TableBody>
            {paginatedCases.map((c) => {
              return (
                <TableRow
                  key={c.mrn}
                  component={Link}
                  target="_blank"
                  to={c?.ticketRef?.url as string}
                  sx={{
                    "&.MuiTableRow-root:hover .firewatch-chip": {
                      backgroundColor: "background.lighter",
                    },
                    opacity: isOpen ? 1 : 0.4,
                    "&:hover button": {
                      visibility: "visible",
                    },
                  }}
                  data-testid={`${isOpen ? "open" : "closed"}-case-table-row`}
                >
                  <TableCell></TableCell>
                  <CaseTitleCell
                    key={c.mrn}
                    isOpen
                    title={c.title}
                    assets={c.assetsCount}
                    createdAt={c.createdAt}
                    ticketId={c.ticketRef?.ticketId as string}
                    url={c?.ticketRef?.url as string}
                    tags={c.tags || []}
                  />
                  {isOpen ? (
                    <CloseCaseButtonCell
                      isLoading={isLoading}
                      isOpen={isOpen}
                      onClick={async (e) => {
                        e.preventDefault();
                        onClose?.(c.mrn);
                      }}
                    />
                  ) : (
                    <TableCell>
                      <Flex
                        flex="1 0 auto"
                        justifyContent="flex-end"
                        sx={{ pr: 3 }}
                      >
                        <Typography color="text.disabled" fontSize={13}>
                          CLOSED
                        </Typography>
                      </Flex>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={pageInfo}
        totalCount={totalCount}
        setPageItems={setPageItems}
      />
    </Paper>
  );
};
