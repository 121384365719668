import { ChangeEvent, useCallback, useEffect } from "react";
import {
  Select,
  MenuItem,
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  BoxProps,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import Flex from "../../layouts/Flex";
import Text from "../../typography/Text";
import { getPageIndexes } from "../../../utils";
import { PAGE, rowsPerPageOptions, PaginationOptions } from "../../../types";

type PaginationProps = BoxProps & {
  page?: number;
  count?: number;
  rowsPerPage?: number;
  paginationText?: string;
  onPaginationChange: (val: PaginationOptions) => void;
};

const Pagination = ({
  page = PAGE,
  count = 0,
  rowsPerPage = rowsPerPageOptions[0],
  onPaginationChange,
  ...restProps
}: PaginationProps) => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const onPageChange = useCallback<NonNullable<MuiPaginationProps["onChange"]>>(
    (e, _page) => {
      onPaginationChange({
        page: _page,
        count,
        rowsPerPage,
        ...getPageIndexes({
          page: _page,
          rowsPerPage,
        }),
      });

      scrollToTop();
    },
    [page, count, rowsPerPage],
  );

  const onRowsPerPageChange = useCallback(
    (e: SelectChangeEvent) => {
      const _rowsPerPage = Number(e.target.value);

      onPaginationChange({
        page: PAGE,
        count,
        rowsPerPage: _rowsPerPage,
        ...getPageIndexes({
          page: PAGE,
          rowsPerPage: _rowsPerPage,
        }),
      });

      scrollToTop();
    },
    [page, count, rowsPerPage],
  );

  useEffect(() => {
    onPageChange({} as ChangeEvent, PAGE);
  }, [count]);

  return (
    <Flex
      center
      sx={{
        mt: "20px",
        justifyContent: "end",
        ul: {
          flexWrap: "nowrap",
        },
        fieldset: {
          border: 0,
          borderColor: "transparent !important",
        },
      }}
      {...restProps}
    >
      <Text>Rows per page</Text>
      <Select
        size="small"
        variant="outlined"
        value={String(rowsPerPage)}
        onChange={onRowsPerPageChange}
      >
        {rowsPerPageOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <MuiPagination
        page={page}
        count={Math.ceil(count / rowsPerPage)}
        onChange={onPageChange}
      />
    </Flex>
  );
};

export default Pagination;
