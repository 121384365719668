import { Component, useState } from "react";
import {
  createPath,
  Link as RouterLink,
  Outlet,
  parsePath,
  useOutletContext,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  Grid,
  CardHeader,
  Chip,
  styled,
  Link,
  Breadcrumbs,
  SelectChangeEvent,
  Paper,
} from "@mui/material";
import { IamActions } from "~/lib/iam";
import { PolicyMrnToURIEncodedId } from "../../lib/mrn";
import { isPlatformVulnPolicy } from "~/lib/isPlatformVulnerabilityPolicy";
import { LoadingButton } from "../loading-button";
import { PolicyDownloadButton } from "../policy-download-button";
import { HomeIcon } from "../icons";
import {
  LoadPolicyQuery,
  TestIamActionsQuery,
  useAssignPolicyMutation,
  useUnassignPolicyMutation,
  LoadPolicyDocument,
  ExceptionType,
  ExceptionGroup,
  ScoringSystem,
} from "~/operations";
import { Space } from "~/lib/types";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { PendingExceptionsTooltip } from "~/components/exceptions/pending-exceptions-tooltip";
import { MSelect } from "../Select/Select";
import { usePolicyViewer } from "./hooks/usePolicyViewer";

const TagChip = styled(Chip)`
  margin-top: ${(props) => props.theme.spacing(1)};
  margin-right: ${(props) => props.theme.spacing(1)};
  padding: ${(props) => props.theme.spacing(1)};
`;

export interface TagProps {
  tagkey: string;
  tagvalue?: string;
}

export class Tag extends Component<TagProps> {
  render() {
    const { tagkey, tagvalue } = this.props;
    let text = "";
    text += tagkey;
    if (tagvalue != undefined && tagvalue != "") {
      text += " : " + tagvalue;
    }

    return <TagChip variant="outlined" size="small" label={text} />;
  }
}

export type Policy = NonNullable<LoadPolicyQuery["policy"]>;

export type PolicyOutletContextType = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  policy: Policy;
  policyEnabled: boolean;
  exceptionGroups: ExceptionGroup[];
};

export type PolicyViewerProps = {
  space: Space;
  policy: Policy;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function PolicyViewer({
  space,
  policy,
  availablePermissions,
}: PolicyViewerProps) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    scoringSystem,
    setScoringSystem,
    scoreByMenuItems,
    scoringSystemDescription,
  } = usePolicyViewer({ policy });

  const [assignPolicy, { loading: assignLoading }] = useAssignPolicyMutation({
    variables: {
      input: {
        policyMrn: policy.mrn,
        assetMrn: space.mrn,
        scoringSystem,
      },
    },
    refetchQueries: [LoadPolicyDocument],
  });

  const [unassignPolicy, { loading: unassignLoading }] =
    useUnassignPolicyMutation({
      variables: {
        input: {
          policyMrn: policy.mrn,
          assetMrn: space.mrn,
        },
      },
      refetchQueries: [LoadPolicyDocument],
    });

  const { exceptionGroups, pendingExceptionsGroups } = useFetchExceptions({
    mrn: policy.mrn,
    scopeMrn: space.mrn,
    types: [ExceptionType.Security],
  });

  const handleChangeScoringSystem = async (
    event: SelectChangeEvent<unknown>,
  ) => {
    setScoringSystem(event.target.value as ScoringSystem);
    // if policy is already assigned, update the assigned policy to use the new scoring
    if (policy.assigned) {
      try {
        await assignPolicy();
        enqueueSnackbar("Successfully updated policy scoring", {
          variant: "success",
        });
      } catch (error) {
        enqueueSnackbar("Failed to update policy scoring", {
          variant: "error",
        });
      }
    }
  };

  const isQuerying = assignLoading || unassignLoading;
  // queries is actually checks until this page is redone
  const isMondooPlatformVulnPolicy = isPlatformVulnPolicy(policy.mrn);
  const tagKey = policy.access === "PRIVATE" ? "private" : "public";

  // viewers do not have permission to assign or unassign policies
  const togglePolicyPermission = [
    IamActions.POLICY_DISTRIBUTOR_ASSIGN,
    IamActions.POLICY_DISTRIBUTOR_UNASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_UNASSIGN,
  ].some((permission) => availablePermissions?.includes(permission));

  const generateHref = (tab: string): string => {
    const parsedPath = parsePath(
      `/space/security/policies/${PolicyMrnToURIEncodedId(policy.mrn)}`,
    );
    const pathname = `${parsedPath.pathname}/${tab}`;
    const search = parsedPath.search;
    return createPath({ pathname, search });
  };

  const tabList: TabListItem[] = [
    {
      label: "Overview",
      to: generateHref("overview"),
      route: "/overview",
    },
    {
      label: "Checks",
      to: generateHref("queries"),
      route: "/queries",
    },
    {
      label: "Assets",
      to: generateHref("assets"),
      route: "/assets",
    },
    {
      label: (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          Exceptions
          {pendingExceptionsGroups.length > 0 && <PendingExceptionsTooltip />}
        </Box>
      ),
      to: generateHref("exceptions"),
      route: "/exceptions",
    },
  ];

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "overview",
  );

  const handleTogglePolicy = (): void => {
    policy.assigned ? disablePolicy() : enablePolicy();
  };

  const enablePolicy = async () => {
    try {
      await assignPolicy();
      enqueueSnackbar("Successfully enabled policy", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to enable policy", { variant: "error" });
    }
  };

  const disablePolicy = async () => {
    try {
      await unassignPolicy();
      enqueueSnackbar("Successfully disabled policy", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to disable policy", { variant: "error" });
    }
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/security/policies"
      component={RouterLink}
      to={`/space/security/policies?spaceId=${space.id}`}
      display="flex"
    >
      Security
    </Link>,
    <Typography key={2}>{policy.name}</Typography>,
  ];

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Breadcrumbs sx={{ mb: 1, overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ width: "100%", padding: 5 }}>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>{policyIcon(policy.name, "large")}</Box>
            <Typography variant="h4">
              {policy.name}{" "}
              <Typography component="span">v{policy.version}</Typography>
            </Typography>
          </Box>
          <Box mb={4}>
            <Tag tagkey={tagKey} />
          </Box>
          <Grid container spacing={2}>
            {/* Scoring System Selector */}
            <Grid item xs={12} sm="auto">
              {togglePolicyPermission && (
                <LoadingButton
                  color="primary"
                  variant="contained"
                  disabled={isQuerying}
                  loading={isQuerying}
                  buttonText={
                    isQuerying
                      ? "loading..."
                      : policy.assigned
                        ? "disable"
                        : "enable"
                  }
                  onClick={handleTogglePolicy}
                  sx={{ mr: 1 }}
                />
              )}
              <PolicyDownloadButton
                data-name={`policy-download-button-${policy.mrn}`}
                policy={policy}
                space={space}
                color="primary"
                variant="contained"
              />
            </Grid>
            {togglePolicyPermission && (
              <Grid
                item
                xs={12}
                sm
                sx={{
                  display: "flex",
                  alignItems: { xs: "center", sm: "flex-end" },
                  justifyContent: { xs: "start", sm: "flex-end" },
                }}
              >
                <MSelect
                  id="score-by"
                  label="Score by"
                  value={scoringSystem}
                  menuItems={scoreByMenuItems}
                  onChange={handleChangeScoringSystem}
                  formHelperText={scoringSystemDescription}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <TabNavigation
          {...{ id: "policy-tabs", tabList, currentTab, marginBottom: 0 }}
        />
      </Grid>
      {/* Navigation Tabs List */}
      <Grid item xs={12}>
        <Outlet
          context={{
            space,
            availablePermissions,
            policy,
            policyEnabled: policy.assigned,
            exceptionGroups,
          }}
        />
      </Grid>
    </Grid>
  );
}

export function usePolicyOutletContext() {
  return useOutletContext<PolicyOutletContextType>();
}
