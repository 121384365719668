import { RiskFactorStats } from "~/operations";
import { Flex } from "~/components/ui-library";
import { Tooltip, Typography } from "@mui/material";
import { RiskFactorsIndicators } from "./types";
import { getRiskFactorIcon } from "~/pages/space/security/components/RiskFactors/utils";
import { useRiskFactorsContentStatus } from "~/components/vulnerabilities/useRiskFactorsContentStatus";

type RiskFactorsIconsProps = {
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  shouldLimit?: boolean;
  activeOnly?: boolean; // this is used to filter out any risk factors that are inactive.  Inactive risk factors don't have an impact on the asset and are not displayed so they do not cause confusion to the end user.
};

export const RiskFactorsIcons = ({
  riskFactors,
  shouldLimit = true,
  activeOnly = true,
}: RiskFactorsIconsProps) => {
  const { getRiskStatus } = useRiskFactorsContentStatus();
  const iconColor = "warning.main";

  let activeRiskFactors = riskFactors || [];
  if (activeOnly) {
    // filter out the risk factors that are inactive, i.e. do not affect the asset
    activeRiskFactors =
      riskFactors?.filter(
        (rf) =>
          getRiskStatus(rf?.affected || 0, rf?.total || 0, rf?.indicator)
            .status === "active",
      ) || [];
  }

  const riskFactorsGroups = activeRiskFactors.reduce<
    Array<RiskFactorsIndicators>
  >((acc, item) => {
    if (!acc.includes(item?.indicator as RiskFactorsIndicators))
      acc.push(item?.indicator as RiskFactorsIndicators);

    return acc;
  }, []);

  const sortOrder = [
    "internet-facing",
    "exploitable",
    "db-access",
    "user-risk",
    "asset-in-use",
    "code-execution",
    "sensitive-data",
    "credentials",
    "containers",
    "eol",
    "defensive",
  ];

  const iconsToDisplay = sortOrder.filter((rf) =>
    activeRiskFactors.find((rf2) => rf2?.indicator === rf),
  );

  const limitedIconsToDisplay = shouldLimit
    ? iconsToDisplay.slice(0, 4)
    : iconsToDisplay;

  const restRiskFactors =
    Object.keys(riskFactorsGroups).length - limitedIconsToDisplay.length || 0;

  return (
    <Flex flexWrap="wrap">
      {limitedIconsToDisplay.map((item) => {
        const icon = getRiskFactorIcon({
          indicator: item as RiskFactorsIndicators,
          sx: {
            color: item === "defensive" ? "success.main" : iconColor,
            mx: 0.25,
            opacity: 0.5,
            ":hover": { opacity: 1 },
          },
        });

        const content = activeRiskFactors
          .filter((rf) => rf?.indicator === item)
          .map((rf) => <Typography key={rf?.mrn}>{rf?.title}</Typography>);

        return (
          <Tooltip
            title={content}
            key={item}
            placement="top"
            arrow
            data-testid="risk-factors-tooltip"
          >
            {icon}
          </Tooltip>
        );
      })}
      {(riskFactors || []).length > 0 && restRiskFactors > 0 && shouldLimit && (
        <Typography
          variant="caption"
          color="warning.main"
          sx={{ alignSelf: "center" }}
          data-testid="risk-factors-rest-count"
        >
          +{restRiskFactors}
        </Typography>
      )}
    </Flex>
  );
};
