import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Typography,
  Link,
} from "@mui/material";
import { ChevronRightIcon, HomeIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { RecommendedPolicies } from "../components/recommended-policies";

type Props = {
  space: Space;
};

export const K8AddPolicies = ({ space }: Props) => {
  const { integrationId } = useParams();

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations/add?spaceId=${space.id}`}
    >
      Add
    </Link>,
    <Typography key={1}>Kubernetes</Typography>,
    <Typography key={2}>Recommended Policies</Typography>,
  ];

  return (
    <Box component={motion.div} {...fadeInOut}>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Typography variant="h4" fontWeight={700} sx={{ mb: 3 }}>
        Recommended Policies
      </Typography>

      <Paper sx={{ px: 2 }}>
        <RecommendedPolicies {...{ space }} filterTypes={["kubernetes"]} />
      </Paper>
      <Box
        sx={{
          display: "flex",
          placeContent: "flex-end",
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to={`/space/integrations/kubernetes/${integrationId}?spaceId=${space.id}`}
          endIcon={<ChevronRightIcon style={{ fontSize: 30 }} />}
          data-name="k8s-finish-set-policies-button"
        >
          show integration
        </Button>
      </Box>
    </Box>
  );
};
