import { ReactNode } from "react";

export type Maybe<T> = T | null;

export type MaybeUndefined<T> = T | undefined;

export const PAGE: number = 1;

export const rowsPerPageOptions: number[] = [10, 25, 50, 100];

export enum Status {
  Idle = "idle",
  Pending = "pending",
  Fulfilled = "fulfilled",
  Rejected = "rejected",
}

export enum FlagTypes {
  featured = "FEATURED",
  unlock = "UNLOCK",
  review = "REVIEW",
}

export enum OrderDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export enum SearchResultItemOrderField {
  Name = "NAME",
}

export enum Access {
  All = "ALL",
  Authenticated = "AUTHENTICATED",
  Public = "PUBLIC",
}

export type Obj = { [key in string]: any };

export type Route = {
  name: string | ReactNode;
  href?: { pathname: string; query?: Obj };
};

export type TabOption = {
  label: string | ReactNode;
  value: string;
  icon?: ReactNode;
};

export type FieldOption = {
  name: string;
  value: string | string[];
};

export type FilterOption = {
  name: string;
  label?: string;
  type?: "search" | "select";
  value: FieldOption["value"];
  options?: string[];
  multiple?: boolean;
  placeholder?: string;
};

export type PaginationOptions = {
  page?: number;
  count?: number;
  rowsPerPage?: number;
  from?: number;
  to?: number;
};

export type ListName = {
  name: string;
};

export type UploadFileInput = {
  spaceMrn: string;
  force?: boolean;
  dataurl: string;
};

export type AssignmentInput = {
  assign: boolean;
  policyMrn: string;
};

export enum Email {
  Share = "hello@mondoo.com",
}
