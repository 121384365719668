import { useState } from "react";
import {
  CreateCaseInput,
  TestIamActionsQuery,
  useCreateCaseMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";
import { IamActions } from "~/lib/iam";

type UseCasesParams = {
  createCaseRefetchQueries: InternalRefetchQueryDescriptor[];
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function useCases({
  createCaseRefetchQueries,
  availablePermissions,
}: UseCasesParams) {
  const [isCreateCaseModalOpen, setIsCreateCaseModalOpen] = useState(false);
  const [
    isCreateCaseIntegrationModalOpen,
    setIsCreateCaseIntegrationModalOpen,
  ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [createCase, { loading }] = useCreateCaseMutation({
    refetchQueries: createCaseRefetchQueries,
  });

  const handleCreateCaseModalOpen = () => {
    setIsCreateCaseModalOpen(true);
  };

  const handleCreateCaseModalClose = () => {
    setIsCreateCaseModalOpen(false);
  };

  const handleCreateCaseIntegrationModalOpen = () => {
    setIsCreateCaseIntegrationModalOpen(true);
  };

  const handleCreateCaseIntegrationModalClose = () => {
    setIsCreateCaseIntegrationModalOpen(false);
  };

  const handleCreateCase = async (data: CreateCaseInput) => {
    // We don't need to pass description anymore because it's just readonly field
    delete data.description;

    const input: CreateCaseInput = {
      ...data,
    };

    const { data: createCaseData } = await createCase({
      variables: { input },
    });

    enqueueSnackbar(
      `Case ${createCaseData?.createCase.ticketRef?.ticketId} successfully created!`,
      {
        variant: "success",
      },
    );
  };

  const hasCreateCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE,
  );
  const hasListCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  );
  const hasIntegrationCreatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );

  return {
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCase,
    hasCreateCasePermissions,
    hasListCasePermissions,
    hasIntegrationCreatePermission,
    loading,
  };
}
