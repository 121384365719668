import { Fragment } from "react";
import { AffectedResourcesHeader } from "./affected-resources-header";
import {
  PanelContainer,
  ResizablePanel,
} from "~/pages/resources-explorer/components";
import { useAffectedResources } from "./use-affected-resources";

type Props = {};

// UNDER CONSTRUCTION
export function ARResource({}: Props) {
  const { actuals } = useAffectedResources();

  const data = actuals
    .map((result) => {
      if (!result.actual) return null;
      return result.actual[0];
    })
    .pop();

  return (
    <Fragment>
      <AffectedResourcesHeader />
      <PanelContainer height={400}>
        <ResizablePanel />
        {/* {data?.fields?.map((field, index) => <ResizablePanel key={index} />)} */}
      </PanelContainer>
    </Fragment>
  );
}
