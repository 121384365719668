import {
  ChangeEvent,
  Fragment,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import {
  Grid,
  Paper,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Divider,
  Typography,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";
import { LoadingButton } from "../components/loading-button";

import { Validations } from "../lib/validations";

import { RefreshIcon } from "~/components/icons";
import { LoadingPage, LoadingFailedPage } from "../components/loading";
import { ApolloError } from "@apollo/client";
import { useViewer } from "~/providers/viewer";
import { useNavigate } from "react-router-dom";
import {
  useSpaceIdSuggestionLazyQuery,
  useCreateSpaceMutation,
} from "~/operations";

export interface CreateSpaceProps {}
export interface CreateSpaceState {
  owner?: string;
  space?: string;
  spaceError: string;
  spaceID: string;
  spaceIDError: string;
  spaceIDEdit: boolean;
}

const initialSpaceState: CreateSpaceState = {
  owner: "",
  space: "",
  spaceError: "",
  spaceID: "",
  spaceIDError: "",
  spaceIDEdit: false,
};

export function CreateSpacePage({}: CreateSpaceProps) {
  const { viewer, refetch, currentOrg } = useViewer();
  const navigate = useNavigate();
  const [state, setState] = useState<CreateSpaceState>({
    ...initialSpaceState,
    owner: currentOrg()?.mrn,
  });
  const [creating, setCreating] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [spaceIdSuggestion] = useSpaceIdSuggestionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const getIdSuggestion = async (id = "") => {
    // ID needs to be hyphenated to be valid
    const idAvailable = id.trim().split(" ").join("-").toLowerCase();
    const parentMrn = state.owner;
    const result = await spaceIdSuggestion({
      variables: { input: { parentMrn, idAvailable } },
    });
    return result.data?.spaceIDSuggestion;
  };

  const generateRandomId = async () => {
    const suggestion = await getIdSuggestion();
    if (suggestion && suggestion.available) {
      setState((prevState) => ({
        ...prevState,
        spaceID: suggestion.id,
        spaceIDError: "",
      }));
    }
  };

  useEffect(() => {
    generateRandomId();
  }, []);

  const handleGenerateIdClick = () => {
    generateRandomId();
  };

  const [createSpace] = useCreateSpaceMutation();

  const handleCreateClick = async () => {
    setCreating(true);

    try {
      const { owner, space, spaceID } = state;
      const org = viewer?.organizations?.find((org) => org.mrn === owner);
      if (!org) throw "org is required";
      if (!space) throw "space is required";
      if (!owner) throw "owner is required";

      const id = spaceID.trim().split(" ").join("-").toLowerCase();
      const name = space;
      const createSpaceResult = await createSpace({
        variables: {
          input: {
            orgMrn: org.mrn,
            name,
            id,
            settings: { terminatedAssetsConfiguration: { cleanup: true } },
          },
        },
      });
      const createdSpace = createSpaceResult.data?.createSpace;
      if (!createdSpace) throw "Failed to create space";

      enqueueSnackbar(`Successfully created space: ${space}`, {
        variant: "success",
      });
      // we have to wait for viewer and settings to load again
      // before we can navigate to the new space
      await refetch();
      navigate(`/space/overview?spaceId=${createdSpace.id}`);
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.map((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
      } else {
        enqueueSnackbar("Failed to create space", { variant: "error" });
      }
    } finally {
      setCreating(false);
    }
  };

  const handleChange = (evt: SelectChangeEvent<string>): void => {
    setState((prevState) => ({
      ...prevState,
      owner: evt.target.value,
    }));
  };
  let value = state.owner;
  if (value == "") {
    value = viewer?.mrn;
  }

  const validateSpaceName = (
    evt:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | KeyboardEvent<HTMLDivElement>,
  ): void => {
    const space = (evt.target as HTMLInputElement).value;

    let spaceError = "";
    if (space == null || !space.match(Validations.nameInvalidLengthRegex)) {
      spaceError = Validations.nameInvalidLengthErrorMsg;
    } else if (
      space == null ||
      !space.match(Validations.nameInvalidCharactersRegex)
    ) {
      spaceError = Validations.nameInvalidCharactersErrorMsg;
    }

    setState((prevState) => ({ ...prevState, spaceError, space }));
  };

  const enableEditSpaceID = (evt: MouseEvent<HTMLElement>): void => {
    setState((prevState) => ({ ...prevState, spaceIDEdit: true }));
  };

  const validateSpaceId = async (
    evt:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | KeyboardEvent<HTMLDivElement>,
  ) => {
    const spaceID = (evt.target as HTMLInputElement).value;

    let spaceIDError = "";
    if (spaceID == null || !spaceID.match(Validations.idInvalidLengthRegex)) {
      spaceIDError = Validations.idInvalidLengthErrorMsg;
    } else if (
      spaceID == null ||
      !spaceID.match(Validations.idInvalidCharactersRegex)
    ) {
      spaceIDError = Validations.idInvalidCharactersErrorMsg;
    }

    if (!state.owner) {
      spaceIDError = "space requires an owner";
    }

    setState((prevState) => ({ ...prevState, spaceIDError, spaceID }));

    if (state.owner) {
      // check if the id is still available
      try {
        const suggestion = await getIdSuggestion(spaceID);
        if (suggestion?.available !== true) {
          throw "This space id is not available";
        }
      } catch (err) {
        setState((prevState) => ({
          ...prevState,
          spaceIDError: String(err),
        }));
      }
    }
  };

  if (!viewer) {
    return <LoadingPage what="spaces" />;
  }

  if (viewer.organizations?.length === 0) {
    return <LoadingFailedPage what="organization" />;
  }

  return (
    <Fragment>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{
            maxWidth: 1200,
            margin: "auto",
          }}
        >
          <NewPaper>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">Create a new space</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                  A space contains all assets including their report history.
                  They help you to organize your assets within your
                  organization.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={5}>
                <FormControl variant="outlined">
                  <InputLabel id="space-owner-label">Owner</InputLabel>
                  <Select
                    labelId="space-owner-label"
                    id="space-owner"
                    label="Owner"
                    value={value}
                    onChange={handleChange}
                  >
                    {viewer.organizations?.map((org) => {
                      return (
                        <MenuItem value={org.mrn} key={org.mrn}>
                          {org.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant="body1"
                  component="span"
                  gutterBottom
                  style={{
                    lineHeight: "3rem",
                    fontSize: "2rem",
                    margin: "10px",
                  }}
                >
                  /
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="space-name-input"
                  fullWidth
                  label="Space Name"
                  variant="outlined"
                  onChange={validateSpaceName}
                  error={state.spaceError.length === 0 ? false : true}
                  helperText={state.spaceError}
                />
              </Grid>
              <Grid item xs={12}>
                {!state.spaceIDEdit && (
                  <Fragment>
                    <span>Space ID:&nbsp;</span>
                    <strong data-testid="create-space-id">
                      {state.spaceID}
                    </strong>
                    <span>&nbsp;It&nbsp;</span>
                    <strong>cannot be changed later.</strong>
                    <Button onClick={enableEditSpaceID}>Edit</Button>
                  </Fragment>
                )}
                {state.spaceIDEdit && (
                  <Fragment>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="spaceid-input">Space ID</InputLabel>
                      <OutlinedInput
                        id="spaceid-input"
                        label="Space ID"
                        onChange={validateSpaceId}
                        error={state.spaceIDError.length === 0 ? false : true}
                        value={state.spaceID}
                        aria-describedby="spaceid-helper-text"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="generate new id"
                              onClick={handleGenerateIdClick}
                              size="large"
                            >
                              <RefreshIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="spaceid-helper-text">
                        {state.spaceIDError || Validations.idInformMsg}{" "}
                      </FormHelperText>
                    </FormControl>
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <LoadingButton
                  buttonText="Create Space"
                  variant="contained"
                  color="primary"
                  onClick={handleCreateClick}
                  disabled={
                    state.space == "" ||
                    state.spaceIDError != "" ||
                    state.spaceError != ""
                  }
                  loading={creating}
                />
              </Grid>
            </Grid>
          </NewPaper>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const NewPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(3)};
`;
