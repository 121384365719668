import { Exceptions } from "~/components/exceptions/exceptions";
import {
  AssetAdvisoryException,
  AssetCheckException,
  ExceptionGroup,
  GetVulnerabilityScoresDocument,
  ListExceptionGroupsDocument,
  LoadAssetReportDocument,
  VulnerabilityScoreType,
} from "~/operations";
import { useAssetOutlet } from "~/pages/inventory/asset";
import {
  AssetAdvisoryExceptionTable,
  AssetCheckExceptionTable,
} from "./AssetExceptionsTable";

export const AssetExceptionsWrapper = () => {
  const { availablePermissions, exceptionGroups, space, assetId, assetMrn } =
    useAssetOutlet();

  return (
    <Exceptions
      availablePermissions={availablePermissions}
      emptyStateType="check-exceptions"
      exceptionGroups={exceptionGroups}
      refetchQueries={[
        ListExceptionGroupsDocument,
        LoadAssetReportDocument,
        {
          query: GetVulnerabilityScoresDocument,
          variables: {
            entityMrn: assetMrn,
            filter: { scoreType: VulnerabilityScoreType.Advisory },
          },
        },
      ]}
      renderTable={function (exceptionGroup: ExceptionGroup) {
        const { exceptions } = exceptionGroup;

        const item = exceptions[0];
        // Determine if the exception is on an advisory or a check
        const isAdvisory = item && item.__typename === "AssetAdvisoryException";
        const isCheck = item && item.__typename === "AssetCheckException";

        if (isCheck) {
          return (
            <AssetCheckExceptionTable
              space={space}
              assetId={assetId}
              exceptions={exceptions as AssetCheckException[]}
            />
          );
        }

        if (isAdvisory) {
          return (
            <AssetAdvisoryExceptionTable
              space={space}
              exceptions={exceptions as AssetAdvisoryException[]}
            />
          );
        }

        return <></>;
      }}
    />
  );
};
