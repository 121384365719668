import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Card,
  Link,
  Table,
  TableRow,
  ListItem,
  TableHead,
  TableBody,
  TableCell,
  Typography,
  CardContent,
  ListItemText,
  TableContainer,
} from "@mui/material";
import { StatusTag } from "~/components/score-card";
import { Markdown } from "~/components/markdown";
import { Code } from "~/components/code";
import { stateFromScore } from "~/lib/score";
import { PolicyEdge } from "./print-asset";
import { getQueryResults } from "~/pages/inventory/components/Checks";

type PrintPolicyReportQueriesProps = {
  edge: PolicyEdge;
};

export function PrintPolicyReportQueries({
  edge,
}: PrintPolicyReportQueriesProps) {
  const tableRows = ({ showDetails = false }: { showDetails?: boolean } = {}) =>
    edge.node.queries?.map((query) => {
      let text = "UNKNOWN";
      let status = "unknown"; // 'high', 'critical', 'unknown'
      let detailsRow = null;

      // ERROR = 4, FAIL = 3, SKIP = 2,  PASS = 1, UNKNOWN = 0
      let state = stateFromScore(query.score);
      if (state === 3) {
        text = "FAIL";
        status = "high";
      } else if (state === 1) {
        text = "PASS";
        status = "good";
      } else if (state === 4) {
        text = "ERROR";
        status = "critical";
      } else if (state === 2) {
        text = "SKIP";
        status = "none";
      }

      // render more detailed output for failure and error
      if (state == 3 || state == 4) {
        let q = query.mquery;
        let queryCode = null;
        const result = getQueryResults(query);
        let desc = null;
        let audit = null;
        let remediation = null;

        queryCode = <Code className="javascript">{query.mquery.code}</Code>;
        if (query.mquery.docs?.desc) {
          desc = <Markdown source={query.mquery.docs.desc}></Markdown>;
        }
        if (query.mquery.docs?.audit) {
          audit = <Markdown source={query.mquery.docs.audit}></Markdown>;
        }
        if (query.mquery.docs?.remediation) {
          remediation = (
            <Markdown source={query.mquery.docs.remediation}></Markdown>
          );
        }

        detailsRow = (
          <TableRow key={query.mquery.title + "-details"}>
            <TableCell colSpan={2}>
              <DescList>
                {queryCode && (
                  <DescQueryListItem key="Query">
                    <DescListItemText
                      primary={
                        <>
                          <Headline variant="h6">Query</Headline>
                          {queryCode}
                        </>
                      }
                    />
                  </DescQueryListItem>
                )}
                {result && (
                  <DescQueryListItem key="Result">
                    <DescListItemText
                      primary={
                        <>
                          <Headline variant="h6">Result</Headline>
                          {result}
                        </>
                      }
                    />
                  </DescQueryListItem>
                )}
                {desc != null && (
                  <DescQueryListItem key="description">
                    <DescListItemText
                      primary={
                        <>
                          <Headline variant="h6">Description</Headline>
                          {desc}
                        </>
                      }
                    />
                  </DescQueryListItem>
                )}
                {audit != null && (
                  <DescQueryListItem key="audit">
                    <DescListItemText
                      primary={
                        <>
                          <Headline variant="h6">Audit</Headline>
                          {audit}
                        </>
                      }
                    />
                  </DescQueryListItem>
                )}
                {remediation != null && (
                  <DescQueryListItem key="remediation">
                    <DescListItemText
                      primary={
                        <>
                          <Headline variant="h6">Remediation</Headline>
                          {remediation}
                        </>
                      }
                    />
                  </DescQueryListItem>
                )}
              </DescList>
            </TableCell>
          </TableRow>
        );
      }

      const checksStyle = !showDetails
        ? {
            marginLeft: 0,
            paddingLeft: 0,
          }
        : {};

      return showDetails ? (
        <TableRow key={query.mquery.title} id={query.mquery.mrn}>
          <TableCell colSpan={2}>
            <InvisibleBox>
              <DescTable>
                <TableBody>
                  <TableRow key={query.mquery.title}>
                    <TableCell>
                      <StatusTag status={status} text={text} displaydot />
                    </TableCell>
                    <TableTitleCell>{query.mquery.title}</TableTitleCell>
                  </TableRow>
                  {detailsRow}
                </TableBody>
              </DescTable>
            </InvisibleBox>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow key={query.mquery.title}>
          <TableCell sx={checksStyle}>
            <StatusTag status={status} text={text} displaydot />
          </TableCell>
          <TableCell>
            <Link href={`#${query.mquery.mrn}`}>{query.mquery.title}</Link>
          </TableCell>
        </TableRow>
      );
    });

  return (
    <>
      <Card variant="outlined" sx={{ my: 6, mx: 0 }}>
        <CardContent>
          <Typography variant="h4" component="h2" mb={2}>
            Checks
          </Typography>
          <TableContainer sx={{ border: "none" }}>
            <PrintPolicyReportQueriesTable>
              <TableBody>{tableRows()}</TableBody>
            </PrintPolicyReportQueriesTable>
          </TableContainer>
        </CardContent>
      </Card>

      <TableContainer>
        <PrintPolicyReportQueriesTable>
          <TableHead>
            <TableRow>
              <QueryHeaderTableCell>Checks</QueryHeaderTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tableRows({ showDetails: true })}</TableBody>
        </PrintPolicyReportQueriesTable>
      </TableContainer>
    </>
  );
}

const Headline = styled(Typography)`
  font-size: 10pt;
`;

const DescList = styled(List)`
  font-size: 10pt;
  padding-left: 6px;
  padding-right: 6px;
`;

const DescQueryListItem = styled(ListItem)`
  && {
    padding: 0;
    font-size: inherit;
    font-style: inherit;
  }
`;

const DescListItemText = styled(ListItemText)`
  && {
    font-size: inherit;
    font-style: inherit;

    p,
    li {
      font-size: inherit;
      font-style: inherit;
    }

    code,
    pre {
      font-size: inherit;
      font-style: inherit;
      background: ${(p) => p.theme.palette.code.background};
      margin-top: 6px;
      margin-bottom: 6px;
      padding: 6px;
      border-radius: 6px;
    }

    pre {
      overflow-x: hidden;
      white-space: pre-wrap; /* css-3, does not work in chrome */
      word-break: break-all; /* chrome */
    }

    pre code {
      background: none;
      padding: 0;
      margin: 0;
      color: ${(p) => p.theme.palette.code.text};
    }
  }
`;

const TableTitleCell = styled(TableCell)`
  font-weight: 600;
`;

const PrintPolicyReportQueriesTable = styled(Table)`
  td:nth-of-type(1) {
    width: 10%;
  }
`;

const InvisibleBox = styled(Box)`
  display: contents;
`;

const DescTable = styled(Table)`
  page-break-inside: avoid;
  tr:last-child td {
    border-bottom: unset;
  }
`;

const QueryHeaderTableCell = styled(TableCell)`
  padding-left: 15px;
`;
