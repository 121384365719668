import { Route, Routes } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";
import { QueriesPage, RegistryPage, PoliciesPage } from "~/pages/registry";
import { Space } from "~/lib/types";
import { QueriesOverview } from "~/pages/dashboard/Queries/overview";
import { QueriesAssets } from "~/pages/dashboard/Queries/QueriesAssets";

export type RegistryRoutesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function RegistryRoutes({
  space,
  availablePermissions,
}: RegistryRoutesProps) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RegistryPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      />
      <Route
        path="/namespace/:namespace/policies/:uid"
        element={
          <PoliciesPage
            space={space}
            availablePermissions={availablePermissions}
            isQuerypack={false}
          />
        }
      />
      <Route
        path="/namespace/:namespace/querypacks/:uid"
        element={
          <PoliciesPage
            space={space}
            availablePermissions={availablePermissions}
            isQuerypack
          />
        }
      />
      <Route
        path="/namespace/:namespace/checks/:uid"
        element={
          <QueriesPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      >
        <Route index element={<QueriesOverview />} />
        <Route path="overview" element={<QueriesOverview />} />
        <Route path="assets" element={<QueriesAssets />} />
      </Route>
      <Route
        path="/namespace/:namespace/queries/:uid"
        element={
          <QueriesPage
            space={space}
            availablePermissions={availablePermissions}
            isQuerypack
          />
        }
      >
        <Route index element={<QueriesOverview />} />
        <Route path="overview" element={<QueriesOverview />} />
        <Route path="assets" element={<QueriesAssets />} />
      </Route>
    </Routes>
  );
}
