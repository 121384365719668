import { useRef } from "react";
import {
  ContentSearchResultItemOrderField,
  OrderDirection,
} from "~/operations";
import {
  Flex,
  Search,
  SortBy,
  FiltersModal,
  Text,
  FilterOption,
} from "~/components/ui-library";
import { UseRegistriesReturnProps } from "./useRegistries";

type FacetsProps = {
  filtersLoading?: boolean;
  filtersErrors?: string[] | undefined;
  filterOptions: FilterOption[];
  filters: UseRegistriesReturnProps["filters"];
  sortByOptions: UseRegistriesReturnProps["sortByOptions"];
  facets: UseRegistriesReturnProps["facets"];
  state: UseRegistriesReturnProps["state"];
  onFacetsChange: UseRegistriesReturnProps["onFacetsChange"];
};

const Facets = ({
  filtersLoading,
  filtersErrors,
  filterOptions,
  filters,
  sortByOptions,
  facets,
  state,
  onFacetsChange,
}: FacetsProps) => {
  const ref = useRef<null | HTMLElement>(null);

  return (
    <>
      <Flex center data-testid="registries-facets" gap={3}>
        <Search
          size="small"
          name="Search"
          label=""
          placeholder="Search for Names, Technologies, Checks, ..."
          value={facets.query}
          onChangeHandler={({ name, value }) => {
            onFacetsChange({
              query: value as string,
            });
          }}
          fullWidth
        />
        <SortBy
          sortByOptions={sortByOptions}
          value={facets.orderBy.direction}
          onChangeHandler={({ name, value }) => {
            onFacetsChange({
              orderBy: {
                field: name as ContentSearchResultItemOrderField,
                direction: value as OrderDirection,
              },
            });
          }}
          sx={{
            minWidth: 160,
          }}
        />
        <FiltersModal
          filtersLoading={filtersLoading}
          filtersErrors={filtersErrors}
          filterOptions={filterOptions}
          filters={filters}
          onChangeHandler={(values) => {
            onFacetsChange({
              ...values,
            });
          }}
          refEle={ref.current}
        />
      </Flex>
      <div
        data-testid="registries-selected-filters"
        ref={(ele) => (ref.current = ele)}
        style={{
          paddingTop: 16,
        }}
      />
      <Flex alignItems="center" justifyContent="space-between" my={5}>
        <Text secondary>{state.paginationText}</Text>
      </Flex>
    </>
  );
};

export default Facets;
