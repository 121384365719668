import { Markdown } from "~/components/markdown";
import { Card } from "@mui/material";
import { usePolicyOutletContext } from "./policy-gql";
import { useOutletContext } from "react-router-dom";
import { Space } from "~/lib/types";
import { Mquery } from "~/operations";

type QueriesContextType = {
  space: Space;
  queryData?: Mquery;
  namespace: string;
  queryId: string;
  policyId: string;
  docs: Mquery["docs"];
  mql: Mquery["mql"];
  properties: Mquery["properties"];
};

type Props = {};

export function useQueriesContext() {
  return useOutletContext<QueriesContextType>();
}

export function Overview({}: Props) {
  const { policy } = usePolicyOutletContext();
  const overviewDocs = policy.docs ? <Markdown source={policy.docs} /> : null;

  return <Card sx={{ mb: 2, p: 3 }}>{overviewDocs}</Card>;
}
