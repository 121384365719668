import { Grid } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { AddAnElementCard } from "~/components/add-an-element-card";
import { EmptyState } from "~/components/empty-state/empty-state";
import { ScoreCard, PolicyScoreCard } from "../PolicyScoreCard";
import { fadeInOut } from "~/lib/animations";
import { TestIamActionsQuery } from "~/operations";
import { Policies } from "./types";
import { usePolicyGrid } from "../../hooks";

type PolicyCardGridProps = {
  policies: Policies["edges"];
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isCicd?: boolean;
  limit?: number;
};

export function PolicyCardGrid({
  policies,
  availablePermissions,
  isCicd,
  limit,
}: PolicyCardGridProps) {
  const { scorecards } = usePolicyGrid({ policies });

  if (scorecards.length === 0) {
    return (
      <Grid container mt={3}>
        <Grid item xs={12}>
          <EmptyState contentType="" />
        </Grid>
      </Grid>
    );
  }

  if (limit) {
    scorecards.splice(limit);
  }

  const addPolicyHref = `/space/registry`;

  return (
    <Grid container spacing={2} mt={1}>
      <AnimatePresence>
        {scorecards?.map((policyScorecard: ScoreCard) => {
          return (
            <Grid {...MotionGridProps} key={policyScorecard.title}>
              <PolicyScoreCard
                {...{ policyScorecard, availablePermissions, isCicd }}
              />
            </Grid>
          );
        })}
        {/* Add a Policy Card */}
        {!limit && (
          <Grid {...MotionGridProps}>
            <AddAnElementCard
              label="Add a Policy"
              to={addPolicyHref}
              minHeight={176}
            />
          </Grid>
        )}
      </AnimatePresence>
    </Grid>
  );
}

export const MotionGridProps = {
  container: true,
  item: true,
  xs: 12,
  sm: 6,
  md: 4,
  // framer-motion
  component: motion.div,
  layout: true,
  ...fadeInOut,
};
