import {
  CircularProgress,
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  Link,
  Typography,
} from "@mui/material";
import { Route, Status } from "~/components/ui-library";
import { HomeIcon } from "~/components/icons";

export type BreadcrumbsProps = MuiBreadcrumbsProps & {
  routes: Route[];
  onChange?: () => void;
  onNavigate: (value: Route["href"]) => void;
};

export function Breadcrumbs({
  routes,
  onNavigate,
  onChange,
  ...restProps
}: BreadcrumbsProps) {
  const breadcrumbs = routes.map(({ name, href }, index) => {
    const key = `${href?.pathname}-${index}`;
    const isLastCrumb = index === routes.length - 1;

    if (!href || isLastCrumb) {
      return (
        <Typography key={key} onClick={onChange}>
          {name === Status.Pending ? <CircularProgress size={14} /> : name}
        </Typography>
      );
    } else if (href) {
      return (
        <Link
          key={key}
          onClick={(e) => {
            e.preventDefault();
            onNavigate(href);
          }}
          display="flex"
        >
          {index === 0 ? <HomeIcon fontSize="inherit" /> : name}
        </Link>
      );
    }

    return null;
  });

  return (
    <MuiBreadcrumbs
      sx={{ overflowWrap: "anywhere" }}
      separator="›"
      {...restProps}
    >
      {breadcrumbs}
    </MuiBreadcrumbs>
  );
}
