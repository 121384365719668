import { Fragment, useState } from "react";
import {
  ConfigurationPaper,
  ConfigurationList,
  ConfigurationItemText,
  ConfigurationItemTextProps,
} from "~/components/configuration-items";
import {
  DangerZone,
  DangerZoneItem,
  DangerZoneItemProps,
} from "~/components/danger-zone";
import { useOrgSettingsOutletContext } from "./settings";
import { IamActions } from "~/lib/iam";
import { useViewer } from "~/providers/viewer";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";

type OrganizationSettingsPageState = {
  name: string;
  nameErrMsg: string;
  isDeleting: boolean;
};

export function GeneralSettings() {
  const { availablePermissions, org } = useOrgSettingsOutletContext();
  const { updateOrgName, deleteOrg } = useViewer();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialState: OrganizationSettingsPageState = {
    name: org.name,
    nameErrMsg: "",
    isDeleting: false,
  };

  const [state, setState] = useState(initialState);
  const [name, setName] = useState<string>(org.name);

  const mergeState = (params: Partial<OrganizationSettingsPageState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const deleteOrgPermission = availablePermissions?.includes(
    IamActions.CAPTAIN_DELETEORGANIZATION,
  );
  const updateOrgPermission = availablePermissions?.includes(
    IamActions.CAPTAIN_UPDATEORGANIZATION,
  );

  const handleChangeName: ConfigurationItemTextProps["onChange"] = (e) => {
    setName(e.target.value);
  };

  const handleBlurName: ConfigurationItemTextProps["onBlur"] = async (e) => {
    try {
      await updateOrgName({
        variables: { input: { mrn: org.mrn, name } },
      });
      mergeState({ nameErrMsg: "" });
    } catch (error) {
      mergeState({ nameErrMsg: "Cannot store organization name" });
    }
  };

  const handleDeleteOrg: DangerZoneItemProps["onClick"] = async () => {
    mergeState({ isDeleting: true });
    try {
      await deleteOrg({ variables: { orgMrn: org.mrn } });
      enqueueSnackbar(`Successfully deleted organization: ${org.name}`, {
        variant: "success",
      });
      navigate("/dashboard");
    } catch (error) {
      enqueueSnackbar(`Could not delete organization ${org.name}`, {
        variant: "error",
      });
    } finally {
      mergeState({ isDeleting: false });
    }
  };

  return (
    <Fragment>
      <ConfigurationPaper>
        <ConfigurationList title="General">
          <ConfigurationItemText
            disabled={true}
            primary="Name"
            secondary="A unique ID used to identify this organization"
            label="Name"
            value={org.id}
          ></ConfigurationItemText>
          <ConfigurationItemText
            onBlur={handleBlurName}
            disabled={!updateOrgPermission}
            primary="Display Name"
            secondary="The name that users will see for the organization"
            label="Display Name"
            value={name}
            onChange={handleChangeName}
            error={state.nameErrMsg.length === 0 ? false : true}
            helperText={state.nameErrMsg}
          ></ConfigurationItemText>
        </ConfigurationList>
      </ConfigurationPaper>

      {deleteOrgPermission && (
        <DangerZone>
          <DangerZoneItem
            primary="Delete Organization"
            secondary="Once you delete an organization, all its data will be erased including spaces, assets and reports. Please be certain."
            confirm="I confirm that I want to delete this organization including all its related content"
            action="Delete"
            isDeleting={state.isDeleting}
            onClick={handleDeleteOrg}
          />
        </DangerZone>
      )}
    </Fragment>
  );
}
