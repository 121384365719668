import { ChangeEvent, Fragment, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { debounce } from "lodash";
import {
  Box,
  Checkbox,
  Chip,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Loading, LoadingFailed } from "../loading";
import { IamActions } from "~/lib/iam";
import { DatasetSearchBar } from "../search";
import { EnabledPolicyStatsBar } from "../enabled-policy-stats-bar";
import {
  Access,
  MqueryState,
  PolicyQuerySummariesDocument,
  PolicyQuerySummariesQuery,
  ReviewStatus,
  usePolicyQuerySummariesQuery,
} from "~/operations";
import { usePolicyOutletContext } from "./policy-gql";
import { DataTable } from "../data-table";
import { MoonIcon } from "~/components/ui-library";
import { BlockIcon } from "~/components/icons";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";
import { SelectedEntity } from "~/components/exceptions/types";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { Impact } from "~/components/impact";
import getQueryMrn from "../../utils/getQueryMrn";

type SpacePolicySummaryPayload = Extract<
  NonNullable<PolicyQuerySummariesQuery["spacePolicySummary"]>,
  { __typename: "SpacePolicySummaryPayload" }
>;
type QuerySummariesConnection = NonNullable<
  SpacePolicySummaryPayload["querySummaries"]
>;
type QuerySummariesEdge = NonNullable<QuerySummariesConnection["edges"]>[0];

type Sort = {
  field: string;
  direction: "asc" | "desc";
};

type SortDirection = 1 | -1;

export type QueriesProps = {};

export function Queries({}: QueriesProps) {
  const {
    policy,
    policyEnabled,
    space,
    availablePermissions,
    exceptionGroups,
  } = usePolicyOutletContext();
  const { policyId } = useParams();
  const isPrivatePolicy = policy.access === Access.Private;
  const policyMrn = policy.mrn;

  const url = new URL(window.location.toString());
  const navigate = useNavigate();
  const [selectedChecks, setSelectedChecks] = useState<Array<SelectedEntity>>(
    [],
  );
  const { data, loading, error } = usePolicyQuerySummariesQuery({
    variables: {
      input: { spaceMrn: space.mrn, policyMrn },
    },
    fetchPolicy: "no-cache",
  });

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedChecks([]);
    },
    onRemoveException: () => {
      setSelectedChecks([]);
    },
    scopeMrns: [space.mrn],
    queryMrns: mapSelectedEntitiesToString(selectedChecks),
    refetchQueries: [PolicyQuerySummariesDocument],
  });

  // Display a loading spinner in the queries tab until we've successfully
  // returned and loaded the data
  if (loading)
    return (
      <Box pt={4}>
        <Loading what="checks" />
      </Box>
    );

  // Display an error if the SpacePolicyReport call fails
  if (error)
    return (
      <Box pt={4}>
        <LoadingFailed what="checks" />
      </Box>
    );

  const querySummaries = data?.spacePolicySummary?.querySummaries?.edges || [];

  let filteredQuerySummaries = querySummaries.slice();

  // Determine if a user has the correct permissions that allow them to
  // add exceptions
  const mutateQueryPermission = availablePermissions?.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  // Check if the user is sorting by reading search Parameters
  // By default, we'll sort the rows by Policy Name, A => Z
  const sort: Sort = {
    field: url.searchParams.get("field") || "SCORE",
    direction:
      (url.searchParams.get("direction") as Sort["direction"]) || "asc",
  };
  const sortDirection: SortDirection = sort.direction === "asc" ? -1 : 1;

  // If user is filtering, we read the parameters for query
  // and deliver the results.
  const query = url.searchParams.get("query");
  if (query && filteredQuerySummaries) {
    filteredQuerySummaries = filteredQuerySummaries.filter((edge) => {
      const title = edge.node?.mquery.title.toLowerCase();
      return title && title.indexOf(query.toLowerCase()) > -1;
    });
  }

  const handleSort = (a: QuerySummariesEdge, b: QuerySummariesEdge) => {
    switch (sort.field) {
      case "QUERY":
        return sortByName(a, b, sortDirection);
      case "ASSETS":
        return sortByAssets(a, b, sortDirection);
      case "SCORE":
        return sortByScore(a, b, sortDirection);
      case "IMPACT":
        return sortByImpact(a, b, sortDirection);
      default:
        return sortByName(a, b, sortDirection);
    }
  };

  // handle action when a user filters by a custom string
  // This will set the query as a parameter and then navigate to the new url.
  const handleFilter = (query: string) => {
    if (query.length > 0) {
      url.searchParams.set("query", query);
    } else {
      url.searchParams.delete("query");
    }
    navigate(url.pathname + url.search, { replace: true });
  };

  // handle action when a user checks a single query
  const handleCheck = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    { mrn, exception, groupId }: SelectedEntity,
  ) => {
    if (checked) {
      setSelectedChecks((prev) => [
        ...prev,
        {
          mrn,
          exception,
          groupId,
        },
      ]);
    } else {
      setSelectedChecks(
        selectedChecks.filter((selection) => mrn !== selection.mrn),
      );
    }
  };

  // handle action when a user clicks the indeterminate/select all
  // checkbox. If at least one query is selected, we deselect all the options.
  // If all are unselected, we will select all.
  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setSelectedChecks(
        filteredQuerySummaries.map((edge) => ({
          groupId: edge?.node?.exception?.id,
          mrn: edge?.node?.mquery?.mrn || "",
          exception: !edge?.node?.exception
            ? null
            : {
                justification: edge.node.exception.justification,
                action: edge.node.exception.action,
              },
        })),
      );
    } else {
      setSelectedChecks([]);
    }
  };

  // Uncheck all the selected queries
  const unCheckAll = () => {
    setSelectedChecks([]);
  };

  const handleCancelClick = () => {
    unCheckAll();
  };

  // Handle action when a user clicks on the "up/down" arrow
  // at the top of each column
  const handleSortClick = (field: string) => {
    if (sort.field === field && sort.direction === "asc") {
      url.searchParams.set("direction", "desc");
    } else {
      url.searchParams.set("direction", "asc");
    }

    url.searchParams.set("field", field);
    navigate(url.pathname + url.search, { replace: true });
  };

  const hasEditPermission = mutateQueryPermission && policyEnabled;

  return (
    <Fragment>
      <Box sx={{ mb: 4 }}>
        <DatasetSearchBar
          onChange={debounce((query) => handleFilter(query), 300)}
          placeholder="Filter checks..."
          value={url.searchParams.get("query")}
          onQuery={() => {}}
        />
      </Box>
      <DataTable
        id="policy-queries-list"
        selectable={hasEditPermission}
        selection={selectedChecks}
      >
        <TableHead>
          <TableRow>
            <TableCell width={50} sx={{ display: policyEnabled ? "" : "none" }}>
              <Checkbox
                checked={selectedChecks.length === querySummaries.length}
                indeterminate={
                  selectedChecks.length > 0 &&
                  selectedChecks.length < querySummaries.length
                }
                onChange={(_, checked) => handleCheckAll(checked)}
              />
            </TableCell>
            {tableHeaders.map((header: Header) => (
              <TableCell
                key={header.id}
                sortDirection={
                  sort.field === header.id ? sort.direction : false
                }
                sx={{
                  display: shouldDisplay(header.id, policyEnabled),
                  ...header.options,
                }}
              >
                <TableSortLabel
                  onClick={() => handleSortClick(header.id)}
                  direction={sort.direction}
                  active={sort.field === header.id}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/* Policy Queries List */}
        <TableBody>
          {filteredQuerySummaries
            .sort(handleSort)
            .map((edge: QuerySummariesEdge) => {
              if (!edge.node) return <></>;
              const { mquery, assetGrades, mqueryState, exception } = edge.node;

              const uid = mquery.mrn.split("/").pop();

              // needs different link for private policies
              let namespace = isPrivatePolicy ? space.id : "mondoohq";

              const checkMrn = getQueryMrn({ namespace, uid: uid as string });
              const urlLink = `/space/security/check?spaceId=${space.id}&findingMrn=${checkMrn}&policyId=${policyId}`;

              const exceptionsUrl = `/space/security/policies/${encodeURIComponent(
                policyId as string,
              )}/exceptions/?spaceId=${space.id}&exceptionId=${exception?.id}`;
              const isSelected = Boolean(
                selectedChecks.find((check) => check.mrn === mquery.mrn),
              );
              const className = isSelected ? "selected" : "";
              const isPendingException =
                exception?.reviewStatus === ReviewStatus.NotReviewed;

              return (
                <TableRow
                  key={uid}
                  onClick={() => {
                    navigate(urlLink);
                  }}
                  className={className}
                >
                  {/* We display different content based on whether a policy is enabled vs disabled */}
                  {policyEnabled && (
                    <Fragment>
                      <TableCell>
                        <Checkbox
                          checked={isSelected}
                          onChange={(e, checked) =>
                            handleCheck?.(e, checked, {
                              mrn: mquery.mrn,
                              exception,
                              groupId: exception?.id,
                            })
                          }
                          onClick={(e) => e.stopPropagation()}
                        />
                      </TableCell>
                      <TableCell>
                        {/* severity is noted as deprecated, but impact doesn't seem
                        to have taken its place in the API just yet, this handles both */}
                        {
                          <Box component={RouterLink} to={urlLink}>
                            <Impact
                              impact={
                                mquery.impact
                                  ? mquery.impact.value
                                  : mquery.severity
                                    ? mquery.severity
                                    : 0
                              }
                            />
                          </Box>
                        }
                      </TableCell>
                      <TableCell>
                        <Box component={RouterLink} to={urlLink}>
                          <EnabledPolicyStatsBar {...{ assetGrades }} />
                        </Box>
                      </TableCell>
                    </Fragment>
                  )}
                  <TableCell>
                    <Box
                      component={RouterLink}
                      to={urlLink}
                      display="flex"
                      alignItems="center"
                      gap={1}
                    >
                      {mqueryState === MqueryState.Snoozed && (
                        <Chip
                          label="Snoozed"
                          icon={<MoonIcon />}
                          size="small"
                        />
                      )}
                      {mqueryState === MqueryState.Disabled && (
                        <Chip
                          label="Disabled"
                          icon={<BlockIcon />}
                          size="small"
                        />
                      )}
                      {isPendingException && (
                        <NavigateToExceptionButton
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            navigate(exceptionsUrl);
                          }}
                        />
                      )}
                      <Typography
                        className="mquery-title"
                        fontSize="inherit"
                        fontWeight="inherit"
                        display="inline-block"
                      >
                        {mquery.title}
                      </Typography>
                    </Box>
                  </TableCell>
                  {policyEnabled && (
                    <TableCell align="right" sx={{ pr: 5 }}>
                      <Box component={RouterLink} to={urlLink}>
                        {assetGrades.total}
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
        <ExceptionsModals
          isSetExceptionModalOpen={isSettingException}
          isRemoveExceptionModalOpen={isRemovingException}
          onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
          onSetExceptionModalClose={handleSetExceptionModalClose}
          onSetExceptionModalSave={handleSetException}
          onRemoveExceptionModalSave={handleRemoveException}
          loading={exceptionsLoading}
          target="check"
          role="security"
          exceptionGroups={exceptionGroups}
          selectedEntities={selectedChecks}
        />
      </DataTable>
      {selectedChecks.length > 0 && (
        <>
          <ExceptionsToolbar
            target="check"
            onCancel={handleCancelClick}
            onRemoveExceptionClick={handleRemoveExceptionModalOpen}
            onSetExceptionClick={handleSetExceptionModalOpen}
            selectedEntities={selectedChecks}
            totalCount={filteredQuerySummaries.length}
          />
        </>
      )}
    </Fragment>
  );
}

export type Header = {
  id: string;
  label: string;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

export const tableHeaders: Header[] = [
  { id: "IMPACT", label: "Impact" },
  { id: "SCORE", label: "Scores", options: { width: 150 } },
  { id: "QUERY", label: "Check" },
  {
    id: "ASSETS",
    label: "Assets",
    options: { textAlign: "right", width: 150 },
  },
];

const shouldDisplay = (
  id: string,
  policyEnabled: boolean | null,
): string | undefined => {
  if (!policyEnabled && id !== "QUERY") {
    return "none";
  }
};

///////// SORTING HELPERS

const sortByName = (
  a: QuerySummariesEdge,
  b: QuerySummariesEdge,
  sortDirection: SortDirection,
) => {
  if (!a.node || !b.node) return 0;
  let aName = a.node.mquery.title.toUpperCase();
  let bName = b.node.mquery.title.toUpperCase();

  switch (true) {
    case aName > bName:
      return -1 * sortDirection;
    case aName < bName:
      return 1 * sortDirection;
    default:
      return 0;
  }
};

const sortByAssets = (
  a: QuerySummariesEdge,
  b: QuerySummariesEdge,
  sortDirection: SortDirection,
) => {
  if (!a.node || !b.node) return 0;
  let aValue = a.node.assetGrades.total;
  let bValue = b.node.assetGrades.total;

  switch (true) {
    case aValue > bValue:
      return -1 * sortDirection;
    case aValue < bValue:
      return 1 * sortDirection;
    default:
      return 0;
  }
};

const sortByScore = (
  a: QuerySummariesEdge,
  b: QuerySummariesEdge,
  sortDirection: SortDirection,
) => {
  if (!a.node || !b.node) return 0;
  const statsA = a.node.assetGrades;
  const statsB = b.node.assetGrades;
  const mqueryA = a.node.mquery;
  const mqueryB = b.node.mquery;
  switch (true) {
    case statsA.F > statsB.F:
      return 1 * sortDirection;
    case statsA.F < statsB.F:
      return -1 * sortDirection;

    case statsA.D > statsB.D:
      return 1 * sortDirection;
    case statsA.D < statsB.D:
      return -1 * sortDirection;

    case statsA.C > statsB.C:
      return 1 * sortDirection;
    case statsA.C < statsB.C:
      return -1 * sortDirection;

    case statsA.B > statsB.B:
      return 1 * sortDirection;
    case statsA.B < statsB.B:
      return -1 * sortDirection;

    case statsA.A > statsB.A:
      return 1 * sortDirection;
    case statsA.A < statsB.A:
      return -1 * sortDirection;

    case statsA.U > statsB.U:
      return 1 * sortDirection;
    case statsA.U < statsB.U:
      return -1 * sortDirection;

    case statsA.X > statsB.X:
      return 1 * sortDirection;
    case statsA.X < statsB.X:
      return -1 * sortDirection;

    default:
      return mqueryA.mrn.localeCompare(mqueryB.mrn);
  }
};

const sortByImpact = (
  a: QuerySummariesEdge,
  b: QuerySummariesEdge,
  sortDirection: SortDirection,
) => {
  if (!a.node || !b.node) return 0;
  let impactA = a.node.mquery.impact?.value ?? a.node.mquery.severity;
  let impactB = a.node.mquery.impact?.value ?? b.node.mquery.severity;

  if (!impactA) {
    impactA = 100;
  }

  if (!impactB) {
    impactB = 100;
  }

  switch (true) {
    case impactA > impactB:
      return -1 * sortDirection;
    case impactA < impactB:
      return 1 * sortDirection;
    default:
      return 0;
  }
};
