import { Fragment, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { debounce } from "lodash";
import { Typography, Link, Breadcrumbs, Grid } from "@mui/material";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import { TestIamActionsQuery, useSpaceReportQuery } from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { DatasetSearchBar } from "~/components/search";
import { usePolicies } from "./hooks/usePolicies";
import { SelectedPolicies } from "./types";
import { LayoutSwitch } from "~/components/LayoutSwitch/LayoutSwitch";
import { List } from "./list";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Policies({ space, availablePermissions }: Props) {
  const url = new URL(window.location.toString());

  const [selectedItems, setSelectedItems] = useState<SelectedPolicies>([]);
  const { handle, permissions, sort, state } = usePolicies({
    availablePermissions,
    selectedItems,
    setSelectedItems,
    space,
  });

  const { data, loading, error } = useSpaceReportQuery({
    variables: { input: { spaceMrn: space.mrn } },
    // fetchPolicy can be removed once PolicyMarketplace page uses apollo hooks
    // for enabling / disabling policies
    fetchPolicy: "network-only",
  });

  // If data is still loading, we'll show a loading spinner
  if (loading) {
    return <Loading what="enabled policies" />;
  }

  // if fetching data fails, we'll show a failure
  if (error || data?.spaceReport?.__typename !== "SpaceReport") {
    return <LoadingFailed what="enabled policies" />;
  }

  // Keep one copy of the original data and copy the data returned into a new array
  const enabledPolicies = data.spaceReport.policyReportSummaries?.edges || [];
  let filteredPolicies = enabledPolicies.slice();

  // If user is filtering, we read the parameters for query
  // and deliver the results.
  const query = url.searchParams.get("query");
  if (query && filteredPolicies) {
    filteredPolicies = filteredPolicies.filter((edge) => {
      if (!edge.node) return false;
      return (
        edge.node.policy.name.toLowerCase().indexOf(query.toLowerCase()) > -1
      );
    });
  }

  // handle action when a user clicks the indeterminate/select all
  // checkbox. If at least one query is selected, we deselect all the options.
  // If all are unselected, we will select all.
  const handleCheckAll = () => {
    let newSelections: SelectedPolicies = [];

    if (selectedItems.length < 1) {
      newSelections = [
        ...filteredPolicies.map((edge) => ({
          policyMrn: String(edge.node?.policy.mrn),
          action: edge.node?.policy.action,
        })),
      ];
    }

    setSelectedItems(newSelections);
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={2}>Policies</Typography>,
  ];

  document.title = "Security · Mondoo";

  return (
    <Fragment>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Typography variant="h4" fontWeight={700} sx={{ mb: 3 }}>
        Policies
      </Typography>
      <Grid container spacing={3}>
        <LayoutSwitch
          countOfCountCaption={`Showing ${filteredPolicies.length} of ${enabledPolicies.length} Policies`}
          filterBar={
            <DatasetSearchBar
              onChange={debounce((query) => handle.filter(query), 300)}
              onQuery={() => {}}
              placeholder="Filter policies..."
              value={url.searchParams.get("query")}
            />
          }
          views={{
            list: (
              <List
                {...{
                  space,
                  handle,
                  state,
                  handleCheckAll,
                  permissions,
                  filteredPolicies,
                  enabledPolicies,
                  sort,
                  selectedItems,
                }}
              />
            ),
          }}
        />
      </Grid>
    </Fragment>
  );
}
