import React from "react";
import { SectionHeading, Stats } from "~/components/DetailsPage/components";
import { Grid, Link, Typography } from "@mui/material";
import { CveSummary } from "~/components/vulnerabilities";
import { Stat } from "~/components/DetailsPage/components/Stats/Stats";
import { RiskFactorStats, VersionDistribution } from "~/operations";
import { SoftwareVersionDistribution } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionDistribution";
import { SpaceSoftwareNode } from "~/pages/space/software/Software/types";
import { ScoreBlock } from "~/pages/space/security/components/Check/ScoreBlock";
import { Flex } from "~/components/ui-library";
import { ArrowDownwardIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { AggregateScoresEdges } from "~/components/FirewatchPage";

type SoftwareDescriptionProps = {
  stats: Stat[];
  description: string;
  versionDistribution: VersionDistribution | null | undefined;
  showVersionDistribution: boolean;
  softwareNode: SpaceSoftwareNode | undefined;
  findingMrn: string;
  space: Space;
  edges: AggregateScoresEdges;
  title: string;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
};

export const SoftwareDescription = ({
  stats,
  description,
  versionDistribution,
  showVersionDistribution,
  softwareNode,
  findingMrn,
  space,
  edges,
  title,
  riskFactors,
}: SoftwareDescriptionProps) => {
  return (
    <Grid id="description" item xs={12}>
      <SectionHeading heading="Description" />
      <Flex justifyContent="space-between" alignContent="center" mb={1}>
        <Typography textTransform="uppercase" fontWeight={700}>
          Summary
        </Typography>
        <Link
          key="software-assets"
          onClick={() => {
            document
              .querySelector(`#software-assets`)
              ?.scrollIntoView({ behavior: "smooth" });
          }}
          display="inline-flex"
          alignItems="center"
          gap={0.5}
          fontSize={12}
          fontWeight={700}
          sx={{ cursor: "pointer" }}
        >
          ASSETS
          <ArrowDownwardIcon fontSize="inherit" />
        </Link>
      </Flex>
      <Grid container>
        <Grid item xs>
          <Stats id="check-asset-stats" stats={stats} />
          {showVersionDistribution &&
            (versionDistribution?.distribution || []).length > 1 && (
              <SoftwareVersionDistribution
                versionDistribution={versionDistribution}
                findingMrn={findingMrn}
                space={space}
                edges={edges}
                riskFactors={riskFactors}
              />
            )}
          <CveSummary
            id="advisory-summary"
            text={
              description
                ? String(description)
                : "There is no description available for this package."
            }
          />
        </Grid>
        {!showVersionDistribution && (
          <Grid
            item
            container
            xs={12}
            sm="auto"
            sx={{ display: "inline-block" }}
          >
            <ScoreBlock
              mainScore={softwareNode?.riskScore}
              epssScore={softwareNode?.epss}
              cvssScore={softwareNode?.cvss}
              riskFactors={softwareNode?.riskFactors}
              blastRadius={softwareNode?.blastRadius}
              hasScore={Boolean(softwareNode)}
              hasError={false}
            />
          </Grid>
        )}
        {/*<Grid item container xs={12} sm="auto" sx={{ display: "inline-block" }}>
          <VersionCriticalityGrid />
        </Grid>*/}
      </Grid>
    </Grid>
  );
};
