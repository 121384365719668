import { Fragment, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";

import { ChevronLeftIcon } from "~/components/icons";
import { EditableProps } from "./editable-props";
import { MProperty } from "~/operations";
import { Space } from "~/lib/types";
import { useColorMode } from "~/providers/color-mode";

type Props = {
  data: MProperty[];
  space: Space;
};

export function PropsTable({ data, space }: Props) {
  return (
    <Box
      sx={{
        overflow: "hidden",
        borderRadius: 2,
        boxShadow: (theme) => theme.shadows[1],
      }}
    >
      <Paper sx={{ width: "100%", boxShadow: "none" }}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.id} sortDirection="asc">
                    <TableSortLabel
                      direction="asc"
                      active={header.id === "NAME"}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((prop) => {
                return (
                  <CollapsablePropRow key={prop.uid} {...{ prop, space }} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

//////// Table Configuration

type Header = {
  id: string;
  label: string;
  colSpan?: number;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "Property Name (UID)",
    options: { textAlign: "left" },
  },
  { id: "VALUE", label: "Value", options: { textAlign: "left" } },
];

type RowProps = {
  prop: MProperty;
  space: Space;
};

const CollapsablePropRow = ({ prop, space }: RowProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { mode } = useColorMode();

  return (
    <Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        sx={[{ td: { ...(!open && { borderBottom: "none" }) } }]}
      >
        <TableCell sx={{ width: "20%", minWidth: 250 }}>
          <Box sx={{ display: "flex" }}>
            <Box
              component="button"
              sx={{
                background: "transparent",
                border: "none",
                transform: "rotate(270deg)",
              }}
            >
              <ChevronLeftIcon sx={{ fontSize: 16, color: "text.primary" }} />
            </Box>
            <Typography>{prop?.uid}</Typography>
          </Box>
        </TableCell>
        <TableCell sx={{ position: "relative", width: "80%" }}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: { xs: 200, sm: 350, lg: 800 },
              color: "text.primary",
              background: `-webkit-linear-gradient(left, ${
                mode === "light" ? "#000" : "#FFF"
              }, transparent)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {prop.mql}
          </Typography>
        </TableCell>
      </TableRow>
      {/* Accordion Content */}
      <TableRow>
        <TableCell sx={{ py: 0, maxWidth: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ px: 7, py: 2 }}>
              <Typography color="text.secondary">{prop.title}</Typography>
              <EditableProps {...{ space, propMrn: prop.mrn }}>
                {prop.mql}
              </EditableProps>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
