import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { AggregateScoresNode } from "../FirewatchPage";
import { FirewatchChip } from "./ChipText";

type CheckCellProps = { row: AggregateScoresNode; size?: string };

export function CheckCell({ row, size }: CheckCellProps) {
  const { tags } = row || {};
  const isDashboardCard = size === "small";

  return (
    <TableCell>
      <Clamp lines={size === "small" ? 2 : 1}>
        <Typography
          sx={{
            fontSize: isDashboardCard ? 12 : 14,
            fontWeight: isDashboardCard ? 400 : 700,
            lineHeight: "16px",
          }}
        >
          {row?.title || row?.nodeTitle}
        </Typography>
      </Clamp>
      {!isDashboardCard && (
        <Box display="flex" gap={1} mt={1}>
          {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      )}
    </TableCell>
  );
}
