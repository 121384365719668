import React from "react";
import { Impact } from "~/components/impact";
import { Typography } from "@mui/material";

type CheckScoreImpactProps = {
  value: number;
  isActive: boolean;
  isRiskScore?: boolean;
  isCVSS?: boolean;
};

export const ScoreImpact = ({
  value,
  isActive,
  isRiskScore = false,
  isCVSS = false,
}: CheckScoreImpactProps) => {
  return (
    <>
      <Impact
        impact={value}
        showText={false}
        isRiskScore={isRiskScore}
        isActive={isActive}
        hideTooltip={!isActive}
        isCVSS={isCVSS}
      />
    </>
  );
};
