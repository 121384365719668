import { Box, SxProps, Theme } from "@mui/material";

export function RocketPath() {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 494 68"
      width="494px"
      height="68px"
      fill="none"
    >
      <path
        d="M2 65.8967C22 3.39665 110.966 18.255 164.5 40.8965C304 99.8965 463 19.8965 492 1.89648"
        stroke="#987BEA"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
        strokeDasharray="8 8"
      />
    </Box>
  );
}
