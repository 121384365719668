import {
  alpha,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { AssetReport } from "~/pages/inventory/asset/types";

import { createPath, parsePath, useNavigate } from "react-router-dom";
import { Loading } from "~/components/loading";
import { DashboardCard } from "~/pages/organization/dashboard/dashboard-card";
import { MondooIcon } from "~/components/icons/mondoo/mondoo-primary";
import { Impact } from "~/components/impact";

type RecommendedActionEdge = NonNullable<
  AssetReport["recommendedActions"]
>["edges"][0];
type RecommendedActionNode = RecommendedActionEdge["node"];

type Props = {
  assetReport: AssetReport | null;
  assetPath: string | null;
};

export function AssetRecommendedActionsV2({ assetReport, assetPath }: Props) {
  if (!assetReport || !assetPath) {
    return <Loading what="Recommended Actions" />;
  }
  const navigate = useNavigate();
  const recommendedActions = assetReport.recommendedActions?.edges
    .slice(0, 5)
    .map((edge) => edge.node);
  const totalCount = assetReport.recommendedActions?.totalCount || 0;
  const hasRecommendedActions = totalCount > 0;

  const actionTableRow = (action: RecommendedActionNode) => {
    const getKey = () => {
      switch (action.__typename) {
        case "RecommendedActionItemAdvisory":
          return action.id;
        case "RecommendedActionItemControl":
          return action.mrn;
      }
    };

    const handleClick = () => {
      switch (action.__typename) {
        case "RecommendedActionItemAdvisory":
          return;
        case "RecommendedActionItemControl":
          const parsedPath = parsePath(assetPath);
          const pathname = `${parsedPath.pathname}/checks`;
          const search = `${parsedPath.search}&checkMrn=${encodeURIComponent(
            action.mrn,
          )}`;
          return navigate(createPath({ pathname, search }));
      }
    };

    const actionType = () => {
      switch (action.__typename) {
        case "RecommendedActionItemAdvisory":
          return "ADVISORY";
        case "RecommendedActionItemControl":
          return "CHECK";
      }
    };

    return (
      <TableRow
        key={getKey()}
        sx={[
          { td: { px: 0.5 } },
          {
            "&:hover": {
              cursor: "pointer",
            },
          },
        ]}
        onClick={handleClick}
      >
        <TableCell sx={{ p: 0, width: 80 }}>
          <Impact impact={action.severity} showText={false} />
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "200px",
          }}
        >
          <Tooltip
            title={
              <Box sx={{ textAlign: "center" }}>
                {actionType()}: {action.message}
              </Box>
            }
            placement="top-start"
            arrow
          >
            <Box>{action.message}</Box>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <DashboardCard
      title="RECOMMENDED ACTIONS"
      icon={<MondooIcon />}
      sx={{ height: 1 }}
    >
      <TableContainer
        sx={[
          {
            "td, th": {
              fontSize: 12,
              borderBottomColor: "transparent",
            },
          },
        ]}
      >
        <Table size="small" id="recommended-actions-table">
          <TableHead
            sx={[
              { backgroundColor: "transparent", boxShadow: "none" },
              {
                "tr:first-of-type th": {
                  borderBottom: "none",
                },
              },
              {
                "tr:last-child th": {
                  borderBottom: "none",
                },
              },
            ]}
          >
            <TableRow>
              <TableCell
                sx={{
                  pl: 0,
                  fontWeight: 700,
                  textTransform: "uppercase",
                  pointerEvents: "none",
                }}
                colSpan={3}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{recommendedActions?.map(actionTableRow)}</TableBody>
        </Table>
      </TableContainer>
      {!hasRecommendedActions && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            color: (theme) => alpha(theme.palette.text.primary, 0.33),
            minHeight: 148,
            borderRadius: 1,
          }}
        >
          <Typography
            sx={{
              width: 1,
              maxWidth: 500,
              mt: 1,
              textAlign: "center",
              fontWeight: 700,
            }}
          >
            Add more policies for this space in order to get a list of
            prioritized recommendations here
          </Typography>
        </Box>
      )}
    </DashboardCard>
  );
}
