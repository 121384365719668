import { Link, Route, Routes } from "react-router-dom";
import { AggregateScoreType, TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";
import { SecurityPage } from "~/pages/security";
import { Policies } from "~/pages/security/policies/policies";
import { PolicyPage } from "~/pages/policy";
import { Overview } from "~/components/policy-gql/overview";
import { Queries } from "~/components/policy-gql/queries";
import { Assets } from "~/components/policy-gql/assets";
import { ExceptionsWrapper } from "~/components/policy-gql/exceptions";
import { Dashboard } from "~/pages/space/security";
import { FirewatchPage } from "~/components/FirewatchPage";
import { Check } from "~/pages/space/security/Check";

export type SecurityRoutesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function SecurityRoutes({
  space,
  availablePermissions,
}: SecurityRoutesProps) {
  return (
    <Routes>
      <Route
        element={
          <SecurityPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      >
        <Route index element={<Dashboard space={space} />} />
        <Route path="policies">
          <Route
            index
            element={<Policies {...{ space, availablePermissions }} />}
          />
          <Route
            path=":policyId"
            element={
              <PolicyPage
                space={space}
                query={window.location.search}
                availablePermissions={availablePermissions}
              />
            }
          >
            <Route index element={<Overview />} />
            <Route path="overview" element={<Overview />} />
            <Route path="queries" element={<Queries />} />
            <Route path="assets" element={<Assets />} />
            <Route path="exceptions" element={<ExceptionsWrapper />} />
          </Route>
        </Route>
        <Route path="checks">
          <Route
            index
            element={
              <FirewatchPage
                {...{ space, pageType: AggregateScoreType.Check }}
              />
            }
          />
        </Route>
        <Route
          path="check"
          element={
            <Check availablePermissions={availablePermissions} {...{ space }} />
          }
          handle={{
            crumb: () => <Link to="/Check">Check</Link>,
          }}
        />
      </Route>
    </Routes>
  );
}
