export const sharedColorBlindPalette = {
  primary: {
    main: "#6E45E2",
    light: "#987bea",
    gradient: "linear-gradient(to bottom right, #6E45E2 0%, #A22E95 100%)",
  },
  secondary: {
    main: "#A22E95",
    light: "#D85FCB",
  },
  error: {
    main: "#FB6000",
    light: "#FF873D",
    gradient: "linear-gradient(90deg, #FF873D 0%, #FB6000 100%)",
  },
  warning: {
    main: "#FBED53",
    lighter: "#FFF79F",
    gradient: "linear-gradient(90deg, #FFF79F 0%, #FBED53 100%)",
  },
  critical: {
    main: "#FF8024",
    lighter: "#FFA361",
    gradient: "linear-gradient(90deg, #FFA361 0%,#FF8024  100%)",
  },
  high: {
    main: "#CD8362",
    lighter: "#D9A087",
    gradient: "linear-gradient(90deg, #D9A087 0%,#CD8362  100%)",
  },
  medium: {
    main: "#AC8589",
    lighter: "#C1A4A7",
    gradient: "linear-gradient(90deg, #C1A4A7 0%,#AC8589  100%)",
  },
  low: {
    main: "#8388BC",
    lighter: "#A6A9CF",
    gradient: "linear-gradient(90deg,#A6A9CF  0%, #8388BC 100%)",
  },
  // none and good are the same for CVE purposes; none will be treated differently
  // when looking at general-purpose reports
  none: {
    main: "#518AF8",
    lighter: "#84ADFA",
    gradient: "linear-gradient(90deg, #84ADFA 0%, #518AF8 100%)",
  },
  unknown: {
    main: "#BBC3C3",
    lighter: "#D6DBDB",
    gradient: "linear-gradient(90deg, #D6DBDB 0%, #BBC3C3 100%)",
  },
  errorScan: {
    main: "#F4DC00",
    lighter: "#FFED4D",
    gradient: "linear-gradient(90deg, #F4DC00 0%, #FFED4D 100%)",
  },
  // score rating
  fail: {
    main: "#FF8024",
    lighter: "#FFA361",
    gradient: "linear-gradient(90deg, #FFA361 0%,#FF8024  100%)",
  },
  poor: {
    main: "#CD8362",
    lighter: "#D9A087",
    gradient: "linear-gradient(90deg, #D9A087 0%,#CD8362  100%)",
  },
  fair: {
    main: "#AC8589",
    lighter: "#C1A4A7",
    gradient: "linear-gradient(90deg, #C1A4A7 0%,#AC8589  100%)",
  },
  good: {
    main: "#8388BC",
    lighter: "#A6A9CF",
    gradient: "linear-gradient(90deg,#A6A9CF  0%, #8388BC 100%)",
  },
  excellent: {
    main: "#518AF8",
    lighter: "#84ADFA",
    gradient: "linear-gradient(90deg, #84ADFA 0%, #518AF8 100%)",
  },
  unrated: {
    main: "#BBC3C3",
    lighter: "#D6DBDB",
    gradient: "linear-gradient(90deg, #D6DBDB 0%, #BBC3C3 100%)",
  },
};
