import {
  Link as RouterLink,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { ArrowBackIcon, ArrowForwardIcon, PrintIcon } from "~/components/icons";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { PolicyQueryAssessment } from "~/pages/inventory/asset/types";
import { Space } from "~/lib/types";
import { ControlResultIcon } from "~/components/control-result-icon";
import { Flex, PropertyItem, ShareButton } from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { LoadingFailed } from "~/components/loading";
import { AffectedResources } from "./affected-resources/affected-resources";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { Code } from "~/components/code";
import { MqueryState } from "~/operations";
import { ImpactResult } from "~/components/impact";
import { getQueryStatus } from "../components/Checks";

type ActionableResultsOutletContext = {
  assessment: PolicyQueryAssessment;
  space: Space;
};

type Props = {
  space: Space;
};

export function ActionableResults({ space }: Props) {
  const { assetReport, asset } = useAssetOutlet();
  const [searchParams] = useSearchParams();
  const { queries: checks } = assetReport || {};
  const checkMrn = searchParams.get("checkMrn");

  const check = checks?.edges.find((check) => {
    return check.node.mquery.mrn === checkMrn;
  });

  if (!check) {
    return (
      <Flex center sx={{ py: 10 }}>
        <LoadingFailed what="check" />
      </Flex>
    );
  }

  const { node } = check || {};
  const { mquery, mqueryState, assessment, action, score } = node || {};

  const isActive = mqueryState === MqueryState.Enabled;

  const policyLinks = node.policies?.map((policy) => {
    const name = policy.split("/").pop();
    return {
      name,
      href: `/space/registry/namespace/mondoohq/policies/${name}?spaceId=${space.id}`,
    };
  });

  const remediation = check.node.mquery.docs?.remediations;
  const hasRemediation =
    remediation?.__typename === "Remediations" &&
    remediation.entries.length > 0;

  const properties = check.node.mquery.properties;
  const hasProperties = properties && properties.length > 0;

  const buildPoliciesLink = () => {
    const policy = check.node.policies?.[0].split("/").pop();
    const isPrivate = check.node.policies?.[0].split("/")[3] === "spaces";

    let namespace = "namespace/mondoohq";
    if (isPrivate) {
      namespace = `namespace/${space.id}`;
    }

    return `/space/registry/${namespace}/policies/${policy}?spaceId=${space.id}&registryType=PROPERTIES`;
  };

  let status = getQueryStatus(node);

  const emailLink = `mailto:?subject=${encodeURIComponent(
    "Take a look at this policy check",
  )}&body=${encodeURIComponent(window.location.href)}`;

  return (
    <Box>
      <ScrollToTop />
      <Button
        component={RouterLink}
        to={`/space/inventory/${asset?.id}/checks?spaceId=${space.id}`}
        startIcon={<ArrowBackIcon />}
        sx={{ color: "primary.light", my: 3 }}
      >
        Back to all checks
      </Button>
      <Grid container gap={1.5}>
        <Grid item xs="auto" sx={{ display: "flex", placeItems: "center" }}>
          <ControlResultIcon status={status} />
        </Grid>
        <Grid item xs sx={{ display: "flex", placeItems: "center" }}>
          <Typography variant="h5" fontWeight={700}>
            {mquery.title}
          </Typography>
        </Grid>
        <Grid item xs={12} sm="auto" display="flex" justifyContent="end">
          <ShareButton emailLink={emailLink} />

          <IconButton
            size="large"
            href={`/print?what=check&assetMrn=${asset?.mrn}&checkMrn=${check.node.mquery.mrn}`}
            target="_blank"
          >
            <PrintIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {policyLinks && policyLinks.length > 0 && (
            <Typography variant="caption" color="text.secondary">
              Policies:{" "}
              {policyLinks?.map((policy, i) => (
                <Typography sx={{ display: "inline" }} key={policy.name}>
                  <Link
                    variant="caption"
                    component={RouterLink}
                    to={policy.href}
                    key={policy.name}
                    sx={{
                      color: "text.secondary",
                      textDecoration: "underline",
                    }}
                  >
                    {policy.name}
                  </Link>
                  {i < policyLinks.length - 1 ? ", " : ""}
                </Typography>
              ))}
            </Typography>
          )}
        </Grid>
        <Grid item xs>
          {check.node.mquery.docs?.desc && (
            <Markdown
              source={check.node.mquery.docs.desc}
              sx={{ fontSize: 14, p: 0, m: 0 }}
            />
          )}
        </Grid>
        <Grid item xs={12} sm="auto">
          <ImpactResult
            isActive={isActive}
            impact={mquery.impact?.value || 0}
            score={score}
          />
        </Grid>
      </Grid>
      {hasProperties && (
        <Grid container sx={{ mt: 5 }}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontWeight={700}
              sx={{ mb: 2, display: "inline-block" }}
            >
              Properties
            </Typography>
            {/* https://console.mondoo.com/space/registry/namespace/mondoohq/policies/amazon-web-services-operational-best-practices-for-nist-1800-25?spaceId=beautiful-wiles-231755&registryType=PROPERTIES */}
            <Button
              component={RouterLink}
              to={buildPoliciesLink()}
              variant="text"
              endIcon={<ArrowForwardIcon />}
              sx={{ color: "primary.light" }}
            >
              Edit Properties
            </Button>
          </Grid>
          <Grid item xs={12}>
            {check.node.mquery.properties?.map((property, index) => {
              return (
                <PropertyItem
                  key={property.uid}
                  index={index}
                  uid={property.uid}
                  mql={property.mql}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
      {assessment && (
        <AffectedResources assessment={assessment} space={space} />
      )}

      <Grid container alignItems="center" sx={{ mt: 5 }}>
        <Grid item sx={{ display: "flex", alignItems: "center" }}>
          <Box id="some-id">
            <Typography
              sx={{
                display: "inline-block",
                pr: 3,
                pb: 1,
                fontWeight: 700,
              }}
            >
              Query
            </Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Code className="javascript" copyButton>
            {check.node.mquery.mql}
          </Code>
        </Grid>
      </Grid>

      {hasRemediation && (
        <Grid container alignItems="center" sx={{ mt: 5 }}>
          <Grid item sx={{ display: "flex", alignItems: "center" }}>
            <Box id="some-id">
              <Typography
                sx={{
                  display: "inline-block",
                  pr: 3,
                  pb: 1,
                  fontWeight: 700,
                }}
              >
                Remediation
              </Typography>
            </Box>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            {remediation.entries.map((entry) => {
              return (
                <Markdown
                  key={entry.id}
                  source={entry.desc}
                  sx={{ fontSize: 14, p: 0, m: 0 }}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export function useActionableResultsOutletContext() {
  return useOutletContext<ActionableResultsOutletContext>();
}
