import { Box, Tooltip } from "@mui/material";
import { SpacePolicyReportQuery } from "~/operations";

type SpacePolicyReport = Extract<
  NonNullable<SpacePolicyReportQuery["spacePolicyReport"]>,
  { __typename: "SpacePolicyReport" }
>;
type AssetSummariesConnection = NonNullable<
  SpacePolicyReport["assetSummaries"]
>;
type AssetSummariesEdge = NonNullable<AssetSummariesConnection["edges"]>[0];
type AssetSummariesNode = NonNullable<AssetSummariesEdge["node"]>;
type Distribution = AssetSummariesNode["mqueryResultsDistribution"];

type Props = {
  distribution: Distribution;
};

type Colors = {
  status: "passed" | "failed" | "unknown" | "error";
  width: string;
  color: string; //theme colors
  total: number;
};

export function DistributionBarGraph({ distribution }: Props) {
  const { pass, fail, unknown, error } = distribution;
  const total = pass + fail + unknown + error;

  // Calculate the width percentage of each letter grade
  const getStop = (grade: number): number => {
    return Math.round((grade / total) * 100) || 0;
  };

  // determine the width of each grade and return all as an Array
  const fillColors = (): Colors[] => {
    const errorStop = getStop(distribution.error);
    const uStop = getStop(distribution.unknown);
    const fStop = getStop(distribution.fail);
    const aStop = getStop(distribution.pass);

    return [
      {
        status: "error",
        width: `${errorStop}%`,
        color: "error.main",
        total: distribution.error,
      }, // Errors
      {
        status: "unknown",
        width: `${uStop}%`,
        color: "unknown.main",
        total: distribution.unknown,
      }, // Unknown
      {
        status: "failed",
        width: `${fStop}%`,
        color: "fail.main",
        total: distribution.fail,
      }, // F
      {
        status: "passed",
        width: `${aStop}%`,
        color: "excellent.main",
        total: distribution.pass,
      }, // A
    ];
  };

  const sections = fillColors();

  return (
    <Box
      width={1}
      height={12}
      display="flex"
      sx={{
        backgroundColor: "background.default",
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      {sections.map((x) => (
        <Tooltip
          key={x.status}
          title={`${x.total} ${x.status}`}
          placement="top"
        >
          <Box width={x.width} sx={{ backgroundColor: x.color }} />
        </Tooltip>
      ))}
    </Box>
  );
}
