import { useEffect, useState } from "react";
import {
  isBefore,
  add,
  FormatDateYear,
  formatDistanceStrict,
} from "~/lib/date";
import { Asset, AssetReport } from "~/pages/inventory/asset/types";
import { MessageSeverity, Warning } from "~/components/user-warning";
import { EolStatus } from "~/operations";

type useWarningsProps = {
  asset: Asset;
  assetReport?: AssetReport | null;
};

export function useWarnings({ asset, assetReport }: useWarningsProps) {
  const [warnings, setWarnings] = useState<Warning[] | null>(null);
  const [isEndOfLife, setIsEndOfLife] = useState<boolean>(false);

  useEffect(() => {
    let warningsArray: Warning[] = [];
    if (assetReport?.analysis) {
      // calculate if the end-of-life date is within the next year
      const isWithinOneYear = (date: string) => {
        return isBefore(new Date(date), add(new Date(), { years: 1 }));
      };

      const { eol } = assetReport.analysis;
      if (eol && (isWithinOneYear(eol.date) || eol.status === EolStatus.Eol)) {
        setIsEndOfLife(true);
        const formattedEolDate = FormatDateYear(eol.date);
        const dayDiff = formatDistanceStrict(new Date(), new Date(eol.date), {
          unit: "day",
        });
        const formattedDays =
          eol.status === EolStatus.Eol ? `${dayDiff} ago` : dayDiff;
        const verb =
          eol.status === EolStatus.Eol ? "has reached" : "is scheduled";
        const severity =
          eol.status === EolStatus.Eol
            ? MessageSeverity.Error
            : MessageSeverity.Info;
        const msg = `This operating system release ${verb} end of life (EOL) on ${formattedEolDate} (${formattedDays})`;
        warningsArray.push({ severity, msg });
      }

      if (assetReport.score.message) {
        warningsArray.push({
          severity: MessageSeverity.Error,
          msg: assetReport.score.message,
        });
      }

      // Gather asset warnings from specific asset labels
      asset.labels.forEach((label) => {
        const isSsmConnectLabel = [
          "mondoo.app/ssm-connection",
          "mondoo.com/ssm-connection",
        ].includes(label.key);
        const isNotFoundValue = label.value === "Not Found";
        const isUnscoredAsset = assetReport.score.type === 16;
        const showSSMConnectMsg =
          isSsmConnectLabel && isNotFoundValue && isUnscoredAsset;
        if (showSSMConnectMsg) {
          warningsArray.push({
            severity: MessageSeverity.Error,
            msg: "No connection info found for asset",
          });
        }
      });

      setWarnings(warningsArray);
    }
  }, [asset.mrn]);

  return { warnings, isEndOfLife };
}
