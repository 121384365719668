import {
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Text from "../../typography/Text";
import QueriesTable from "../QueriesTable";
import { Obj } from "../../../types";
import { ExpandMoreIcon } from "~/components/icons";

type RegistryGroupItemProps = {
  isQuerypack?: boolean;
  group: Obj;
  groupQueries: Obj[];
  rowsPerGroup?: number;
  onNavigate: (value: any) => void;
};

const RegistryGroupItem = ({
  isQuerypack = false,
  group,
  groupQueries,
  rowsPerGroup,
  onNavigate,
}: RegistryGroupItemProps) => {
  return (
    <Box
      component={Paper}
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: theme.spacing(1),
        boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.5)",
      })}
    >
      <Accordion
        defaultExpanded
        sx={{
          backgroundColor: "background.light",
        }}
      >
        <AccordionSummary
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Text>{group.title}</Text>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {group.summary && (
            <Text secondary px={2} py={0}>
              {group.summary}
            </Text>
          )}
          <QueriesTable
            isQuerypack={isQuerypack}
            queries={groupQueries}
            onNavigate={onNavigate}
            rowsPerPage={rowsPerGroup}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default RegistryGroupItem;
