import {
  BarChartIcon,
  FormatDate,
  PolicyMrnToURIEncodedId,
} from "~/components/ui-library";
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Grid,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import { Space } from "~/lib/types";
import { usePolicies } from "./hooks/usePolicies";
import { PolicyReportSummariesEdge, SelectedPolicies } from "./types";
import { AccessTimeIcon, NavDrawerFleetIcon } from "~/components/icons";
import { getColor } from "~/lib/colors";
import { PolicyAction } from "~/operations";
import { PreviewIcon } from "~/components/icons/mondoo/preview";
import { PreviewFlag } from "~/components/flags/preview-flag";
import { pluralize } from "~/lib/pluralize";
import { BlockOutlined } from "@mui/icons-material";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";

type ListProps = {
  space: Space;
  selectedItems: SelectedPolicies;
  handleCheckAll: () => void;
  enabledPolicies: PolicyReportSummariesEdge[];
  filteredPolicies: PolicyReportSummariesEdge[];
  permissions: ReturnType<typeof usePolicies>["permissions"];
  sort: ReturnType<typeof usePolicies>["sort"];
  handle: ReturnType<typeof usePolicies>["handle"];
  state: ReturnType<typeof usePolicies>["state"];
};

type Header = {
  id: string;
  label: string;
  options?: {
    align?: "inherit" | "left" | "center" | "right" | "justify";
    colSpan?: number;
    pl?: number;
  };
};
const tableHeaders: Header[] = [
  { id: "POLICY", label: "Policy", options: { colSpan: 2, pl: 1.5 } },
  { id: "DISTRIBUTION", label: "Distribution", options: { colSpan: 2 } },
];

export function List({
  sort,
  space,
  permissions,
  selectedItems,
  handle,
  state,
  handleCheckAll,
  enabledPolicies,
  filteredPolicies,
}: ListProps) {
  return (
    <Fragment>
      <DataTable
        selectable={permissions.edit}
        selection={selectedItems}
        id="enabled-policies-list"
      >
        <TableHead>
          <TableRow>
            {permissions.edit && (
              <TableCell>
                <Checkbox
                  checked={selectedItems.length === enabledPolicies?.length}
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < enabledPolicies.length
                  }
                  onChange={handleCheckAll}
                />
              </TableCell>
            )}
            {tableHeaders.map((header: Header) => (
              <TableCell
                key={header.id}
                sortDirection={
                  sort.sort.field === header.id ? sort.sort.direction : false
                }
                align={header.options?.align}
                colSpan={header.options?.colSpan}
              >
                <TableSortLabel
                  onClick={() => handle.sortClick(header.id)}
                  direction={sort.sort.direction}
                  active={sort.sort.field === header.id}
                  sx={{ pl: header.options?.pl }}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {/* Enabled Policies List */}
        <TableBody>
          {filteredPolicies.sort(handle.sort).map((edge) => {
            if (!edge.node) return undefined;
            const { policy, assetGrades } = edge.node;

            const inPreviewMode = policy.action === PolicyAction.Ignore;

            const href = `/space/security/policies/${PolicyMrnToURIEncodedId(
              policy.mrn,
            )}/overview?spaceId=${space.id}`;
            const isSelected = selectedItems.find(
              (i) => i.policyMrn === edge?.node?.policy.mrn,
            );
            const className = isSelected ? "selected" : "";

            const { __typename, total, U, ...strippedAssetGrades } =
              assetGrades;

            // map the strippedAssetGrades so that X and U are merged into a single value as "-"
            const modifiedAssetGrades = Object.entries(strippedAssetGrades).map(
              ([key, value]) => {
                if (key === "X") {
                  return { key: "-", value: value + U.valueOf() };
                }
                return { key, value };
              },
            );

            // calculate which asset grade has the highest value
            let highestAssetGrade = modifiedAssetGrades.reduce(
              (prev, current) => {
                return prev.value > current.value ? prev : current;
              },
            );

            return (
              <TableRow
                key={policy.mrn}
                onClick={() => handle.navigate(href)}
                className={className + " " + "policy-row"}
                sx={{ opacity: inPreviewMode ? 0.4 : 1 }}
              >
                {permissions.edit && (
                  <TableCell>
                    <Checkbox
                      checked={Boolean(
                        selectedItems.find((i) => i.policyMrn === policy.mrn),
                      )}
                      onChange={(e) =>
                        handle.check(e, {
                          policyMrn: policy.mrn,
                          action: policy.action,
                        })
                      }
                      onClick={(e) => e.stopPropagation()}
                    />
                  </TableCell>
                )}
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {policyIcon(policy.name, "large")}
                  </Box>
                </TableCell>
                <TableCell sx={{ maxWidth: "1px!important", width: "100%" }}>
                  <Box>
                    <Box>
                      <Typography
                        fontWeight={700}
                        className="policy-name"
                        sx={{
                          mb: 1,
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {policy.name}
                      </Typography>
                    </Box>

                    <Grid
                      item
                      container
                      xs={12}
                      sx={{ columnGap: 3, rowGap: 0.5 }}
                    >
                      {inPreviewMode && (
                        <Grid
                          item
                          xs={12}
                          sm="auto"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <PreviewFlag />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="policy-asset-count"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: (theme) => theme.spacing(1.5),
                            color: "text.secondary",
                          }}
                        >
                          <NavDrawerFleetIcon fontSize="small" sx={{ mr: 1 }} />
                          {assetGrades.total} Assets
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm="auto"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="policy-last-updated"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: (theme) => theme.spacing(1.5),
                            color: "text.secondary",
                          }}
                        >
                          <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                          Last Update: {FormatDate(policy.updatedAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                <TableCell
                  className="policy-distributions"
                  sx={{
                    minWidth: {
                      md: 300,
                    },
                  }}
                >
                  <Grid container sx={{ columnSpacing: 1 }}>
                    {modifiedAssetGrades.map((grade) => {
                      const hasZeroValue = grade.value === 0;

                      return (
                        <Grid
                          key={grade.key}
                          item
                          xs={4}
                          md={3.5}
                          lg={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            rowGap: 0.5,
                            opacity: hasZeroValue ? 0.3 : 1,
                          }}
                          className="policy-distribution"
                        >
                          <Box
                            sx={(theme) => ({
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: theme.spacing(4),
                              height: theme.spacing(4),
                              p: 0.5,
                              borderRadius: 1,
                              border:
                                highestAssetGrade.value > 0 &&
                                highestAssetGrade.key === grade.key
                                  ? "1px solid"
                                  : "none",
                              borderColor: inPreviewMode
                                ? theme.palette.text.disabled
                                : getColor(theme, grade.key),
                              background: inPreviewMode
                                ? alpha(theme.palette.text.disabled, 0.1)
                                : alpha(getColor(theme, grade.key), 0.1),
                            })}
                          >
                            <Typography
                              sx={(theme) => ({
                                color: inPreviewMode
                                  ? theme.palette.text.disabled
                                  : getColor(theme, grade.key),
                                fontWeight: 700,
                              })}
                              className="policy-distribution-grade"
                            >
                              {grade.key}
                            </Typography>
                          </Box>
                          <Typography
                            sx={(theme) => ({
                              color: inPreviewMode
                                ? theme.palette.text.disabled
                                : getColor(theme, grade.key),
                              fontSize: theme.spacing(1.5),
                            })}
                          >
                            {grade.value}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </DataTable>

      {selectedItems.length > 0 && (
        <SelectionToolbar>
          <Typography>
            Selected {selectedItems.length} of {filteredPolicies.length}{" "}
            policies
          </Typography>
          {(state.hasSelectedBothEnabledAndPreviewedPolicies ||
            state.hasSelectedPreviewedPolicy) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handle.enablePolicies}
              startIcon={<BarChartIcon />}
            >
              Enable {pluralize("Policy", selectedItems.length)}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handle.disablePolicies}
            startIcon={<BlockOutlined />}
          >
            Disable {pluralize("Policy", selectedItems.length)}
          </Button>
          {(state.hasSelectedBothEnabledAndPreviewedPolicies ||
            state.hasSelectedEnabledPolicy) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handle.previewPolicies}
              startIcon={<PreviewIcon />}
            >
              Preview {pluralize("Policy", selectedItems.length)}
            </Button>
          )}
          <Button onClick={handle.unCheckAll}>Cancel</Button>
        </SelectionToolbar>
      )}
    </Fragment>
  );
}
