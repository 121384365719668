import { PolicyIndicatorProps } from "~/components/ui-library";
import { Maybe, PolicyAction } from "~/operations";

const getPolicyIndicatorState = (
  value?: Maybe<PolicyAction>,
): PolicyIndicatorProps["indicator"] => {
  switch (value) {
    case PolicyAction.Active:
      return "enabled";
    case PolicyAction.Ignore:
      return "preview";
    default:
      return "disabled";
  }
};

export default getPolicyIndicatorState;
