import { format, formatDistanceToNow, getYear } from "date-fns";

const thisYear = getYear(new Date());

// June 30, 8:50am
export const FormatDate = (str: string | null | undefined): string => {
  if (str == null) return "";
  if (str == "") return str;
  const year = getYear(new Date(str));
  if (year <= 1) return "Never";

  const m = new Date(str);
  if (getYear(m) < thisYear || getYear(m) > thisYear)
    return format(m, "MMMM dd, yyyy");
  return format(m, "MMMM dd, hh:mma");
};

// An hour ago
export const FormatRelativeDate = (str: string | null): string => {
  if (str == null) return "";
  if (str == "") return str;

  const year = getYear(new Date(str));
  if (year < 1) return "Never";

  return formatDistanceToNow(new Date(str), { addSuffix: true });
};

// 08:37:12
export const FormatTime = (str: string | null): string => {
  if (str == null) return "";
  if (str == "") return str;

  return format(new Date(str), "hh:mm:ssaaa");
};
