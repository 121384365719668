import React from "react";
import { AffectedAssetsTable } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsTable";
import { Space } from "~/lib/types";
import {
  SelectionProps,
  VulnerabilityScoreType,
} from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { useVulnerabilityAffectedAssets } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks/useVulnerabilityAffectedAssets";
import { Grid } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities";
import { Loading, LoadingFailed } from "~/components/loading";

type VulnerabilityAffectedAssetsAdapterProps = {
  vulnerabilityId: string;
  vulnerabilityScoreType: VulnerabilityScoreType;
  space: Space;
  selectionProps?: SelectionProps | undefined;
};

export const VulnerabilityAffectedAssetsAdapter = ({
  vulnerabilityId,
  space,
  selectionProps,
  vulnerabilityScoreType,
}: VulnerabilityAffectedAssetsAdapterProps) => {
  const {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount,
    pageInfo,
    fetchMore,
    error,
    loading,
    urlContextType,
  } = useVulnerabilityAffectedAssets({
    space,
    vulnerabilityId,
    vulnerabilityScoreType,
  });

  if (error) {
    return <LoadingFailed what="Affected Assets" />;
  }

  if (loading) {
    return <Loading what="Affected Assets" />;
  }

  return (
    <Grid id="affected-assets" item xs={12}>
      <SectionHeading heading="Affected Assets" />
      {(assets?.length || 0) > 0 && !error ? (
        <AffectedAssetsTable
          assets={assets}
          orderBy={orderBy}
          handleSortClick={handleSortClick}
          space={space}
          filteredTotalCount={filteredTotalCount}
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          selectionProps={selectionProps}
          urlContextType={urlContextType}
          contextId={vulnerabilityId}
        />
      ) : (
        <EmptySection
          id="affected-assets-list-empty"
          text={`There are currently no affected assets for this ${vulnerabilityScoreType}`}
        />
      )}
    </Grid>
  );
};
