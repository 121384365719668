import { alpha, Box, Button, Paper, Typography } from "@mui/material";
import { groupBy, partition } from "lodash";
import { Clamp } from "~/components/clamp/clamp";
import { KubernetesIcon } from "~/components/icons/mondoo/kubernetes";
import { OperatingSystemsIcon } from "~/components/icons/mondoo/operating-systems";
import { DomainsHostsIcon } from "~/components/icons/mondoo/domains-hosts";
import { NetworkDevicesIcon } from "~/components/icons/mondoo/network-devices";
import { SaasIcon } from "~/components/icons/mondoo/saas";
import { ContainersIcon } from "~/components/icons/mondoo/containers";
import { AzureColorIcon } from "~/components/icons/mondoo/azure-color";
import { OktaIcon } from "~/components/icons/mondoo/okta";
import { SlackColorIcon } from "~/components/icons/mondoo/slack-color";
import { MS365Icon } from "~/components/icons/mondoo/ms365-color";
import { GoogleWSColorIcon } from "~/components/icons/mondoo/google-workspace-color";
import { OCIColorIcon } from "~/components/icons/mondoo/oci-color";
import { GCPColorIcon } from "~/components/icons/mondoo/gcp-color";
import { AwsColorIcon } from "~/components/icons/mondoo/aws-color";
import { FolderOpenFilled } from "~/components/icons/mondoo/folder-open-filled";
import {
  GitHubIcon,
  InfrastructureAsCodeIcon,
  VMWareIcon,
} from "~/components/icons";
import { pluralize } from "~/lib/pluralize";
import { GitLabColorIcon } from "../icons/mondoo/gitlab-color";
import { TerraformColorIcon } from "../icons/mondoo/terraform-color";
import { LoadSpaceStatsQuery } from "~/operations";
import { useGroupingButton } from "./useGroupingButton";
import { AtlassianIcon } from "../icons/mondoo/atlassian";

type AssetGroupStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>["assetGroupStatistics"][0];

type Props = {
  group: AssetGroupStats;
  onClick?: () => void;
};

export function GroupingButton({ group, onClick }: Props) {
  const { selectedGroupingButton } = useGroupingButton();

  const getIcon = (type: string | null) => {
    if (!type) return <EmptyIcon />;

    const svgStyles = { fontSize: 40 };

    switch (type.toLowerCase()) {
      case "k8s":
        return <KubernetesIcon sx={svgStyles} />;
      case "atlassian":
        return <AtlassianIcon sx={svgStyles} />;
      case "aws":
        return <AwsColorIcon sx={svgStyles} />;
      case "gcp":
        return <GCPColorIcon sx={svgStyles} />;
      case "github":
        return <GitHubIcon sx={svgStyles} />;
      case "gitlab":
        return <GitLabColorIcon sx={svgStyles} />;
      case "azure":
        return <AzureColorIcon sx={svgStyles} />;
      case "vmware":
        return <VMWareIcon sx={svgStyles} />;
      case "os":
        return <OperatingSystemsIcon sx={svgStyles} />;
      case "network_devices":
        return <NetworkDevicesIcon sx={svgStyles} />;
      case "domains_and_hosts":
        return <DomainsHostsIcon sx={svgStyles} />;
      case "saas_services":
        return <SaasIcon sx={svgStyles} />;
      case "terraform":
        return <TerraformColorIcon sx={svgStyles} />;
      case "google-workspace":
        return <GoogleWSColorIcon sx={svgStyles} />;
      case "okta":
        return <OktaIcon sx={svgStyles} />;
      case "ms365":
        return <MS365Icon sx={svgStyles} />;
      case "slack":
        return <SlackColorIcon sx={svgStyles} />;
      case "oci":
        return <OCIColorIcon sx={svgStyles} />;
      case "container":
        return <ContainersIcon sx={svgStyles} />;
      case "iac":
        return <InfrastructureAsCodeIcon sx={svgStyles} />;
      default:
        return <FolderOpenFilled sx={svgStyles} />;
    }
  };

  // accept the content object and convert it into a single
  // comma separated string
  const formatContent = (statistics: AssetGroupStats["statistics"]) => {
    if (!statistics) return;

    if (group.groupType === "k8s") {
      const arr = Object.values(statistics)
        .filter((data) => data.count > 0)
        .filter(
          (data) =>
            data.type.assetType === "k8s.cluster" ||
            data.type.assetType === "_alias.k8s_object_",
        )
        .map((data) => {
          return `${data.count} ${pluralize(data.type.shortName, data.count)}`;
        });

      return arr.join(", ");
    }
    if (group.groupType === "aws") {
      // Split accounts and resources and get their total counts
      const [accounts, resources] = partition(
        Object.values(statistics)
          .filter((data) => data.count > 0)
          .filter((data) => data.type.assetType.startsWith("aws")),
        (data) => data.type.assetType === "aws.account",
      ).map((data) => data.reduce((t, c) => t + c.count, 0));

      // Format the string i.e. "1 account, 2 resources"
      const arr = [
        { count: accounts, title: "account" },
        { count: resources, title: "resource" },
      ]
        .flatMap((object) =>
          object.count > 0
            ? [`${object.count} ${pluralize(object.title, object.count)}`]
            : [],
        )
        .join(", ");

      return arr;
    }
    if (group.groupType === "iac") {
      const filteredStats = statistics.filter((data) => data.count > 0);
      return filteredStats.length + " assets";
    }
    if (group.groupType === "gcp") {
      // Filter out empty counts and non-gcp assets
      const filteredStats = statistics
        .filter((data) => data.count > 0)
        .filter((data) => data.type.assetType.startsWith("gcp"));

      // Split accounts, organizations, and resources and get their total counts
      const groups = groupBy(filteredStats, (data) => {
        switch (data.type.assetType) {
          case "gcp.organization":
            return "org";
          case "gcp.project":
            return "project";
          default:
            return "resource";
        }
      });

      // Format the string i.e. "1 organization, 2 projects, 3 resources"
      return Object.entries(groups)
        .map(([key, value]) => ({
          count: value.reduce((t, c) => t + c.count, 0),
          title: key,
        }))
        .flatMap((object) =>
          object.count > 0
            ? [`${object.count} ${pluralize(object.title, object.count)}`]
            : [],
        )
        .join(", ");
    }

    if (group.groupType === "azure") {
      const includedAssetTypes = ["azure.subscription", "azure.compute_vm"];
      const objs = Object.values(statistics).filter((data) => data.count > 0);
      const arr = objs
        .filter((data) => includedAssetTypes.includes(data.type.assetType))
        .map(
          (data) =>
            `${data.count} ${pluralize(data.type.shortName, data.count)}`,
        );
      const othersCount = objs
        .filter((data) => !includedAssetTypes.includes(data.type.assetType))
        .reduce((cur, data) => cur + data.count, 0);
      if (othersCount > 0) {
        arr.push(`${othersCount} ${pluralize("resource", othersCount)}`);
      }
      return arr.join(", ");
    }

    // collect all of the asset types in listAssetTypes so that we can filter
    // out what should not be shown in 'statistics'
    const valuesToDisplay = group.listsAssetTypes.map((x) => x.assetType);

    const arr = Object.values(statistics)
      .filter(
        (data) =>
          data.count > 0 && valuesToDisplay.includes(data.type.assetType),
      )
      .map((data) => {
        return `${data.count} ${pluralize(data.type.shortName, data.count)}`;
      });

    return arr.join(", ");
  };

  return (
    <Paper
      onClick={onClick}
      component={Button}
      disableRipple
      fullWidth
      sx={{
        p: 2,
        height: "100%",
        cursor: "pointer",
        justifyContent: "flex-start",
        background: (theme) =>
          Boolean(selectedGroupingButton === group.groupType)
            ? alpha(theme.palette.primary.main, 0.2)
            : "background.paper",
        opacity: Boolean(
          selectedGroupingButton && group.groupType !== selectedGroupingButton,
        )
          ? 0.4
          : 1,
        boxShadow: (theme) =>
          Boolean(selectedGroupingButton === group.groupType)
            ? `0px 0px 0px 1px ${theme.palette.primary.main}`
            : theme.shadows[1],
        transition: "all .35s ease-in-out",
        "&:hover, &:focus-visible": {
          background: (theme) => alpha(theme.palette.primary.main, 0.2),
          opacity: 1,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 2,
          }}
        >
          {getIcon(group?.groupType)}
        </Box>
        <Box sx={{ textAlign: "left" }}>
          <Typography fontWeight={700}>
            {formatType(group.groupType)}
          </Typography>
          <Clamp lines={2}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ textTransform: "uppercase" }}
            >
              {formatContent(group.statistics)}
            </Typography>
          </Clamp>
        </Box>
      </Box>
    </Paper>
  );
}

// accept the type of grouping and format it to match the mocks
// by using title casing
export const formatType = (type: AssetGroupStats["groupType"]) => {
  switch (type.toLowerCase()) {
    case "k8s":
      return "Kubernetes";
    case "atlassian":
      return "Atlassian";
    case "aws":
      return "AWS";
    case "gcp":
      return "GCP";
    case "azure":
      return "Azure";
    case "vmware":
      return "VMware";
    case "os":
      return "Operating Systems";
    case "github":
      return "GitHub";
    case "gitlab":
      return "GitLab";
    case "network_devices":
      return "Network Devices";
    case "domains_and_hosts":
      return "Domains and Hosts";
    case "saas_services":
      return "SaaS Services";
    case "terraform":
      return "Terraform";
    case "google-workspace":
      return "Google Workspace";
    case "slack":
      return "Slack";
    case "oci":
      return "OCI";
    case "okta":
      return "Okta";
    case "container":
      return "Containers";
    case "ms365":
      return "Microsoft 365";
    case "iac":
      return "Infrastructure as Code";
    default:
      // "other"
      return "Uncategorized Assets";
  }
};

// fallback incase we ever somehow send an unknown
// grouping type
const EmptyIcon = () => {
  return (
    <Box
      sx={{
        width: 40,
        height: 40,
        backgroundColor: "background.default",
        boxShadow: 2,
      }}
    />
  );
};
