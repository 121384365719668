import { styled } from "@mui/material/styles";
import { PrintPolicyReportSummary } from "./print-policy-report-summary";
import { PrintPolicyBox } from "./print-policy-report";
import { PrintPackages } from "./print-packages";
import { PrintAdvisories } from "./print-advisories";
import { PolicyEdge } from "./print-asset";

type PrintPolicyVulnReportProps = {
  edge: PolicyEdge;
  assetMrn: string;
};

export function PrintPolicyVulnReport({
  edge,
  assetMrn,
}: PrintPolicyVulnReportProps) {
  return (
    <PrintPolicyVulnBox id={edge.node.policy.mrn}>
      <PrintPolicyReportSummary edge={edge} />
      <PrintAdvisories assetMrn={assetMrn} />
      <PrintPackages assetMrn={assetMrn} hideEmpty />
    </PrintPolicyVulnBox>
  );
}

// PrintPolicyBox currently coming from print-policy-report
const PrintPolicyVulnBox = styled(PrintPolicyBox)`
  td {
    padding: 10px;
  }

  .MuiTableContainer-root {
    margin-bottom: ${(p) => p.theme.spacing(4)};
  }
`;
