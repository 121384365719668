import { RefObject, useEffect, useRef, useState } from "react";
import { useActionableResultsOutletContext } from "../actionable-results";
import { PolicyQueryAssessment } from "~/pages/inventory/asset/types";
import { useParams } from "react-router-dom";

type Props = {};

export function useAffectedResources() {
  const { assessment, space } = useActionableResultsOutletContext();
  const [codeWidth, setCodeWidth] = useState<number | null>(null);
  const containerRef = useRef<HTMLTableElement>(null);
  const { id, encodedCheckCodeId } = useParams();
  const deCodedCheckId = decodeURIComponent(encodedCheckCodeId || "");
  // the constant affected resource path
  // this maps to /space/inventory/<assetId>/checks/<checkId>
  const affectedResourcePath = `/space/inventory/${id}/checks/${deCodedCheckId}`;

  useEffect(() => {
    // adjust the width on mount
    adjustWidth(containerRef.current);

    //calculate the width of the codeblock again if resized
    window.addEventListener("resize", () => adjustWidth(containerRef.current));

    return () => {
      //remove the event listener on unmount
      window.removeEventListener("resize", () => {});
    };
  }, []);

  // filter out empty results
  const actuals = filterActuals(assessment);

  // determine if there is only one table to show
  const singleDisplay = actuals.length === 1;

  // adjust the width of the codeblock in the dropdown
  // so that it doesn't force width of parent container
  const adjustWidth = (current: RefObject<HTMLTableElement>["current"]) => {
    if (current) {
      setCodeWidth(current.offsetWidth - 32);
    }
  };

  const activeTab =
    window.location.pathname.split("/").pop() === "resource"
      ? "resource"
      : "list";

  // set the header text
  let header = "Affected resources";
  let pageSize = 10;
  if (singleDisplay) {
    let countText =
      actuals[0].numResources > pageSize
        ? `(${actuals[0].numResources} items)`
        : "";
    header = `Affected resources in ${actuals[0].resourceName} ${countText}`;
  }

  return {
    activeTab,
    actuals,
    affectedResourcePath,
    codeWidth,
    containerRef,
    header,
    singleDisplay,
    space,
  };
}

//filter out any of the results that don't have a resource name
//if a result does not have a resource name and <actual> content,
//it will not have any other data
export const filterActuals = (assessment: PolicyQueryAssessment) => {
  const actuals =
    assessment?.extendedResults?.filter((result) => {
      if (result.resourceName && result.actual) {
        return result;
      }
    }) || [];
  return actuals;
};
