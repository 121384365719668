import { ReactNode, useEffect, useState } from "react";
import {
  auth,
  checkActionCode,
  applyActionCode,
  sendPasswordResetEmail,
} from "~/login/firebase-provider";
import { CliComponent } from "./cliComponent";
import { waitForTyping } from "./animatedTyping";

type Props = {
  actionCode: string;
};

export function RecoverEmailComponent({ actionCode }: Props) {
  const [typings, setTypings] = useState<ReactNode[]>([]);

  async function handleRecoverEmail(actionCode: string) {
    await waitForTyping(setTypings, {
      id: "1",
      type: "command",
      prepend: "$",
      text: "mondoo recover-email --incognito",
      delay: 1000,
    });
    await waitForTyping(setTypings, {
      id: "2",
      type: "output",
      text: "→ searching for email",
    });

    try {
      // Confirm the action code is valid and email is returned.
      const info = await checkActionCode(auth, actionCode);
      const matchedEmail = info.data.email;
      if (!matchedEmail) {
        throw "Email failed to match";
      }

      await waitForTyping(setTypings, {
        id: "3",
        type: "output",
        text: `→ email matched: ${matchedEmail}`,
      });
      await waitForTyping(setTypings, {
        id: "4",
        type: "output",
        text: "→ recovering email",
      });
      await waitForTyping(setTypings, { id: "5", type: "progress", text: "" });

      try {
        // Apply the action code and send password reset
        await applyActionCode(auth, actionCode);
        await sendPasswordResetEmail(auth, matchedEmail);

        await waitForTyping(setTypings, {
          id: "6",
          type: "output",
          text: "✓ email recovered and password reset instructions sent.",
        });
        await waitForTyping(setTypings, {
          id: "7",
          type: "output",
          text: "Please follow password reset instructions to complete the email recovery process.",
          color: "hotpink",
        });
        // Done. User needs to follow password reset link that was sent to their email.
      } catch (error) {
        await waitForTyping(setTypings, {
          id: "8",
          type: "output",
          text: "𝗑 failed to recover email. The Mondoo team has been notified of this error.",
          color: "hotpink",
        });
      }
    } catch (error) {
      await waitForTyping(setTypings, {
        id: "9",
        type: "output",
        text: "𝗑 email failed to match. We are redirecting you now.",
        color: "hotpink",
      });
    }
  }

  useEffect(() => {
    handleRecoverEmail(actionCode);

    return () => {
      setTypings([]);
    };
  }, [actionCode]);

  return <CliComponent>{typings}</CliComponent>;
}
