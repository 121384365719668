import { MouseEventHandler, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Tooltip, Typography } from "@mui/material";

type GaugeProps = {
  radius: number;
  value: number;
  total: number;
  tooltip: string;
  fill?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  maxWidth?: number;
};

export function AnimatedGauge({
  radius,
  value,
  total,
  tooltip,
  onClick,
  maxWidth,
  fill = "#6E45E2",
}: GaugeProps) {
  const [percentage, setPercentage] = useState<number>(0);
  const [dashArray, setDashArray] = useState<number>(0);
  const [strokeColor, setStrokeColor] = useState<string>("transparent");

  useEffect(() => {
    const circumference = Math.PI * (radius * 2);
    const percent = calculatePercent();

    setDashArray(circumference);
    setPercentage(percent);
    setStrokeColor(percent <= 1 ? "transparent" : fill);
  }, [value]);

  const calculatePercent = () => {
    const percent = (value / total) * 100 || 0;
    return percent;
  };

  return (
    <StyledContainer onClick={onClick} value={value}>
      <StyledTypography>{value}</StyledTypography>
      <Tooltip title={tooltip} aria-label="Gauge summary" placement="top">
        <StyledSvg
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
          maxWidth={maxWidth}
        >
          <circle className="shadow" cx="50" cy="50" r={radius} />
          <circle
            className="value"
            cx="50"
            cy="50"
            r={radius}
            strokeDasharray={`${(percentage * dashArray) / 100} ${dashArray}`}
            stroke={strokeColor}
          />
        </StyledSvg>
      </Tooltip>
    </StyledContainer>
  );
}

const StyledContainer = styled("div")<{ value: number }>`
  position: relative;
  display: ${(props) => (props.value >= 0 ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.onClick === null ? "inherit" : "pointer")};
`;

const StyledTypography = styled(Typography)`
  position: absolute;
`;

const StyledSvg = styled("svg", {
  shouldForwardProp: (prop) => prop !== "maxWidth",
})<{ maxWidth?: number }>`
  width: 100%;
  height: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "100%")};
  transform: rotate(90deg);

  circle {
    fill: none;
    stroke-width: 8;
  }

  .shadow {
    stroke: ${(p) => p.theme.palette.background.light};
  }

  .value {
    stroke-linecap: round;
    transition: 0.4s linear;
  }
`;
