import { Box, TableCell } from "@mui/material";

export function RankCell({ id }: { id: number }) {
  return (
    <TableCell>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: (theme) => theme.spacing(3),
          height: (theme) => theme.spacing(3),
          borderRadius: "50%",
          backgroundColor: "background.lightest",
          fontSize: 12,
          fontWeight: 700,
        }}
      >
        {id}
      </Box>
    </TableCell>
  );
}
