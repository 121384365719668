import { ReactElement, ReactNode } from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import { LiveHelpIcon } from "~/components/icons";

const NumberDot = ({
  number,
  backgroundColor,
}: {
  number: number;
  backgroundColor?: string;
}) => {
  return (
    <Box
      sx={{
        mr: 2,
        width: (theme) => theme.spacing(4),
        height: (theme) => theme.spacing(4),
        minWidth: (theme) => theme.spacing(4),
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        background: backgroundColor
          ? backgroundColor
          : (theme) => theme.palette.primary.gradient,
      }}
    >
      <Typography sx={{ fontWeight: 700 }}>{number}</Typography>
    </Box>
  );
};

type CommandProps = {
  number: number;
  tooltip?: string | ReactElement;
  children: ReactNode;
  options?: {
    fontSize?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
    };
    dotColor?: string;
  };
};

export const Command = ({
  number,
  children,
  tooltip,
  options,
}: CommandProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <NumberDot
        number={number}
        {...(options?.dotColor ? { backgroundColor: options.dotColor } : {})}
      />
      <Typography
        variant="h5"
        component="p"
        fontWeight={700}
        sx={{
          fontSize: { xs: 16, sm: 24 },
          ...(options?.fontSize ? { fontSize: { ...options?.fontSize } } : {}),
        }}
      >
        {children}
      </Typography>
      {tooltip && (
        <Tooltip title={tooltip} tabIndex={0}>
          <LiveHelpIcon
            fontSize="small"
            sx={{ color: "secondary.main", ml: 1.5, mt: 0.5 }}
          />
        </Tooltip>
      )}
    </Box>
  );
};
