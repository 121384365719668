import { useState } from "react";
import {
  GetContentDocument,
  PolicyAction,
  useAssignPolicyMutation,
  useUnassignPolicyMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { Policy } from "../policies";
import {
  PolicyIndicatorAction,
  PolicyIndicatorProps,
} from "~/components/ui-library";

type IndicatorLoading = "enabled" | "disabled" | "preview" | null;

type UsePolicyProps = {
  policy: Policy;
  space: Space;
};

export function usePolicyIndicator({ policy, space }: UsePolicyProps) {
  const [policyIndicatorLoading, setPolicyIndicatorLoading] =
    useState<IndicatorLoading>(null);
  const [assignPolicyMutation] = useAssignPolicyMutation({
    refetchQueries: [GetContentDocument],
  });
  const [unassignPolicyMutation] = useUnassignPolicyMutation({
    refetchQueries: [GetContentDocument],
  });
  const assetMrn = space.mrn;

  const assignPolicy: PolicyIndicatorAction = async (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (policy.action === PolicyAction.Active) return;

    setPolicyIndicatorLoading("enabled");
    try {
      await assignPolicyMutation({
        variables: {
          input: {
            action: PolicyAction.Active,
            policyMrn: policy.mrn,
            assetMrn,
          },
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      setPolicyIndicatorLoading(null);
    }
  };

  const unassignPolicy: PolicyIndicatorAction = async (e) => {
    e?.stopPropagation();
    e?.preventDefault();

    if (policy.action !== PolicyAction.Active) return;

    setPolicyIndicatorLoading("disabled");
    try {
      await unassignPolicyMutation({
        variables: {
          input: {
            action: PolicyAction.Active,
            policyMrn: policy.mrn,
            assetMrn,
          },
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setPolicyIndicatorLoading(null);
    }
  };

  const togglePreviewPolicy: PolicyIndicatorAction = async (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setPolicyIndicatorLoading("preview");
    const isPreviewMode = policy.action === PolicyAction.Ignore;
    const policyMutation = isPreviewMode
      ? unassignPolicyMutation
      : assignPolicyMutation;
    const action = PolicyAction[isPreviewMode ? "Active" : "Ignore"];
    try {
      await policyMutation({
        variables: {
          input: {
            policyMrn: policy.mrn,
            action,
            assetMrn,
          },
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setPolicyIndicatorLoading(null);
    }
  };

  const policyIndicatorActions: PolicyIndicatorProps["actions"] = {
    enablePolicy: assignPolicy,
    disablePolicy: unassignPolicy,
    togglePreview: togglePreviewPolicy,
  };

  const policyIndicatorStatus = () => {
    if (policy.action === PolicyAction.Active) return "enabled";
    if (policy.action === PolicyAction.Ignore) return "preview";
    return "disabled";
  };

  return {
    assignPolicy,
    unassignPolicy,
    togglePreviewPolicy,
    policyIndicatorLoading,
    policyIndicatorStatus,
    policyIndicatorActions,
  };
}
