import { useState } from "react";
import {
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import {
  LoadOrganizationMembersDocument,
  LoadSpaceMembersDocument,
  useLoadOrganizationMembersQuery,
  useLoadSpaceMembersQuery,
  useRemoveOrganizationMembershipMutation,
  useRemoveSpaceMembershipMutation,
  useSetOrganizationMembershipMutation,
  useSetSpaceMembershipMutation,
} from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { IamActions } from "~/lib/iam";
import { Org, Space } from "~/lib/types";
import { Member, roleNames } from "./members-mgmt";
import {
  EditMemberActionMenu,
  EditMemberActionMenuProps,
} from "./edit-member-action-menu";
import {
  EditMemberRoleDialog,
  EditMemberRoleDialogProps,
} from "./edit-member-role-dialog";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { ClickableTableRow } from "~/components/report";
import { DataTable } from "~/components/data-table";
import { AddButton } from "~/components/add-button";
import { getError } from "~/lib/handle-error";

export type ResourceMembersProps = {
  space?: Space;
  org?: Org;
  availablePermissions: string[];
  handleAddMemberClick?: () => void;
};

type ResourceMembersState = {
  openEditMemberDialog: boolean;
  openRemoveMemberConfirmDialog: boolean;
  isRemovingMember: boolean;
  selectedItem?: Member;
};

export function ResourceMembers(props: ResourceMembersProps) {
  const { org, space, availablePermissions, handleAddMemberClick } = props;

  const spaceMrn = space?.mrn || "";
  const orgMrn = org?.mrn || "";

  const theme = useTheme();

  const [state, setState] = useState<ResourceMembersState>({
    openEditMemberDialog: false,
    openRemoveMemberConfirmDialog: false,
    isRemovingMember: false,
  });

  const mergeState = (params: Partial<ResourceMembersState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const [removeOrganizationMembership] =
    useRemoveOrganizationMembershipMutation();
  const [removeSpaceMembership] = useRemoveSpaceMembershipMutation();

  const removeMember = (userMrn: string) => {
    if (orgMrn) {
      return removeOrganizationMembership({
        variables: { input: { orgMrn, userMrn } },
        refetchQueries: [LoadOrganizationMembersDocument],
      });
    }
    if (spaceMrn) {
      return removeSpaceMembership({
        variables: { input: { spaceMrn, userMrn } },
        refetchQueries: [LoadSpaceMembersDocument],
      });
    }
  };

  const [setOrganizationMembership] = useSetOrganizationMembershipMutation();
  const [setSpaceMembership] = useSetSpaceMembershipMutation();

  const spaceMembersResult = useLoadSpaceMembersQuery({
    variables: {
      mrn: spaceMrn,
    },
    skip: !spaceMrn,
  });
  const orgMembersResult = useLoadOrganizationMembersQuery({
    variables: {
      mrn: orgMrn,
    },
    skip: !orgMrn,
  });

  const loading = spaceMembersResult.loading || orgMembersResult.loading;
  const error = spaceMembersResult.error || orgMembersResult.error;
  const members =
    spaceMembersResult.data?.space?.members ||
    orgMembersResult.data?.organization?.members;

  if (loading) {
    return <LoadingPage what="members" />;
  }

  if (error || !members) {
    return <LoadingFailedPage what="members" />;
  }

  const query = searchParams.get("query");
  const allMembers = members.edges?.flatMap((e) => e.node ?? []) || [];
  const filteredMembers = allMembers.filter((m) => {
    if (!query) return true;
    return m.user.name.toLowerCase().includes(query.toLowerCase());
  });

  const hasSetMembershipPermission =
    availablePermissions.includes(IamActions.CAPTAIN_SETSPACEMEMBERSHIP) ||
    availablePermissions.includes(IamActions.CAPTAIN_SETORGANIZATIONMEMBERSHIP);
  const hasRemoveMembershipPermission =
    availablePermissions.includes(IamActions.CAPTAIN_REMOVESPACEMEMBERSHIP) ||
    availablePermissions.includes(
      IamActions.CAPTAIN_REMOVEORGANIZATIONMEMBERSHIP,
    );
  const hasItemActionPermission =
    hasSetMembershipPermission || hasRemoveMembershipPermission;

  const handleOpenEditMember: EditMemberActionMenuProps["handleEditMember"] = (
    e,
    selectedItem,
  ) => {
    mergeState({
      openEditMemberDialog: true,
      selectedItem,
    });
    e.preventDefault();
  };

  const handleRemoveConfirmMember: EditMemberActionMenuProps["handleDeleteMember"] =
    (e, selectedItem) => {
      mergeState({
        openRemoveMemberConfirmDialog: true,
        selectedItem,
      });
      e.preventDefault();
    };

  const handleRemoveMemberCancel = () => {
    closeRemoveMemberConfirmDialog();
  };

  const handleRemoveMemberConfirm = async () => {
    try {
      mergeState({ isRemovingMember: true });
      const userMrn = state.selectedItem?.user.mrn;
      if (!userMrn) throw "Member user mrn is required";
      await removeMember(userMrn);
    } catch (e) {
      const errorMessage = getError(e);
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    } finally {
      mergeState({ isRemovingMember: false });
      closeRemoveMemberConfirmDialog();
    }
  };

  const closeRemoveMemberConfirmDialog = () => {
    mergeState({
      openRemoveMemberConfirmDialog: false,
      selectedItem: undefined,
    });
  };

  const closeEditMemberDialog = () => {
    mergeState({
      openEditMemberDialog: false,
      selectedItem: undefined,
    });
  };

  const handleEditMemberCancel: EditMemberRoleDialogProps["onCancel"] = () => {
    closeEditMemberDialog();
  };

  const handleEditMemberSave: EditMemberRoleDialogProps["onSave"] = async (
    roles,
  ) => {
    try {
      const { org, space } = props;
      if (!org && !space) throw "Org or space prop is required";
      const userMrn = state.selectedItem?.user.mrn;
      if (!userMrn) throw "Member user mrn is required";
      if (org) {
        await setOrganizationMembership({
          variables: { input: { orgMrn: org.mrn, userMrn, roles } },
          refetchQueries: [LoadOrganizationMembersDocument],
        });
      }
      if (space) {
        await setSpaceMembership({
          variables: { input: { spaceMrn: space.mrn, userMrn, roles } },
          refetchQueries: [LoadSpaceMembersDocument],
        });
      }
    } catch {
      enqueueSnackbar("Failed to edit member", {
        variant: "error",
      });
    } finally {
      closeEditMemberDialog();
    }
  };

  const handleFilterChange: TextFieldProps["onChange"] = (e) => {
    const query = e.target.value;
    if (query === "") {
      searchParams.delete("query");
    } else {
      searchParams.set("query", query);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <>
      {state.selectedItem && (
        <EditMemberRoleDialog
          open={state.openEditMemberDialog}
          member={state.selectedItem}
          onCancel={handleEditMemberCancel}
          onSave={handleEditMemberSave}
        />
      )}
      {state.selectedItem && (
        <DeleteConfirmDialog
          items={[state.selectedItem]}
          itemName={(item) => item.user.name}
          itemType="member"
          onClose={handleRemoveMemberCancel}
          onConfirm={handleRemoveMemberConfirm}
          isDeleting={state.isRemovingMember}
          open={state.openRemoveMemberConfirmDialog}
        />
      )}
      <Box sx={{ display: "flex", mb: 3 }}>
        <TextField
          placeholder="Filter members..."
          value={query}
          onChange={handleFilterChange}
          fullWidth
          InputProps={{
            sx: {
              backgroundColor: theme.palette.code.background,
            },
          }}
        />
        {handleAddMemberClick && (
          <Box sx={{ pl: 3, pt: 1 }}>
            <AddButton
              id="invite-member-button"
              onClick={handleAddMemberClick}
              aria-label="Invite Member"
            />
          </Box>
        )}
      </Box>
      <DataTable id="member-list">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell colSpan={2}>Roles</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredMembers.map((member) => {
            const { user, roles = [] } = member;
            return (
              <ClickableTableRow key={user?.mrn} className="member-list-item">
                <TableCell>{user?.name}</TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>{roleNames(roles).join(",")}</TableCell>
                <TableCell align="right">
                  {hasItemActionPermission && (
                    <div className="hideUntilHover">
                      {
                        <EditMemberActionMenu
                          member={member}
                          hasSetMembershipPermission={
                            hasSetMembershipPermission
                          }
                          hasRemoveMembershipPermission={
                            hasRemoveMembershipPermission
                          }
                          handleEditMember={handleOpenEditMember}
                          handleDeleteMember={handleRemoveConfirmMember}
                        />
                      }
                    </div>
                  )}
                </TableCell>
              </ClickableTableRow>
            );
          })}
        </TableBody>
      </DataTable>
    </>
  );
}
