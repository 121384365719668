import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Slider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ExpandMoreIcon, InternetExposed } from "~/components/icons";
import { LoadingPage } from "~/components/loading";
import {
  GetSpaceRiskFactorsDocument,
  RiskFactorAction,
  useGetSpaceRiskFactorsLazyQuery,
  useModifySpaceRiskFactorsMutation,
} from "~/operations";

const formatMagnitude = new Intl.NumberFormat("en-US", {
  signDisplay: "exceptZero",
}).format;

const idFromMrn = (mrn: string) =>
  mrn.split("//policy.api.mondoo.app/risks/")[1];

const fieldId = (mrn: string) => {
  const id = idFromMrn(mrn);
  return id.replaceAll(".", "");
};

const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const min = -100;
const max = 100;
const marks = range(min, max, 20).map((value) => ({ label: "", value }));

type RiskFactorItemInput = {
  enabled: boolean;
  magnitude: number;
  rawMagnitude: string;
  isToxic: boolean;
};

type RiskFactorsConfigurationInput = {
  [id: string]: RiskFactorItemInput;
};

export type RiskFactorsConfigurationProps = {
  spaceMrn: string;
};

export function RiskFactorsConfiguration({
  spaceMrn,
}: RiskFactorsConfigurationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [showAdvanced, setShowAdvanced] = useState(false);

  const [modifySpaceRiskFactors] = useModifySpaceRiskFactorsMutation({
    refetchQueries: [GetSpaceRiskFactorsDocument],
  });

  const [getSpaceRiskFactors, { data, loading }] =
    useGetSpaceRiskFactorsLazyQuery({
      variables: { spaceMrn },
      fetchPolicy: "network-only",
    });

  const riskFactors = data?.riskFactors.edges || [];

  const getDefaultValues = async () => {
    const { data } = await getSpaceRiskFactors();
    const formValues =
      data?.riskFactors.edges?.reduce<RiskFactorsConfigurationInput>(
        (acc, { mrn, action, magnitude }) => {
          const { value, isToxic } = magnitude;
          const rawMagnitude = formatMagnitude(value);
          acc[fieldId(mrn)] = {
            enabled: action === RiskFactorAction.Enable,
            magnitude: value,
            rawMagnitude,
            isToxic,
          };
          return acc;
        },
        {},
      ) || {};
    return formValues;
  };

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getFieldState,
    getValues,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm<RiskFactorsConfigurationInput>({
    mode: "onBlur",
    defaultValues: getDefaultValues,
  });

  // Disable "Reset to Defaults" button if every field matches the Mondoo defaults
  const isDefaults = riskFactors.every((rf) => {
    const { mrn } = rf;
    const { magnitude, isToxic, enabled } = getValues(`${fieldId(mrn)}`);
    const defaultEnabled = true;
    const defaultMagnitude = rf.defaultMagnitude.value;
    const defaultIsToxic = rf.defaultMagnitude.isToxic;
    return (
      enabled === defaultEnabled &&
      magnitude === defaultMagnitude &&
      isToxic === defaultIsToxic
    );
  });

  const onSubmit: SubmitHandler<RiskFactorsConfigurationInput> = async () => {
    try {
      const riskFactors = Object.entries(dirtyFields).map(([id, field]) => {
        const action = field?.enabled
          ? getValues(`${id}.enabled`)
            ? RiskFactorAction.Enable
            : RiskFactorAction.Disable
          : undefined;
        const value = field?.magnitude
          ? getValues(`${id}.magnitude`)
          : undefined;
        const isToxic = field?.isToxic ? getValues(`${id}.isToxic`) : undefined;
        const magnitude =
          value !== undefined || isToxic !== undefined
            ? {
                value: getValues(`${id}.magnitude`),
                isToxic: getValues(`${id}.isToxic`),
              }
            : undefined;
        return {
          mrn: "//policy.api.mondoo.app/risks/" + id,
          ...(action !== undefined && { action }),
          ...(magnitude !== undefined && { magnitude }),
        };
      });
      await modifySpaceRiskFactors({ variables: { spaceMrn, riskFactors } });
      reset(getValues());
      enqueueSnackbar("Successfully updated configuration", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar("Failed to update configuration", { variant: "error" });
    }
  };

  const onCancel = () => {
    reset();
  };

  const onResetToDefault = () => {
    riskFactors.forEach((rf) => {
      const { mrn } = rf;
      const { magnitude, isToxic, enabled } = getValues(`${fieldId(mrn)}`);
      const defaultEnabled = true;
      const defaultMagnitude = rf.defaultMagnitude.value;
      const defaultIsToxic = rf.defaultMagnitude.isToxic;
      if (enabled !== defaultEnabled) {
        setValue(`${fieldId(mrn)}.enabled`, defaultEnabled, {
          shouldDirty: true,
        });
      }
      if (magnitude !== defaultMagnitude) {
        setValue(`${fieldId(mrn)}.magnitude`, defaultMagnitude, {
          shouldDirty: true,
        });
        setValue(
          `${fieldId(mrn)}.rawMagnitude`,
          formatMagnitude(defaultMagnitude),
          { shouldDirty: true },
        );
      }
      if (isToxic !== defaultIsToxic) {
        setValue(`${fieldId(mrn)}.isToxic`, defaultIsToxic, {
          shouldDirty: true,
        });
      }
    });
  };

  return (
    <Box className="risk-factors-config" sx={{ p: 2 }}>
      <Box
        className="risk-factors-config-header"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box className="risk-factors-config-description">
          <Typography>
            Customize the weights for each of the risk factors in Mondoo.
          </Typography>
          <Typography color="warning.main" fontWeight={700}>
            WARNING: Adjusting these risk factors changes the scores on your
            assets.
          </Typography>
        </Box>
        <Box className="risk-factors-config-actions">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowAdvanced(!showAdvanced)}
          >
            {showAdvanced ? "Hide" : "Show"} Advanced Settings
          </Button>
        </Box>
      </Box>
      <Box className="risk-factors-config-content">
        {loading && <LoadingPage what="Risk Factors Configuration" />}
        {!loading && (
          <Box
            className="risk-factors-config-form"
            component="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Box
              className="risk-factors-config-form-actions"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 2,
                position: "sticky",
                top: 64,
                py: 2,
                bgcolor: "background.paper",
                borderBottom: "1px solid",
                borderColor: "divider",
                zIndex: 1,
              }}
            >
              <Button
                variant="text"
                disabled={isDefaults}
                onClick={onResetToDefault}
              >
                Reset to defaults
              </Button>
              <Button
                variant="outlined"
                color="primary"
                disabled={!isValid || !isDirty}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!isValid || !isDirty}
              >
                Save Changes
              </Button>
            </Box>
            <Box className="risk-factors-config-form-content" sx={{ pt: 2 }}>
              {/* TODO: how do we want to group risk factor settings? Maintain a list of risk factor mrns in the UI? */}
              {/* <Button variant="text" color="inherit" endIcon={<ExpandMoreIcon color="primary" />} sx={{ mb: 2 }}>Contextual Risks</Button> */}

              {riskFactors.map((rf) => (
                <Box
                  key={rf.mrn}
                  className="risk-factor-config-item"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: 2,
                    mb: 3,
                    borderLeft: "2px solid",
                    borderColor: getFieldState(fieldId(rf.mrn)).isDirty
                      ? "primary.main"
                      : "transparent",
                  }}
                >
                  <Box
                    className="risk-factor-config-item-toggle"
                    sx={{ display: "flex" }}
                  >
                    <Controller
                      name={`${fieldId(rf.mrn)}.enabled`}
                      control={control}
                      render={({ field }) => (
                        <Switch checked={field.value} {...field} />
                      )}
                    />
                  </Box>
                  <Box
                    className="risk-factor-config-item-info"
                    sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                  >
                    <Box
                      className="risk-factor-config-item-header"
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      {/* TODO: Risk Factor indicator icons */}
                      {/* <InternetExposed /> */}
                      <Typography
                        fontWeight={700}
                        color={
                          getValues(`${fieldId(rf.mrn)}.enabled`)
                            ? "text.primary"
                            : "text.secondary"
                        }
                      >
                        {rf.title}
                      </Typography>
                      {/* TODO: Risk Factor asset stats */}
                      {/* <Typography
                      fontWeight={700}
                      fontSize={14}
                      color="text.secondary"
                    >
                      1109 Assets
                    </Typography> */}
                    </Box>
                    <Box
                      className="risk-factor-config-item-description"
                      sx={{ flex: 1 }}
                    >
                      <Typography color="text.secondary" fontSize={14}>
                        {getValues(`${fieldId(rf.mrn)}.enabled`)
                          ? rf.docs?.active
                          : rf.docs?.inactive}
                      </Typography>
                    </Box>
                  </Box>
                  {getValues(`${fieldId(rf.mrn)}.enabled`) && (
                    <Box
                      className="risk-factor-config-item-adjuster"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        ml: "auto",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Typography
                          fontSize={14}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setValue(`${fieldId(rf.mrn)}.magnitude`, min, {
                              shouldDirty: true,
                            });
                            setValue(
                              `${fieldId(rf.mrn)}.rawMagnitude`,
                              formatMagnitude(min),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                        >
                          {formatMagnitude(min)}%
                        </Typography>
                        <Controller
                          name={`${fieldId(rf.mrn)}.magnitude`}
                          control={control}
                          render={({ field }) => (
                            <Slider
                              {...field}
                              min={min}
                              max={max}
                              marks={marks}
                              sx={{ width: "226px" }}
                              onChange={(e, v) => {
                                field.onChange(e, v);
                                setValue(
                                  `${fieldId(rf.mrn)}.rawMagnitude`,
                                  formatMagnitude(v as number),
                                );
                              }}
                            />
                          )}
                        />
                        <Typography
                          fontSize={14}
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setValue(`${fieldId(rf.mrn)}.magnitude`, max, {
                              shouldDirty: true,
                            });
                            setValue(
                              `${fieldId(rf.mrn)}.rawMagnitude`,
                              formatMagnitude(max),
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                        >
                          {formatMagnitude(max)}%
                        </Typography>
                      </Box>
                      <Controller
                        name={`${fieldId(rf.mrn)}.rawMagnitude`}
                        control={control}
                        rules={{
                          required: "Required",
                          validate: {
                            numberWithinRange: (v) => {
                              const n = parseInt(v);
                              const isNumber = !isNaN(n);
                              const numberWithinRange =
                                isNumber && n >= min && n <= max;
                              return (
                                numberWithinRange ||
                                `Must be a number between ${min} and ${max}`
                              );
                            },
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            size="small"
                            sx={{ width: "80px" }}
                            InputProps={{
                              sx: {
                                "&, .MuiTypography-root": { fontSize: 14 },
                              },
                              endAdornment: (
                                <InputAdornment position="end" children="%" />
                              ),
                            }}
                            onChange={(e) => {
                              field.onChange(e);
                              const magnitude = parseInt(e.target.value);
                              if (!isNaN(magnitude)) {
                                setValue(
                                  `${fieldId(rf.mrn)}.magnitude`,
                                  magnitude,
                                  { shouldDirty: true },
                                );
                              }
                            }}
                            error={Boolean(
                              errors[`${fieldId(rf.mrn)}`]?.rawMagnitude,
                            )}
                          />
                        )}
                      />
                      {showAdvanced && (
                        <FormControlLabel
                          control={
                            <Controller
                              name={`${fieldId(rf.mrn)}.isToxic`}
                              control={control}
                              render={({ field }) => (
                                <Checkbox
                                  checked={field.value}
                                  {...field}
                                  size="small"
                                />
                              )}
                            />
                          }
                          label="Critical Risk"
                          sx={{
                            mr: 0,
                            ".MuiFormControlLabel-label": {
                              fontSize: 14,
                              whiteSpace: "nowrap",
                            },
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
