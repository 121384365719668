import { TableCell } from "@mui/material";
import { Impact as ImpactType } from "~/operations";
import { ImpactResultUpdated } from "../impact/Updated/impact-result-updated";
import { Score } from "~/lib/types";

type ImpactCellProps = {
  impact: ImpactType;
  isActive: boolean;
  isRiskScore: boolean;
  score: Score;
  className?: string;
};

export function ImpactCell({
  impact,
  isActive,
  isRiskScore,
  score,
  className,
}: ImpactCellProps) {
  return (
    <TableCell className={className}>
      <ImpactResultUpdated
        impact={impact}
        isActive={isActive}
        isRiskScore={isRiskScore}
        score={score}
      />
    </TableCell>
  );
}
