import React from "react";
import { alpha, ButtonProps, TableCell } from "@mui/material";
import { Flex, LoadingButton } from "~/components/ui-library";

type CloseCaseButtonCellProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClick: ButtonProps["onClick"];
};

export const CloseCaseButtonCell = ({
  isLoading,
  isOpen,
  onClick,
}: CloseCaseButtonCellProps) => {
  return (
    <TableCell>
      <Flex flex="1 0 auto" justifyContent="flex-end" sx={{ pr: 3 }}>
        {
          <LoadingButton
            variant="outlined"
            loading={isLoading}
            color="primary"
            onClick={onClick}
            data-testid="close-case"
            buttonText={isOpen ? "Close Case" : "Closed"}
            sx={{
              background: (theme) =>
                isOpen
                  ? alpha(theme.palette.primary.main, 0.2)
                  : alpha(theme.palette.unknown.main, 0.2),
              borderColor: (theme) =>
                isOpen
                  ? theme.palette.primary.main
                  : theme.palette.text.disabled,
              color: isOpen ? "text.primary" : "text.disabled",
              visibility: "hidden",
              whiteSpace: "nowrap",
            }}
            disabled={!isOpen}
          />
        }
      </Flex>
    </TableCell>
  );
};
