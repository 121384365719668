import { Box, Tooltip, Typography, alpha } from "@mui/material";
import { useCvssRisk } from "./useCvssRisk";

export type CvssVectorProps = {
  id?: string;
  showTooltips?: boolean;
};

export function CvssVector({ id, showTooltips = false }: CvssVectorProps) {
  const risk = useCvssRisk();
  const isInteracting = Boolean(risk.focusedMetric);
  return (
    <Box
      id={id}
      className="cvss-vector"
      sx={{
        position: "relative",
        zIndex: 1,
        lineHeight: 1,
        cursor: "default",
      }}
    >
      {risk.metrics.map((metric, i) => {
        const highlighted = risk.isFocused(metric);
        return (
          <Tooltip
            key={metric.field.title}
            title={`${metric.field.title}: ${metric.value.title}`}
            placement="top"
            arrow
            open={showTooltips && risk.isFocused(metric)}
          >
            <Typography
              className={`metric ${highlighted ? "highlighted" : ""}`}
              component="span"
              textTransform="uppercase"
              fontWeight={600}
              fontSize={12}
              color={(theme) =>
                isInteracting
                  ? alpha(theme.palette.text.secondary, 0.5)
                  : "text.secondary"
              }
              lineHeight={1}
              onMouseEnter={() => risk.onMetricMouseEnter(metric)}
              onMouseLeave={() => risk.onMetricMouseLeave(metric)}
            >
              {i === 0 ? "" : " / "}
              <Box
                className="field"
                component="span"
                color={highlighted ? "text.primary" : "inherit"}
              >
                {metric.field.acronym}:
              </Box>
              <Box
                className="value"
                component="span"
                color={highlighted ? metric.value.color : "inherit"}
              >
                {metric.value.acronym}
              </Box>
            </Typography>
          </Tooltip>
        );
      })}
    </Box>
  );
}
