import { SyntheticEvent } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "~/components/loading-button";
import { MouseEvent, ReactElement, ReactNode } from "react";
import Flex from "../ui-library/components/layouts/Flex";
import { DeleteIcon } from "~/components/icons";

export interface ConfirmationDialogProps {
  isOpen: boolean;
  onConfirm:
    | ((e: MouseEvent<HTMLElement>) => void)
    | ((e: SyntheticEvent<HTMLButtonElement>) => void);
  onClose: (() => void) | ((e: SyntheticEvent<HTMLButtonElement>) => void);
  loading?: boolean;
  confirmButtonText?: string;
  confirmButtonIcon?: ReactNode;
  content: ReactElement;
  title?: string;
  maxWidth?: DialogProps["maxWidth"];
}

export const ConfirmationDialog = ({
  isOpen,
  onClose,
  loading = false,
  onConfirm,
  confirmButtonText = "Confirm",
  confirmButtonIcon = <DeleteIcon />,
  title = "Are you sure?",
  content,
  maxWidth = "md",
}: ConfirmationDialogProps) => {
  return (
    <Dialog onClose={onClose} open={isOpen} maxWidth={maxWidth} fullWidth>
      <DialogTitle
        component="div"
        data-test-id="exception-delete-confirm-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textTransform: "uppercase",
          px: 5,
          pt: 5,
          pb: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <Flex
        sx={{
          pb: 4,
        }}
      >
        <DialogContent
          sx={{
            px: 5,
          }}
        >
          <Grid
            container
            rowGap={3}
            sx={{
              "& .MuiTypography-root": {
                color: "text.secondary",
              },
            }}
          >
            {content}
          </Grid>
        </DialogContent>

        <DialogActions
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 2,
            px: 0,
            py: 0,
            pr: 5,
          }}
        >
          <LoadingButton
            loading={loading}
            buttonText={confirmButtonText}
            variant="outlined"
            color="critical"
            startIcon={confirmButtonIcon}
            onClick={onConfirm}
            disabled={loading}
            data-name="danger-zone-delete-button"
          />

          <Button
            color="primary"
            variant="outlined"
            sx={{ m: "0 !important", width: "100%" }}
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        </DialogActions>
      </Flex>
    </Dialog>
  );
};
