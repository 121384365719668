import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { useAuth } from "~/login/provider";
import { selectedApiEndpoint } from "./viewer";
import { AuthContextTypes } from "~/login/auth-context";

export type GraphqlContextTypes =
  | { client: ApolloClient<NormalizedCacheObject> }
  | undefined;

export const GraphqlContext = createContext<GraphqlContextTypes>(undefined);

export type GraphqlProviderProps = { children: ReactNode };

export function GraphqlProvider({ children }: GraphqlProviderProps) {
  const auth = useAuth();
  const [client, setClient] = useState<
    ApolloClient<NormalizedCacheObject> | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      const { user } = auth.state;
      if (user) {
        const token = await user.getIdToken(false);
        const refreshToken = () => user.getIdToken(true);
        const client = initApolloClient({ token, refreshToken, auth });
        setClient(client);
      }
    })();
  }, []);

  return (
    <GraphqlContext.Provider value={client ? { client } : undefined}>
      {client && <ApolloProvider client={client}>{children}</ApolloProvider>}
    </GraphqlContext.Provider>
  );
}

export function useGraphql() {
  const context = useContext(GraphqlContext);
  if (context === undefined) {
    throw new Error("useGraphql must be used within a GraphqlProvider");
  }
  return context;
}

export type GraphqlOptions = {
  token: string;
  refreshToken: () => Promise<string>;
  auth: AuthContextTypes;
};

function initApolloClient({ token, refreshToken, auth }: GraphqlOptions) {
  const httpLink = new HttpLink({
    uri: selectedApiEndpoint() + "/query",
  });

  let bearerToken = token;

  const retryLink = new RetryLink({
    delay: {
      initial: 0,
    },
    attempts: {
      max: 2,
      retryIf: (error) => {
        if (error && (error as ServerError).statusCode !== 401) {
          return false;
        }

        return new Promise((resolve, reject) => {
          if (!refreshToken) {
            return reject(
              "graphql> cannot get the global user token refresh function",
            );
          }

          return refreshToken()
            .then((token) => {
              bearerToken = token;
              resolve(true);
            })
            .catch(() => {
              resolve(false);
            });
        });
      },
    },
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: bearerToken != null ? `Bearer ${bearerToken}` : "",
      },
    };
  });

  const errorLink = onError(({ networkError }) => {
    if (networkError && (networkError as ServerError).statusCode === 401) {
      // retryLink attempts have failed if 401 has reached errorLink
      auth?.logout();
    }

    if (networkError && (networkError as ServerError).statusCode === 451) {
      auth?.setGeolock(true);
    }
  });

  // Add extra tracing metadata to request headers
  const traceLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "X-Console-Url": window.location.pathname,
      },
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          advisories: relayStylePagination(["orderBy", "query", "platform"]),
          auditlog: relayStylePagination(["resourceMrn"]),
          assets: relayStylePagination([
            "spaceMrn",
            "queryTerms",
            "platformKind",
            "platformName",
            "reboot",
            "labels",
            "groups",
            "assetTypes",
            "scoreRange",
          ]),
          assetSearch: relayStylePagination(["input", "orderBy"]),
          aggregateScores: relayStylePagination([
            "entityMrn",
            "filter",
            "orderBy",
            ["scoreType", "findingMrn"],
          ]),
          complianceFramework: {
            merge: true,
          },
          cves: relayStylePagination(["orderBy", "query", "state"]),
          serviceAccounts: relayStylePagination(["spaceMrn", "queryTerms"]),
          agents: relayStylePagination([
            "spaceMrn",
            "queryTerms",
            "version",
            "state",
          ]),
          library: relayStylePagination([
            "spaceMrn",
            "severity",
            "type",
            "query",
            "orderBy",
          ]),
          registrationTokens: relayStylePagination(["spaceMrn"]),
          mqueryAssetScores: relayStylePagination(["input"]),
          assetReport: {
            merge: true,
          },
          search: relayStylePagination([
            "scope",
            "query",
            "orderBy",
            "type",
            "filters",
          ]),
          packageScores: relayStylePagination(["input", "orderBy"]),
          vulnerabilityScores: relayStylePagination([
            "orderBy",
            "filter",
            "entityMrn",
          ]),
          checkScores: relayStylePagination(["orderBy", "filter", "entityMrn"]),
          cases: relayStylePagination(["input", "orderBy"]),
        },
      },
      Case: {
        keyFields: ["mrn"],
      },
      VulnerabilityScore: {
        keyFields: ["mrn", "asset"],
      },
      CheckScore: {
        keyFields: ["mrn", "asset"],
      },
      ComplianceControl: {
        keyFields: ["mrn"],
        merge: true,
        fields: {
          checks: relayStylePagination(),
          dataQueries: relayStylePagination(),
        },
      },
      ServiceAccount: {
        keyFields: ["mrn"],
      },
      RegistrationToken: {
        keyFields: ["mrn"],
      },
      // We may want to remove this and just let graphql infer a key
      Integration: {
        keyFields: ["mrn", "name"],
      },
      Space: {
        keyFields: ["mrn"],
        fields: {
          stats: {
            merge: true,
          },
        },
      },
      Organization: {
        keyFields: ["mrn"],
        fields: {
          spacesList: relayStylePagination(["mrn", "name"]),
        },
      },
      User: {
        keyFields: ["mrn"],
      },
      ProductInfo: {
        keyFields: ["id", "name"],
      },
      Asset: {
        keyFields: ["mrn"],
        fields: {
          platform: {
            merge: true,
          },
          report: {
            merge: true,
          },
          score: {
            merge: false,
          },
        },
      },
      MqueryRemediation: {
        keyFields: ["id", "desc"],
      },
      AssetReport: {
        merge: true,
        fields: {
          checks: relayStylePagination([
            "assetMrn",
            "orderBy",
            "filter",
            "impact",
            "policyMrns",
          ]),
        },
      },
      CicdProjectJobs: {
        fields: {
          jobs: relayStylePagination(),
        },
      },
      Report: {
        fields: {
          cves: relayStylePagination(["assetMrn"]),
          packages: relayStylePagination(["assetMrn"]),
          advisories: relayStylePagination(["assetMrn"]),
          stats: {
            merge: true,
          },
        },
      },
      Agent: {
        keyFields: ["mrn"],
      },
      AssetReportQuery: {
        keyFields: ["mquery"],
      },
      Invitation: {
        keyFields: ["mrn"],
      },
      Policy: {
        keyFields: ["mrn"],
      },
      PolicyReport: {
        keyFields: ["mrn"],
      },
      SpaceReport: {
        keyFields: ["spaceMrn"],
        merge: true,
        fields: {
          policyReportSummaries: {
            merge: true,
          },
        },
      },
      Score: {
        keyFields: false,
      },
    },
    possibleTypes: {
      LibraryNode: ["Advisory", "Cve"],
    },
  });

  const client = new ApolloClient({
    link: from([errorLink, retryLink, authLink, traceLink, httpLink]),
    cache,
    connectToDevTools: true,
  });

  return client;
}
