import { useState, useEffect } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { SnackbarKey, useSnackbar } from "notistack";
import {
  Grid,
  Typography,
  IconButton,
  Link,
  Tabs,
  Tab,
  Breadcrumbs,
  TabsProps,
  Box,
} from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { CloseIcon, HomeIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { SpacePagesIntegrations } from "../../../routes";
import { IndividualAccountsTab } from "~/components/integration-individual-accounts-tab";
import { AWSOverviewAccountTab } from "~/components/aws-overview-account-tab";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationQuery,
  GetIntegrationsSummaryDocument,
  TestIamActionsQuery,
  useDeleteClientIntegrationMutation,
  useGetClientIntegrationQuery,
  useGetClientIntegrationTokenQuery,
  useListDiscoveryResultsQuery,
  usePingIntegrationLazyQuery,
  useTriggerActionLazyQuery,
} from "~/operations";
import { AwsAccountHeader } from "./aws-account-header";
import { DeleteConfirmDialog } from "~/components/delete-confirm-dialog";
import { AwsIntegrationAlert } from "./aws-integration-alert";
import { RecommendedPolicies } from "../../components/recommended-policies";

export type ClientIntegration = GetClientIntegrationQuery["clientIntegration"];
export type Integration = ClientIntegration["integration"];
export type ConfigurationOptions = NonNullable<
  Integration["configurationOptions"]
>;
export type AwsConfigurationOptions = Extract<
  ConfigurationOptions,
  { __typename: "AWSConfigurationOptions" }
>;
export type AwsIntegration = Integration & {
  configurationOptions: AwsConfigurationOptions;
  token: string | undefined;
};

export type AWSIntegrationStatus =
  | "ACTIVE"
  | "CONFIGURING"
  | "REGISTERING"
  | "WAITING_FOR_SETUP"
  | "SETUP_IN_PROGRESS"
  | "NOT_READY"
  | "ERROR"
  | "MISSING"
  | "DELETED"
  | "WARNING";

export type AwsIntegrationServerlessPageProps = {
  space?: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type AwsIntegrationServerlessPageState = {
  currentTab: string;
  agent: any | null;
  openDeleteConfirmDialog: boolean;
  scanConfigForm: any;
  isDeleting: boolean;
  lastUpdate: number;
};

export function AwsIntegrationServerlessPage(
  props: AwsIntegrationServerlessPageProps,
) {
  const navigate = useNavigate();
  const { integrationId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const defaultTab = "overview";
  const tab = window.location.pathname.split("/").pop() || defaultTab;
  const currentTab = [
    "overview",
    "individual-accounts",
    "policies",
    "configuration",
  ].includes(tab)
    ? tab
    : defaultTab;

  const [state, setState] = useState<AwsIntegrationServerlessPageState>({
    currentTab,
    agent: null,
    openDeleteConfirmDialog: false,
    scanConfigForm: undefined,
    isDeleting: false,
    lastUpdate: Date.now(),
  });

  function mergeState(params: any) {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  }

  const { space, availablePermissions } = props;
  const mrn = `//integration.api.mondoo.app/spaces/${space?.id}/aws/${integrationId}`;

  const hasIntegrationsGetPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_GET,
  );
  const hasIntegrationsDiscoveryPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_LISTDISCOVERYRESULTS,
  );
  const hasIntegrationsTokenPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_GETTOKENFORINTEGRATION,
  );

  const hasPolicyAssignPermission = [
    IamActions.POLICY_DISTRIBUTOR_ASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
  ].some((permission) => availablePermissions?.includes(permission));

  const clientIntegrationResult = useGetClientIntegrationQuery({
    variables: { mrn },
    pollInterval: 10000,
    fetchPolicy: "network-only",
    skip: !hasIntegrationsGetPermission,
  });

  const listDiscoveryResultsResult = useListDiscoveryResultsQuery({
    variables: { input: { mrn } },
    pollInterval: 10000,
    fetchPolicy: "network-only",
    skip: !hasIntegrationsDiscoveryPermission,
  });

  const clientIntegrationTokenResult = useGetClientIntegrationTokenQuery({
    variables: { input: { mrn, longLivedToken: false } },
    skip: !hasIntegrationsTokenPermission,
  });

  const [pingClientIntegration] = usePingIntegrationLazyQuery({
    variables: { input: { mrn } },
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.RunScan } },
    fetchPolicy: "no-cache",
  });

  const [triggerClientIntegrationRetrySetup] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.RetrySetup } },
  });
  const [triggerForceLambdaUpdate] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.Update } },
  });
  const [triggerSendLambdaMetrics] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.Metrics } },
  });
  const [triggerSendDiagnosticsData] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.Diagnostics } },
  });
  const [triggerCancelScans] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.ClearScanQueue } },
  });

  const [deleteClientIntegration] = useDeleteClientIntegrationMutation({
    refetchQueries: [GetIntegrationsSummaryDocument],
  });

  useEffect(() => {
    const integration =
      clientIntegrationResult.data?.clientIntegration.integration;
    if (
      integration?.configurationOptions?.__typename !==
      "AWSConfigurationOptions"
    ) {
      return;
    }
    const { scanConfiguration } = integration.configurationOptions;
    const { ec2ScanOptions, eventScanTriggers } = scanConfiguration;
    mergeState({
      scanConfigForm: {
        region: integration.configurationOptions.region,
        ...scanConfiguration,
        ec2ScanOptions: {
          ...ec2ScanOptions,
          instanceIdsFilter: ec2ScanOptions.instanceIdsFilter?.join(", ") || "",
          regionsFilter: ec2ScanOptions.regionsFilter?.join(", ") || "",
          tagsFilter: JSON.stringify(ec2ScanOptions.tagsFilter),
          ebsScanOptions: {
            ...ec2ScanOptions.ebsScanOptions,
          },
        },
        eventScanTriggersString: eventScanTriggers?.map((trigger: any) => {
          const { scanType, eventSource, eventDetailType } = trigger;
          return [scanType.toLowerCase(), eventSource, eventDetailType].join(
            ":",
          );
        }),
      },
    });
  }, [clientIntegrationResult.data]);

  if (
    clientIntegrationResult.loading ||
    listDiscoveryResultsResult.loading ||
    clientIntegrationTokenResult.loading
  ) {
    return <LoadingPage what="Integration" />;
  }

  const integration =
    clientIntegrationResult.data?.clientIntegration.integration;
  const token =
    clientIntegrationTokenResult.data?.getClientIntegrationToken.token;
  const discoveryResults =
    listDiscoveryResultsResult.data?.listDiscoveryResults;

  const { scanConfigForm } = state;

  if (
    !space ||
    !integrationId ||
    !integration ||
    !scanConfigForm ||
    mrn !== integration.mrn ||
    integration.type !== ClientIntegrationType.Aws ||
    integration.configurationOptions?.__typename !== "AWSConfigurationOptions"
  ) {
    return <LoadingFailedPage what="Integration" />;
  }

  const awsIntegration = { ...integration, token } as AwsIntegration;
  const configurationOptions = awsIntegration.configurationOptions;

  const discoveredAssets =
    discoveryResults?.stats
      ?.flatMap((asset) => (asset ? [asset] : []))
      .sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }) || [];

  const deleteIntegrationBegin = () => {
    mergeState({ openDeleteConfirmDialog: true });
  };

  const handleDeleteAgentClose = () => {
    mergeState({ isDeleting: false, openDeleteConfirmDialog: false });
  };

  const handleDeleteAgentConfirm = (): void => {
    mergeState({ isDeleting: true });

    const onRemoveSuccess = () => {
      const region = configurationOptions?.region;
      const url = `https://${region}.console.aws.amazon.com/cloudformation/home?region=${region}#/stacks`;
      const msg = (
        <span>
          Successfully removed AWS account. The AWS CloudFormation stack is
          being deleted.
          <br />
          <a
            style={{ textDecoration: "underline" }}
            href={url}
            target="_blank"
            rel="noreferrer noopener"
          >
            Navigate to the AWS Console
          </a>{" "}
          to confirm that the deletion completed.
        </span>
      );
      const action = (key: SnackbarKey) => (
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => closeSnackbar(key)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      );

      // Display success msg
      enqueueSnackbar(msg, {
        persist: true,
        variant: "success",
        action,
      });

      // Nav back to list view
      navigate(`/space/${SpacePagesIntegrations}/aws?spaceId=${space.id}`);
    };

    const onRemoveError = () => {
      mergeState({ isDeleting: false });
      const msg = "Failed to remove AWS account.";
      enqueueSnackbar(msg, { variant: "error" });
    };

    Promise.all(
      [awsIntegration].map((item) =>
        deleteClientIntegration({ variables: { input: { mrn: item.mrn } } }),
      ),
    )
      .then(onRemoveSuccess)
      .catch(onRemoveError);
  };

  const tabs = ["overview"];
  const tabNames = ["Overview"];

  if (configurationOptions?.isOrganization) {
    tabs.push("individual-accounts");
    tabNames.push("Individual Accounts");
  }

  if (hasPolicyAssignPermission) {
    tabs.push("policies");
    tabNames.push("Recommended Policies");
  }

  const handleTabChange: TabsProps["onChange"] = (e, value) => {
    const currentTab = tabs[value];
    const newUrl = `/space/${SpacePagesIntegrations}/aws/${integrationId}/${currentTab}?spaceId=${space?.id}`;
    navigate(newUrl);
  };

  const retrySetup = () => {
    const onRetrySuccess = () => {
      const msg = "AWS setup is being retried.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onRetryError = () => {
      const msg = "Failed to retry AWS setup.";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    triggerClientIntegrationRetrySetup()
      .then(onRetrySuccess)
      .catch(onRetryError);
  };

  const triggerScan = () => {
    const onTriggerSuccess = () => {
      const msg = "Successfully triggered scan.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onTriggerError = () => {
      const msg = "Failed to trigger scan.";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    triggerClientIntegrationScan().then(onTriggerSuccess).catch(onTriggerError);
  };

  const ping = () => {
    const onPingSuccess = () => {
      const msg = "Successfully pinged AWS.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onPingError = () => {
      const msg = "Failed to ping AWS.";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    pingClientIntegration().then(onPingSuccess).catch(onPingError);
  };

  const forceLambdaUpdate = () => {
    const onForceLambdaUpdateSuccess = () => {
      const msg = "Successfully triggered lambda update.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onForceLambdaUpdateError = () => {
      const msg = "Failed to trigger lambda update";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    triggerForceLambdaUpdate()
      .then(onForceLambdaUpdateSuccess)
      .catch(onForceLambdaUpdateError);
  };

  const sendLambdaMetrics = () => {
    const onSendLambdaMetricsSuccess = () => {
      const msg = "Successfully sent lambda metrics.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onSendLambdaMetricsError = () => {
      const msg = "Failed to send lambda metrics.";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    triggerSendLambdaMetrics()
      .then(onSendLambdaMetricsSuccess)
      .catch(onSendLambdaMetricsError);
  };

  const sendDiagnosticsData = () => {
    const onSendDiagnosticsDataSuccess = () => {
      const msg = "Successfully sent diagnostics data.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onSendDiagnosticsDataError = () => {
      const msg = "Failed to send diagnostics data.";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    triggerSendDiagnosticsData()
      .then(onSendDiagnosticsDataSuccess)
      .catch(onSendDiagnosticsDataError);
  };

  const cancelScans = () => {
    const onCancelScansDataSuccess = () => {
      const msg = "Successfully sent cancel scans request.";
      // Display success msg
      enqueueSnackbar(msg, { variant: "success" });
    };

    const onCancelScansDataError = () => {
      const msg = "Failed to send cancel scans request.";
      // Display error msg
      enqueueSnackbar(msg, { variant: "error" });
    };

    triggerCancelScans()
      .then(onCancelScansDataSuccess)
      .catch(onCancelScansDataError);
  };

  let content = null;
  switch (currentTab) {
    case "overview":
      content = (
        <AWSOverviewAccountTab
          {...{
            space,
            integration: awsIntegration,
            integrationMrn: awsIntegration.mrn,
            discoveredAssets,
            integrationStatus: awsIntegration.status as AWSIntegrationStatus,
            token,
          }}
        />
      );
      break;
    case "individual-accounts":
      content = (
        <IndividualAccountsTab
          {...{
            awsIntegration,
            discoveredAssets,
            availablePermissions,
            integrationStatus: awsIntegration.status as AWSIntegrationStatus,
          }}
        />
      );
      break;
    case "policies":
      content = <RecommendedPolicies {...{ space }} filterTypes={["aws"]} />;
      break;
  }

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations/aws"
      component={RouterLink}
      to={`/space/integrations/aws?spaceId=${space.id}`}
    >
      AWS
    </Link>,
    <Typography key={1}>{awsIntegration.name}</Typography>,
  ];

  document.title = `${awsIntegration.name} · AWS · Mondoo`;

  return (
    <Grid container>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid item xs={12}>
        <AwsIntegrationAlert {...{ awsIntegration, availablePermissions }} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <AwsAccountHeader
          actions={{
            retrySetup,
            triggerScan,
            ping,
            forceLambdaUpdate,
            sendLambdaMetrics,
            sendDiagnosticsData,
            cancelScans,
            deleteIntegration: deleteIntegrationBegin,
          }}
          {...{
            mrn,
            space,
            availablePermissions,
            awsIntegration,
            integrationId,
          }}
        />
      </Grid>
      <Grid item xs={12} mt={4} mb={3}>
        <Box
          sx={{
            position: "relative",
            mb: 0,
            "&:after": {
              content: "''",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: 1,
              height: 4,
              backgroundColor: "background.light",
            },
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabs.indexOf(currentTab)}
            onChange={handleTabChange}
            sx={[
              {
                ".MuiTabs-indicator": {
                  height: 4,
                  zIndex: 1,
                },
              },
            ]}
          >
            {tabs.map((tab, i) => {
              return (
                <Tab
                  key={i}
                  label={tabNames[i]}
                  data-name={`integration-aws-tab-${tabNames[i]}`}
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "text.secondary",
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
      <DeleteConfirmDialog
        isDeleting={state.isDeleting}
        open={state.openDeleteConfirmDialog}
        content={
          <Typography>
            Are you sure you want to delete{" "}
            <Typography component="span" fontWeight={700}>
              {awsIntegration.name}
            </Typography>
            ? Deleting an integration erases all of its data. Be sure this is
            what you want.
          </Typography>
        }
        onConfirm={handleDeleteAgentConfirm}
        onClose={handleDeleteAgentClose}
        itemName={(item: any) => item.name}
        itemType="AWS integration"
      />
    </Grid>
  );
}
