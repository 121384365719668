import { CSSProperties, HTMLAttributes, ReactNode } from "react";
import { alpha, Box, IconButton, IconButtonProps } from "@mui/material";
import { CheckIcon, CopyIcon } from "~/components/icons";
import { useCode } from "./use-code";
import { syntaxStyle } from "./syntaxStyle";

type CodeProps = {
  languages?: string[];
  children: ReactNode;
  copyButton?: boolean;
  codeStyle?: CSSProperties;
  dataId?: string;
} & HTMLAttributes<HTMLPreElement>;

// This implementation is based on mit licensed https://github.com/akiran/react-highlight
export function Code({
  className,
  children = "",
  copyButton,
  codeStyle,
  dataId,
  languages = [],
  ...props
}: CodeProps) {
  const { elRef, justCopied, handleCopyToClipboard } = useCode({
    languages,
    className,
  });

  return (
    <Box
      sx={{
        position: "relative",
        my: 2,
        "& code": {
          backgroundColor: "unset",
          border: "unset",
          padding: "unset",
          minHeight: (theme) => theme.spacing(7.25),
        },
        ...syntaxStyle(),
      }}
    >
      {copyButton && children && (
        <CopyButton
          {...{ dataId, justCopied, onClick: handleCopyToClipboard }}
        />
      )}
      <pre ref={elRef} style={{ margin: 0, ...props.style }}>
        <code
          className={className}
          contentEditable={props.contentEditable}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          style={codeStyle}
          suppressContentEditableWarning
        >
          {children}
        </code>
      </pre>
    </Box>
  );
}

type CopyButtonProps = {
  dataId?: string | undefined;
  justCopied: boolean;
  onClick: IconButtonProps["onClick"];
};

////////////////////////////////////////////////

const CopyButton = ({ dataId, justCopied, onClick }: CopyButtonProps) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: (theme) => theme.spacing(1),
        right: (theme) => theme.spacing(1),
        background: (theme) => theme.palette.code.background,
        borderRadius: (theme) => theme.spacing(0.5),
      }}
    >
      <IconButton
        data-name={dataId ? `copy-to-clipboard-${dataId}` : "copy-to-clipboard"}
        onClick={onClick}
        sx={{
          border: "1px solid",
          borderColor: (theme) =>
            justCopied
              ? theme.palette.none.main
              : theme.palette.background.lightest,
          borderRadius: (theme) => theme.spacing(0.5),
          backgroundColor: (theme) =>
            justCopied
              ? alpha(theme.palette.none.main, 0.1)
              : theme.palette.code.background,
          transition: ".300s ease-out",
          pointerEvents: justCopied ? "none" : "auto",
          "& .code-copy-icon": {
            color: (theme) =>
              justCopied ? theme.palette.none.main : theme.palette.text.primary,
          },
          "&:hover": {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.2),
            border: (theme) => `1px solid ${theme.palette.primary.main}`,
          },
          "&:hover .code-copy-icon": {
            color: (theme) => theme.palette.primary.main,
          },
        }}
      >
        {justCopied ? (
          <CheckIcon className="code-copy-icon" fontSize="small" />
        ) : (
          <CopyIcon className="code-copy-icon" fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
};
