import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Space } from "~/lib/types";
import { filterActuals } from "./use-affected-resources";
import { PolicyQueryAssessment } from "~/operations";

type Props = {
  assessment: PolicyQueryAssessment;
  space: Space;
};

export function AffectedResources({ assessment, space }: Props) {
  // filter out empty results
  const actuals = filterActuals(assessment);

  // if there is nothing to show, we don't need to render anything
  if (!actuals.length) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Outlet context={{ assessment, space }} />
      </Grid>
    </Grid>
  );
}
