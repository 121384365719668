const policyMrnRegex =
  /\/\/policy.api.mondoo.app(?:\/spaces\/(.*)){0,1}\/policies\/(.*)/;

// //policy.api.mondoo.app/policies/amazon-linux-2-level-2 -> amazon-linux-2-level-2
// //policy.api.mondoo.app/spaces/space1/policies/amazon-linux-2-level-2 -> space1/amazon-linux-2-level-2
export const PolicyMrnToId = (mrn: string): string => {
  let m = policyMrnRegex.exec(mrn);
  if (m == null || m.length != 3) {
    return mrn;
  }
  let id = "";
  if (m[1] != null && m[1] != "") {
    id = m[1] + "/";
  }
  return id + m[2];
};

export const PolicyMrnToURIEncodedId = (mrn: string): string => {
  return encodeURIComponent(PolicyMrnToId(mrn));
};
