import { Box } from "@mui/material";
import { TestIamActionsQuery } from "~/operations";
import Registries from "~/pages/dashboard/Registries";
import { Space } from "~/lib/types";

type RegistryPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const RegistryPage = ({
  space,
  availablePermissions,
}: RegistryPageProps) => {
  document.title = "Registry · Mondoo";

  return (
    <Box>
      <Registries space={space} availablePermissions={availablePermissions} />
    </Box>
  );
};
