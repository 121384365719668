import { SmallDonutCard, useSmallDonut } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { CVEIcon } from "~/components/icons";

export function CvesDonutChart({ space }: DonutChartCardProps) {
  const { chartData, loading } = useSmallDonut({
    space,
    type: "CVE",
  });

  return (
    <SmallDonutCard
      to={`cves?spaceId=${space.id}`}
      loading={loading}
      data={chartData}
      title="CVES"
      icon={<CVEIcon />}
    />
  );
}
