import { Fragment } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AssetReport } from "~/pages/inventory/asset/types";
import { Space } from "~/lib/types";
import {
  configurationTableSx,
  extractItemKey,
  extractItemValue,
} from "./asset-configuration-section";

type AssetPlatformOverviewProps = {
  assetReport: AssetReport;
  space: Space;
};

export function AssetPlatformOverview({
  assetReport,
  space,
}: AssetPlatformOverviewProps) {
  const platformGroup = assetReport.overviewGroups.find(
    (g) => g.title === "Platform overview",
  );

  const noResults = !platformGroup && (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: 2,
        pt: 2,
      }}
    >
      <Typography variant="body2" color="text.secondary">
        No Data
      </Typography>
    </Box>
  );

  const results = platformGroup && (
    <TableContainer>
      <Table
        sx={{ tableLayout: "fixed", "& tr:hover": { background: "inherit" } }}
      >
        <TableHead sx={{ backgroundColor: "inherit", boxShadow: "none" }}>
          <TableRow
            sx={{
              "&:hover": {
                pointerEvents: "none",
                background: "inherit",
              },
            }}
          >
            <TableCell
              colSpan={2}
              sx={{
                pl: 0,
                pb: 1,
                borderBottom: (theme) =>
                  `1px solid ${theme.palette.background.lightest}`,
                textTransform: "uppercase",
              }}
            >
              {platformGroup.title}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={configurationTableSx}>
          {platformGroup.items.map((item) => {
            const key = extractItemKey(item);
            const value = extractItemValue(item);
            return (
              <Fragment key={item.key}>
                <TableRow>
                  <TableCell className={"key width-50"} sx={{ pl: 0 }}>
                    {key}
                  </TableCell>
                  <TableCell className={"width-50"} sx={{ pl: 0 }}>
                    {value}
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return <Box>{results || noResults}</Box>;
}
