import { Box, useTheme } from "@mui/material";
import { DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { getColor } from "~/lib/colors";
import { useLoadPolicyDashboardStatsQuery } from "~/operations";
import { Space } from "~/lib/types";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

export function PolicyOverviewCard({ space, sizes }: Props) {
  const theme = useTheme();
  const { loading, error, data } = useLoadPolicyDashboardStatsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
  });

  const d = data?.policyDashboardStats?.averagePolicyScores;

  const graphData = [
    { label: "A", value: d?.A || 0, color: getColor(theme, "A") },
    { label: "B", value: d?.B || 0, color: getColor(theme, "B") },
    { label: "C", value: d?.C || 0, color: getColor(theme, "C") },
    { label: "D", value: d?.D || 0, color: getColor(theme, "D") },
    { label: "F", value: d?.F || 0, color: getColor(theme, "F") },
    { label: "Unrated", value: d?.U || 0, color: getColor(theme, "U") },
    { label: "Error", value: d?.X || 0, color: getColor(theme, "X") },
  ];
  return (
    <HubCard
      to={`policies?spaceId=${space.id}`}
      {...{
        loading,
        sizes,
        title: "Policy Overview",
        icon: <NavDrawerPolicyIcon fontSize="small" />,
      }}
      PaperProps={{ height: 264 }}
    >
      <Box sx={{ mt: 2 }}>
        <DonutChart data={graphData} dataType="policies" />
      </Box>
    </HubCard>
  );
}
