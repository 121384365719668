import { MajorScore } from "./scores/mondoo-score-card";
import { alpha, Box, Typography } from "@mui/material";
import { getColor } from "~/lib/colors";
import { Asset } from "~/pages/inventory/asset/types";

type Props = {
  score: Asset["score"];
};

export function AssetScoreBox({ score }: Props) {
  const major = MajorScore(score);
  return (
    <Box
      sx={{
        p: 2.75,
        width: (theme) => theme.spacing(23),
        height: "auto",
        backgroundColor: (theme) => alpha(getColor(theme, major), 0.1),
        borderRadius: 1,
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Typography
          fontSize={104}
          fontWeight={700}
          sx={{
            lineHeight: "104px",
            textAlign: "center",
            color: (theme) => getColor(theme, major),
          }}
        >
          {major}
        </Typography>
      </Box>
      <Box
        sx={{ textAlign: "center", color: (theme) => getColor(theme, major) }}
      >
        <Typography fontWeight={700}>{score.value}/100</Typography>
      </Box>
    </Box>
  );
}
