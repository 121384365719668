import { SxProps, SvgIcon } from "@mui/material";
import {
  MoreHorizIcon,
  DeleteIcon,
  SaveAltIcon,
  RefreshIcon,
  CloseIcon,
  EditIcon,
} from "~/components/icons";

type DynamicIconProps = {
  iconId: IconButtonType | null | undefined;
  size?: string;
  sx?: SxProps;
  defaultIcon?: typeof SvgIcon;
};

export const DynamicButtonIcon = ({
  iconId,
  size,
  sx = {},
  defaultIcon = MoreHorizIcon,
}: DynamicIconProps) => {
  const commonProps = {
    "data-testid": "icon-button",
    sx: {
      fontSize: size || 24,
      ...sx,
    },
  };

  const iconKey = iconId || IconButtonType.Default;

  const iconsDictionary: Record<
    IconButtonType,
    typeof SvgIcon | React.ComponentType
  > = {
    [IconButtonType.Close]: CloseIcon,
    [IconButtonType.Default]: SvgIcon,
    [IconButtonType.Delete]: DeleteIcon,
    [IconButtonType.Edit]: EditIcon,
    [IconButtonType.Download]: SaveAltIcon,
    [IconButtonType.Refresh]: RefreshIcon,
  };

  let Icon = iconsDictionary[iconKey] || defaultIcon;

  return <Icon {...commonProps} />;
};

export enum IconButtonType {
  Close = "CLOSE",
  Default = "DEFAULT",
  Delete = "DELETE",
  Download = "DOWNLOAD",
  Edit = "EDIT",
  Refresh = "REFRESH",
}
