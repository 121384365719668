import { forwardRef } from "react";
import { Tooltip, IconButton as MuiIconButton, SxProps } from "@mui/material";
import { DynamicButtonIcon, IconButtonType } from "./DynamicButtonIcon";

type IconButtonProps = {
  title: string;
  iconType: IconButtonType;
  IconStyles?: SxProps;
} & React.ComponentProps<typeof MuiIconButton>;

export const CircularIconButton = forwardRef<
  HTMLButtonElement,
  IconButtonProps
>(function CircularIconButton(
  { title, iconType, IconStyles, ...buttonProps },
  ref,
) {
  return (
    <Tooltip title={title} placement="top" arrow>
      <MuiIconButton ref={ref} {...buttonProps}>
        <DynamicButtonIcon iconId={iconType} sx={IconStyles} />
      </MuiIconButton>
    </Tooltip>
  );
});
