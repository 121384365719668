import { useTheme } from "@mui/material/styles";
import { GaugeRow } from "../components/gauges/gaugerow";
import { AnimatedGauge } from "./gauges/animated-gauge";

export type ReportsGaugeRowProps = {
  total: number;
  good: number;
  bad: number;
};

export function ReportsGaugeRow({ total, good, bad }: ReportsGaugeRowProps) {
  const theme = useTheme();

  const gaugeData = [
    {
      value: good,
      text: "excellent",
      fill: theme.palette.none.main,
    },
    { value: bad, text: "poor & fail", fill: theme.palette.high.main },
  ];

  return (
    <GaugeRow>
      {gaugeData.map((gauge, index) => (
        <AnimatedGauge
          key={index}
          radius={38}
          maxWidth={54}
          value={gauge.value}
          total={total}
          tooltip={`${gauge.value} ${gauge.text}`}
          fill={gauge.fill}
        />
      ))}
    </GaugeRow>
  );
}
