import { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useViewer } from "~/providers/viewer";
import { OpenInNewIcon } from "~/components/icons";
import { Markdown } from "~/components/markdown";
import { GradientButton } from "~/components/guides/components";
import { ScrollToTop } from "~/lib/scroll-to-top";

export function WorkstationScans({}) {
  const { viewerSettings } = useViewer();
  const spaceId = viewerSettings?.last_space_id;

  return (
    <Box className="integration">
      <ScrollToTop />
      <Box>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            pt: 3,
            mb: 3.75,
          }}
        >
          <Grid item xs={12} sm={6} sx={{ mb: { xs: 1, sm: 0 } }}>
            <Typography variant="h5" fontWeight={700}>
              Available Scans
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{ textAlign: { xs: "left", sm: "right" } }}
          >
            <Button
              href="https://mondoo.com/docs/cnspec/cli/cnspec_scan/"
              target="_blank"
              variant="outlined"
              color="secondary"
              endIcon={<OpenInNewIcon />}
              sx={{ py: 0.5, px: 1 }}
            >
              documentation
            </Button>
          </Grid>
        </Grid>
        <Paper sx={{ p: 3, mb: 5.75 }}>
          {availableScans.map((x, index) => (
            <Fragment key={index}>
              <Typography sx={{ mb: -1, color: "text.secondary" }}>
                {x.instruction}
              </Typography>
              <Markdown
                source={x.scans}
                className="integration all-white"
                copyButton={Boolean(index > 4)}
                {...(Boolean(index > 4)
                  ? {
                      dataId: x.instruction
                        .replace(/[.,:'-]/g, "")
                        .split(" ")
                        .join("-")
                        .toLowerCase(),
                    }
                  : {})}
              />
            </Fragment>
          ))}
        </Paper>
      </Box>
      {/* Available Commands */}
      <Box>
        <Grid container sx={{ mb: 3.75 }}>
          <Grid item xs={12} sm={6} sx={{ mb: { xs: 1, sm: 0 } }}>
            <Typography variant="h5" fontWeight={700}>
              Available Commands
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ textAlign: { xs: "left", sm: "right" } }}
          >
            <Button
              href="https://mondoo.com/docs/cnspec/cli/cnspec/"
              target="_blank"
              variant="outlined"
              color="secondary"
              endIcon={<OpenInNewIcon />}
              sx={{ py: 0.5, px: 1, borderWidth: "2px" }}
            >
              Documentation
            </Button>
          </Grid>
        </Grid>

        <Paper sx={{ p: 3, mb: 5.75 }}>
          <Box>
            <Typography sx={{ mb: -1, color: "text.secondary" }}>
              To use the following commands, type:
            </Typography>
            <Markdown
              className="integration all-white"
              source={commandInstructions}
              copyButton={false}
            />
          </Box>
          <Box>
            <Typography sx={{ mb: -1, color: "text.secondary" }}>
              List of commands:
            </Typography>

            <Markdown
              className="integration all-white"
              source={availableCommands}
              copyButton={false}
            />
          </Box>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <GradientButton
            component={RouterLink}
            to={`/space/integrations/managed?spaceId=${spaceId}`}
            sx={{
              px: 2,
              py: 1,
              mt: -1,
              textTransform: "uppercase",
              fontWeight: 500,
            }}
          >
            finalize setup
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
}

const availableScans = [
  {
    instruction: "Scan Kubernetes using your local kubectl credentials:",
    scans:
      "```bash\ncnspec scan k8s\ncnspec scan k8s --namespaces [string]\ncnspec scan k8s --discover all```\n",
  },
  {
    instruction:
      "Scan hosts remotely via ssh. Credentials are automatically retrieved from ssh-agent and sshd_config:",
    scans:
      "```bash\ncnspec scan ssh ec2-user@52.51.185.215 \ncnspec scan ssh ec2-user@52.51.185.215:2222```",
  },
  {
    instruction:
      "Scan AWS accounts and instances. Credentials and config are automatically retrieved from your .aws folder:",
    scans: "```bash\ncnspec scan aws\ncnspec scan aws ec2 ssm host```",
  },
  {
    instruction:
      "Scan Azure or GCP accounts and instances. Here are a few examples:",
    scans:
      "```bash\ncnspec scan azure --subscription ID --group NAME\ncnspec scan gcp --project ID```",
  },
  {
    instruction:
      "Scan Docker containers & images locally, and remotely scan images in Docker registries:",
    scans:
      "```bash\ncnspec scan docker container b62b276baab6\ncnspec scan docker image ubuntu:latest```",
  },
  {
    instruction: "Scan a container registry:",
    scans:
      "```bash\ncnspec scan cr harbor.yourdomain.com\ncnspec scan cr 123456789.dkr.ecr.us-east-1.amazonaws.com/repository```",
  },
  {
    instruction: "Scan Google Container Registry:",
    scans: "```bash\ncnspec scan gcp gcr PROJECT_ID```",
  },
  {
    instruction: "Scan Vagrant:",
    scans: "```bash\ncnspec scan vagrant HOST```",
  },
  {
    instruction: "Leverage an inventory file:",
    scans: "```bash\ncnspec scan --inventory-file inventory.yml```",
  },
  {
    instruction: "Leverage your existing Ansible inventory:",
    scans:
      "```bash\nansible-inventory -i hosts.ini --list | cnspec scan --inventory-ansible```",
  },
];

const commandInstructions =
  "```bash\ncnspec scan [flags]\ncnspec scan [command]```\n";

const availableCommands =
  "```bash\n\
arista           Scan an Arista EOS device\n\
atlassian        Scan an Atlassian Cloud Jira, Confluence or Bitbucket instance\n\
aws              Scan an AWS account\n\
azure            Scan an Azure subscription\n\
container        Scan a running container or container image\n\
docker           Scan a running Docker container or Docker image\n\
equinix          Scan an Equinix Metal organization\n\
filesystem       Scan a mounted file system target\n\
gcp              Scan a Google Cloud project or folder\n\
github           Scan a GitHub organization or repository\n\
gitlab           Scan a GitLab group or project\n\
google-workspace Scan a Google Workspace account\n\
host             Scan a remote host\n\
ipmi             Scan an IPMI interface\n\
k8s              Scan a Kubernetes cluster or local manifest file(s)\n\
local            Scan your local system\n\
ms365            Scan a Microsoft 365 account\n\
oci              Scan an Oracle Cloud Infrastructure tenancy\n\
okta             Scan an Okta organization\n\
opcua            Scan an OPC UA device\n\
slack            Scan a Slack team\n\
ssh              Scan a remote system via SSH\n\
terraform        Scan a Terraform HCL file or directory\n\
vagrant          Scan a Vagrant host\n\
vcd              Scan a VMware Cloud Director installation\n\
vsphere          Scan a VMware vSphere installation\n\
winrm            Scan a remote system via WinRM\n```";
