import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ShieldOutlinedIcon } from "~/components/icons";
import { GridData, GridSegment } from "../grid-segment";
import { CheckStats, useLoadPolicyDashboardStatsQuery } from "~/operations";
import { Space } from "~/lib/types";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

export function ChecksPassedCard({ space, sizes }: Props) {
  const { loading, error, data } = useLoadPolicyDashboardStatsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
  });

  const graphData: GridData[] =
    data?.policyDashboardStats?.checkStatsBySeverity
      ?.filter((x): x is CheckStats => x !== null)
      .map((x) => ({
        title: x.severity,
        score: x.passedAcrossAllAssets,
        total: x.total,
      })) || [];

  return (
    <HubCard
      {...{
        loading,
        sizes,
        title: "Total Checks Passed",
        icon: <ShieldOutlinedIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
    >
      <Grid container spacing={3} sx={{ pt: 2 }}>
        {graphData.map((item) => (
          <GridSegment key={item.title} data={item} color />
        ))}
      </Grid>
    </HubCard>
  );
}
