import React from "react";
import { Command } from "~/components/guides/components";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import { Box, Button, Divider, useTheme } from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Control } from "react-hook-form";
import { JiraFormInput } from "~/pages/integrations/hosted-integrations/forms/jira/types";
import { JiraProject } from "~/operations";

type JiraFormStep2Props = {
  control: Control<JiraFormInput>;
  isValid: boolean;
  disabled: boolean;
  isProjectsEmpty: boolean;
  projects: JiraProject[] | null | undefined;
  isUpdateFlow: boolean;
};

export const JiraFormStep2 = ({
  control,
  projects,
  isValid,
  disabled,
  isProjectsEmpty,
  isUpdateFlow,
}: JiraFormStep2Props) => {
  const theme = useTheme();

  return (
    <>
      <Box pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Configure preferences
        </Command>
        <Box mt={3}>
          <AutoConfigurationSection
            control={control}
            projects={projects}
            disabled={disabled}
            isProjectsEmpty={isProjectsEmpty}
            showDefaultProjectDropdown
          />
        </Box>
      </Box>
      <Box width={1}>
        <Divider />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <Button
          sx={{ textTransform: "uppercase" }}
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          Finalize {isUpdateFlow ? "Update" : "Setup"}
        </Button>
      </Box>
    </>
  );
};
