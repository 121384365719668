import { Obj } from "~/components/ui-library";

type GetPolicyMrnProps = Obj & {
  namespace: string;
  uid: string;
};

const getPolicyMrn = ({ namespace, uid }: GetPolicyMrnProps): string =>
  namespace !== "mondoohq"
    ? `//policy.api.mondoo.app/spaces/${namespace}/policies/${uid}`
    : `//policy.api.mondoo.app/policies/${uid}`;

export default getPolicyMrn;
