import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Divider, Grid, Link, Typography } from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";

export type TitleDividerProps = {
  title: string;
  href?: string;
  icon?: ReactNode;
};

export function TitleDivider({ title, href, icon }: TitleDividerProps) {
  const stripTitle = (title: string) => {
    return title.replace(/[^\w]/gi, "").toLowerCase();
  };

  return (
    <Grid container alignItems="center" mt={5} mb={3}>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        {icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
        <Box id={stripTitle(title)}>
          <Typography
            fontWeight="fontWeightBold"
            sx={{
              display: "inline-block",
              pr: 3,
              fontSize: { xs: 20, sm: 24 },
            }}
          >
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      {href && (
        <Grid item xs="auto">
          <Link
            component={RouterLink}
            to={href}
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 3,
              textTransform: "uppercase",
            }}
          >
            Open Organization <ChevronRightIcon />
          </Link>
        </Grid>
      )}
    </Grid>
  );
}
