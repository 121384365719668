import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { getRiskFactorIcon } from "~/pages/space/security/components/RiskFactors/utils";
import { UpperSectionProps } from "../..";
import {
  AggregateScoreType,
  RiskFactorStats,
  useGetAggregateScoresQuery,
} from "~/operations";
import { useFirewatchSearchParams } from "~/components/FirewatchPage/hooks/UseFirewatchSearchParams";
import { Clamp } from "~/components/clamp/clamp";
import { Loading, LoadingFailed } from "~/components/loading";
import { Fragment } from "react";

export function AffectedAssetsUpperSection({ space }: UpperSectionProps) {
  const theme = useTheme();
  const { handle, selectedRiskFactor } = useFirewatchSearchParams();

  const { data, loading, error } = useGetAggregateScoresQuery({
    variables: {
      entityMrn: space.mrn,
      filter: {
        scoreType: AggregateScoreType.Other,
        findingMrn: "//security.api.mondoo.app/findings/vulns",
        maxRiskScore: 99,
      },
    },
    fetchPolicy: "no-cache",
  });

  if (loading) {
    return (
      <Grid
        container
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 4,
        }}
      >
        <Loading what="Risk Factor Filters" />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        item
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 4,
        }}
      >
        <LoadingFailed what="Risk Factor Filters" />
      </Grid>
    );
  }

  const allRiskFactors =
    data?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? data.aggregateScores.edges?.flatMap(
          (edge) => edge.node?.riskFactors?.flatMap((rf) => rf ?? []) ?? [],
        ) || []
      : [];

  // Filter out risk factors that are not affecting any assets
  const filteredRiskFactors = allRiskFactors.filter((rf) => rf.affected > 0);

  // TODO: this is a temporary fix.  The risk factors are possibly duplicating because of the way the data is being fetched in two places on the same endpoint.
  // deduplicate riskFactors so that the cateogry is only shown once
  const riskFactors: RiskFactorStats[] = filteredRiskFactors.reduce(
    (acc: RiskFactorStats[], current) => {
      const x = acc.find((item) => item.mrn === current.mrn);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    },
    [],
  );

  // Risk factors should be sorted so that the 'positive' ones are always last
  // the indicator 'defensive' is currently used for all positive risk factors.
  const sortedRiskFactors = riskFactors.sort((a, b) => {
    const alwaysLast = ["defensive"];
    return alwaysLast.includes(a?.indicator)
      ? 1
      : alwaysLast.includes(b?.indicator)
        ? -1
        : 0;
  });

  if (sortedRiskFactors.length === 0) {
    return <Fragment />;
  }

  if (riskFactors.length === 0) {
    return <Fragment />;
  }

  return (
    <Grid container item>
      <Grid item xs={12} mb={1}>
        <Typography sx={{ color: "text.secondary", fontWeight: 700 }}>
          Risk Factors
        </Typography>
      </Grid>
      <Grid container item spacing={3}>
        {riskFactors?.map((riskFactor, i) => {
          const icon = getRiskFactorIcon({
            indicator: riskFactor?.indicator,
            sx: {
              color:
                riskFactor?.indicator === "defensive"
                  ? theme.palette.excellent.main
                  : theme.palette.warning.main,
              fontSize: 40,
            },
          });

          return (
            <Grid
              item
              key={`${riskFactor?.indicator}-${i}`}
              xs={12}
              sm={6}
              md={4}
            >
              <Paper
                {...(riskFactor?.mrn && {
                  onClick: () => handle.riskFilterClick(riskFactor.mrn),
                })}
                component={Button}
                disableRipple
                fullWidth
                sx={{
                  p: 2,
                  cursor: "pointer",
                  height: "100%",
                  justifyContent: "flex-start",
                  background: (theme) =>
                    Boolean(selectedRiskFactor === riskFactor?.mrn)
                      ? alpha(theme.palette.primary.main, 0.2)
                      : "background.paper",
                  opacity: Boolean(
                    selectedRiskFactor &&
                      riskFactor?.mrn !== selectedRiskFactor,
                  )
                    ? 0.4
                    : 1,
                  boxShadow: (theme) =>
                    Boolean(selectedRiskFactor === riskFactor?.mrn)
                      ? `0px 0px 0px 1px ${theme.palette.primary.main}`
                      : theme.shadows[1],
                  transition: "all .35s ease-in-out",
                  "&:hover, &:focus-visible": {
                    background: (theme) =>
                      alpha(theme.palette.primary.main, 0.2),
                    opacity: 1,
                  },
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: 2,
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textAlign: "left", textTransform: "none" }}>
                    <Clamp lines={2}>
                      <Typography sx={{ fontWeight: 700 }}>
                        {riskFactor?.title}
                      </Typography>
                    </Clamp>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}
