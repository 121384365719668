import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  Fragment,
} from "react";
import {
  Box,
  styled,
  Switch,
  ListItem,
  IconButton,
  SwitchProps,
  ListItemProps,
} from "@mui/material";
import isBoolean from "lodash/isBoolean";
import { useSnackbar } from "notistack";
import Flex from "../../layouts/Flex";
import Text from "../../typography/Text";
import Flags from "../../buttons/Flags";
import Caption from "../../typography/Caption";
import { getFlags, getGraphQLErrors } from "../../../utils";
import {
  Obj,
  Route,
  ListName,
  FlagTypes,
  AssignmentInput,
} from "../../../types";
import {
  PolicyIndicator,
  PolicyIndicatorProps,
} from "../../buttons/PolicyIndicator";
import { DeleteIcon } from "~/components/icons";

type RegistryItemProps = {
  isQuerypack: boolean;
  registryEntry: Obj;
  icon?: ReactNode;
  policyIndicatorProps?: PolicyIndicatorProps;
  policyActionIcon?: JSX.Element;
  onNavigate: (value: Obj) => void;
  changeAssignMutation?: (value: AssignmentInput) => Promise<any>;
  deleteItem?: () => void;
};

const RegistryItem = ({
  isQuerypack,
  registryEntry,
  icon,
  onNavigate,
  changeAssignMutation,
  policyIndicatorProps,
  policyActionIcon,
  deleteItem,
}: RegistryItemProps) => {
  const [showIndicator, setShowIndicator] = useState<boolean>(false);
  const [assigned, setAssigned] = useState<boolean>(registryEntry.assigned);
  const switchRef = useRef<HTMLButtonElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setAssigned(registryEntry.assigned);
  }, [registryEntry.assigned]);

  const { flags, checks, queries } = useMemo(() => {
    return {
      flags: getFlags(registryEntry),
      checks: registryEntry.statistics.checks,
      queries: registryEntry.statistics.queries,
    };
  }, [registryEntry]);

  const onNavigateHandler: NonNullable<ListItemProps["onClick"]> = useCallback(
    (e) => {
      if (switchRef.current?.contains(e.target as Node)) {
        return;
      }

      onNavigate(registryEntry);
    },
    [registryEntry, switchRef.current],
  );

  const onChangeAssignHandler: NonNullable<SwitchProps["onChange"]> =
    useCallback(
      async (e, checked) => {
        setAssigned(checked);

        if (!changeAssignMutation) {
          return;
        }

        try {
          await changeAssignMutation({
            assign: checked,
            policyMrn: registryEntry.mrn,
          });

          enqueueSnackbar(
            `Successfully ${checked ? "assigned" : "unassigned"} ${
              isQuerypack ? "querypack" : "policy"
            }`,
            {
              variant: "success",
            },
          );
        } catch (error: any) {
          setAssigned(!checked);

          enqueueSnackbar(getGraphQLErrors({ error }), { variant: "error" });
        }
      },
      [registryEntry.mrn, isQuerypack],
    );

  return (
    <Container
      onClick={onNavigateHandler}
      onMouseEnter={() => setShowIndicator(true)}
      onMouseLeave={() => setShowIndicator(false)}
    >
      <Box sx={{ display: "flex", width: "100%" }}>
        {icon ? icon : null}
        <Box sx={{ ml: 3.5, width: "100%" }}>
          <Flex justifyContent="space-between" gap={2}>
            <Flex flexDirection="column">
              <Text bold>{registryEntry.name}</Text>
              <Caption
                trustLevel={registryEntry.trustLevel}
                authors={(registryEntry.authors || []) as ListName[]}
                version={registryEntry.version}
                certifiedBy={(registryEntry.certifiedBy || {}) as ListName}
                gap={2}
              />
            </Flex>
            <Flex
              height="fit-content"
              alignItems="center"
              flexShrink={0}
              gap={2}
              sx={{ minHeight: (theme) => theme.spacing(6) }}
            >
              {registryEntry?.trustLevel === "PRIVATE" && deleteItem && (
                <IconButton onClick={deleteItem}>
                  <DeleteIcon />
                </IconButton>
              )}
              <Flex justifyContent="flex-end" gap={2} sx={{ minWidth: 180 }}>
                {showIndicator &&
                policyIndicatorProps &&
                isBoolean(registryEntry.assigned) ? (
                  <PolicyIndicator {...policyIndicatorProps} />
                ) : (
                  <Fragment>
                    {flags.map((name) => (
                      <Flags key={name} name={name} />
                    ))}
                    {(isQuerypack ? queries > 0 : checks > 0) && (
                      <Text
                        color="#454545"
                        secondary={!flags.includes(FlagTypes.unlock)}
                        whiteSpace="nowrap"
                        mx={1.5}
                        sx={{
                          minWidth: 88,
                        }}
                      >
                        {isQuerypack
                          ? `${queries} Queries`
                          : `${checks} Checks`}
                      </Text>
                    )}
                    {isQuerypack && isBoolean(registryEntry.assigned) ? (
                      <Switch
                        size="small"
                        ref={switchRef}
                        checked={assigned}
                        onChange={onChangeAssignHandler}
                      />
                    ) : null}
                    {!isQuerypack &&
                      isBoolean(registryEntry.assigned) &&
                      Boolean(policyActionIcon) &&
                      policyActionIcon}
                  </Fragment>
                )}
              </Flex>
            </Flex>
          </Flex>

          <Text secondary mt={2}>
            {registryEntry.summary}
          </Text>
        </Box>
      </Box>
    </Container>
  );
};

export default RegistryItem;

const Container = styled(ListItem)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
  padding: theme.spacing(3),
  cursor: "pointer",
  ":not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.background.lighter}`,
  },
  ":hover": {
    backgroundColor: theme.palette.background.light,
  },
}));
