import { Fragment } from "react";
import { Button, Divider, BoxProps } from "@mui/material";
import Flex from "../../layouts/Flex";
import { TabOption } from "../../../types";

export type ButtonGroupProps = BoxProps & {
  options: TabOption[];
  selectedValue: string;
  onChange: (value: string) => void;
};

const ButtonGroup = ({
  options,
  selectedValue,
  onChange,
  ...restProps
}: ButtonGroupProps) => {
  return (
    <Flex
      width="fit-content"
      border="1px solid"
      borderColor="divider"
      borderRadius="5px"
      overflow="hidden"
      {...restProps}
    >
      {options.map(({ label, value }, key) => (
        <Fragment key={key}>
          {key !== 0 && (
            <Divider orientation="vertical" sx={{ height: "auto" }} />
          )}
          <Button
            sx={(theme) => ({
              minWidth: theme.spacing(4),
              borderRadius: 0,
              ...(selectedValue === value
                ? { backgroundColor: "background.lightest" }
                : {}),
            })}
            onClick={() => {
              onChange(value);
            }}
          >
            {label}
          </Button>
        </Fragment>
      ))}
    </Flex>
  );
};

export default ButtonGroup;
