import { Snackbar, Alert, Slide, SlideProps } from "@mui/material";
import { useAuth } from "~/login/provider";

export function AuthErrorsComponent() {
  const { state, clearErrors } = useAuth();

  function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="up" />;
  }
  return (
    <Snackbar
      sx={{
        p: 2,
        width: 1,
        maxWidth: 400,
        minWidth: 330,
        ".MuiSnackbar-root": {
          left: "unset",
          right: "unset",
        },
      }}
      open={Boolean(state.error)}
      TransitionComponent={SlideTransition}
    >
      <Alert
        sx={{
          "&.MuiSnackbar-anchorOriginBottomCenter": {
            left: {
              md: "25%",
            },
          },
        }}
        onClose={clearErrors}
        severity="error"
      >
        {state.error?.message}
      </Alert>
    </Snackbar>
  );
}
