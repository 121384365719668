import { SmallDonutCard, useSmallDonut } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { AdvisoriesIcon } from "~/components/icons";

export function AdvisoriesDonutChart({ space }: DonutChartCardProps) {
  const { chartData, loading } = useSmallDonut({
    space,
    type: "ADVISORY",
  });

  return (
    <SmallDonutCard
      to={`advisories?spaceId=${space.id}`}
      loading={loading}
      data={chartData}
      title="Advisories"
      icon={<AdvisoriesIcon />}
    />
  );
}
