import React from "react";
import { useJiraTicketContext } from "~/hooks/useJiraTicketContext";
import { IamActions } from "~/lib/iam";
import {
  ClientIntegrationType,
  TestIamActionsQuery,
  useGetJiraIntegrationsQuery,
  useProcessCaseEventsMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { Box } from "@mui/material";
import { CaseSettingsIntegration } from "~/components/cases/components/CaseSettings/CaseSettingsIntegration";
import { CreateTicketingIntegration } from "~/components/cases/components/CasesPage/components";
import { isFeatureEnabled } from "~/login/features";
import { LoadingButton } from "~/components/loading-button";
import { Refresh } from "@mui/icons-material";
import { Flex } from "~/components/ui-library";
import { useSnackbar } from "notistack";
import { CaseSettingsGeneral } from "~/components/cases/components/CaseSettings/CaseSettingsGeneral";

type CaseSettingsProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const CaseSettings = ({
  space,
  availablePermissions,
}: CaseSettingsProps) => {
  const { projects, isProjectsEmpty } = useJiraTicketContext({ space });
  const { enqueueSnackbar } = useSnackbar();

  const hasIntegrationReadPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_LIST,
  );

  const hasIntegrationCreatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );

  const { data: jiraIntegrationsData } = useGetJiraIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
        filterQuery: {
          types: [ClientIntegrationType.TicketSystemJira],
        },
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasIntegrationReadPermission,
  });

  const [processCaseEvents, { loading: processCaseEventsLoading }] =
    useProcessCaseEventsMutation();

  const integrations = (
    jiraIntegrationsData?.listClientIntegrations?.integrations || []
  )
    .flatMap((a) => a ?? [])
    .filter(
      (i) => i?.configurationOptions?.__typename === "JiraConfigurationOptions",
    );

  const handleProcessClick = async () => {
    if (!isFeatureEnabled("Developer Tools")) return;

    await processCaseEvents({
      variables: {
        input: {
          scopeMrn: space.mrn,
        },
      },
    });
    enqueueSnackbar("Successfully processed case events.", {
      variant: "success",
    });
  };

  if (integrations.length === 0) {
    return (
      <CreateTicketingIntegration
        space={space}
        hasIntegrationCreatePermission={hasIntegrationCreatePermission}
      />
    );
  }

  return (
    <Box>
      {isFeatureEnabled("Developer Tools") && (
        <Flex justifyContent="flex-end">
          <LoadingButton
            loading={processCaseEventsLoading}
            buttonText="Process Case Events"
            variant="contained"
            color="primary"
            startIcon={<Refresh />}
            onClick={handleProcessClick}
            disabled={processCaseEventsLoading}
            data-name="danger-zone-delete-button"
            sx={{ mb: 4 }}
          />
        </Flex>
      )}
      <Box mb={3}>
        <CaseSettingsGeneral space={space} />
      </Box>
      {integrations.map((integration) => (
        <CaseSettingsIntegration
          key={integration.mrn}
          isProjectsEmpty={isProjectsEmpty}
          projects={projects}
          integration={integration}
          space={space}
        />
      ))}
    </Box>
  );
};
