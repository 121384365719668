import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import {
  OrderDirection,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreOrder,
  VulnerabilityScoreOrderField,
  VulnerabilityScoreType,
} from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { VulnerabilitiesTable } from "./VulnerabilitiesTable";
import { useSearch } from "~/components/search/useSearch";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  DisabledScanning,
  NoDataFound,
  NoMatchFilters,
} from "~/pages/inventory/components/EmptyState";
import { Space } from "~/lib/types";
import { useGetSupportVulnerabilities } from ".";

export type CvesPageProps = {
  assetMrn: string;
  space: Space;
  isVulnerabilityScanningEnabled: boolean;
};

export function Vulnerabilities({
  assetMrn,
  space,
  isVulnerabilityScanningEnabled,
}: CvesPageProps) {
  const { supported } = useGetSupportVulnerabilities({ assetMrn });

  const { handleFilterQuery, handleClearSearch, searchFilters } = useSearch();
  const { handleSortClick, orderBy } = useSort<VulnerabilityScoreOrder>({
    defaultSort: {
      field: VulnerabilityScoreOrderField.RiskScore,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_SCORE", "PUBLISHED_DATE", "MRN"],
  });

  const { data, error, loading, fetchMore } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 10,
      orderBy,
      filter: {
        queryTerms: searchFilters,
        scoreType: VulnerabilityScoreType.Cve,
        // state: null,
      },
    },
  });

  if (error) {
    return <LoadingFailedPage what="Vulnerabilities" />;
  }

  if (loading) {
    return <LoadingPage what="Vulnerabilities" />;
  }

  const reportStatus = data?.vulnerabilityScores;
  const hasReport = !!reportStatus;
  const hasVulnerabilities =
    reportStatus?.__typename === "VulnerabilityScoresConnection";

  const vulnerabilityScores =
    data?.vulnerabilityScores?.__typename === "VulnerabilityScoresConnection"
      ? data?.vulnerabilityScores
      : undefined;

  if (!reportStatus) {
    return <LoadingFailedPage what="vulnerabilities" />;
  }

  const noEntriesTitle = hasReport
    ? "You're all set!"
    : "Vulnerability data not available";
  const noEntriesStatus: AlertColor = hasReport ? "success" : "error";

  let noEntriesMessage = <Typography>No vulnerabilities found</Typography>;

  if (reportStatus.__typename === "NotFoundError") {
    noEntriesMessage = (
      <Typography>Mondoo didn't find any packages on this asset.</Typography>
    );
  }

  if (!supported) {
    noEntriesMessage = (
      <Typography>
        Mondoo didn't find any vulnerabilities on this asset because we don't
        yet support the platform. Please let us know what platforms you want
        support for:{" "}
        <Link
          sx={{ textDecoration: "underline", color: "inherit" }}
          href="mailto:hello@mondoo.com"
        >
          hello@mondoo.com
        </Link>
      </Typography>
    );
  }

  const noEntries = (
    <Alert severity={noEntriesStatus} variant="outlined" sx={{ my: 3 }}>
      <AlertTitle>{noEntriesTitle}</AlertTitle>
      {noEntriesMessage}
    </Alert>
  );

  if (!isVulnerabilityScanningEnabled) {
    return (
      <Box mt={3}>
        <DisabledScanning space={space} />
      </Box>
    );
  }

  if (!hasVulnerabilities) return noEntries;

  return (
    <Box>
      <Box>
        <Box mt={5}>
          <FilterBar
            searchId="vulnerabilities-search"
            placeholder="vm_vulnerabilities"
            searchFilters={searchFilters}
            handleFilterQuery={handleFilterQuery}
          />
        </Box>
        {(vulnerabilityScores?.edges?.length || 0) > 0 ? (
          <VulnerabilitiesTable
            onSort={handleSortClick}
            orderBy={orderBy}
            vulnerabilityScores={vulnerabilityScores}
            fetchMore={fetchMore}
          />
        ) : (
          <Grid item xs={12} mt={1}>
            {searchFilters.length > 0 ? (
              // TODO @JAMIE: get count of all vulnerabilities available
              <NoMatchFilters
                title="No vulnerabilities match your filter selection"
                content={`Out of the vulnerabilities affecting this asset, none match the filters you selected. Reset them to reveal all vulnerabilities.`}
                onClick={() => handleClearSearch()}
              />
            ) : (
              <NoDataFound
                title="Good news: No vulnerabilities found!"
                content={`Mondoo scanned this asset and did not find that it's affected by any of the our known vulnerabilities.`}
              />
            )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
