import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PrintAssetSummary } from "./print-asset-summary";
import { PrintPolicies } from "./print-policies";
import { useLoadAssetReportQuery } from "~/operations";
import { AssetReport } from "~/pages/inventory/asset/types";

export type PolicyEdge = NonNullable<
  NonNullable<AssetReport["policies"]>["edges"]
>[0];

type PrintAssetProps = {
  assetMrn: string;
};

export function PrintAsset({ assetMrn }: PrintAssetProps) {
  const spaceId = assetMrn.match(/spaces\/([\w-]+)/)?.[1] || "";
  const assetId = assetMrn.split("/").pop() || "";
  const { data, loading, error } = useLoadAssetReportQuery({
    variables: {
      mrn: assetMrn,
      assetLinkInput: { spaceID: spaceId, assetId },
      assetReportInput: { assetMrn },
    },
  });

  const asset = data?.asset;
  const assetReport = data?.assetReport;

  if (loading) {
    return <LoadingPage what="asset" />;
  }

  if (error || !asset || assetReport?.__typename !== "AssetReport") {
    return <LoadingFailedPage what="asset" />;
  }

  return (
    <>
      <PrintAssetSummary asset={asset} />
      <PrintPolicies asset={asset} assetReport={assetReport} />
    </>
  );
}
