import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { getColor } from "~/lib/colors";

type FirewatchTooltipProps = {
  data: {
    [key: string]: number;
  };
};

export function FirewatchTooltip({ data }: FirewatchTooltipProps) {
  return (
    <Box>
      <Table
        size="small"
        sx={{
          "th, td": {
            fontSize: 10,
            fontWeight: "bold",
            borderBottom: "unset",
            px: 0.5,
            py: 0,
            textAlign: "right",
          },
          thead: { backgroundColor: "unset", boxShadow: "unset" },
          th: {
            color: "text.primary",
            fontSize: 10,
            textTransform: "uppercase",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Score</TableCell>
            <TableCell>Assets</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(data)
            .filter(([_, value]) => value && value > 0)
            .map(([key, value]) => (
              <TableRow
                key={key}
                sx={(theme) => ({
                  td: {
                    color: getColor(theme, key.toLowerCase()),
                    textTransform: "uppercase",
                  },
                })}
              >
                <TableCell>{key}</TableCell>
                <TableCell>{value}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
}
