import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { Space } from "~/lib/types";
import { IntegrationTypeSummary } from "~/operations";
import { formatIntegrationName, mapIntegrationType } from "~/lib/integrations";
import { DynamicIntegrationIcon } from "~/pages/integrations/components/dynamic-integration-icon";
import { WarningAmberIcon } from "~/components/icons";

const TRANSITION_SPEED = "transform 325ms";

type Props = {
  space: Space;
  integration: IntegrationTypeSummary;
};

export function IntegrationSummaryCard({ space, integration }: Props) {
  const href = `/space/integrations/${encodeURIComponent(
    mapIntegrationType(integration.type),
  )}?spaceId=${space.id}`;

  const totalWarningCount = integration.warningCount + integration.failedCount;
  const showWarningIcon = totalWarningCount > 0;

  const warningTooltip = `${totalWarningCount} of your ${formatIntegrationName(
    integration.type,
  )} integrations need attention.`;

  const countsMap = {
    total: integration.totalCount,
    assets: integration.assetsCount,
    policies: integration.policiesCount,
    checks: integration.checksCount,
  };

  return (
    <Box
      component={RouterLink}
      to={href}
      className="integration-card"
      data-name={`existing-${integration.type}-integrations-card-link`}
    >
      <Paper
        sx={{
          p: 3,
          height: 1,
          backgroundColor: "background.light",
          ...(showWarningIcon && {
            border: "1px solid",
            borderColor: "warning.main",
          }),
          "&:hover": {
            transform: "scale(1.05)",
            transition: TRANSITION_SPEED,
            ".gradient-arrow": {
              transform: "translateX(-10px)",
            },
          },
          transition: TRANSITION_SPEED,
        }}
      >
        <Grid
          container
          direction={"column"}
          justifyContent="space-between"
          sx={{ height: 1 }}
        >
          <Grid container item columnGap={3} sx={{ mb: 3 }}>
            <Grid item>
              <DynamicIntegrationIcon
                {...{ type: integration.type, sx: { fontSize: 48 } }}
              />
            </Grid>
            <Grid
              item
              xs
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 1,
              }}
            >
              <Typography
                className="integration-name"
                fontSize={24}
                fontWeight="fontWeightBold"
                lineHeight={"24px"}
              >
                {formatIntegrationName(integration.type)}
              </Typography>
              {showWarningIcon && (
                <NoMaxWidthTooltip title={warningTooltip} placement="top" arrow>
                  <WarningAmberIcon sx={{ color: "warning.main" }} />
                </NoMaxWidthTooltip>
              )}
            </Grid>
          </Grid>
          <Grid container item justifyContent="flex-end">
            {Object.entries(countsMap).map(([key, value]) => (
              <Grid item key={key} xs={3} sx={{ textAlign: "center" }}>
                <Typography
                  fontSize={11}
                  lineHeight="16px"
                  color="text.secondary"
                  sx={{ textTransform: "uppercase", mb: 2 }}
                >
                  {key}
                </Typography>
                <Typography
                  className={"integrations-value"}
                  fontSize={24}
                  lineHeight="15px"
                  fontWeight={800}
                >
                  {value.toLocaleString()}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});
