import { Theme } from "@mui/material";
import { ScoreType } from "./score";

export const getColor = (theme: Theme, label: string, gradient = false) => {
  if (label === undefined || label === null) {
    return "#000000";
  }
  switch (label.toLowerCase()) {
    case "a":
    case "excellent":
    case "none":
    case "pass":
    case "passed":
    case "active":
      return gradient
        ? theme.palette.excellent.gradient
        : theme.palette.excellent.main;
    case "b":
    case "good":
    case "low":
      return gradient ? theme.palette.good.gradient : theme.palette.good.main;
    case "c":
    case "fair":
    case "medium":
    case "not_ready":
    case "waiting_for_setup":
      return gradient ? theme.palette.fair.gradient : theme.palette.fair.main;
    case "d":
    case "poor":
    case "bad":
    case "high":
      return gradient ? theme.palette.poor.gradient : theme.palette.poor.main;
    case "f":
    case "fail":
    case "failed":
    case "critical":
    case "missing":
      return gradient ? theme.palette.fail.gradient : theme.palette.fail.main;
    case "u":
    case "unknown":
    case "unrated":
    case "unscored":
    case "ignored":
    case "info":
    case "data":
    case "snooze":
    case "snoozed":
    case "skip":
      return gradient
        ? theme.palette.unknown.gradient
        : theme.palette.unknown.main;
    case "recommended":
    case "warning":
    case "paused":
    case "security":
      return gradient
        ? theme.palette.warning.gradient
        : theme.palette.warning.main;
    case "x":
    case "errorscan":
      return gradient
        ? theme.palette.errorScan.gradient
        : theme.palette.errorScan.main;
    case "error":
    case "deleted":
      return gradient ? theme.palette.error.gradient : theme.palette.error.main;
    case "-":
    case "disabled":
    case "n":
      return theme.palette.text.disabled;
    case "primarytext":
      return theme.palette.text.primary;
    default:
      return "#000000";
  }
};

export const getColorByCompletion = (
  theme: Theme,
  value: number,
  gradient = false,
) => {
  const status = ScoreType(value);
  return getColor(theme, status, gradient);
};
