import { Box, Divider } from "@mui/material";
import { Flex, PropertyItem, Text, TextTitle } from "~/components/ui-library";
import { Markdown } from "~/components/markdown";
import { Code } from "~/components/code";
import { useQueriesContext } from "~/components/policy-gql/overview";

export function QueriesOverview() {
  const { docs, mql, properties } = useQueriesContext();

  const remediations = docs?.remediations;
  const hasRemediations =
    remediations?.__typename === "Remediations" &&
    remediations.entries.length > 0;

  const hasProperties = properties && properties.length > 0;

  const hasMql = Boolean(mql);

  return (
    <Box>
      {(hasProperties || hasMql) && (
        <Flex center gap={3} my={4}>
          <TextTitle bold>Queries</TextTitle>
          <Box width={1}>
            <Divider />
          </Box>
        </Flex>
      )}
      {hasProperties && (
        <Box>
          <Flex alignItems="center" justifyContent="space-between" my={4}>
            <Text bold>PROPERTIES</Text>
          </Flex>
          {properties.map(({ uid, mql }, key) => (
            <PropertyItem key={key} index={key} uid={uid} mql={mql} />
          ))}
        </Box>
      )}
      {mql && (
        <Box>
          <Flex alignItems="center" justifyContent="space-between" my={3}>
            <Text bold>MQL</Text>
          </Flex>
          <Box>
            <Code copyButton className="coffeescript">
              {mql}
            </Code>
          </Box>
        </Box>
      )}
      {hasRemediations && (
        <Box>
          <Flex center gap={3} my={4}>
            <TextTitle bold>Remediation</TextTitle>
            <Box width={1}>
              <Divider />
            </Box>
          </Flex>
          <Box>
            {remediations.entries.map((remediation) => (
              <Markdown key={remediation.id} source={remediation.desc} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
