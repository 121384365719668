import { Obj, Access, FlagTypes } from "../types";

const getFlags = (registryEntry: Obj): FlagTypes[] => {
  return ["featured", "access"].reduce((acc, val) => {
    const flag = registryEntry[val];

    if (val === "featured" && flag) {
      acc.push(FlagTypes.featured);
    } else if (val === "access" && flag === String(Access.Authenticated)) {
      acc.push(FlagTypes.unlock);
    }

    return acc;
  }, [] as FlagTypes[]);
};

export default getFlags;
