import { SmallDonutCard, useSmallDonut } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { NavDrawerVulnerabilitiesIcon } from "~/components/icons";

export function VulnerabilitiesDonutChart({ space }: DonutChartCardProps) {
  const { chartData, loading } = useSmallDonut({
    space,
    type: "ASSET",
  });

  return (
    <SmallDonutCard
      to={`affected-assets?spaceId=${space.id}`}
      loading={loading}
      data={chartData}
      title="Vulnerable Assets"
      icon={<NavDrawerVulnerabilitiesIcon />}
    />
  );
}
