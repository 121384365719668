import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { Link as RouterLink } from "react-router-dom";
import { Space } from "~/lib/types";
import { CreateTicketingIntegrationFirstStep } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationFirstStep";
import { CreateTicketingIntegrationSecondStep } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationSecondStep";
import { CreateTicketingIntegrationThirdStep } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationThirdStep";

type CreateJiraIntegrationProps = {
  space: Space;
  hasIntegrationCreatePermission: boolean;
};

export const CreateTicketingIntegration = ({
  space,
  hasIntegrationCreatePermission,
}: CreateJiraIntegrationProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Flex justifyContent="space-around" sx={{ mt: 10, gap: 2 }}>
        <Flex flexDirection="column" maxWidth={232}>
          <CreateTicketingIntegrationFirstStep
            sx={{ width: "100%", height: 144 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Set up an integration with the ticketing system of your choice.
          </Typography>
        </Flex>
        <Flex flexDirection="column" maxWidth={232}>
          <CreateTicketingIntegrationSecondStep
            sx={{ width: "100%", height: 144 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Create cases from any findings within Mondoo.
          </Typography>
        </Flex>
        <Flex flexDirection="column" maxWidth={232}>
          <CreateTicketingIntegrationThirdStep
            sx={{ width: "100%", height: 144 }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            As you fix issues, seamlessly sync progress between Mondoo and your
            ticketing system.
          </Typography>
        </Flex>
      </Flex>
      <Flex justifyContent="center" sx={{ mt: 10 }}>
        <Button
          disabled={!hasIntegrationCreatePermission}
          component={RouterLink}
          to={`/space/integrations/add/mondoo/ticket_system_jira?spaceId=${space.id}&redirectTo=/space/cases?spaceId=${space.id}`}
          variant="contained"
          color="primary"
          sx={{
            mt: 5,
            background: (theme) => theme.palette.primary.gradient,
            color: "common.white",
          }}
        >
          Add Integration
        </Button>
        {!hasIntegrationCreatePermission && (
          <Typography component="span" sx={{ fontSize: 10 }} color="error">
            You don't have permission to create an integration.
          </Typography>
        )}
      </Flex>
    </Box>
  );
};
