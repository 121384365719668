import React from "react";
import { ConfigurationListSubheader } from "~/components/configuration-items";
import { Flex, getGraphQLErrors } from "~/components/ui-library";
import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { useViewer } from "~/providers/viewer";
import { Space } from "~/lib/types";
import { SwitchProps } from "@mui/material/Switch/Switch";
import { useSnackbar } from "notistack";

type CaseSettingsGeneralProps = {
  space: Space;
};

export const CaseSettingsGeneral = ({ space }: CaseSettingsGeneralProps) => {
  const { updateSpace } = useViewer();
  const { enqueueSnackbar } = useSnackbar();

  const settings = space.settings;

  const handleAutoCreateChange: SwitchProps["onChange"] = async (e, value) => {
    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings: {
              casesConfiguration: {
                autoCreate: value,
              },
            },
          },
        },
      });

      enqueueSnackbar("Case settings successfully updated", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        getGraphQLErrors({
          error,
          errorMessage: "Failed to update case settings.",
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <Box>
      <ConfigurationListSubheader component="div">
        <Flex
          justifyContent="space-between"
          flex="1 0 auto"
          alignItems="center"
        >
          <Typography component="span" variant="h6">
            General
          </Typography>
        </Flex>
      </ConfigurationListSubheader>
      <Paper sx={{ p: 3 }}>
        <Grid container xs={12} sx={{ pr: 2 }}>
          <Grid item xs>
            <Typography fontWeight={700} className="form-text">
              Automatically create cases on drift
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="form-text"
            >
              Automatically create a case when previously passing checks fail.
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleAutoCreateChange}
                  checked={settings?.casesConfiguration.autoCreate}
                  size="small"
                  disabled={false}
                  sx={{
                    "& .Mui-checked+.MuiSwitch-track": {
                      backgroundColor: "primary.main",
                    },
                  }}
                />
              }
              label={settings?.casesConfiguration.autoCreate ? "On" : "Off"}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
