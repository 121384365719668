import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ResourcesIcon } from "~/components/icons/mondoo/resources";
import { GridSegment, GridData } from "~/pages/dashboard/grid-segment";
import { useGetAssetPolicyDistributionQuery } from "~/operations";
import { useAssetOutlet } from "../../asset";

type Props = {
  sizes: CardSizes;
  assetMrn: string;
  handleCategoryFilterClick: (category: string) => void;
  selectedCategories?: string[];
};

export function AssetPolicyDistributionCard({
  sizes,
  assetMrn,
  handleCategoryFilterClick,
  selectedCategories,
}: Props) {
  const { hasMondooPlatformVulnPolicy } = useAssetOutlet();
  const [gridData, setGridData] = useState<GridData[] | null>(null);
  const { loading, error, data } = useGetAssetPolicyDistributionQuery({
    variables: {
      input: {
        assetMrn,
      },
    },
  });

  useEffect(() => {
    if (
      data?.assetReport?.__typename === "AssetReport" &&
      data?.assetReport?.policyDistribution
    ) {
      const { policyDistribution } = data.assetReport;
      const nextGridData = policyDistribution.map((x) => {
        let distributionGroup = {
          title: x?.category || "",
          score: x?.count || 0,
          total: 0,
        };

        // Until backend removes the platform vuln policy from the count, we are managing it here
        if (
          hasMondooPlatformVulnPolicy &&
          (x?.category === "security" || x?.category === "total")
        ) {
          distributionGroup.total = x?.count > 0 ? x.count - 1 : 0;
          distributionGroup.score = x?.count > 0 ? x.count - 1 : 0;
        }

        return distributionGroup;
      });

      setGridData(nextGridData);
    }
  }, [data]);

  return (
    <HubCard
      {...{
        id: "asset-policy-distribution-card",
        loading,
        sizes,
        title: "Policy Distribution",
        icon: <ResourcesIcon fontSize="small" />,
      }}
    >
      <Grid container spacing={3} sx={{ pt: 2, justifyContent: "flex-end" }}>
        {gridData
          ?.filter((x): x is GridData => x !== null)
          .map((data) => (
            <GridSegment
              key={data.title}
              data={data}
              showTotal={false}
              handleCategoryFilterClick={handleCategoryFilterClick}
              isSelected={selectedCategories?.includes(data.title)}
            />
          ))}
      </Grid>
    </HubCard>
  );
}
