import { Link as RouterLink } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ControlResultIcon } from "~/components/control-result-icon";
import { Loading, LoadingFailed } from "~/components/loading";
import {
  AssetOrderField,
  MqueryAssetScoresQuery,
  OrderDirection,
  useMqueryAssetScoresQuery,
} from "~/operations";
import { getPolicyMrn, getQueryMrn } from "~/utils";
import { ScoreStatus } from "~/lib/score";
import { EmptyState } from "~/components/empty-state/empty-state";
import { LoadMore } from "~/components/load-more";
import { useQueriesContext } from "~/components/policy-gql/overview";

type MqueryAssetScoresConnection = NonNullable<
  MqueryAssetScoresQuery["mqueryAssetScores"]
>;
type MquerySummaryEdge = NonNullable<MqueryAssetScoresConnection["edges"]>[0];
type MquerySummaryNode = NonNullable<MquerySummaryEdge["node"]>;
type QueryAssetScore = NonNullable<MquerySummaryNode["score"]>;

type Props = {};

export function QueriesAssets({}: Props) {
  const { space, namespace, queryId, policyId, queryData } =
    useQueriesContext();

  const policyMrn = getPolicyMrn({
    namespace: namespace,
    uid: policyId.split("/").pop() as string,
  });

  const queryMrn = getQueryMrn({
    namespace: namespace,
    uid: queryId.split("/").pop() as string,
  });

  const { data, error, loading, fetchMore } = useMqueryAssetScoresQuery({
    skip: !queryMrn,
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        orderBy: {
          direction: OrderDirection.Desc,
          field: AssetOrderField.LastUpdated,
        },
        query: null,
        // TODO: once all queries are reported by query mrn instead of
        // code id, we can remove the policyMrn and just use the queryMrn
        policyMrn: policyMrn,
        queryMrn: queryMrn,
        scopeMrn: space.mrn,
      },
      first: 25,
      orderBy: {
        direction: OrderDirection.Desc,
        field: AssetOrderField.LastUpdated,
      },
    },
  });

  const mqueryAssetScores = data?.mqueryAssetScores;
  const endCursor = mqueryAssetScores?.pageInfo.endCursor;

  const handleLoadMore = () => {
    fetchMore({
      variables: { after: endCursor },
    });
  };

  if (error) {
    return <LoadingFailed what="Assets" />;
  }

  if (loading && !mqueryAssetScores) {
    return <Loading what="Assets" />;
  }

  const overallScores = {
    pass: mqueryAssetScores?.scoreDistribution?.pass || 0,
    fail: mqueryAssetScores?.scoreDistribution?.fail || 0,
    error: mqueryAssetScores?.scoreDistribution?.error || 0,
    skip: mqueryAssetScores?.scoreDistribution?.unknown || 0,
  };

  const getPassFail = (score?: QueryAssetScore | null) => {
    const grade = score ? ScoreStatus(score) : "error";
    switch (grade) {
      case "error":
        return "error";
      case "unknown":
        return "pass";
      case "high":
      case "critical":
        return "fail";
      default:
        return "pass";
    }
  };

  const assets = mqueryAssetScores?.edges?.map((edge) => edge?.node);

  return (
    <Box>
      <Box sx={{ display: "flex", mb: 5 }}>
        <Grid container spacing={3}>
          {!overallScores ? (
            <Grid item>
              <Box />
            </Grid>
          ) : (
            <Grid item sx={{ display: "flex" }}>
              {Object.entries(overallScores).map(([key, value], index) => (
                <Box
                  key={key}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mr: index < 3 ? 3 : 0,
                  }}
                >
                  <ControlResultIcon status={key} />
                  <Typography sx={{ ml: 0.5, fontWeight: 500 }}>
                    {value}
                  </Typography>
                </Box>
              ))}
            </Grid>
          )}
        </Grid>
      </Box>
      <Grid container spacing={3}>
        {!assets ? (
          <Grid item xs={12}>
            <EmptyState contentType="security-policy-assets" />
          </Grid>
        ) : (
          assets.map((asset) => {
            const status = getPassFail(asset?.score);
            const assetId = asset?.assetMrn?.split("/").pop();
            const queryLink = queryData?.mrn
              ? `/space/inventory/${assetId}/checks/${queryId}?spaceId=${space.id}&checkMrn=${queryData.mrn}`
              : `/space/inventory/${assetId}/checks?spaceId=${space.id}`;
            return (
              <Grid item xs={12} md={6} lg={4} key={asset?.assetMrn}>
                <Paper
                  component={RouterLink}
                  to={queryLink}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Box sx={{ width: 1 }}>
                    <Typography
                      sx={{
                        display: "block",
                        fontWeight: 700,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        width: { xs: 200, md: 300, lg: 240 },
                      }}
                    >
                      {asset?.assetName}
                    </Typography>
                    <Typography sx={{ fontSize: 12, color: "text.secondary" }}>
                      {asset?.assetPlatform?.name}{" "}
                      {asset?.assetPlatform?.release}
                    </Typography>
                  </Box>
                  <Box>
                    <ControlResultIcon status={status} size="lg" />
                  </Box>
                </Paper>
              </Grid>
            );
          })
        )}
      </Grid>
      <LoadMore
        handleLoadMore={() => handleLoadMore()}
        hasNextPage={Boolean(mqueryAssetScores?.pageInfo?.hasNextPage)}
        loading={loading}
        sx={{ mt: 4 }}
      />
    </Box>
  );
}
