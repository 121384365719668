import React, { Fragment, useState } from "react";
import {
  EntityInfoAsset,
  Icon_Ids,
  OrderDirection,
  RiskFactorStats,
} from "~/operations";
import {
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { DataTable } from "~/components/data-table";
import { Link as RouterLink } from "react-router-dom";
import { CheckboxCell, RiskFactorsCell } from "~/components/Cells";
import { Impact } from "~/components/impact";
import { AssetCell } from "~/components/FirewatchRow/AssetCell";
import { DateCell } from "~/components/FirewatchRow/DateCell";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
  UsePaginationProps,
} from "~/components/pagination";
import { Header } from "~/components/FirewatchPage/FirewatchPageColumns";
import { Space } from "~/lib/types";
import {
  AssetContextualLinkType,
  SelectionProps,
} from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { useAssetContextualLink } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks/useAssetContextualLink";

type HeaderObjects = {
  [key: string]: Header;
};

export type AffectedAsset = {
  score: number;
  lastUpdated: string;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  title?: string;
  tags?: EntityInfoAsset["tags"];
  iconId?: Icon_Ids | null;
  id: string;
  mrn: string;
};

type AffectedAssetsTableProps = {
  assets: Array<AffectedAsset> | undefined;
  selectionProps?: SelectionProps;
  orderBy: {
    field: string;
    direction: string;
  };
  handleSortClick: (field: string) => void;
  space: Space;
  filteredTotalCount: number;
  fetchMore: UsePaginationProps["fetchMore"];
  pageInfo: UsePaginationProps["pageInfo"];
  urlContextType: (typeof AssetContextualLinkType)[keyof typeof AssetContextualLinkType];
  contextId: string;
};

export const AffectedAssetsTable = ({
  selectionProps,
  assets,
  orderBy,
  handleSortClick,
  space,
  filteredTotalCount,
  fetchMore,
  pageInfo,
  urlContextType,
  contextId,
}: AffectedAssetsTableProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const TableColumns: HeaderObjects = {
    Select: {
      id: "SELECT",
      field: "SELECT",
      label: (
        <Checkbox
          checked={
            selectionProps?.selectedEntities?.length === (assets?.length || 0)
          }
          indeterminate={
            (selectionProps?.selectedEntities?.length || 0) > 0 &&
            (selectionProps?.selectedEntities?.length || 0) <
              (assets?.length || 0)
          }
          onChange={(_, checked) => selectionProps?.onCheckAll(checked, assets)}
        />
      ),
      sortable: false,
      options: {},
    },
    Impact: {
      id: "IMPACT",
      field: "RISK_SCORE", // Risk score is the appropriate field for impact component for sorting purposes
      label: "Risk",
      sortable: true,
      options: { width: 130 },
    },
    Asset: {
      id: "ASSET",
      field: "TITLE",
      label: "Asset",
      sortable: true,
    },
    RiskFactors: {
      id: "RISK_FACTORS",
      field: "RISK_FACTORS",
      label: "Risk Factors",
      sortable: true,
      options: { width: 180 },
    },
    LastUpdated: {
      id: "LAST_UPDATED",
      field: "LAST_UPDATED",
      label: "Last Updated",
      sortable: true,
      options: { width: 180 },
    },
  };

  const tableHeaders =
    [
      selectionProps ? TableColumns.Select : undefined,
      TableColumns.Impact,
      TableColumns.Asset,
      TableColumns.RiskFactors,
      TableColumns.LastUpdated,
    ].flatMap((e) => e ?? []) || [];

  return (
    <Fragment>
      <DataTable
        id="affected-assets-list"
        selectable={
          selectionProps ? selectionProps.hasSelectPermissions : false
        }
        selection={selectionProps?.selectedEntities}
      >
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => {
              return (
                <TableCell
                  key={header.field}
                  align={header.options?.textAlign}
                  width={header.options?.width}
                  sx={header.options?.sx}
                >
                  {header.sortable ? (
                    <TableSortLabel
                      onClick={() => handleSortClick(header.field)}
                      direction={
                        orderBy.direction === OrderDirection.Asc
                          ? "asc"
                          : "desc"
                      }
                      active={header.field === orderBy.field}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {assets?.slice(pageItems.from, pageItems.to).map((asset) => {
            const { contextUrl } = useAssetContextualLink({
              assetId: asset.id,
              space,
              type: urlContextType,
              contextId: contextId,
            });
            const riskFactors = asset.riskFactors;
            const score = asset.score;

            return (
              <TableRow key={asset.id} component={RouterLink} to={contextUrl}>
                {selectionProps && (
                  <CheckboxCell
                    onClick={(e) => selectionProps?.onCheckboxClick(e)}
                    onChange={(e, changed) =>
                      selectionProps?.onCheckboxChange(e, changed, asset)
                    }
                    checked={selectionProps.getIsRowSelected(asset?.mrn || "")}
                  />
                )}
                {/* Risk */}
                <TableCell>
                  <Impact impact={score} isRiskScore />
                </TableCell>

                {/* Asset */}
                <AssetCell
                  title={asset.title}
                  iconId={asset.iconId}
                  tags={asset.tags}
                />

                {/* Risk Factors */}
                <RiskFactorsCell riskFactors={riskFactors} />

                {/* Last Updated */}
                <DateCell date={asset.lastUpdated} />
              </TableRow>
            );
          })}
        </TableBody>
        {selectionProps?.renderToolbar?.(filteredTotalCount || 0)}
      </DataTable>
      <Pagination
        fetchMore={fetchMore}
        totalCount={filteredTotalCount || 0}
        setPageItems={setPageItems}
        pageInfo={pageInfo}
      />
    </Fragment>
  );
};
