import React, { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";

type CheckScoreItemProps = {
  key: string;
  title: string;
  component?: ReactNode;
  amount?: ReactNode;
};

export const ScoreLayout = ({
  key,
  title,
  component,
  amount,
}: CheckScoreItemProps) => {
  return (
    <Grid item container key={key}>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "left",
          flex: "1 0 auto!important",
        }}
        gap={1}
      >
        <Typography sx={{ fontWeight: 700, color: "text.secondary" }}>
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {component}
      </Grid>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          flexGrow: "0",
        }}
      >
        {amount}
      </Grid>
    </Grid>
  );
};
