import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Divider,
  ButtonGroup,
  alpha,
  Button,
} from "@mui/material";
import { useAffectedResources } from "./use-affected-resources";

export function AffectedResourcesHeader() {
  const { header, activeTab, space, affectedResourcePath } =
    useAffectedResources();

  return (
    <Grid container item alignItems="center" sx={{ mt: 5 }}>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Box id="affected-resources">
          <Typography
            sx={{
              display: "inline-block",
              pr: 3,
              fontWeight: 700,
            }}
          >
            {header}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      {/* ToDo: How do I know if a resource view should exist? */}
      {/* {true && (
        <Grid item xs="auto">
          <ButtonGroup
            variant="outlined"
            aria-label="outlined primary button group"
            sx={{
              "& .active": {
                backgroundColor: (theme) =>
                  alpha(theme.palette.text.primary, 0.16),
              },
            }}
          >
            <Button
              className={activeTab === "list" ? "active" : ""}
              component={RouterLink}
              to={`${affectedResourcePath}/list?spaceId=${space.id}`}
              sx={{
                color: "text.primary",
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.12),
                fontWeight: 400,
              }}
            >
              List View
            </Button>
            <Button
              className={activeTab === "resource" ? "active" : ""}
              component={RouterLink}
              to={`${affectedResourcePath}/resource?spaceId=${space.id}`}
              sx={{
                color: "text.primary",
                borderColor: (theme) => alpha(theme.palette.text.primary, 0.12),
                fontWeight: 400,
              }}
            >
              Resource View
            </Button>
          </ButtonGroup>
        </Grid>
      )} */}
    </Grid>
  );
}
