import { Box } from "@mui/material";
import { useImpactRating } from "./use-impact-rating";
import { ImpactRatingUnit } from "./impact-rating-unit";
import { ImpactData } from "./types";
import { ImpactRatingOptions } from "~/components/impact/impact";

type ImpactCirclesProps = {
  impactData: ImpactData;
  color: string;
  options?: ImpactRatingOptions;
};

export const ImpactRating = ({
  impactData,
  color,
  options,
}: ImpactCirclesProps) => {
  const { paintedImpactRating, gap } = useImpactRating({
    impactData,
    color,
    options,
  });

  return (
    <Box display="flex" gap={`${gap}px`}>
      {paintedImpactRating.map((paintedImpactRatingUnit, index) => (
        <ImpactRatingUnit
          key={index}
          backgroundColor={paintedImpactRatingUnit.backgroundColor}
          borderColor={paintedImpactRatingUnit.borderColor}
          options={options}
        />
      ))}
    </Box>
  );
};
