import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Grid,
  GridSize,
  Paper,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

export type CardSizes = {
  xs: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
};

type Props = {
  id?: string;
  children?: ReactNode;
  loading: boolean;
  title: string | ReactNode;
  /*
  the titleTo prop is a URL.
  if a titleTo prop is supplied, the Title will hyperlink to the URL provided*/
  titleTo?: string;
  tagline?: string;
  icon?: JSX.Element;
  sizes?: CardSizes;
  to?: string;
  PaperProps?: SxProps;
  sx?: SxProps<Theme>;
};

export function HubCard({
  id,
  children,
  loading,
  title,
  tagline,
  icon,
  sizes,
  to,
  titleTo,
  PaperProps,
  sx,
}: Props) {
  return (
    <Grid
      id={id}
      container
      item
      {...(to ? { component: RouterLink, to: to } : {})}
      {...sizes}
      sx={[
        { position: "relative", height: "auto" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Paper
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          p: 3,
          minWidth: 0,
          width: 1,
          ...PaperProps,
        }}
      >
        <Grid container item>
          <Grid item xs>
            <Typography
              variant="h5"
              {...titleStyling}
              {...(titleTo ? { component: RouterLink, to: titleTo } : {})}
            >
              {title}
            </Typography>
            {tagline && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {tagline}
              </Typography>
            )}
          </Grid>
          {icon && (
            <Grid item sx={{ p: 0.5 }}>
              {icon}
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "start",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 4,
              }}
            >
              <CircularProgress size={28} />
            </Box>
          ) : (
            children
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}

const titleStyling = {
  sx: { textTransform: "uppercase", mb: -0.5, fontWeight: 700 },
};

type LinkedTitleProps = { to: string; title: string };
export function LinkedTitle({ to, title }: LinkedTitleProps) {
  return (
    <Typography variant="h5" {...titleStyling} component={RouterLink} to={to}>
      {title}
    </Typography>
  );
}
