import { Link as RouterLink, Outlet, useOutletContext } from "react-router-dom";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Space } from "~/lib/types";
import { HomeIcon } from "~/components/icons";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { TestIamActionsQuery } from "~/operations";
import { isFeatureEnabled } from "~/login/features";

export interface SpaceSettingsProps {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
}

export interface SpaceSettingsState {
  nameErrMsg: string;
  isDeleting: boolean;
  isDeletingAssets: boolean;
}

type SpaceSettingsOutletContextType = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function SpaceSettings({
  space,
  availablePermissions,
}: SpaceSettingsProps) {
  const href = (tab: string) => {
    return `/space/settings/${tab}?spaceId=${space.id}`;
  };

  const tabList: TabListItem[] = [
    { label: "General Settings", to: href("general"), route: "/general" },
    isFeatureEnabled("Cases")
      ? { label: "Cases", to: href("cases"), route: "/cases" }
      : undefined,
    { label: "Members", to: href("members"), route: "/members" },
    { label: "Audit Log", to: href("auditlog"), route: "/auditlog" },
    {
      label: "Service Accounts",
      to: href("serviceaccounts"),
      route: "/serviceaccounts",
    },
    {
      label: "Registration Tokens",
      to: href("registration-tokens"),
      route: "/registration-tokens",
    },
    {
      label: "API Tokens",
      to: href("api-tokens"),
      route: "/api-tokens",
    },
  ].flatMap((tab) => (tab ? [tab] : []));

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "general",
  );

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={1}>Settings</Typography>,
  ];

  document.title = `Settings · Mondoo`;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
        {/* Heading */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            Space Settings
          </Typography>
        </Box>
        <TabNavigation
          {...{ id: "space-settings-tabs", tabList, currentTab }}
        />
      </Grid>
      <Grid item xs>
        <Outlet context={{ space, availablePermissions }} />
      </Grid>
    </Grid>
  );
}

export function useSpaceSettingsOutletContext() {
  return useOutletContext<SpaceSettingsOutletContextType>();
}
