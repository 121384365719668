import { Box, useTheme } from "@mui/material";
import { ChartData, DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { getColor } from "~/lib/colors";
import { Policies } from "../../components/Policies/types";
import { isPlatformVulnPolicy } from "~/lib/isPlatformVulnerabilityPolicy";

type Props = {
  sizes: CardSizes;
  policies: Policies;
  handleGradeFilterClick?: (data: ChartData) => void;
  selectedGrades?: string[];
};

export function AssetPolicyOverviewCard({
  sizes,
  policies,
  handleGradeFilterClick,
  selectedGrades,
}: Props) {
  const theme = useTheme();

  const d = policies?.edges
    // filter out platform vuln policy
    .filter((edge) => !isPlatformVulnPolicy(edge.node.mrn))
    .reduce(
      (acc, edge) => {
        const grade = edge.node.score.grade;
        if (grade) {
          if (acc[grade]) {
            acc[grade] += 1;
          } else {
            acc[grade] = 1;
          }
        }
        return acc;
      },
      {} as { [key: string]: number },
    );

  const graphData = [
    { label: "A", value: d?.A || 0, color: getColor(theme, "A") },
    { label: "B", value: d?.B || 0, color: getColor(theme, "B") },
    { label: "C", value: d?.C || 0, color: getColor(theme, "C") },
    { label: "D", value: d?.D || 0, color: getColor(theme, "D") },
    { label: "F", value: d?.F || 0, color: getColor(theme, "F") },
    { label: "Unrated", value: d?.U || 0, color: getColor(theme, "U") },
    { label: "Error", value: d?.X || 0, color: getColor(theme, "X") },
  ];
  return (
    <HubCard
      {...{
        id: "asset-policy-overview-card",
        loading: false,
        sizes,
        title: "Policy Overview",
        icon: <NavDrawerPolicyIcon fontSize="small" />,
        PaperProps: { maxHeight: "264px" },
      }}
    >
      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DonutChart
          data={graphData}
          dataType="policies"
          onSectionClick={handleGradeFilterClick}
          selectedSections={selectedGrades}
        />
      </Box>
    </HubCard>
  );
}
